import React, { useState, useRef } from "react";
import { connect, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { getUser } from "../../utils/User";
import Input from "../../components/forms/Input";
import Button from "../../components/buttons/Button";

// ACTION REDUX
import AuthService from "../../store/actions/auth";

const ForgotPassword = ({ t, history }) => {
  const formikRef = useRef();
  const dispatch = useDispatch();

  const validation = Yup.object().shape({
    email: Yup.string()
      .email("Email yang Anda masukkan tidak valid")
      .required("Required")
      .nullable(),
  });

  return (
    <div className="main-wrapper">
      <div className="login-container">
        <div className="content-wrapper">
          <div className="title-icon" onClick={() => history.push("/")}>
            <span className="material-icons ">arrow_back</span>
          </div>
          <p className="super-title text-extra-bold">Lupa Kata Sandi</p>
          <p className="subtitle text-medium" style={{ marginBottom: 50 }}>
            Masukkan email terdaftar untuk mereset kata sandi
          </p>
          <div className="form-wrapper">
            <Formik
              innerRef={formikRef}
              initialValues={{
                email: "",
              }}
              validationSchema={validation}
              onSubmit={(values, { setStatus, setSubmitting }) => {
                setStatus();
                dispatch(AuthService.forgotPassword(values));
              }}
            >
              {(props) => (
                <Form>
                  <Input
                    title="Email"
                    name="email"
                    type="text"
                    placeholder="Masukkan Email"
                    errors={props.errors}
                    touched={props.touched}
                    {...props.getFieldProps("email")}
                  />
                  <div className="button-wrapper" style={{ marginTop: "30px" }}>
                    <Button
                      title="Kirimkan"
                      rounded
                      type="Submit"
                      variant="primary"
                      style={{ width: "100%", textAlign: "center" }}
                    />
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
        <div className="side-login"></div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ user }) => {
  return { user };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ForgotPassword)
);
