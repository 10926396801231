import {
  POST_AUTH_LOGIN_PENDING,
  POST_AUTH_LOGIN_SUCCESS,
  POST_AUTH_LOGIN_ERROR,
  POST_AUTH_FORGOT_PENDING,
  POST_AUTH_FORGOT_SUCCESS,
  POST_AUTH_FORGOT_ERROR,
} from "../../actions/auth";

const user = JSON.parse(localStorage.getItem("user_aws"));
const initialState = user
  ? { loggedIn: true, user }
  : {
      loggingIn: false,
    };

const auth_login = (state = initialState, action) => {
  switch (action.type) {
    case POST_AUTH_LOGIN_PENDING:
      return {
        loggingIn: true,
      };
    case POST_AUTH_LOGIN_SUCCESS:
      return {
        loggedIn: true,
        loggingIn: false,
        user: action.data,
      };
    case POST_AUTH_LOGIN_ERROR:
      return {};
    case POST_AUTH_FORGOT_PENDING:
      return {
        loggingIn: true,
      };
    case POST_AUTH_FORGOT_SUCCESS:
      return {
        loggedIn: true,
        loggingIn: false,
        user: action.data,
      };
    case POST_AUTH_FORGOT_ERROR:
      return {};
    default:
      return state;
  }
};

export default auth_login;
