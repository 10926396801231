import { createTheme, ThemeProvider } from "@material-ui/core/styles"
import id from "date-fns/locale/id"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"

const theme = createTheme({
  palette: {
    primary: {
      main: "#04AA59",
    },
  },
  typography: {
    fontFamily: ["PlusJakartaSans-Regular", "-apple-system", "BlinkMacSystemFont", '"Segoe UI"', "Roboto", '"Helvetica Neue"', "Arial", "sans-serif", '"Apple Color Emoji"', '"Segoe UI Emoji"', '"Segoe UI Symbol"'].join(","),
  },
})

function BasicDateDatePicker({ autoOk, variant, inputVariant, value, onChange, disabled, format, orientation, errors, touched, name, className, title, fullWidth, minDate, maxDate, disablePast, disableFuture, views, label }) {
  const error = errors && name ? errors[name] : ""
  const touchs = touched && name ? touched[name] : ""

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDateFns} localeText={id}>
        <div
          className={`form-group ${className}`}
          style={{
            fontFamily: "sans-serif",
          }}
        >
          {title && (
            <label className="form-label" style={{ textAlign: "left", display: "block" }}>
              {title}
            </label>
          )}
          <DatePicker
            sx={{ width: "100%" }}
            views={views || ["year", "month"]}
            autoOk={autoOk || true}
            label={label}
            disableFuture={disableFuture}
            disablePast={disablePast}
            inputVariant={inputVariant || "filled"}
            onChange={onChange}
            disabled={disabled}
            format={format}
            minDate={minDate}
            maxDate={maxDate}
            orientation={orientation || "portrait"}
            fullWidth={fullWidth || true}
            error={touchs && error ? true : false}
            value={value}
            variant={variant || "inline"}
          />
          {touchs && error && (
            <div className="invalid-feedback" style={{ textAlign: "left", display: "block" }}>
              {error}
            </div>
          )}
        </div>
      </LocalizationProvider>
    </ThemeProvider>
  )
}

export default BasicDateDatePicker
