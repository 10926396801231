import {
  GET_INPUT_PENDING,
  GET_INPUT_SUCCESS,
  GET_INPUT_ERROR,
  POST_INPUT_PENDING,
  POST_INPUT_SUCCESS,
  POST_INPUT_ERROR,
  DELETE_INPUT_PENDING,
  DELETE_INPUT_SUCCESS,
  DELETE_INPUT_ERROR,
} from "../../../actions/master_data/input";

const initialState = {
  pending: false,
  error: null,
  data_list: null,
  pending_post: false,
  data_post: null,
  pending_delete: false,
  data_delete: null,
};

const inputs = (state = initialState, action) => {
  switch (action.type) {
    case GET_INPUT_PENDING:
      return {
        ...state,
        pending: true,
      };
    case GET_INPUT_SUCCESS:
      return {
        ...state,
        pending: false,
        data_list: action.data,
      };
    case GET_INPUT_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case POST_INPUT_PENDING:
      return {
        ...state,
        pending_post: true,
      };
    case POST_INPUT_SUCCESS:
      return {
        ...state,
        pending_post: false,
        data_post: action.data,
      };
    case POST_INPUT_ERROR:
      return {
        ...state,
        pending_post: false,
        error: action.error,
      };
    case DELETE_INPUT_PENDING:
      return {
        ...state,
        pending_delete: true,
      };
    case DELETE_INPUT_SUCCESS:
      return {
        ...state,
        pending_delete: false,
        data_delete: action.data,
      };
    case DELETE_INPUT_ERROR:
      return {
        ...state,
        pending_delete: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default inputs;
