import { useState, useEffect, useRef } from "react";
import SideMenu from "../../components/commons/menu/SideMenu";
import HeaderMenu from "../../components/commons/menu/HeaderMenu";
import Button from "../../components/buttons/Button";
import SearchButton from "../../components/buttons/SearchButton";
import { getUser } from "../../utils/User";
import { Role } from "../../utils/Constants";
import { toastSuccess } from "../../components/commons/toast";

// side menu contents
// ------------------
import AuditTrail from "./audit_trail";
import UserManagement from "./user_management";
import NotificationManagement from "./notification_management";

const Index = () => {
  const currentUser = getUser().user;
  const [isEditNotification, setIsEditNotification] = useState(false);
  const [isCancelNotification, setIsCancelNotification] = useState(false);
  const [isSaveNotification, setIsSaveNotification] = useState(false);
  const [showContentSideMenu, setShowContentSideMenu] =
    useState("user_management");

  // refs
  // ----
  const userManagementRef = useRef();
  const auditTrailRef = useRef();

  // modal toggles
  // -------------
  const toggleModalFilterUser = () => {
    userManagementRef.current.toggleModalFilterUser();
  };

  const toggleModalAddUser = () => {
    userManagementRef.current.toggleModalAddUser();
  };

  const toggleModalFilterAuditTrail = () => {
    auditTrailRef.current.toggleModalFilter();
  };
  // --------------------------------------------------------------------------------

  const triggerSearch = (value) => {
    userManagementRef.current.handleSearch(value);
  };

  useEffect(() => {
    if (isCancelNotification) {
      setIsCancelNotification(false);
    }
  }, [isCancelNotification]);

  useEffect(() => {
    const justLogin = localStorage.getItem("just_login");

    if (justLogin && currentUser.role_code === Role.It_admin) {
      toastSuccess(`Selamat Anda berhasil login sebagai ${justLogin}`);

      localStorage.removeItem("just_login");
    }
  }, []);

  return (
    <div className="menu-container">
      <div className="grid-header">
        <div className="side-menu">
          <div className="menu-title text-extra-bold">Sistem</div>
          <SideMenu
            title="Manajemen User"
            subtitle="Daftar User"
            active={showContentSideMenu === "user_management"}
            toggle={() => {
              setShowContentSideMenu("user_management");
              setIsEditNotification(false);
              setIsCancelNotification(false);
              setIsSaveNotification(false);
            }}
          />
          <SideMenu
            title="Audit Trail"
            subtitle="Log Aktivitas User"
            active={showContentSideMenu === "audit_trail"}
            toggle={() => {
              setShowContentSideMenu("audit_trail");
              setIsEditNotification(false);
              setIsCancelNotification(false);
              setIsSaveNotification(false);
            }}
          />
          {currentUser.role_code === Role.Superadmin && (
            <SideMenu
              title="Notifikasi"
              subtitle="Manajemen Notifikasi"
              active={showContentSideMenu === "notification_management"}
              toggle={() => setShowContentSideMenu("notification_management")}
            />
          )}
        </div>
        {showContentSideMenu === "user_management" && (
          <>
            <div>
              <HeaderMenu title="Manajemen User">
                <SearchButton
                  placeholder="Cari Nama User"
                  toggle={(value) => triggerSearch(value)}
                  optClass={"mr-3"}
                />

                <Button
                  title="Filter"
                  size="lg"
                  style={{
                    minWidth: "35px",
                  }}
                  rounded={true}
                  variant="button-light"
                  onClick={toggleModalFilterUser}
                />
                {currentUser.role_code !== Role.Kadiv && (
                  <>
                    <div className="nav-separator mr-3"></div>

                    <Button
                      title="Tambah User"
                      leftIcon="add"
                      size="md"
                      variant="btn-lg button-default"
                      rounded={true}
                      onClick={toggleModalAddUser}
                      disabled={currentUser.role_code === Role.Kadiv}
                    />
                  </>
                )}
              </HeaderMenu>
            </div>
            <UserManagement ref={userManagementRef} />
          </>
        )}
        {showContentSideMenu === "audit_trail" && (
          <>
            <div>
              <HeaderMenu title="Audit Trail">
                <Button
                  title="Filter"
                  size="lg"
                  style={{
                    minWidth: "35px",
                  }}
                  rounded={true}
                  variant="button-light"
                  onClick={toggleModalFilterAuditTrail}
                />
              </HeaderMenu>
            </div>
            <AuditTrail ref={auditTrailRef} />
          </>
        )}
        {showContentSideMenu === "notification_management" && (
          <>
            <HeaderMenu
              goBack={isEditNotification}
              title={isEditNotification ? "Edit Notifikasi" : "Notifikasi"}
            >
              {isEditNotification ? (
                <>
                  <Button
                    title="Batal"
                    size="md"
                    variant="btn-lg button-light"
                    rounded={true}
                    disabled={isSaveNotification}
                    onClick={() => {
                      setIsCancelNotification(true);
                      setIsEditNotification(false);
                    }}
                  />
                  <div className="mr-3" />
                  <Button
                    title="Simpan"
                    size="md"
                    variant="btn-lg button-default"
                    rounded={true}
                    disabled={isSaveNotification}
                    isLoading={isSaveNotification}
                    onClick={() => setIsSaveNotification(true)}
                  />
                </>
              ) : (
                <Button
                  title="Edit Notifikasi"
                  leftIcon="edit"
                  size="md"
                  variant="btn-lg button-default"
                  rounded={true}
                  onClick={() => setIsEditNotification(true)}
                />
              )}
            </HeaderMenu>
            <NotificationManagement
              toggleEdit={setIsEditNotification}
              toggleSave={setIsSaveNotification}
              isEdit={isEditNotification}
              isCancel={isCancelNotification}
              isSave={isSaveNotification}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default Index;
