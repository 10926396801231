import { useState } from "react";

const Index = ({ placeholder, toggle, trigger, value }) => {
  const [show, setShow] = useState(false);
  const [val, setVal] = useState(value);

  function handleInputChange(event) {
    if (toggle) {
      toggle(event.target.value);
    }
    setVal(event.target.value);
  }

  function handleTriggerEnter(event) {
    if (trigger) {
      trigger(val);
    }
  }

  return (
    <div className="search-container search-button-default">
      <input
        className={`input-search default`}
        placeholder={placeholder}
        onChange={handleInputChange}
        onKeyPress={(event) => {
          if (event.key === "Enter") {
            handleTriggerEnter(event);
          }
        }}
        value={value}
      />
      <div
        className="search-ico-left search-toggle mini-hide"
        style={{
          cursor: "pointer",
          position: "absolute",
          left: "0px",
          padding: "14px 0px !important",
        }}
        onClick={() => setShow(!show)}
      >
        <span className="material-icons-round icon-only mini-hide">search</span>
      </div>

      {/* Showing search bar */}
      <div
        className="mini-show"
        style={{ position: "relative", width: "100%" }}
      >
        <input
          className="input-search"
          placeholder={placeholder}
          onChange={handleInputChange}
          onKeyUp={handleTriggerEnter}
        />
        <div
          className="button search-toggle"
          style={{
            cursor: "pointer",
            position: "absolute",
            marginLeft: "-50px",
          }}
        >
          <span className="material-icons-round icon-only">search</span>
        </div>
      </div>
    </div>
  );
};

export default Index;
