import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Row,
  Col,
  Label,
} from "reactstrap";
import { Formik, Form } from "formik";
import { getUser } from "../../utils/User";
import * as Yup from "yup";
import moment from "moment";

import DynamicButton from "../../components/forms/DynamicButton";
import ButtonModal from "../../components/modal/ButtonModal";
import Input from "../../components/forms/Input";
import { toastError, toastSuccess } from "../../components/commons/toast";

function ModalRepair({ show, toggle, data, confirm, refresh, repairWork }) {
  const formikRef = useRef();
  const fileRef = useRef();
  const timezone = moment(new Date()).format("Z");
  const [repair, setRepair] = useState([]);
  const [fileProp, setFileProp] = useState({
    title: "Berkas Perbaikan",
    subtitle: "File",
    message_error: false,
  });

  const validation = Yup.object().shape({
    date_repairing: Yup.string()
      .required(
        `Tanggal ${data?.status === 0 ? "mulai" : "selesai"} wajib diisi`
      )
      .nullable(),
    time: Yup.string()
      .required(`Waktu ${data?.status === 0 ? "mulai" : "selesai"} wajib diisi`)
      .nullable(),
  });

  useEffect(() => {
    if (data) {
      setRepair(data);
    }
  }, [data]);

  const currentUser = getUser();

  const title = data?.status === 0 ? "Mulai Perbaikan" : "Selesai Perbaikan";

  const handleConfirm = () => {
    setFileProp({
      title: "Berkas Perbaikan",
      subtitle: "File",
      message_error: false,
    });
    toggle();
  };

  const callback = () => {
    setFileProp({
      title: "Berkas Perbaikan",
      subtitle: "File",
      message_error: false,
    });
    toastSuccess("Berhasil ubah status laporan");
    toggle();
    refresh();
  };

  const openFileDialog = () => {
    fileRef.current.click();
  };

  const handleChange = (selectorFiles, formik) => {
    if (Number(selectorFiles.files[0].size) < 15728640) {
      formik.setFieldValue("reparation_file", selectorFiles.files[0]);
      setFileProp({
        title: selectorFiles.files[0].name,
        subtitle:
          (Number(selectorFiles.files[0].size) * 0.001).toFixed(2) + " KB",
        message_error: false,
      });
    } else {
      toastError("Maksimal file yang dapat diimport 15 MB");
    }
  };

  const handleFile = (formik) => {
    if (data?.status === 1) {
      if (!formik.values.reparation_file) {
        setFileProp({
          title: "Berkas Perbaikan",
          subtitle: "File",
          message_error: "File wajib diisi",
        });
      }
    }
  };

  return (
    <>
      <Modal
        isOpen={show}
        modalTransition={{ timeout: 200 }}
        backdropTransition={{ timeout: 1300 }}
      >
        <ModalHeader className="text-extra-bold pt-5">
          {" "}
          {title}{" "}
          <span className="close clickable" onClick={() => handleConfirm()}>
            &times;
          </span>
        </ModalHeader>
        <Formik
          initialValues={{
            date_repairing: "",
            time: "",
          }}
          validationSchema={validation}
          onSubmit={(values, { setStatus }) => {
            setStatus();

            let date_format =
              values?.date_repairing +
              "T" +
              values?.time +
              ":00.000" +
              timezone;
            let valid = true;
            values.id = data?.id;
            if (data?.status === 1) {
              values.finish_repairing_at = date_format;
              if (!values?.reparation_file) {
                setFileProp({
                  title: "Berkas Perbaikan",
                  subtitle: "File",
                  message_error: "File wajib diisi",
                });
                valid = false;
              }
            } else {
              values.start_repairing_at = date_format;
            }

            if (valid) {
              let start_date =
                data?.status === 0
                  ? moment(data.reported_at).format("YYYY-MM-DD") +
                    "T" +
                    moment(data.reported_at).format("HH:mm") +
                    ":00.000" +
                    timezone
                  : moment(data.start_repairing_at).format("YYYY-MM-DD") +
                    "T" +
                    moment(data.start_repairing_at).format("HH:mm") +
                    ":00.000" +
                    timezone;
              let end_date =
                data?.status === 0
                  ? values.start_repairing_at
                  : values.finish_repairing_at;
              if (
                Date.parse(end_date.toString()) <
                Date.parse(start_date.toString())
              ) {
                toastError(
                  `${
                    data?.status === 0
                      ? "Tanggal perbaikan"
                      : "Tanggal selesai perbaikan"
                  } tidak boleh kurang dari ${
                    data?.status === 0
                      ? "tanggal lapor"
                      : "tanggal mulai perbaikan"
                  }`
                );
              } else {
                let formData = new FormData();
                Object.keys(values).forEach(function eachKey(key) {
                  formData.append(key, values[key]);
                });

                confirm(formData, callback);
              }
            }
          }}
        >
          {(props) => (
            <Form style={{ marginTop: 30 }}>
              <ModalBody style={{ margin: "0px 20px" }}>
                <FormGroup>
                  <Label className="form-label">{`Waktu ${
                    data?.status === 0 ? "Mulai" : "Selesai"
                  } Perbaikan`}</Label>
                  <Row>
                    <Col md={6}>
                      <Input
                        name="date_repairing"
                        type="date"
                        errors={props?.errors}
                        touched={props?.touched}
                        min={
                          data?.status === 0 && repair?.reported_at
                            ? moment(repair?.reported_at).format("YYYY-MM-DD")
                            : data?.status === 1 && repair?.start_repairing_at
                            ? moment(repair?.start_repairing_at).format(
                                "YYYY-MM-DD"
                              )
                            : ""
                        }
                        {...props?.getFieldProps("date_repairing")}
                        value={props.values?.date_repairing}
                      />
                    </Col>
                    <Col md={6}>
                      <Input
                        name="time"
                        type="time"
                        errors={props?.errors}
                        touched={props?.touched}
                        {...props?.getFieldProps("time")}
                        value={props.values?.time}
                      />
                    </Col>
                  </Row>
                </FormGroup>
                {data?.status === 1 && (
                  <DynamicButton
                    titleLeft={fileProp?.title}
                    subtitleLeft={fileProp?.subtitle}
                    iconLeft="insert_drive_file"
                    toggle={openFileDialog}
                    error={fileProp?.message_error}
                  />
                )}
                <input
                  type="file"
                  ref={fileRef}
                  style={{ display: "none" }}
                  onChange={(e) => handleChange(e.target, props)}
                  accept="image/png, image/jpeg"
                />
              </ModalBody>
              <ModalFooter>
                <ButtonModal
                  toggle={() => handleConfirm()}
                  confirmTitle="Simpan"
                  confirm={() => handleFile(props)}
                  isLoading={repairWork.pending_put}
                  disabledConfrim={repairWork.pending_put}
                />
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
}

const mapStateToProps = ({ masterDataPDAM, repairWork }) => {
  return { masterDataPDAM, repairWork };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalRepair);
