import React, { useEffect, useState } from 'react'
import { Formik, Form } from 'formik';
import {
    Modal,
    ModalHeader,
    ModalBody,
} from 'reactstrap';
import { connect, useDispatch } from "react-redux";
import Select2 from '../../../components/forms/Select2';
import ButtonModal from '../../../components/modal/ButtonModal';
import DynamicButton from '../../../components/forms/DynamicButton';
import Checkbox from '../../../components/forms/Checkbox';

import { getUser } from '../../../utils/User';

import InputService from "../../../store/actions/master_data/input";
import ZonaService from "../../../store/actions/master_data/zone";
import DMAService from "../../../store/actions/master_data/dma";

function ModalFilter({
    show,
    toggle,
    filter,
    toggleFilter,
    isDelete,
    masterDataDMA,
    masterDataPDAM,
    masterDataZone,
    masterDataInput
}) {

    const currentUser = getUser();
    const dispatch = useDispatch()
    const [selectedPDAM, setSelectedPDAM] = useState(filter?.pdam_id ?? currentUser?.user?.pdam?.id ?? '')
    const [selectedZone, setSelectedZone] = useState(filter?.zone_id ?? '')
    const [selectedInput, setSelectedInput] = useState(filter?.input_id ?? '')
    const [input, setInput] = useState([]);
    const [pdam, setPDAM] = useState([])
    const [zone, setZone] = useState([]);
    const [dma, setDMA] = useState([])

    const handleReset = (formik) => {
        formik.setFieldValue('pdam', currentUser?.user?.pdam?.id ?? '')
        formik.setFieldValue('input', '')
        formik.setFieldValue('dma', '')
        formik.setFieldValue('zone', '')
        formik.setFieldValue('status', '')
        setSelectedPDAM(currentUser?.user?.pdam?.id ?? '')
        setSelectedZone('')
        setSelectedInput('')
        toggleFilter({ pdam_id: currentUser?.user?.pdam?.id ?? '', input_id: '', zone_id: '', dma_id: '', status: '' })
        toggle()
    }

    useEffect(() => {
        if (masterDataPDAM) {
            let pdam_list = masterDataPDAM?.data_list?.data.data;
            setPDAM(pdam_list)
        }
    }, [masterDataPDAM]);

    useEffect(() => {
        const param = {
            draw: 1,
            length: 999,
            page: 1,
        }
        dispatch(InputService.get(param));
        dispatch(ZonaService.get(param));
    }, [])

    useEffect(() => {
        setSelectedPDAM(filter?.pdam_id)
    }, [filter])

    useEffect(() => {

        if (masterDataInput) {
            let input_list = masterDataInput?.data_list?.data?.data;
            if (selectedPDAM) {
                input_list = input_list?.filter(item => item.pdam_id === selectedPDAM);
            }
            setInput(input_list)
        }
    }, [selectedPDAM, masterDataInput]);

    useEffect(() => {

        if (masterDataZone) {
            let zone_list = masterDataZone?.data_list?.data?.data;
            if (selectedPDAM) {
                zone_list = zone_list?.filter(item => item.pdam_id === selectedPDAM);
            }
            if (selectedInput) {
                zone_list = zone_list?.filter(item => item.input_id === selectedInput);
            }
            setZone(zone_list)
        }

    }, [selectedPDAM, selectedInput, masterDataZone]);

    useEffect(() => {

        if (masterDataDMA) {
            let dma_list = masterDataDMA?.data_list?.data?.data;

            if (selectedPDAM) {
                dma_list = dma_list?.filter(item => item.pdam_id === selectedPDAM);
            }

            if (selectedInput) {
                dma_list = dma_list?.filter(item => item.input_id === selectedInput);
            }

            if (selectedZone) {
                dma_list = dma_list?.filter(item => item.zone_id === selectedZone);
            }

            setDMA(dma_list)
        }
    }, [selectedPDAM, selectedInput, selectedZone, masterDataDMA]);

    const handleClose = () => {
        setSelectedPDAM(currentUser?.user?.pdam?.id ?? '')
        setSelectedZone('')
        setSelectedInput('')
        toggle()
    }

    return (
        <>

            <Modal isOpen={show} modalTransition={{ timeout: 200 }} backdropTransition={{ timeout: 1300 }}>
                <ModalHeader className="text-extra-bold pt-5"> Filter <span className="close clickable" onClick={handleClose}>&times;</span></ModalHeader>
                <ModalBody style={{ margin: '0px 20px' }}>
                    <DynamicButton
                        titleLeft='Pilih data yang ingin difilter'
                        iconLeft='info'
                    />
                    <Formik
                        initialValues={{
                            pdam: currentUser?.user?.pdam?.id ?? filter?.pdam_id ?? '',
                            input: filter?.input_id ?? '',
                            zone: filter?.zone_id ?? '',
                            dma: filter?.dma_id ?? '',
                            status: filter?.status ?? ''
                        }}
                        onSubmit={(values, { setStatus, setSubmitting }) => {
                            setStatus();
                            toggleFilter({
                                pdam_id: currentUser?.user?.pdam?.id ?? values?.pdam ?? '',
                                input_id: values?.input ?? '',
                                zone_id: values?.zone ?? '',
                                dma_id: values?.dma ?? '',
                                status: values?.status ?? '',
                            })
                            toggle()
                        }}
                    >
                        {(props) => (
                            <Form style={{ marginTop: 30 }}>
                                <Select2
                                    title="PDAM"
                                    name="pdam"
                                    options={pdam?.map((item) => ({ value: item?.id, label: item?.name }))}
                                    errors={props.errors}
                                    touched={props.touched}
                                    value={props.values?.pdam}
                                    placeholder="Pilih data PDAM"
                                    onChange={(name, value) => {
                                        props.setFieldValue("pdam", value);
                                        props.setFieldValue("input", "");
                                        props.setFieldValue("zone", "");
                                        props.setFieldValue("dma", "");
                                        setSelectedPDAM(value)
                                    }}
                                    onBlur={props.setFieldTouched}
                                    disabled={currentUser?.user?.pdam}
                                />
                                <Select2
                                    title="Input"
                                    name="input"
                                    options={input?.map((item) => ({ value: item?.id, label: item?.name }))}
                                    errors={props.errors}
                                    touched={props.touched}
                                    value={props.values?.input}
                                    placeholder="Pilih data Input"
                                    onChange={(name, value) => {
                                        props.setFieldValue("input", value);
                                        props.setFieldValue("zone", "");
                                        props.setFieldValue("dma", "");
                                        setSelectedInput(value)
                                    }}
                                    onBlur={props.setFieldTouched}
                                />
                                <Select2
                                    title="Zona"
                                    name="zone"
                                    options={zone?.map((item) => ({ value: item?.id, label: item?.name }))}
                                    errors={props.errors}
                                    touched={props.touched}
                                    value={props.values?.zone}
                                    placeholder="Pilih data Zona"
                                    onChange={(name, value) => {
                                        props.setFieldValue("zone", value);
                                        props.setFieldValue("dma", "");
                                        setSelectedZone(value)
                                    }}
                                    onBlur={props.setFieldTouched}
                                />
                                <Select2
                                    title="DMA"
                                    name="dma"
                                    options={dma?.map((item) => ({ value: item?.id, label: item?.name }))}
                                    errors={props.errors}
                                    touched={props.touched}
                                    value={props.values?.dma}
                                    placeholder="Pilih data DMA"
                                    onChange={(name, value) => {
                                        props.setFieldValue("dma", value);
                                    }}
                                    onBlur={props.setFieldTouched}
                                />
                                <div className="form-group">
                                    <label className="form-label">Status</label>
                                </div>
                                <Checkbox
                                    name="status"
                                    label="Aktif"
                                    checked={props?.values?.status}
                                    value={1}
                                    onChange={props?.handleChange}
                                />
                                <Checkbox
                                    name="status"
                                    label="Ditangguhkan"
                                    checked={props?.values?.status}
                                    value={2}
                                    onChange={props?.handleChange}
                                    custom
                                />
                                <Checkbox
                                    name="status"
                                    label="Putus"
                                    checked={props?.values?.status}
                                    value={3}
                                    onChange={props?.handleChange}
                                    custom
                                />

                                <div style={{ marginTop: 50, marginBottom: 30 }}>
                                    <ButtonModal
                                        toggle={handleClose}
                                        reset={() => handleReset(props)}
                                        deleted={isDelete}
                                        confirmTitle="Terapkan"
                                    />
                                </div>
                            </Form>
                        )}
                    </Formik>
                </ModalBody>
            </Modal>
        </>
    )
}

const mapStateToProps = ({ masterDataInput, masterDataPDAM, masterDataDMA, masterDataZone }) => {
    return { masterDataInput, masterDataPDAM, masterDataDMA, masterDataZone };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};


export default connect(mapStateToProps, mapDispatchToProps)(ModalFilter);
