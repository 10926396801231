import HydraulicIcon from "./HydraulicIcon"
import { useState, useEffect } from "react"

const HydraulicToolBox = ({ toggleCursor, toggleZoom, toggleDraw, toggleDelete, toggleSave, toggleEdit, iconCursor }) => {
  const [active, setActive] = useState("cursor")

  useEffect(() => {
    const updateActiveIcon = async () => {
      if (iconCursor === "cursor") {
        setActive(iconCursor)
      }
    }

    updateActiveIcon()
  }, [iconCursor])

  return (
    <div className="hydraulic-menu-bar inactive-pointer-evt" style={{ zIndex: 500 }}>
      <HydraulicIcon
        first
        icon="save"
        tooltip="Simpan"
        active={active === "save"}
        toggle={() => {
          toggleSave(true)
          setActive("save")
        }}
      />
      <HydraulicIcon
        image={
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M20 13.8494L7.00864 2L7 19.5061L10.9423 15.7645L12.8556 20.0909C13.0781 20.5939 13.6649 20.8228 14.1692 20.6034L15.9477 19.8295C16.4563 19.6082 16.6876 19.0153 16.4633 18.5081L14.5548 14.1927L20 13.8494Z"
              fill="currentColor"
            />
          </svg>
        }
        active={active === "cursor"}
        tooltip="Pilih Objek"
        toggle={() => {
          setActive("cursor")
          toggleCursor("default")
        }}
      />
      <HydraulicIcon
        icon="open_with"
        tooltip="Geser"
        toggle={() => {
          setActive("move")
          toggleEdit("move")
        }}
        active={active === "move"}
      />
      <HydraulicIcon
        icon="zoom_in"
        tooltip="Perbesar"
        toggle={() => {
          setActive("zoom_in")
          toggleZoom("in")
        }}
        active={active === "zoom_in"}
      />
      <HydraulicIcon
        icon="zoom_out"
        tooltip="Perkecil"
        toggle={() => {
          setActive("zoom_out")
          toggleZoom("out")
        }}
        active={active === "zoom_out"}
      />
      <HydraulicIcon
        icon="close"
        tooltip="Hapus Objek"
        toggle={(e) => {
          setActive("close")
          toggleDelete(e)
        }}
        active={active === "close"}
      />
      <div className="nav-separator mr-3" style={{ height: "70%" }} />
      <HydraulicIcon
        icon="circle"
        tooltip="Junction"
        toggle={(e) => {
          setActive("junction")
          toggleDraw(e, "marker", "junction")
        }}
        active={active === "junction"}
      />
      <HydraulicIcon
        icon="all_inbox"
        tooltip="Reservoar"
        toggle={(e) => {
          setActive("reservoar")
          toggleDraw(e, "marker", "reservoar")
        }}
        // toggleClose={() => {
        //   setActive("cursor");
        // }}
        active={active === "reservoar"}
      />
      <HydraulicIcon
        icon="inventory_2"
        tooltip="Tangki"
        toggle={(e) => {
          setActive("tank")
          toggleDraw(e, "marker", "tank")
        }}
        // toggleClose={() => {
        //   setActive("cursor");
        // }}
        active={active === "tank"}
      />
      <HydraulicIcon
        filled
        icon="linear_scale"
        tooltip="Pipa"
        toggle={(e) => {
          setActive("pipe")
          toggleDraw(e, "polyline")
        }}
        // toggleClose={() => {
        //   setActive("cursor");
        // }}
        active={active === "pipe"}
      />
      <HydraulicIcon
        icon="podcasts"
        tooltip="Pompa"
        toggle={(e) => {
          setActive("pump")
          toggleDraw(e, "marker", "pump")
        }}
        active={active === "pump"}
      />
      <HydraulicIcon
        icon="control_point_duplicate"
        tooltip="Katup"
        toggle={(e) => {
          setActive("valve")
          toggleDraw(e, "marker", "katup")
        }}
        active={active === "valve"}
      />
    </div>
  )
}

export default HydraulicToolBox
