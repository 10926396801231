import { NavLink } from "react-router-dom";
import { Row } from "reactstrap";

const SubMenu = ({ link, title }) => {
  return (
    <NavLink to={link} className="nav-item">
      <Row className="mr-0 ml-2">
        <span className="title">{title}</span>
      </Row>
    </NavLink>
  );
};

export default SubMenu;
