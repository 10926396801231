import { connect, useDispatch } from "react-redux"
import { useState, useEffect } from "react"
import Button from "../../components/buttons/Button"
import SearchButton from "../../components/buttons/SearchButton"
import Data from "./data"
import Diagram from "./diagram"
import DatePicker from "../../components/forms/MonthPicker"
import DataDiagramBaseLayout from "../../components/layout/data_diagram"
import { getUser } from "../../utils/User"
import ModalWaterBalance from "./ModalWaterBalance"
import Select from "react-select"
import Loader from "../../components/commons/Loader"

import moment from "moment"
import localization from "moment/locale/id"

// services
import WaterBalance from "../../store/actions/water_balance"
import { Role, monthOptions } from "../../utils/Constants"

const Index = ({ switchPDAM, waterBalance }) => {
  const dispatch = useDispatch()
  const currentUser = getUser().user
  const [activeView, setActiveView] = useState("diagram")
  const [search, setSearch] = useState("")
  const [selectedDate, setSelectedDate] = useState(new Date())
  const [selectedMonth, setSelectedMonth] = useState(moment().format("M"))
  const [maxMonth, setMaxMonth] = useState(moment().format("M"))
  const [showModalUpdate, setShowModalUpdate] = useState(false)
  const [dataWaterBalance, setDataWaterBalance] = useState([])
  const [totalData, setTotalData] = useState(null)
  const [page, setPage] = useState(1)
  const [maxPage, setMaxPage] = useState(1)
  const [update, setUpdate] = useState(false)
  const handleSearch = (value) => {
    setSearch(value)
  }

  const customSelect = {
    control: (base, state) => ({
      ...base,
      background: "#fffff",
      borderRadius: "34px",
      marginLeft: "10px",
      marginRight: "10px",
      padding: "1px",
      height: "48px",
      minWidth: "200px",
      textAlign: "center",
      alignItems: "center",
      border: "1px solid #d8d8d8",
      boxShadow: null,
      "&:hover": {
        borderColor: "#d8d8d8",
      },
    }),
    option: (options) => ({
      ...options,
      textAlign: "left",
    }),
  }

  const handleRefresh = () => {
    new Promise((resolve) => {
      const param = {
        page: page === 0 ? 1 : page ?? 1,
        length: 10,
        id: null,
        search_text: search,
        pdam_id: currentUser?.pdam?.id ?? "",
        month: selectedMonth,
        year: selectedDate ? moment(selectedDate).locale("id", localization).format("YYYY") : "",
      }
      dispatch(WaterBalance.get(param, resolve))
    }).then((res) => {
      setDataWaterBalance(res?.data)
      setTotalData(res?.totalCount)
      setMaxPage(Math.ceil(res?.totalCount / 10))
    })
  }

  useEffect(() => {
    handleRefresh()
  }, [search, page, switchPDAM, activeView, selectedDate, selectedMonth])

  const handleUpdateModal = () => {
    setShowModalUpdate(true)
  }

  const handleMonth = (value) => {
    if (value) {
      setSelectedMonth(value.value)
    } else {
      setSelectedMonth(null)
    }
  }

  const exportWater = () => {
    const param = {
      pdam_id: currentUser?.pdam?.id ?? "",
      month: selectedMonth || "",
      year: selectedDate ? moment(selectedDate).locale("id", localization).format("YYYY") : "",
    }
    dispatch(WaterBalance.exportWater(param))
  }

  const dataHeaderContent = (
    <>
      <SearchButton optClass="mr-4" placeholder="DMA atau Kelurahan" toggle={handleSearch} />
      <Select
        isSearchable={false}
        options={monthOptions(maxMonth)}
        styles={customSelect}
        defaultValue={
          monthOptions(maxMonth).filter((item) => item.value === parseInt(selectedMonth)).length > 0
            ? monthOptions(maxMonth).filter((item) => item.value === parseInt(selectedMonth))
            : {
                label: "Semua Bulan",
                value: null,
              }
        }
        placeholder="Pilih Bulan"
        className="mr-2"
        onChange={(value) => handleMonth(value)}
        isDisabled={waterBalance.pending_export}
      />
      <div className="wrapper-month-picker-new" style={{ maxWidth: 150 }}>
        <DatePicker
          name="date"
          openTo="month"
          views={["year"]}
          minDate={new Date("2016-01-01")}
          value={selectedDate}
          onChange={(val) => {
            setSelectedDate(val)
            if (moment(val).format("YYYY") === moment().format("YYYY")) {
              setMaxMonth(moment().format("M"))
            } else {
              setMaxMonth(12)
            }
          }}
          variant={"dialog"}
          disableFuture
          disabled={waterBalance.pending_export}
        />
      </div>

      <div className="nav-separator mr-3" style={{ maxHeight: 50, marginLeft: 20 }} />
    </>
  )

  const diagramHeaderContent = (
    <>
      <Select
        isSearchable={false}
        options={monthOptions(maxMonth)}
        styles={customSelect}
        defaultValue={
          monthOptions(maxMonth).filter((item) => item.value === parseInt(selectedMonth)).length > 0
            ? monthOptions(maxMonth).filter((item) => item.value === parseInt(selectedMonth))
            : {
                label: "Semua Bulan",
                value: null,
              }
        }
        placeholder="Pilih Bulan"
        className="mr-2"
        onChange={(value) => handleMonth(value)}
        isDisabled={waterBalance.pending_export}
      />
      <div className="wrapper-month-picker-new" style={{ maxWidth: 150 }}>
        <DatePicker
          name="date"
          openTo="month"
          views={["year"]}
          minDate={new Date("2016-01-01")}
          value={selectedDate}
          onChange={(val) => {
            setSelectedDate(val)
            if (moment(val).format("YYYY") === moment().format("YYYY")) {
              setMaxMonth(moment().format("M"))
            } else {
              setMaxMonth(12)
            }
          }}
          variant={"dialog"}
          disableFuture
          disabled={waterBalance.pending_export}
        />
      </div>

      <div className="nav-separator mr-3" style={{ maxHeight: 50, marginLeft: 20 }} />

      <Button title={`Export`} leftIcon={`file_download`} size="md" variant="btn-lg button-default mr-3" rounded={true} onClick={() => exportWater()} disabled={waterBalance.pending_export} />
      <Button
        title={`Update`}
        leftIcon={`edit`}
        size="md"
        variant="btn-lg button-default"
        rounded={true}
        onClick={handleUpdateModal}
        disabled={(currentUser.role_code !== Role.Superadmin && currentUser.role_code !== Role.User_cust_rep) || waterBalance.pending_export || !selectedMonth}
      />

      <div className="nav-separator mr-3" style={{ maxHeight: 50, marginLeft: 20 }} />
    </>
  )

  const dataContent = <Data dataWaterBalance={dataWaterBalance} selectedDate={selectedDate} search_text={search} page={page} maxPage={maxPage} totalData={totalData} setPage={setPage} />

  const diagramContent = <Diagram dataDiagram={dataWaterBalance} selectedDate={selectedDate} selectedMonth={selectedMonth} switchPDAM={switchPDAM} update={update} />

  return (
    <div className="menu-container pr-0">
      <DataDiagramBaseLayout title={`Water Balance`} dataContent={dataContent} diagramContent={diagramContent} dataHeaderContent={dataHeaderContent} diagramHeaderContent={diagramHeaderContent} onChangeView={(view) => setActiveView(view)} />
      {(waterBalance?.pending_diagrams || waterBalance?.pending) && <Loader loading={waterBalance?.pending_diagrams || waterBalance?.pending} fullScreen />}

      <ModalWaterBalance show={showModalUpdate} toggle={() => setShowModalUpdate(false)} selectedDate={selectedDate} selectedMonth={selectedMonth} refresh={handleRefresh} switchPDAM={switchPDAM} update={(value) => setUpdate(value)} />
    </div>
  )
}

const mapStateToProps = ({ waterBalance }) => {
  return { waterBalance }
}

const mapDispatchToProps = (dispatch) => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(Index)
