const Index = ({ color, title, total }) => {
  return (
    <div className="d-flex align-items-center mb-3">
      <div className={`square-icon ${color || "biru"}`}>
        <span className="material-icons">invert_colors</span>
      </div>
      <div className="col detail-pdam">
        <div>
          <span className="normal-title">{title || "-"}</span>
        </div>
        <div>
          <span className="title text-bold">{total || "0"}</span>
        </div>
      </div>
    </div>
  );
};

export default Index;
