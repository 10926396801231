import { history } from "../../../utils/History";
import { actionPending, actionSuccess, actionError } from "../actionTypes";

import { toastSuccess, toastError } from "../../../components/commons/toast";

import API from "../API";
import { handleResponse } from "../HandleRespone";

// ACTION TYPES TO DISPATCH: METHOD_URL_TYPES
export const POST_FILE_PENDING = "POST_FILE_PENDING";
export const POST_FILE_SUCCESS = "POST_FILE_SUCCESS";
export const POST_FILE_ERROR = "POST_FILE_ERROR";

export const SHOW_FILE_PENDING = "SHOW_FILE_PENDING";
export const SHOW_FILE_SUCCESS = "SHOW_FILE_SUCCESS";
export const SHOW_FILE_ERROR = "SHOW_FILE_ERROR";

// URL: URL_{URL}
const FILE_URL = `v1/files`;

const post = (params, resolve, reject, callback) => (dispatch) => {
  dispatch(actionPending(POST_FILE_PENDING));
  API.defaults.headers.common["accept"] = "*/*";
  API.defaults.headers.common["Content-Type"] = "application/json";
  API.post(FILE_URL, params)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(POST_FILE_SUCCESS, res));
      if (resolve) {
        resolve({
          data: res.data,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(POST_FILE_ERROR));
      // toastError(err?.response?.data?.message);
    });
};

const show = (params, resolve, reject, callback) => (dispatch) => {
  dispatch(actionPending(SHOW_FILE_PENDING));
  API.post(FILE_URL, params, { responseType: "arraybuffer" })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(SHOW_FILE_SUCCESS, res));
      if (resolve) {
        resolve({
          data: res.data,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      // dispatch(actionError(SHOW_FILE_ERROR));
      let not_logout = handleResponse(err?.response);
      if (not_logout) {
        let message = err?.response?.data?.message;
        if (message?.includes("java")) {
          message = "Data tidak ditemukan";
        }
        toastError(message);
      }
      // toastError(err?.response?.data?.message);
    });
};

const fileManagement = {
  post,
  show,
};
export default fileManagement;
