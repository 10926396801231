import { history } from "../../../utils/History";
import { actionPending, actionSuccess, actionError } from "../actionTypes";

import { toastSuccess, toastError } from "../../../components/commons/toast";

import API from "../API";
import { handleResponse } from "../HandleRespone";

// ACTION TYPES TO DISPATCH: METHOD_URL_TYPES
export const GET_DATA_LOGGER_PENDING = "GET_DATA_LOGGER_PENDING";
export const GET_DATA_LOGGER_SUCCESS = "GET_DATA_LOGGER_SUCCESS";
export const GET_DATA_LOGGER_ERROR = "GET_DATA_LOGGER_ERROR";
export const EXPORT_DATA_LOGGER_PENDING = "EXPORT_DATA_LOGGER_PENDING";
export const EXPORT_DATA_LOGGER_SUCCESS = "EXPORT_DATA_LOGGER_SUCCESS";
export const EXPORT_DATA_LOGGER_ERROR = "EXPORT_DATA_LOGGER_ERROR";

// URL: URL_{URL}
const DATA_LOGGER_URL = `v1/logger`;

const get = (param, resolve, reject, callback) => (dispatch) => {
  dispatch(actionPending(GET_DATA_LOGGER_PENDING));
  API.get(DATA_LOGGER_URL, { params: param })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(GET_DATA_LOGGER_SUCCESS, res));
      if (resolve) {
        const records_total = res.data.total;
        let data = res.data.data.map((item, i) => ({
          ...item,
          no: i + 1 + (param?.page - 1) * param?.length,
        }));
        resolve({
          data: data,
          page: param?.page - 1,
          totalCount: records_total,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(GET_DATA_LOGGER_ERROR));
      let not_logout = handleResponse(err?.response);
      if (not_logout) {
        let message = err?.response?.data?.message;
        if (message?.includes("java")) {
          message = "Data tidak ditemukan";
        }
        toastError(message);
      }
      // toastError(err?.response?.data?.message);
    });
};

const exportData = (param, callback) => (dispatch) => {
  dispatch(actionPending(EXPORT_DATA_LOGGER_PENDING));
  API.get(DATA_LOGGER_URL + "/export", { params: param, responseType: "blob" })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(EXPORT_DATA_LOGGER_SUCCESS, res));
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `Expor Data Logger.xlsx`);
      document.body.appendChild(link);
      link.click();
      if (callback) {
        callback();
      }

      return res;
    })
    .catch((err) => {
      dispatch(actionError(EXPORT_DATA_LOGGER_ERROR));
      toastError(err?.response?.data?.message);
    });
};

const dataLogger = {
  get,
  exportData,
};
export default dataLogger;
