import React from "react";
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter
} from "reactstrap";
import Button from "../../components/buttons/Button";

function HydraulicConfirmationModal({
    data,
    show,
    icon,
    toggle,
    confirm,
    confirmNo,
    variantIcon,
    disabled
}) {
    return (
        <>
            <Modal
                isOpen={show}
                modalTransition={{ timeout: 700 }}
                backdropTransition={{ timeout: 1300 }}
                toggle={toggle}
            >
                <ModalHeader className="text-extra-bold pt-5">
                    {" "}
                    {"Konfirmasi"}{" "}
                    <span className="close clickable" onClick={toggle}>
                        &times;
                    </span>
                </ModalHeader>
                <ModalBody>
                    <div
                        style={{
                            marginLeft: 20,
                            marginRight: 20,
                            display: "flex",
                            lineHeight: "2rem",
                        }}
                    >
                        {icon && (
                            <i
                                className={`material-icons-outlined icon-modal ${variantIcon}`}
                                style={{ marginRight: 10 }}
                            >
                                {icon}
                            </i>
                        )}
                        <p style={{ fontSize: "16px", margin: "0 20px" }}>
                            {`Apakah Anda ingin menyimpan ${data?.name} sebelum keluar?`}
                        </p>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <div style={{ margin: "20px 0px" }}>
                        <div
                            style={{
                                lineHeight: "30px",
                                display: "flex",
                                alignItems: "right",
                            }}
                        >
                            <Button
                                variant="button secondary"
                                rounded
                                title="Batal"
                                onClick={toggle}
                                type="Button"
                                disabled={disabled}
                            />
                            <Button
                                variant="button secondary"
                                rounded
                                title="Tidak"
                                onClick={confirmNo}
                                type="Button"
                                disabled={disabled}
                            />
                            <Button
                                variant="button primary"
                                rounded
                                title="Ya, Simpan"
                                onClick={confirm}
                                type="Button"
                                disabled={disabled}
                            />
                        </div>
                    </div>
                </ModalFooter>
            </Modal>
        </>
    );
}

export default HydraulicConfirmationModal;
