import React from "react";
import { useDispatch } from "react-redux";
import { Container } from "reactstrap";
import Table from "../../../components/table/index";
import DetailButton from "../../../components/table/detailButton/index";
import TableStatus from "../../../components/table/status/index";
import MenuItem from "@material-ui/core/MenuItem";
// import Tab from "../../components/tab";
// import TabContent from "../../components/tab/TabContent";
import moment from "moment";
import {
  useState,
  useEffect,
  forwardRef,
  useRef,
  useImperativeHandle,
} from "react";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import { getUser } from "../../../utils/User";
import { RoleName } from "../../../utils/Constants";

// services
import masterDataUserService from "../../../store/actions/master_data/user";

// modal
import FormUserAdaroModal from "./FormUserAdaroModal";
import FormUserPDAMModal from "./FormUserPDAMModal";
import ConfirmationModal from "../../../components/modal/ConfirmModal";
import FilterUserModal from "./FilterUserModal";

const Index = forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const user = getUser();
  const [activeTab, setActiveTab] = useState(
    user.user.role_code !== "superadmin" ? "0" : "1"
  );
  const [dataAdaro, setDataAdaro] = useState([]);
  const [dataPDAM, setDataPDAM] = useState([]);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [pending, setPending] = useState(false);
  // const [userManagementRoleCodes, setUserManagementRoleCodes] = useState([
  //   "superadmin",
  //   "kadiv",
  //   "user_cust_rep",
  //   "it_administrator",
  // ]);
  const [userManagementRoleCodes, setUserManagementRoleCodes] = useState("");
  const [userManagementStatus, setUserManagementStatus] = useState("");
  const [userDetail, setUserDetail] = useState(null);

  // pagination
  const [totalData, setTotalData] = useState(null);
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);

  // refs
  const modalFormUserAdaroRef = useRef();
  const modalFormUserPDAMRef = useRef();
  const modalFilterUserRef = useRef();

  const dataAdaroColumns = [
    {
      Header: "NO.",
      accessor: "no",
      Cell: ({ row }) => {
        const { original } = row;

        return <>{original.no}</>;
      },
    },
    {
      Header: "NAMA",
      accessor: "first_name",
      Cell: ({ row }) => {
        const { original } = row;
        return (
          <>
            {original.first_name +
              " " +
              original.mid_name +
              " " +
              original.last_name.replace("  ", " ")}
          </>
        );
      },
    },
    {
      Header: "USERNAME",
      accessor: "username",
    },
    {
      Header: "ROLE",
      accessor: "role_code",
      Cell: ({ row }) => {
        const { original } = row;
        const { role_code } = original;
        return RoleName[role_code] || "-";
      },
    },
    {
      Header: "STATUS USER",
      accessor: "status",
      Cell: ({ row }) => {
        const { original } = row;
        const { status } = original;

        return (
          <TableStatus
            status={status === 1 ? "Aktif" : status === 0 ? "Nonaktif" : "-"}
            color={status === 1 ? "primary" : status === 0 ? "danger" : "-"}
          />
        );
      },
    },
    {
      Header: "",
      accessor: "id",
      Cell: ({ row }) => {
        const { original } = row;

        return (
          <DetailButton>
            <MenuItem onClick={() => handleOnClickEllipsis(original, "detail")}>
              <span style={{ fontSize: 14 }}>Detail</span>
            </MenuItem>
            {user.user.role_code !== "kadiv" && (
              <MenuItem onClick={() => handleOnClickEllipsis(original, "edit")}>
                <span style={{ fontSize: 14 }}>Edit</span>
              </MenuItem>
            )}
            {user.user.role_code === "kadiv" && (
              <MenuItem
                onClick={() => handleOnClickEllipsis(original, "delete")}
              >
                <span style={{ fontSize: 14 }}>Hapus</span>
              </MenuItem>
            )}
          </DetailButton>
        );
      },
    },
  ];

  const dataPDAMColumns = [
    {
      Header: "NO.",
      accessor: "no",
      Cell: ({ row }) => {
        const { original } = row;

        return <>{original.no}</>;
      },
    },
    {
      Header: "NAMA",
      accessor: "first_name",
      Cell: ({ row }) => {
        const { original } = row;
        return (
          <>
            {original.first_name +
              " " +
              original.mid_name +
              " " +
              original.last_name.replace("  ", " ")}
          </>
        );
      },
    },
    {
      Header: "USERNAME",
      accessor: "username",
    },
    {
      Header: "PDAM",
      accessor: "pdam_name",
    },
    {
      Header: "STATUS USER",
      accessor: "status",
      Cell: ({ row }) => {
        const { original } = row;
        const { status } = original;

        return (
          <TableStatus
            status={status === 1 ? "Aktif" : status === 0 ? "Nonaktif" : "-"}
            color={status === 1 ? "primary" : status === 0 ? "danger" : "-"}
          />
        );
      },
    },
    {
      Header: "",
      accessor: "id",
      Cell: ({ row }) => {
        const { original } = row;

        return (
          <DetailButton>
            <MenuItem onClick={() => handleOnClickEllipsis(original, "detail")}>
              <span style={{ fontSize: 14 }}>Detail</span>
            </MenuItem>
            {user.user.role_code !== "kadiv" && (
              <MenuItem onClick={() => handleOnClickEllipsis(original, "edit")}>
                <span style={{ fontSize: 14 }}>Edit</span>
              </MenuItem>
            )}
            {user.user.role_code === "kadiv" && (
              <MenuItem
                onClick={() => handleOnClickEllipsis(original, "delete")}
              >
                <span style={{ fontSize: 14 }}>Hapus</span>
              </MenuItem>
            )}
          </DetailButton>
        );
      },
    },
  ];

  useImperativeHandle(ref, () => ({
    handleSearch(searchText) {
      setSearchText(searchText.length > 0 ? searchText : "");
    },

    refresh() {
      handleRefresh();
    },

    toggleModalAddUser() {
      switch (activeTab) {
        case "0":
          modalFormUserAdaroRef.current.setActionType("add");
          modalFormUserAdaroRef.current.toggle();
          break;
        case "1":
          modalFormUserPDAMRef.current.setActionType("add");
          modalFormUserPDAMRef.current.toggle();
          break;
      }
    },

    toggleModalFilterUser() {
      modalFilterUserRef.current.setShowRole(activeTab == 0);
      modalFilterUserRef.current.setShowStatus(true);
      modalFilterUserRef.current.toggle();
    },

    getActiveTab() {
      return activeTab;
    },
  }));

  const onApplyFilter = (roles, applied_status) => {
    setUserManagementRoleCodes(roles);
    setUserManagementStatus(applied_status);
    modalFilterUserRef.current.toggle();
  };

  const onSubmitFormUserAdaro = () => {
    handleRefresh();
    modalFormUserAdaroRef.current.toggle();
  };

  const onSubmutFormUserPDAM = () => {
    handleRefresh();
    modalFormUserPDAMRef.current.toggle();
  };

  const handleRefresh = () => {
    let role = userManagementRoleCodes || [
      "superadmin",
      "kadiv",
      "user_cust_rep",
      "it_administrator",
    ];

    if (activeTab == "1") {
      role = ["viewer"];
    }

    new Promise((resolve) => {
      let param = {
        draw: 1,
        id: null,
        length: 10,
        page: page,
        role_code: role,
        search_text: searchText,
        start: null,
        status: userManagementStatus,
      };
      setPending(true);
      dispatch(masterDataUserService.get(param, resolve));
    }).then((res) => {
      if (activeTab === "0") {
        setDataAdaro(res?.data);
      } else if (activeTab === "1") {
        setDataPDAM(res?.data);
      }
      setTotalData(res?.totalCount);
      setMaxPage(Math.ceil(res?.totalCount / 10));
      setPending(false);
      // console.log("applied_status very after", userManagementStatus);
      // console.log(res);
    });
  };

  const confirmDelete = () => {
    if (!userDetail) return;
    new Promise((resolve) => {
      let param = {
        id: userDetail.id,
      };
      dispatch(masterDataUserService.del(param, resolve));
    }).then((res) => {
      handleRefresh();
      toggleModalConfirm();
    });
  };

  const toggleModalConfirm = () => {
    setShowDeleteConfirm(!showDeleteConfirm);
  };

  const handleOnClickEllipsis = (user, action) => {
    user.full_name =
      user.first_name +
      " " +
      user.mid_name +
      " " +
      user.last_name.replace("  ", " ");
    setUserDetail(user);
    if (action == "delete") {
      setShowDeleteConfirm(true);
      return;
    }
    new Promise((resolve) => {
      let param = {
        id: user.id,
      };
      dispatch(masterDataUserService.get(param, resolve));
    }).then((res) => {
      if (activeTab === "0") {
        modalFormUserAdaroRef.current.setActionType(action);
        modalFormUserAdaroRef.current.setInitialValues(res?.data[0]);
        modalFormUserAdaroRef.current.toggle();
      } else if (activeTab === "1") {
        modalFormUserPDAMRef.current.setActionType(action);
        modalFormUserPDAMRef.current.setInitialValues(res?.data[0]);
        modalFormUserPDAMRef.current.toggle();
      }
    });
  };

  const showTable = (active) => {
    switch (active) {
      case "0":
        return (
          <Table
            id="tbl-data-system-user-management"
            isLoading={pending}
            columns={dataAdaroColumns}
            data={dataAdaro}
            pagination={{ page, totalData, maxPage, toggle: setPage }}
          />
        );
      case "1":
        return (
          <Table
            id="tbl-data-system-user-management"
            isLoading={pending}
            columns={dataPDAMColumns}
            data={dataPDAM}
            pagination={{ page, totalData, maxPage, toggle: setPage }}
          />
        );
    }
  };

  useEffect(() => {
    handleRefresh();
  }, [searchText, userManagementRoleCodes, userManagementStatus, page]);

  useEffect(() => {
    handleRefresh();
  }, [activeTab]);

  return (
    <Container>
      <FilterUserModal
        ref={modalFilterUserRef}
        filterRoleCode={userManagementRoleCodes}
        filterStatus={userManagementStatus}
        onApplyFilter={onApplyFilter}
        activeTab={activeTab}
      />
      <FormUserAdaroModal
        ref={modalFormUserAdaroRef}
        onSubmited={onSubmitFormUserAdaro}
      />
      <FormUserPDAMModal
        ref={modalFormUserPDAMRef}
        onSubmited={onSubmutFormUserPDAM}
      />
      <ConfirmationModal
        show={showDeleteConfirm}
        title="Konfirmasi"
        message={
          "Apakah anda yakin ingin menghapus data user <b>" +
          userDetail?.full_name +
          "</b> ?"
        }
        isDelete={true}
        confirm={confirmDelete}
        toggle={toggleModalConfirm}
        icon="info"
      />
      <div className="pl-4">
        <Tabs
          value={activeTab}
          textColor="primary"
          indicatorColor="primary"
          onChange={(event, newValue) => {
            setActiveTab(newValue);
          }}
        >
          {user.user.role_code !== "superadmin" && (
            <Tab label="Adaro" value="0" />
          )}
          {user.user.role_code !== "it_administrator" && (
            <Tab label="PDAM" value="1" />
          )}
        </Tabs>

        {showTable(activeTab)}
      </div>
    </Container>
  );
});

export default Index;
