import React, { useState, useRef, useEffect } from 'react'
import { connect, useDispatch } from "react-redux"
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    FormGroup,
} from 'reactstrap';
import { Formik, Form } from 'formik';
import { getUser } from '../../utils/User';
import * as Yup from 'yup';

import LoaderModal from "../../components/commons/LoaderModal";
import CardFlush from '../../components/cards/cardFlush';
import ButtonModal from '../../components/modal/ButtonModal';
import ConfirmationModal from '../../components/modal/ConfirmModal';
import Input from "../../components/forms/Input";
import moment from 'moment';
import localization from 'moment/locale/id';

// Service
import WaterBalance from '../../store/actions/water_balance';

function ModalWaterBalance({
    show,
    toggle,
    selectedDate,
    selectedMonth,
    waterBalance,
    switchPDAM,
    update
}) {
    const dispatch = useDispatch()
    const currentUser = getUser().user;

    const [showModalConfirm, setShowModalConfirm] = useState(false)
    const [dataPrepareForm, setDataPrepareForm] = useState([])

    const modalTitle = 'Update Data'

    const handleConfirm = () => {
        setShowModalConfirm(false)
        toggle(false)
    }

    const handleRefresh = () => {
        let param = {
            pdam_id: currentUser?.pdam?.id ?? "",
            month: selectedMonth ? selectedMonth : moment().locale('id', localization).format('MM'),
            year: selectedDate ? moment(selectedDate).locale('id', localization).format('YYYY') : moment().locale('id', localization).format('YYYY'),
        }
        dispatch(WaterBalance.prepare_form(param))
    }

    useEffect(() => {
        handleRefresh()
    }, [selectedDate, selectedMonth, switchPDAM])

    useEffect(() => {
        if (waterBalance?.data_prepare?.data?.data) {
            setDataPrepareForm(waterBalance?.data_prepare?.data?.data)
        }
    }, [waterBalance])

    const validation = Yup.object().shape({
        non_metered_and_billed_consumption: Yup.string().required('Data wajib diisi').nullable(),
        non_metered_and_non_billed_consumption: Yup.string().required('Data wajib diisi').nullable(),
        metered_and_non_billed_consumption: Yup.string().required('Data wajib diisi').nullable(),
        unofficial_consumption: Yup.string().required('Data wajib diisi').nullable(),
        data_inaccuracies: Yup.string().required('Data wajib diisi').nullable(),
    })

    return (
        <>
            <Modal isOpen={show} modalTransition={{ timeout: 200 }} backdropTransition={{ timeout: 1300 }}>
                <LoaderModal show={waterBalance?.pending_put} />
                <ModalHeader className="text-extra-bold pt-5"> {modalTitle} <span className="close clickable" onClick={() => setShowModalConfirm(true)}>&times;</span></ModalHeader>
                <Formik
                    enableReinitialize
                    initialValues={{
                        id: dataPrepareForm?.id,
                        pdam_id: currentUser?.pdam?.id,
                        non_metered_and_billed_consumption: dataPrepareForm?.non_metered_and_billed_consumption ?? '',
                        non_metered_and_non_billed_consumption: dataPrepareForm?.non_metered_and_non_billed_consumption ?? '',
                        metered_and_non_billed_consumption: dataPrepareForm?.metered_and_non_billed_consumption ?? '',
                        unofficial_consumption: dataPrepareForm?.unofficial_consumption,
                        data_inaccuracies: dataPrepareForm?.data_inaccuracies,
                        year: moment(selectedDate).locale('id', localization).format('YYYY'),
                        month: selectedMonth ? selectedMonth.toString() : moment().locale('id', localization).format('MM'),
                    }}
                    validationSchema={validation}
                    onSubmit={(values, { setStatus }) => {
                        setStatus();
                        const callback = () => {
                            toggle()
                            handleRefresh()
                            update(values)
                        }
                        dispatch(WaterBalance.put(values, callback));
                    }
                    }
                >
                    {(props) => (
                        <Form>
                            <ModalBody className="mx-3">
                                <CardFlush
                                    status
                                    color={`bg-light text-secondary`}
                                    desc={`Update Data ${moment(`${selectedMonth}/01/${moment(selectedDate).locale('id', localization).format('YYYY')}`).locale('id', localization).format('MMMM YYYY')}`}
                                />
                                <FormGroup>
                                    <Input
                                        title="Konsumsi Tak Bermeter Berekening"
                                        name="non_metered_and_billed_consumption"
                                        type="number"
                                        errors={props?.errors}
                                        touched={props?.touched}
                                        {...props?.getFieldProps("non_metered_and_billed_consumption")}
                                        value={props.values?.non_metered_and_billed_consumption}
                                        rightLabel={(<>m<sup>3</sup></>)}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Input
                                        title='Konsumsi Bermeter Tak Berekening'
                                        name='metered_and_non_billed_consumption'
                                        type="number"
                                        errors={props?.errors}
                                        touched={props?.touched}
                                        {...props?.getFieldProps("metered_and_non_billed_consumption")}
                                        rightLabel={(<>m<sup>3</sup></>)}
                                        value={props.values?.metered_and_non_billed_consumption}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Input
                                        title='Konsumsi Tak Bermeter Tak Berekening'
                                        name='non_metered_and_non_billed_consumption'
                                        type="number"
                                        errors={props?.errors}
                                        touched={props?.touched}
                                        {...props?.getFieldProps("non_metered_and_non_billed_consumption")}
                                        rightLabel={(<>m<sup>3</sup></>)}
                                        value={props.values?.non_metered_and_non_billed_consumption}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Input
                                        title='Konsumsi Tak Resmi'
                                        name='unofficial_consumption'
                                        type="number"
                                        errors={props?.errors}
                                        touched={props?.touched}
                                        {...props?.getFieldProps("unofficial_consumption")}
                                        rightLabel={(<>m<sup>3</sup></>)}
                                        value={props.values?.unofficial_consumption}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Input
                                        title='Ketidakakuratan Meter dan Penanganan Data'
                                        name='data_inaccuracies'
                                        type='number'
                                        errors={props?.errors}
                                        touched={props?.touched}
                                        {...props?.getFieldProps("data_inaccuracies")}
                                        rightLabel={(<>m<sup>3</sup></>)}
                                        value={props.values?.data_inaccuracies}
                                    />
                                </FormGroup>
                            </ModalBody>
                            <ModalFooter>
                                <ButtonModal
                                    toggle={() => setShowModalConfirm(true)}
                                    confirmTitle="Simpan"
                                    disabledConfrim={waterBalance?.pending_put}
                                />
                            </ModalFooter>
                        </Form>
                    )}
                </Formik>
            </Modal>

            <ConfirmationModal
                show={showModalConfirm}
                toggle={() => setShowModalConfirm(false)}
                confirm={handleConfirm}
                isDelete='delete'
                cancelTitle="Tidak"
                confirmTitle="Ya"
                message={"Apakah anda yakin ingin membatalkan update data <b>Diagram</b> ?"}
                icon='info'
            />
        </>
    )
}

const mapStateToProps = ({ waterBalance }) => {
    return { waterBalance };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};


export default connect(mapStateToProps, mapDispatchToProps)(ModalWaterBalance);
