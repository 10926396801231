import {
  GET_STEP_TEST_PENDING,
  GET_STEP_TEST_SUCCESS,
  GET_STEP_TEST_ERROR,
} from "../../actions/step_test_support";

const initialState = {
  pending: false,
  error: null,
  data_list: null,
};

const stepTestSupport = (state = initialState, action) => {
  switch (action.type) {
    case GET_STEP_TEST_PENDING:
      return {
        ...state,
        pending: true,
      };
    case GET_STEP_TEST_SUCCESS:
      return {
        ...state,
        pending: false,
        data_list: action.data,
      };
    case GET_STEP_TEST_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };

    default:
      return state;
  }
};

export default stepTestSupport;
