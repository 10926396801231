import { useState } from "react";
import Empty_Data from '../../assets/images/empty-state/Not Found.svg';


const EmptyData = (props) => {
    // const [showModal, setShowModal] = useState(props);
    const showModal =  props.showModal;
   
    return (
        <div className="empty-state">
            <img src={Empty_Data} className="img-empty-state" alt="account"/>
            <div className="empty-state-caption">
                <h5>TIDAK DITEMUKAN</h5>
                <p>Halaman yang Anda cari tidak dapat ditemukan</p>
            </div>
        </div>
    );
  };
  
  export default EmptyData;