import { useEffect } from "react";
import * as Yup from "yup";
import { connect } from "react-redux";
import { Formik, Form } from "formik";
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter
} from "reactstrap";
import Input from "../../components/forms/Input";
import ButtonModal from "../../components/modal/ButtonModal";

const ModalCopy = ({
    show,
    toggle,
    confirm,
    data,
    hydraulicModel,
}) => {

    const validation = Yup.object().shape({
        name: Yup.string().required("Nama simulasi wajib diisi").nullable(),
    });

    return (
        <Modal
            isOpen={show}
            modalTransition={{ timeout: 200 }}
            backdropTransition={{ timeout: 1300 }}
        >
            <ModalHeader className="text-extra-bold pt-5">
                {" "}
                Salin Simulasi{" "}
                <span className="close clickable" onClick={toggle}>
                    &times;
                </span>
            </ModalHeader>
            <Formik
                enableReinitialize
                initialValues={{
                    name: data?.name ?? '',
                }}
                validationSchema={validation}
                onSubmit={(values, { setStatus, setSubmitting }) => {
                    setStatus();
                    values.id = data?.id
                    values.url = '/copy'
                    confirm(values)
                }}
            >
                {(props) => (
                    <Form style={{ marginTop: 30 }}>
                        <ModalBody style={{ margin: "0px 20px" }}>
                            <Input
                                title="Nama Simulasi"
                                name="name"
                                errors={props.errors}
                                touched={props.touched}
                                {...props.getFieldProps("name")}
                                placeholder="Masukkan nama simulasi"
                                type="text"
                                disabled={hydraulicModel.pending}
                            />
                        </ModalBody>
                        <ModalFooter>
                            <ButtonModal
                                toggle={toggle}
                                confirmTitle="Salin"
                                disabledConfrim={hydraulicModel.pending}
                            />
                        </ModalFooter>
                    </Form>
                )}
            </Formik>
        </Modal>
    )
}

const mapStateToProps = ({ hydraulicModel }) => {
    return { hydraulicModel };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalCopy);
