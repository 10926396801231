import { history } from "../../../../utils/History";
import { actionPending, actionSuccess, actionError } from "../../actionTypes";

import { toastSuccess, toastError } from "../../../../components/commons/toast";

import API from "../../API";
import { handleResponse } from '../../HandleRespone';

// ACTION TYPES TO DISPATCH: METHOD_URL_TYPES
export const GET_DMA_PENDING = "GET_DMA_PENDING";
export const GET_DMA_SUCCESS = "GET_DMA_SUCCESS";
export const GET_DMA_ERROR = "GET_DMA_ERROR";
export const POST_DMA_PENDING = "POST_DMA_PENDING";
export const POST_DMA_SUCCESS = "POST_DMA_SUCCESS";
export const POST_DMA_ERROR = "POST_DMA_ERROR";
export const DELETE_DMA_PENDING = "DELETE_DMA_PENDING";
export const DELETE_DMA_SUCCESS = "DELETE_DMA_SUCCESS";
export const DELETE_DMA_ERROR = "DELETE_DMA_ERROR";

// URL: URL_{URL}
const DMA_URL = `v1/master_data/dma`;

const get = (param, resolve, reject, callback) => (dispatch) => {
  dispatch(actionPending(GET_DMA_PENDING));
  API.get(DMA_URL, { params: param })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(GET_DMA_SUCCESS, res));
      if (resolve) {
        const records_total = res.data.total;
        let data = res.data.data.map((item, i) => ({
          ...item,
          no: i + 1 + (param?.page - 1) * param?.length,
          pdam_name: item?.pdam?.name ?? "-",
          zone_name: item?.zone?.name ?? "-",
          region_name: item?.region_name ?? "-",
          device_id: item?.device_id ?? "-",
        }));
        resolve({
          data: data,
          page: param?.page - 1,
          totalCount: records_total,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(GET_DMA_ERROR));
      let not_logout = handleResponse(err?.response)
      if (not_logout) {
        let message = err?.response?.data?.message;
        if (message?.includes("java")) {
          message = "Data tidak ditemukan";
        }
        toastError(message);
      }
    });
};

const post = (param, callback) => (dispatch) => {
  dispatch(actionPending(POST_DMA_PENDING));
  API.post(DMA_URL + "/import", param)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(POST_DMA_SUCCESS, res));
      toastSuccess("Berhasil import data dma");
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      let not_logout = handleResponse(err?.response)
      if (not_logout) {
        let message = err?.response?.data?.message;
        // if (message?.includes("java")) {
        //   message = "Format file tidak sesuai";
        // }
        toastError(message);
        dispatch(actionError(POST_DMA_ERROR));
      }
    });
};

const deleted = (param, callback) => (dispatch) => {
  dispatch(actionPending(DELETE_DMA_PENDING));
  API.delete(DMA_URL + `?id=${param}`)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(DELETE_DMA_SUCCESS, res));
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(DELETE_DMA_ERROR));
      let message = err?.response?.data?.message;
      if (message?.includes("java")) {
        message = "Gagal menghapus data";
      }
      toastError(message);
    });
};

const dma = {
  get,
  post,
  deleted,
};
export default dma;
