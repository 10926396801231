const Index = ({ title, itemStyle, last, toggle }) => {
  return (
    <div className="profiles-item" style={itemStyle} onClick={toggle}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          cursor: toggle ? "pointer" : "",
        }}
      >
        <div className="profiles-detail">
          <p className="profiles-info text-bold">{title}</p>
        </div>
      </div>
      {!last && <hr style={{ margin: 0 }} />}
    </div>
  );
};

export default Index;
