import {
  GET_NOTIFICATION_PENDING,
  GET_NOTIFICATION_SUCCESS,
  GET_NOTIFICATION_ERROR,
  GET_UNREAD_NOTIFICATION_PENDING,
  GET_UNREAD_NOTIFICATION_SUCCESS,
  GET_UNREAD_NOTIFICATION_ERROR,
  PUT_NOTIFICATION_PENDING,
  PUT_NOTIFICATION_SUCCESS,
  PUT_NOTIFICATION_ERROR,
  NOTIFICATION_DATA,
} from "../../actions/notification";

const initialState = {
  pending: false,
  error: null,
  data_list: null,
  data_put: null,
  pending_put: false,
  detailData: null,
  count_unread: null,
};

const notification = (state = initialState, action) => {
  switch (action.type) {
    case GET_NOTIFICATION_PENDING:
      return {
        ...state,
        pending: true,
      };
    case GET_NOTIFICATION_SUCCESS:
      return {
        ...state,
        pending: false,
        data_list: action.data,
      };
    case GET_NOTIFICATION_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case GET_UNREAD_NOTIFICATION_PENDING:
      return {
        ...state,
        pending: true,
      };
    case GET_UNREAD_NOTIFICATION_SUCCESS:
      return {
        ...state,
        pending: false,
        count_unread: action.data.data.total,
      };
    case GET_UNREAD_NOTIFICATION_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case PUT_NOTIFICATION_PENDING:
      return {
        ...state,
        pending_put: true,
      };
    case PUT_NOTIFICATION_SUCCESS:
      return {
        ...state,
        pending: false,
        data_put: action.data,
      };
    case PUT_NOTIFICATION_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case NOTIFICATION_DATA:
      return {
        ...state,
        detailData: action.data,
      };

    default:
      return state;
  }
};

export default notification;
