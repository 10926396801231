import Sidebar from "./sidebar/Sidebar";
import Header from "./header/header";
import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import { getUser } from "../../utils/User";
import { toastSuccess } from "../commons/toast";

const styledGrid = {
  display: "grid",
  gridTemplateColumns: "min-content 1fr",
  gridTemplateRows: "min-content 1fr",
  height: "100%",
  minHeight: "100hv",
};

const styledMain = {
  padding: "0px",
};

const Index = ({ children, hideHeader, mustSwitch, masterDataPDAM }) => {
  const [showNav, setShowNav] = useState(false);
  const [switchPDAM, setSwitchPDAM] = useState(false);
  const user = getUser();

  const toggle = () => {
    setShowNav(!showNav);
  };
  const togglePDAM = (value) => {
    setSwitchPDAM(value);
  };

  if (mustSwitch && !user.user.pdam && masterDataPDAM.data_all) {
    let pdam = user.user.is_all_pdam === 1 ? masterDataPDAM.data_all.data.data[0] : (user.user?.pdam_list ? user.user?.pdam_list[0] : []);
    user.user.pdam = pdam;
    togglePDAM(pdam);
    localStorage.setItem("user_aws", JSON.stringify(user));
    toastSuccess(`Berhasil berganti ke ${pdam.name}`);
  }
  return (
    <div style={styledGrid}>
      {/* Sidebar menu */}
      <div className="navbar-container">
        <Sidebar visible={showNav} close={toggle} togglePDAM={togglePDAM} />
      </div>

      <div className="header-top">
        {
          !hideHeader && (
            <Header toggle={toggle} />)
        }
      </div>

      {/* Child menu */}
      <div
        style={styledMain}
        className={`main-top pr-0 ${showNav ? "under-element" : ""}`}
      >
        {React.Children.map(children, (child) => {
          return React.cloneElement(child, { switchPDAM, togglePDAM }, null);
        })}
      </div>
    </div>
  );
};

const mapStateToProps = ({ masterDataPDAM }) => {
  return { masterDataPDAM };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
