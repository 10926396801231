import axios from "axios";
import { actionPending, actionSuccess, actionError } from "../actionTypes";
import { toastError } from "../../../components/commons/toast";

export const GET_ELEVATION_PENDING = "GET_ELEVATION_PENDING";
export const GET_ELEVATION_SUCCESS = "GET_ELEVATION_SUCCESS";
export const GET_ELEVATION_ERROR = "GET_ELEVATION_ERROR";

const ELEVATION_URL = `https://api.open-elevation.com/api/v1/lookup?locations=`;

const getElevation = (param, resolve) => (dispatch) => {
  dispatch(actionPending(GET_ELEVATION_PENDING));
  if (param?.lat && param.lng) {
    axios
      .get(ELEVATION_URL + `${param?.lat},${param?.lng}`)
      .then((res) => {
        if (res.error) {
          throw res.error;
        }
        dispatch(actionSuccess(GET_ELEVATION_SUCCESS, res));
        if (resolve) {
          resolve({
            data: res?.data?.results[0]?.elevation,
          });
        }
      })
      .catch((err) => {
        dispatch(actionError(GET_ELEVATION_ERROR));
        if (resolve) {
          toastError(
            "Gagal mengambil data elevasi, silakan atur elevasi secara manual"
          );
          resolve({
            data: 0,
          });
        }
      });
  }
};

const elevation = {
  getElevation,
};
export default elevation;
