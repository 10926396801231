import { connect } from "react-redux"
import { useState } from "react"
import Button from "../../components/buttons/Button"
import SearchButton from "../../components/buttons/SearchButton"
import Data from "./Data"
import HeaderMenu from "../../components/commons/menu/HeaderMenu"
import ModalFilter from "./FilterCommercialModal"
import { getUser } from "../../utils/User"

const Index = ({ commercialLosses }) => {
  const [searchTable, setSearchTable] = useState("")
  const [showModalFilter, setShowModalFilter] = useState(false)
  const currentUser = getUser().user
  const [filter, setFilter] = useState({
    pdam_id: currentUser?.pdam?.id || "",
    dma_id: "",
    date: new Date(),
  })

  const handleSearchTable = (value) => {
    setSearchTable(value)
  }

  return (
    <div className="menu-container pr-0">
      <div className={`main-content-wrapper`}>
         <HeaderMenu
            title="Commercial Losses" 
            optClass="inactive-pointer-evt pr-5"
            titleClass="header-mini-title"
         >
            <SearchButton
            placeholder="Masukkan Kata Kunci"
            toggle={handleSearchTable}
            width={"200px"}
            style={{ marginRight: '20px' }}
            />
            <Button
               className="ml-5"
               title="Filter"
               rounded={true}
               variant="button-light"
               onClick={() => setShowModalFilter(true)}
            />
         </HeaderMenu>
         <div className="main-fill-content">
            <Data
               search_text={searchTable}
               filter={filter}
            />
         </div>
      </div>
      <ModalFilter
         show={showModalFilter}
         toggle={() => setShowModalFilter(false)}
         filter={filter} toggleFilter={setFilter}
      />
    </div>
  )
}

const mapStateToProps = ({ masterDataDMA, commercialLosses }) => {
  return { masterDataDMA, commercialLosses }
}

const mapDispatchToProps = () => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(Index)
