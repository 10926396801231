import React, { useState, useRef, useMemo, useEffect } from "react"
import { divIcon } from "leaflet"
import { Marker } from "react-leaflet"

export function DraggableMarker({ center, source, labelName, toggle }) {
  const initialCenter = center ? center : [-6.8116, 107.6174]
  const [draggable, setDraggable] = useState(true)
  const [position, setPosition] = useState(initialCenter)
  const markerRef = useRef(null)

  useEffect(() => {
    const marker = markerRef.current
    if (marker != null) {
      marker.setLatLng(center)
    }
  }, [center])

  useEffect(() => {
    if (position?.lat && position.lng) {
      toggle([position.lat, position.lng])
    }
  }, [position])

  const eventHandlers = useMemo(
    () => ({
      dragend() {
        const marker = markerRef.current
        if (marker != null) {
          setPosition(marker.getLatLng())
        }
      },
    }),
    []
  )

  return (
    <Marker
      draggable={draggable}
      eventHandlers={eventHandlers}
      position={position}
      ref={markerRef}
      icon={divIcon({
        html: "<div className='marker'><img style='heigh=40px;' src='" + source + "'/><div/><div className='marker-title marker-title-bottom text-bold' style=''>" + labelName + "</div>",
      })}
    />
  )
}
