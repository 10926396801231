import {
    GET_HYDRAULIC_PENDING,
    GET_HYDRAULIC_SUCCESS,
    GET_HYDRAULIC_ERROR,
    POST_HYDRAULIC_PENDING,
    POST_HYDRAULIC_SUCCESS,
    POST_HYDRAULIC_ERROR,
    COPY_HYDRAULIC_PENDING,
    COPY_HYDRAULIC_SUCCESS,
    COPY_HYDRAULIC_ERROR,
    PUT_HYDRAULIC_PENDING,
    PUT_HYDRAULIC_SUCCESS,
    PUT_HYDRAULIC_ERROR,
    DELETE_HYDRAULIC_PENDING,
    DELETE_HYDRAULIC_SUCCESS,
    DELETE_HYDRAULIC_ERROR,
    DOWNLOAD_HYDRAULIC_PENDING,
    DOWNLOAD_HYDRAULIC_SUCCESS,
    DOWNLOAD_HYDRAULIC_ERROR,
} from "../../actions/hydraulic_model";

const initialState = {
    pending: false,
    pending_download: false,
    error: null,
    data_list: null,
    data_post: null,
    data_put: false,
    data_delete: null,
    data_copy: null,
    data_download: null
};

const hydraulic = (state = initialState, action) => {
    switch (action.type) {
        case GET_HYDRAULIC_PENDING:
            return {
                ...state,
                pending: true,
            };
        case GET_HYDRAULIC_SUCCESS:
            return {
                ...state,
                pending: false,
                data_list: action.data,
            };
        case GET_HYDRAULIC_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error,
            };
        case POST_HYDRAULIC_PENDING:
            return {
                ...state,
                pending: true,
            };
        case POST_HYDRAULIC_SUCCESS:
            return {
                ...state,
                pending: false,
                data_post: action.data,
            };
        case POST_HYDRAULIC_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error,
            };
        case COPY_HYDRAULIC_PENDING:
            return {
                ...state,
                pending: true,
            };
        case COPY_HYDRAULIC_SUCCESS:
            return {
                ...state,
                pending: false,
                data_copy: action.data,
            };
        case COPY_HYDRAULIC_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error,
            };
        case PUT_HYDRAULIC_PENDING:
            return {
                ...state,
                pending: true,
            };
        case PUT_HYDRAULIC_SUCCESS:
            return {
                ...state,
                pending: false,
                data_put: action.data,
            };
        case PUT_HYDRAULIC_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error,
            };
        case DELETE_HYDRAULIC_PENDING:
            return {
                ...state,
                pending: true,
            };
        case DELETE_HYDRAULIC_SUCCESS:
            return {
                ...state,
                pending: false,
                data_delete: action.data,
            };
        case DELETE_HYDRAULIC_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error,
            };
        case DOWNLOAD_HYDRAULIC_PENDING:
            return {
                ...state,
                pending_download: true,
            };
        case DOWNLOAD_HYDRAULIC_SUCCESS:
            return {
                ...state,
                pending_download: false,
                data_download: action.data,
            };
        case DOWNLOAD_HYDRAULIC_ERROR:
            return {
                ...state,
                pending_download: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export default hydraulic;
