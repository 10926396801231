import MenuIcon from '@material-ui/icons/Menu';

import './header.scss';

const Index = ({ toggle }) => {

    return (
        <div className="header-container">
            <div className="header-icon" onClick={toggle}>
                <span className="material-icons-round" style={{ color: '#000' }}>sort</span>
            </div>
        </div>
    )
}

export default Index;