import {
  POST_USER_CHANGE_PASSWORD_PENDING,
  POST_USER_CHANGE_PASSWORD_SUCCESS,
  POST_USER_CHANGE_PASSWORD_ERROR,
} from "../../actions/user";

const user = JSON.parse(localStorage.getItem("user_aws"));
const initialState = user
  ? { loggedIn: true, user }
  : {
    loggingIn: false,
    pendingReset: false,
  };

const users = (state = initialState, action) => {
  switch (action.type) {
    case POST_USER_CHANGE_PASSWORD_PENDING:
      return {
        pendingReset: true,
      };
    case POST_USER_CHANGE_PASSWORD_SUCCESS:
      return {
        pendingReset: false,
        loggedIn: true,
      };
    case POST_USER_CHANGE_PASSWORD_ERROR:
      return {};
    default:
      return state;
  }
};

export default users;
