import { connect, useDispatch } from "react-redux";
import Table from "../../../components/table";

const Index = ({ dataWaterBalance, page, totalData, maxPage, setPage }) => {
  
  const toFixed = (data) => {
    let dataFixed;
    data = data.toString().replace(/\./g, '').replace(/\,/g, '.')
    dataFixed = parseFloat(data).toFixed(2).replace(".", ",")
    return dataFixed;
  };

  const numberWithDots = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  };

  const columns = [
    {
      Header: "NO URUT",
      accessor: "no",
    },
    {
      Header: "Nama DMA",
      accessor: "dma_name",
    },
    {
      Header: "KELURAHAN",
      accessor: "region_name",
    },
    {
      Header: "TOTAL NRW",
      accessor: "nrw_percentage",
    },
    {
      Header: () => (
        <>
          TOTAL KUBIKASI INLET (m<sup>3</sup>)
        </>
      ),
      // Header: "TOTAL KUBIKASI INLET (M3)",
      accessor: "production_total",
      Cell: ({ row }) => {
        const { original } = row;
        const { production_total } = original;

        return numberWithDots(toFixed( production_total ))
      }
    },
    {
      Header: "KONSUMSI PELANGGAN",
      accessor: "customers_consumption",
      Cell: ({ row }) => {
        const { original } = row;
        const { customers_consumption } = original;

        return numberWithDots(toFixed( customers_consumption ))
      }
    },
    {
      Header: "TOTAL KEHILANGAN AIR",
      accessor: "water_losses",
      Cell: ({ row }) => {
        const { original } = row;
        const { water_losses } = original;

        return numberWithDots(toFixed( water_losses ))
      }
    },
  ];

  return (
    <div className="menu-container">
      <div>
        <div className="content-container-side">
          <div className="overflow">
            <Table
              columns={columns}
              data={dataWaterBalance}
              pagination={{
                page,
                totalData,
                maxPage,
                toggle: setPage,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ waterBalance: { pending, pending_diagrams } }) => {
  return { pending, pending_diagrams };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
