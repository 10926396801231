import React, { Component } from "react";
import { ErrorMessage } from "formik";

const textareaStyled = {
  background: "white 0% 0% no-repeat padding-box",
  borderRadius: 6,
  opacity: 1,
  minHeight: 48,
  textAlign: "left",
  color: "#222D17",
  paddingLeft: 12,
};

export default class Textarea extends Component {
  render() {
    return (
      <div className="form-group">
        {this.props?.title && (
          <label className="form-label">{this.props?.title}</label>
        )}
        <textarea
          {...this.props}
          value={this.props.value ? this.props.value : ""}
          className={
            "form-control " +
            this.props.className +
            (this.props?.disabled ? "input-disabled " : "") +
            (this.props.errors[this.props.name] &&
            this.props.touched[this.props.name]
              ? " is-invalid"
              : "")
          }
          style={textareaStyled}
        />
        <ErrorMessage
          name={this.props.name}
          component="div"
          className="invalid-feedback"
        />
      </div>
    );
  }
}
