import React from "react";
import { Route, Redirect } from "react-router-dom";
import { getUser } from "../../../utils/User";
import Layout from "../../../components/layout";

const PrivateRoute = ({
  component: Component,
  roles,
  mustSwitch,
  hideHeader,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) => {
      const currentUser = getUser();
      if (!currentUser) {
        // not logged in so redirect to login page with the return url
        return (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        );
      }

      if (roles && roles.indexOf(currentUser.user.role_code) === -1) {
        // role not authorised so redirect to home page
        return <Redirect to={{ pathname: "/access-denied" }} />;
      }

      // authorised so return component

      return (
        <Layout mustSwitch={mustSwitch} hideHeader={hideHeader}>
          <Component {...props} />
        </Layout>
      );
    }}
  />
);

export default PrivateRoute;
