import { useState, useRef, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { getUser } from "../../utils/User";
import { Role } from "../../utils/Constants";
import Select from "react-select";
import SearchButton from "../../components/buttons/SearchButton";
import Button from "../../components/buttons/Button";
import { reproject, crss } from "reproject";

import DataMapBaseLayout from "../../components/layout/data_map";
import RepairData from "./RepairData";
import RepairMap from "./RepairMap";
import ModalDownload from "./ModalDownload";

// Service
import RepairService from "../../store/actions/repair_work";
import { toastSuccess, toastError } from "../../components/commons/toast";

const Index = ({ switchPDAM, pending, repairWork }) => {
  const currentUser = getUser().user;
  const dispatch = useDispatch();

  const [search, setSearch] = useState("");
  const [status, setStatus] = useState("");
  const [showModalAdd, setShowModalAdd] = useState(false);
  const [showModalDownload, setShowModalDownload] = useState(false);
  const [activeView, setActiveView] = useState("data");
  const [detailRepair, setDetailRepair] = useState(null);
  const [dataRepair, setDataRepair] = useState([]);
  const [totalData, setTotalData] = useState(null);
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);

  const [currentCenter, setCurrentCenter] = useState(null);
  const [currentZoom, setCurrentZoom] = useState(10);
  const flyToZoom = 18;

  const dataMapBaseLayoutRef = useRef();

  const handleSearch = (value) => {
    setSearch(value);
  };

  const handleFilterStatus = (value) => {
    if (value) {
      setStatus(value?.value);
    }
  };
  const handleAddModal = () => {
    setShowModalAdd(true);
  };

  const handleRefresh = () => {
    new Promise((resolve) => {
      const param = {
        draw: 1,
        length: activeView === "peta" ? 999999 : 10,
        page: page === 0 ? 1 : page ?? 1,
        status: activeView === "peta" ? 0 : status,
        search_text: search,
        pdam_id: switchPDAM ? switchPDAM?.id : "",
        start: null,
      };
      dispatch(RepairService.get(param, resolve));
    }).then((res) => {
      if (activeView === "peta" && res?.data?.length < 1) {
        toastError("Data tidak ditemukan");
      }
      setDataRepair(res?.data);
      setDetailRepair(res?.data[0] ?? null);
      setTotalData(res?.totalCount);
      setMaxPage(Math.ceil(res?.totalCount / 10));
    });
  };

  const optionsStatus = [
    {
      label: "Baru",
      value: 0,
    },
    {
      label: "Sedang Perbaikan",
      value: 1,
    },
    {
      label: "Selesai",
      value: 2,
    },
    // {
    //   label: "Batal",
    //   value: 3,
    // },
    {
      label: "Semua Status",
      value: "",
    },
  ];

  const customSelect = {
    control: (base, state) => ({
      ...base,
      cursor: "pointer",
      background: "#fffff",
      borderRadius: "32px",
      padding: "1px",
      height: "48px",
      minWidth: "200px",
      border: "2px solid #E5E5F0",
      borderColor: state.isFocused ? "#E5E5F0" : "#E5E5F0",
      boxShadow: state.isFocused ? null : null,
      "&:hover": {
        borderColor: state.isFocused ? "#E5E5F0" : "#E5E5F0",
      },
    }),
    indicatorsContainer: (base) => ({
      ...base,
      marginRight: "7px",
    }),
    indicatorSeparator: (base) => ({
      ...base,
      backgroundColor: "#fff",
    }),
    option: (options, state) => ({
      ...options,
      cursor: "pointer",
      textAlign: "left",
      paddingLeft: "15px",
    }),
  };

  const handleDownload = (value) => {
    value.pdam_id = switchPDAM ? switchPDAM?.id : "";
    dispatch(RepairService.download(value));
  };

  const ButtonAdd = ({ variant }) => {
    return (
      <>
        <Button
          title="Tambah Laporan"
          leftIcon="add"
          size="md"
          variant={`btn-lg button-default ${
            variant === "mobile" ? "px-5 shadow" : ""
          }`}
          rounded={true}
          onClick={handleAddModal}
          style={{ maxHeight: 50 }}
          disabled={
            currentUser.role_code === Role.Kadiv ||
            currentUser.role_code === Role.Viewer
          }
        />
      </>
    );
  };

  const dataHeaderContent = (
    <>
      <SearchButton placeholder="Masukkan Kata Kunci" toggle={handleSearch} />

      <div className="mini-hide ml-3">
        <Select
          placeholder={"Status"}
          isSearchable={false}
          options={optionsStatus}
          styles={customSelect}
          onChange={(value) => handleFilterStatus(value)}
        />
      </div>

      <div className="mini-show">
        <Select
          isSearchable={false}
          options={optionsStatus}
          styles={customSelect}
          onChange={(value) => handleFilterStatus(value)}
        />
      </div>
      <div
        className="nav-separator mr-3"
        style={{ maxHeight: 50, marginLeft: 20 }}
      />

      {/* <Button
        title="Download"
        leftIcon="file_download"
        size="md"
        variant="button-light"
        rounded={true}
        onClick={() => setShowModalDownload(true)}
        style={{ maxHeight: 50 }}
        disabled={
          currentUser.role_code === Role.Kadiv ||
          currentUser.role_code === Role.Viewer
        }
      />

      <div className="mini-hide">{<ButtonAdd variant="web" />}</div> */}
    </>
  );

  const mapHeaderContent = (
    <div style={{ padding: "0px 20px" }}>
      <SearchButton placeholder="No Tiket atau Lokasi" toggle={handleSearch} />
    </div>
  );

  const dataContent = (
    <RepairData
      search={search}
      status={status}
      setShowModalAdd={() => setShowModalAdd()}
      showModalAdd={showModalAdd}
      switchPDAM={switchPDAM}
      handleRefresh={() => handleRefresh()}
      dataRepair={dataRepair}
      page={page}
      maxPage={maxPage}
      totalData={totalData}
      setPage={setPage}
    />
  );

  const mapContent = (
    <RepairMap dataRepair={dataRepair} detailRepair={detailRepair} />
  );

  useEffect(() => {
    if (detailRepair) {
      setCurrentCenter([detailRepair?.latitude, detailRepair?.longitude]);
    }
  }, [detailRepair]);

  useEffect(() => {
    setPage((prev) => (prev === 1 ? null : 1));
  }, [status, search]);

  useEffect(() => {
    handleRefresh();
  }, [page, switchPDAM, activeView]);

  useEffect(() => {
    dataMapBaseLayoutRef.current.getMap()?.flyTo(currentCenter, currentZoom);
  }, [currentCenter]);

  return (
    <div className="menu-container pr-0">
      <div>
        <DataMapBaseLayout
          ref={dataMapBaseLayoutRef}
          title="Repair Work Report"
          dataContent={dataContent}
          mapContent={mapContent}
          dataHeaderContent={dataHeaderContent}
          mapHeaderContent={mapHeaderContent}
          initialCenter={currentCenter}
          initialZoom={currentZoom}
          onChangeView={(view) => setActiveView(view)}
          elipsis
        />

        {activeView === "data" && (
          <div className="mini-show" style={{ marginBottom: "70px" }}>
            <div className="text-center fixed-bottom">
              {<ButtonAdd variant="mobile" />}
            </div>
          </div>
        )}
      </div>
      <ModalDownload
        show={showModalDownload}
        toggle={() => setShowModalDownload(false)}
        refresh={handleRefresh}
        confirm={(value) => handleDownload(value)}
      />
    </div>
  );
};

const mapStateToProps = ({ repairWork }) => {
  return { repairWork };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
