import { useEffect, useState } from "react";
import Button from "../../components/buttons/Button";
import CardFlush from "../../components/cards/cardFlush";
import { toastError } from "../../components/toast";

const HydraulicCurvePopUp = ({ curves, readOnly, toggle, show }) => {
  const [temptCurve, setTempCurve] = useState({});
  const [nameCurve, setNameCurve] = useState(null);
  const [head, setHead] = useState(null);
  const [flow, setFlow] = useState(null);

  const handleUpdCurve = (value, label) => {
    let oldCurve = temptCurve;
    if (label === "Curve Name") {
      oldCurve.name = value;
      if (show === "edit") {
        let data = curves.filter((x) => x.name === value);
        if (data && data[0]) {
          oldCurve.flow = data[0].flow;
          oldCurve.head = data[0].head;
        }
      }
    } else if (label === "Flow") {
      oldCurve.flow = value;
    } else {
      oldCurve.head = value;
    }
    setNameCurve(oldCurve.name);
    setHead(oldCurve.head);
    setFlow(oldCurve.flow);
    setTempCurve(oldCurve);
  };

  const handleSaveCurve = (isdeleted) => {
    let data = temptCurve;
    let isValid = true;

    if (data?.name && data.flow && data.head) {
      if (handleCheckIfNumber(data.flow) && handleCheckIfNumber(data.flow)) {
        let splitFlow = data.flow.includes(",") ? data.flow.split(",") : [];
        let splitHead = data.head.includes(",") ? data.head.split(",") : [];
        let sortSplitFlow = splitFlow?.sort(function (a, b) {
          return Number(a) - Number(b);
        }).join(",")
        if (splitFlow.length > 0) {
          if (splitFlow.length !== splitHead.length) {
            isValid = false;
            toastError("Jumlah data flow dan data head tidak sama");
          } else {
            if (sortSplitFlow !== data.flow.replace(" ", "")) {
              isValid = false;
              toastError("Data flow harus berurutan dari yang paling kecil");
            }
          }
        }
      } else {
        toastError(
          "Data flow dan head harus berupa angka dan koma sebagai separator"
        );
        isValid = false;
      }

      if (isValid) {
        if (toggle) {
          if (isdeleted) {
            data.deleted = true;
          }
          toggle(data);
          setTempCurve({});
          setNameCurve(null);
          setHead(null);
          setFlow(null);
        }
      }
    } else {
      toastError("Mohon lengkapi seluruh data curve");
    }
  };

  const handleCheckIfNumber = (value) => {
    let isTrue = true;
    let regexp = /[^0-9\.,]/g;
    if (!regexp.test(value)) {
      let data = value.includes(",") ? value.split(",") : [value];
      data.map((item) => {
        if (regexp.test(item)) {
          isTrue = false;
        }
      });
      return isTrue;
    } else {
      return false;
    }
  };

  return (
    <>
      <p
        style={{
          opacity: 0.5,
          fontSize: 10,
          fontWeight: 500,
          position: "absolute",
          marginTop: "-10px",
        }}
      >
        Silakan gunakan koma jika terdapat lebih dari satu data. Contoh data
        Flow : 100,300
      </p>
      <div
        className="hydraulic-information-detail"
        style={{
          height: 200,
          overflowY: "auto",
          overflowX: "hidden",
          marginLeft: 10,
          width: 300,
        }}
      >
        <CardFlush
          list
          reverse
          label="Curve Name"
          desc={nameCurve}
          options={
            show === "edit"
              ? curves.map((item) => {
                return { ...item, value: item.name, label: item.name };
              })
              : null
          }
          // outside
          size={{ label: 5, desc: 6 }}
          editable={
            readOnly ? null : (value, label) => handleUpdCurve(value, label)
          }
        />
        {(show !== "edit" || flow) && (
          <CardFlush
            list
            reverse
            label="Flow"
            desc={flow ?? "-"}
            size={{ label: 5, desc: 6 }}
            editable={
              readOnly ? null : (value, label) => handleUpdCurve(value, label)
            }
          />
        )}
        {(show !== "edit" || head) && (
          <CardFlush
            list
            reverse
            label="Head"
            desc={head ?? "-"}
            size={{ label: 5, desc: 6 }}
            editable={
              readOnly ? null : (value, label) => handleUpdCurve(value, label)
            }
          />
        )}

        <div
          style={{
            display: "flex",
            marginLeft: "auto",
            padding: "10px",
          }}
        >
          {show === "edit" && (
            <Button
              variant="button secondary"
              rounded
              title="Hapus"
              onClick={() => handleSaveCurve("delete")}
              type="Button"
              style={{ height: 0, marginLeft: "auto" }}
              disabled={readOnly}
            />
          )}
          <Button
            variant="button primary"
            rounded
            title="Simpan"
            onClick={() => handleSaveCurve()}
            type="Button"
            style={{ height: 0, marginLeft: "auto" }}
            disabled={readOnly}
          />
        </div>
      </div>
    </>
  );
};

export default HydraulicCurvePopUp;
