import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { Formik, Form, useFormikContext } from "formik";
import * as Yup from "yup";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Row,
  Col,
} from "reactstrap";
import { getUser } from "../../../utils/User";
import Select2 from "../../../components/forms/Select2";
import ButtonModal from "../../../components/modal/ButtonModal";
import ConfirmationModal from "../../../components/modal/ConfirmModal";
import Input from "../../../components/forms/Input";
import { Divider } from "@material-ui/core";
import ZoneService from "../../../store/actions/master_data/zone";
import LoaderModal from "../../../components/commons/LoaderModal"; 

function ModalAdd({
  show,
  toggle,
  refresh,
  masterDataInput,
  masterDataPDAM,
  masterDataZone,
  switchPDAM,
}) {
  const dispatch = useDispatch();
  const currentUser = getUser();

  const [loading, setLoading] = useState(false); 
  const [pdam, setPDAM] = useState([]);
  const [input, setInput] = useState([]);
  const [selectedPDAM, setSelectedPDAM] = useState(
    switchPDAM?.id ?? currentUser?.user?.pdam?.id ?? ""
  );
  const [showModalConfirm, setShowModalConfirm] = useState(false);

  useEffect(() => {
    if (masterDataPDAM.data_list) {
      let pdam_list = masterDataPDAM.data_list.data.data;
      setPDAM(pdam_list);
    }
  }, [masterDataPDAM]);

  useEffect(() => {
    if (switchPDAM?.id) {
      setSelectedPDAM(switchPDAM?.id);
    } else {
      setSelectedPDAM(currentUser?.user?.pdam?.id ?? "");
    }
  }, [switchPDAM]);

  useEffect(() => {
    if (masterDataInput) {
      let input_list = [];
      if (selectedPDAM) {
        input_list = masterDataInput?.data_list?.data?.data;
        input_list = input_list?.filter((item) => item.pdam_id === selectedPDAM);
      }
      setInput(input_list);
    }
  }, [selectedPDAM, masterDataInput]);

  const validation = Yup.object().shape({
    pdam_id: Yup.string().required("PDAM wajib diisi").nullable(),
    input_id: Yup.array().of(Yup.string()).required("Input wajib diisi").nullable(),
    name: Yup.string().required("Nama Zona wajib diisi").nullable(),
    device_id: Yup.string().required("ID Sensor wajib diisi").nullable(),
    diameter: Yup.string().required("Diameter wajib diisi").nullable(),
    longitude: Yup.string().required("Longitude wajib diisi").nullable(),
    latitude: Yup.string().required("Latitude wajib diisi").nullable(),
  });

  const handleConfirm = () => {
    setSelectedPDAM(currentUser?.user?.pdam?.id ?? "");
    setShowModalConfirm(false);
    toggle(false);
  };

  return (
    <>
      <Modal
        isOpen={show}
        modalTransition={{ timeout: 200 }}
        backdropTransition={{ timeout: 1300 }}
      >
        <LoaderModal show={loading || masterDataZone?.pending} /> 
        <ModalHeader className="text-extra-bold pt-5">
          {" "}
          Tambah Zona{" "}
          <span className="close clickable" onClick={() => setShowModalConfirm(true)}>
            &times;
          </span>
        </ModalHeader>
        <Formik
          initialValues={{
            pdam_id: currentUser?.user?.pdam?.id ?? "",
            input_id: [],
            name: "",
            device_id: "",
            diameter: "",
            longitude: "",
            latitude: "",
          }}
          validationSchema={validation}
          onSubmit={(values, { setStatus, setSubmitting }) => {
            setStatus();
            setLoading(true);  

            let formData = new FormData();
            formData.append("pdam_id", values.pdam_id);
            formData.append("input_id", values.input_id);
            formData.append("name", values.name);
            formData.append("device_id", values.device_id);
            formData.append("diameter", values.diameter);
            formData.append("longitude", values.longitude);
            formData.append("latitude", values.latitude);

            dispatch(ZoneService.post(formData, () => {
              setLoading(false);  
              toggle();
              refresh(values);
            }));
          }}
        >
          {(props) => (
            <Form style={{ marginTop: 30 }}>
              <ModalBody style={{ margin: "0px 20px" }}>
                <Select2
                  title="PDAM"
                  name="pdam_id"
                  options={pdam?.map((item) => ({
                    value: item?.id,
                    label: item?.name,
                  }))}
                  errors={props.errors}
                  touched={props.touched}
                  value={props.values?.pdam_id}
                  placeholder="Pilih data PDAM"
                  onChange={(name, value) => {
                    props.setFieldValue("pdam_id", value);
                    props.setFieldValue("input_id", []);
                    setSelectedPDAM(value);
                  }}
                  onBlur={props.setFieldTouched}
                  disabled={currentUser?.user?.pdam}
                />
                <Select2
                  title="Input"
                  name="input_id"
                  options={input?.map((item) => ({
                    value: item?.id,
                    label: item?.name,
                  }))}
                  errors={props.errors}
                  touched={props.touched}
                  value={props.values?.input_id}
                  placeholder="Pilih data Input"
                  onChange={(name, value) => {
                    props.setFieldValue("input_id", value);
                  }}
                  onBlur={props.setFieldTouched}
                  isMulti
                />
                <Divider orientation="horizontal" className="my-3" />
                <FormGroup>
                  <Input
                    title="Nama Zona"
                    name="name"
                    type="text"
                    errors={props?.errors}
                    touched={props?.touched}
                    {...props?.getFieldProps("name")}
                    placeholder="Masukkan nama zona"
                    value={props.values?.name}
                  />
                </FormGroup>
                <FormGroup>
                  <Input
                    title="ID Sensor"
                    name="device_id"
                    type="text"
                    errors={props?.errors}
                    touched={props?.touched}
                    {...props?.getFieldProps("device_id")}
                    placeholder="Masukkan id sensor"
                    value={props.values?.device_id}
                  />
                </FormGroup>
                <FormGroup>
                  <Input
                    title="Diameter"
                    name="diameter"
                    type="number"
                    errors={props?.errors}
                    touched={props?.touched}
                    {...props?.getFieldProps("diameter")}
                    placeholder="Masukkan diameter"
                    value={props.values?.diameter}
                  />
                </FormGroup>
                <Row>
                  <Col md={6}>
                    <FormGroup>
                      <Input
                        title="Longitude"
                        name="longitude"
                        type="number"
                        errors={props?.errors}
                        touched={props?.touched}
                        {...props?.getFieldProps("longitude")}
                        placeholder="Masukkan longitude"
                        value={props.values?.longitude}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Input
                        title="Latitude"
                        name="latitude"
                        type="number"
                        errors={props?.errors}
                        touched={props?.touched}
                        {...props?.getFieldProps("latitude")}
                        placeholder="Masukkan latitude"
                        value={props.values?.latitude}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </ModalBody>
              <ModalFooter>
                <ButtonModal
                  toggle={() => setShowModalConfirm(true)}
                  confirmTitle="Simpan"
                  confirm={props.handleSubmit}
                  disabledConfrim={loading || masterDataZone?.pending}
                />
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </Modal>

      <ConfirmationModal
        isDelete
        show={showModalConfirm}
        toggle={() => setShowModalConfirm(false)}
        confirm={handleConfirm}
        cancelTitle="Tidak"
        confirmTitle="Ya"
        message="Yakin ingin membatalkan import data?"
        icon="info"
        disabledConfrim={masterDataZone?.pending}
      />
    </>
  );
}

const mapStateToProps = ({ masterDataZone, masterDataInput, masterDataPDAM }) => {
  return { masterDataZone, masterDataInput, masterDataPDAM };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalAdd);
