import React, { useState, useRef } from "react";
import { connect, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Container,
} from "reactstrap";
import Divider from "@material-ui/core/Divider";
import Input from "../../../forms/Input";
import ButtonModal from "../../../modal/ButtonModal";

import UserService from "../../../../store/actions/user";

function ChangePassword({ t, show, toggle, confirm }) {
  const formikRef = useRef();
  const dispatch = useDispatch();
  const [isMatch, setIsMatch] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [matchMessage, setMatchMessage] = useState(null);
  const [checkMessage, setCheckMessage] = useState(null);
  const alphanumeric = /^(?=.*[a-zA-Z])(?=.*[0-9])/;
  const alphaspecial = /[a-zA-Z]+[(@!#\$%\^\&*\)\(+=._-]{1,}/;
  const numericspecial = /[0-9]+[(@!#\$%\^\&*\)\(+=._-]{1,}/;

  function checkPassword() {
    const values = formikRef?.current?.values;
    const value = values?.new_password;
    setMatchMessage("");
    setIsValid(false);
    formikRef?.current.setFieldValue("confirmation_password", "");
    if (values?.new_password?.length < 8) {
      setCheckMessage("Password minimal terdiri dari 8 karakter");
      setIsValid(false);
    } else if (
      value.match(alphanumeric) === null &&
      value.match(alphaspecial) === null &&
      value.match(numericspecial) === null
    ) {
      setCheckMessage(
        "Password minimal terdiri dari kombinasi huruf, angka, atau karakter spesial"
      );
      setIsValid(false);
    } else {
      setCheckMessage(null);
      setIsValid(true);
    }
  }

  function confirmationPassword() {
    const values = formikRef?.current?.values;

    if (values?.new_password?.length > 7 && values?.confirmation_password) {
      if (values?.new_password === values?.confirmation_password) {
        setIsMatch(true);
        setMatchMessage("Kata sandi baru dan konfirmasi sesuai");
      } else {
        setIsMatch(false);
        setMatchMessage("Kata sandi baru dan konfirmasi tidak sesuai");
      }
    } else {
      setIsMatch(false);
      setMatchMessage(null);
    }
  }

  const validation = Yup.object().shape({
    old_password: Yup.string()
      .required("Kata sandi lama" + " wajib diisi")
      .nullable(),
    new_password: Yup.string()
      .required("Kata sandi baru" + " wajib diisi")
      .nullable(),
    confirmation_password: Yup.string()
      .required("Konfirmasi Kata Sandi" + " wajib diisi")
      .nullable(),
  });

  return (
    <>
      <Modal
        isOpen={show}
        modalTransition={{ timeout: 700 }}
        backdropTransition={{ timeout: 1300 }}
      >
        <ModalHeader className="text-extra-bold pt-5">
          {"Ubah Kata Sandi"}
          <span className="close clickable" onClick={toggle}>
            &times;
          </span>
        </ModalHeader>
        <Formik
          innerRef={formikRef}
          initialValues={{
            old_password: "",
            new_password: "",
            confirmation_password: "",
          }}
          validationSchema={validation}
          onSubmit={(values, { setStatus, setSubmitting }) => {
            setStatus();
            dispatch(UserService.changePassword(values));
          }}
        >
          {(props) => (
            <Form>
              <ModalBody>
                <Container>
                  <Input
                    title={"Kata sandi lama"}
                    name="old_password"
                    errors={props.errors}
                    touched={props.touched}
                    {...props.getFieldProps("old_password")}
                    placeholder={"Kata sandi lama"}
                    type="password"
                  />
                  <Divider
                    orientation="horizontal"
                    variant="middle"
                    style={{ margin: "30px 0px 20px" }}
                  />
                  <Input
                    title={"Kata sandi baru"}
                    name="new_password"
                    errors={props.errors}
                    touched={props.touched}
                    onKeyUp={() => checkPassword()}
                    {...props.getFieldProps("new_password")}
                    placeholder={"Kata sandi baru"}
                    type="password"
                  />
                  {checkMessage && (
                    <div
                      className="subtitle text-medium"
                      style={{
                        fontSize: 10,
                        marginTop: "-5px",
                        marginBottom: 10,
                      }}
                    >
                      {checkMessage}
                    </div>
                  )}
                  <Input
                    title={"Konfirmasi Kata Sandi"}
                    name="confirmation_password"
                    errors={props.errors}
                    touched={props.touched}
                    onKeyUp={() => confirmationPassword()}
                    {...props.getFieldProps("confirmation_password")}
                    placeholder={"Konfirmasi Kata Sandi"}
                    type="password"
                  />
                  {matchMessage && (
                    <div
                      className="text-medium"
                      style={{
                        fontSize: 10,
                        color: isMatch ? "#04AA59" : "#D10D0D",
                        marginTop: "-5px",
                        marginBottom: 10,
                      }}
                    >
                      {matchMessage}
                    </div>
                  )}
                </Container>
              </ModalBody>
              <ModalFooter>
                <ButtonModal
                  toggle={toggle}
                  confirm={confirm}
                  confirmTitle="Ubah Sandi"
                  disabledConfrim={!isMatch || !isValid}
                />
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
}

const mapStateToProps = ({ user }) => {
  return { user };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ChangePassword)
);
