import React, {
  useState,
  useMemo,
  forwardRef,
  useImperativeHandle,
} from "react";
import proj4 from "proj4";
import { MapContainer, TileLayer, GeoJSON } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import "./map.scss";

const Map = forwardRef(
  ({ defaultCenter, defaultZoom, type, children, onCreated, data }, ref) => {
    const [map, setMap] = useState(null);
    const currentCenter = defaultCenter ? defaultCenter : [-6.8116, 107.6174];
    const currentZoom = defaultZoom ? defaultZoom : 10;

    useImperativeHandle(ref, () => ({
      zoomIn(delta) {
        if (map) map.zoomIn(delta);
      },
      zoomOut(delta) {
        if (map) map.zoomOut(delta);
      },
      flyTo(coordinate, zoomLevel) {
        if (map)
          map.flyTo(coordinate, zoomLevel, {
            animate: true,
            duration: 1.5,
          });
      },
      getMap() {
        return map;
      },
    }));

    return (
      <MapContainer
        key={data ?? 1}
        id="map"
        whenCreated={(map) => {
          setMap(map);
          onCreated?.();
        }}
        center={currentCenter}
        zoom={currentZoom}
        scrollWheelZoom={true}
        zoomControl={false}
      >
        {type == "Default" && (
          <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        )}
        {type == "Satelit" && (
          <TileLayer url="http://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}" />
        )}
        {children}
      </MapContainer>
    );
  }
);

export default Map;
