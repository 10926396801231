import { history } from "../../../utils/History";
import { actionPending, actionSuccess, actionError } from "../actionTypes";

import { toastSuccess, toastError } from "../../../components/commons/toast";

import API from "../API";
import { handleResponse } from "../HandleRespone";

// ACTION TYPES TO DISPATCH: METHOD_URL_TYPES
export const GET_WARNING_SYSTEM_PENDING = "GET_WARNING_SYSTEM_PENDING";
export const GET_WARNING_SYSTEM_SUCCESS = "GET_WARNING_SYSTEM_SUCCESS";
export const GET_WARNING_SYSTEM_ERROR = "GET_WARNING_SYSTEM_ERROR";
export const PUT_WARNING_SYSTEM_PENDING = "PUT_WARNING_SYSTEM_PENDING";
export const PUT_WARNING_SYSTEM_SUCCESS = "PUT_WARNING_SYSTEM_SUCCESS";
export const PUT_WARNING_SYSTEM_ERROR = "PUT_WARNING_SYSTEM_ERROR";

// URL: URL_{URL}
const WARNING_SYSTEM_URL = `v1/physical_losses`;

const get = (param, resolve, reject, callback) => (dispatch) => {
  dispatch(actionPending(GET_WARNING_SYSTEM_PENDING));
  API.get(WARNING_SYSTEM_URL, { params: param })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(GET_WARNING_SYSTEM_SUCCESS, res));
      if (resolve) {
        const records_total = res.data.total;
        let data = res.data.data.map((item, i) => ({
          ...item,
          no: i + 1 + (param?.page - 1) * param?.length,
        }));
        resolve({
          data: data,
          page: param?.page - 1,
          totalCount: records_total,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(GET_WARNING_SYSTEM_ERROR));
      let not_logout = handleResponse(err?.response);
      if (not_logout) {
        let message = err?.response?.data?.message;
        if (message?.includes("java")) {
          message = "Data tidak ditemukan";
        }
        toastError(message);
      }
      // toastError(err?.response?.data?.message);
    });
};

const put = (param, resolve, reject, callback) => (dispatch) => {
  dispatch(actionPending(PUT_WARNING_SYSTEM_PENDING));
  API.put(WARNING_SYSTEM_URL + "/update_status", param)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(PUT_WARNING_SYSTEM_SUCCESS, res));
      toastSuccess("Berhasil Ubah Data Warning System");
      if (resolve) {
        resolve({
          data: res.data.data,
          message: res.data.message,
          status: res.data.status,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(PUT_WARNING_SYSTEM_ERROR));
      toastError(err?.response?.data?.message);
    });
};

const pdam = {
  get,
  put,
};
export default pdam;
