import React, { useState, useRef, useEffect } from "react"
import { connect, useDispatch } from "react-redux"
import Map from "../../components/map"
import Button from "../../components/buttons/Button"
import HeaderMenu from "../../components/commons/menu/HeaderMenu"
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap"
import L from "leaflet"
import { Workspace, Project, NodeType, LinkType, InitHydOption, NodeProperty, LinkProperty, CountType, Option, FlowUnits, HeadLossType } from "epanet-js"
import moment from "moment"
import localization from "moment/locale/id"
import { FeatureGroup, Polyline, Circle } from "react-leaflet"
import { OpenStreetMapProvider } from "leaflet-geosearch"

import { getUser } from "../../utils/User"
import { history } from "../../utils/useHistory"
import { convertCoordinate } from "../../utils/ConverCoordinate"
import { useClickaway } from "../../components/hooks/ClickawayHooks"
import { toastSuccess, toastError } from "../../components/toast/index"

import Loader from "../../components/commons/Loader"
import ConfirmationModal from "../../components/modal/ConfirmModal"
import SearchButton from "../../components/buttons/SearchAutocomplete"

import MarkerComponent from "./Marker"
import HydraulicPopUp from "./HydraulicPopUp"
import HydraulicDrawMap from "./HydraulicDrawMap"
import HydraulicToolBox from "./HydraulicToolBox"
import HydraulicConfirmationModal from "./HydraulicConfirmModal"
import Legend from "./HydraulicLegend"

import reservoar from "../../assets/images/marker/reservoar.svg"
import pump from "../../assets/images/marker/pump.svg"
import katup from "../../assets/images/marker/katup.svg"
import tank from "../../assets/images/marker/tank.svg"
import junction from "../../assets/images/marker/junction.svg"

import HydraulicService from "../../store/actions/hydraulic_model"
import ElevationService from "../../store/actions/elevation"

import "leaflet/dist/leaflet.css"
import "leaflet-geosearch/dist/geosearch.css"
import "leaflet-draw/dist/leaflet.draw.css"

const HydraulicMap = ({ match, pending }) => {
  const mapRef = useRef()
  const dropdownRef = useRef()
  const [title, setTitle] = useState("")
  const [saved, setSaved] = useState(0)
  const [status, setStatus] = useState(0)
  const [searchMap, setSearchMap] = useState("")
  const [show, setShow] = useState(false)
  const [cursor, setCursor] = useState("default")
  const [initialZoom, setInitialZoom] = useState(18)
  const [suggestion, setSuggestion] = useState([])
  const [selectMapShow, setSelectMapShow] = useState(false)
  const [selectedMapType, setSelectedMapType] = useState("Default")
  const [showToolbar, setShowToolbar] = useState(match?.params?.type ? false : true)
  const [initialCenter, setInitialCenter] = useState([-6.902253152913098, 107.6187018])
  const [icon, setIcon] = useState(null)
  const [dataHydraulic, setDataHydraulic] = useState(localStorage.getItem("hydraulic") ? JSON.parse(localStorage.getItem("hydraulic")) : [])
  const [dmaGeoJson, setDmaGeoJson] = useState(null)
  const [lineGeoJson, setLineGeoJson] = useState(null)
  const [inputGeoJson, setInputGeoJson] = useState(null)
  const [zoneGeoJson, setZoneGeoJson] = useState(null)
  const [customerGeoJson, setCustomerGeoJson] = useState(null)
  const [showMessage, setShowMessage] = useState(false)
  const [popUpOption, setPopUpOption] = useState(null)
  const [showPopUp, setShowPopUp] = useState(null)
  const [isShow, setIsShow] = useState(null)
  const [drawLine, setDrawLine] = useState(false)
  const [junctionItem, setJunctionItem] = useState(false)
  const [editableFG, setEditableFG] = useState(null)
  const [showModalConfirm, setShowModalConfirm] = useState(null)
  const [showModalDelete, setShowModalDelete] = useState(null)
  const [polylineDrawer, setPolylineDrawer] = useState(null)
  const [deleteActive, setDeleteActive] = useState(false)
  const [itemData, setItemData] = useState(null)
  const [report, setReport] = useState(null)
  const [draggable, setDraggable] = useState(false)
  const [iconCursor, setIconCursor] = useState("cursor")
  const [dataPressure, setDataPressure] = useState(null)
  const [dataLink, setDataLink] = useState(null)
  const [key, setKey] = useState(Math.random())
  const [isSimulation, setIsSimulation] = useState(false)
  const [curves, setCurves] = useState(null)
  const [errorElement, setErrorElement] = useState(null)
  const [limitationData, setLimitationData] = useState(1000)

  const dispatch = useDispatch()
  const currentUser = getUser().user

  const toggleSelectMap = () => setSelectMapShow(!selectMapShow)

  const handleSearchMap = (value) => {
    setSearchMap(value)
    setShow(value)
  }

  const handleShowPopUp = (value, id, name) => {
    setPopUpOption(null)
    if (id === showPopUp) {
      setShowPopUp(null)
      setIsShow(null)
    } else {
      if (id && value) {
        setShowPopUp(id)
        setPopUpOption(value)
        setIsShow(name)
      }
    }
  }

  useEffect(() => {
    if (icon && editableFG) {
      editableFG.clearLayers()
      setIconCursor("cursor")

      let cursor = icon
      delete L.Icon.Default.prototype._getIconUrl
      L.Icon.Default.mergeOptions({
        iconRetinaUrl: cursor,
        iconUrl: cursor,
        shadowUrl: "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/images/marker-shadow.png",
      })
    }
  }, [icon])

  useEffect(() => {
    const fetchData = async () => {
      if (show) {
        const provider = new OpenStreetMapProvider()
        const results = await provider.search({ query: searchMap })
        setSuggestion(results.slice(0, 10))
      }
    }

    fetchData()
  }, [show, searchMap])

  useEffect(() => {
    const updateGeoJson = async () => {
      if (dataPressure) {
        let junctionData = customerGeoJson
        let features = junctionData?.features
        let zoneData = zoneGeoJson
        let featuresZone = zoneData?.features
        let dmaData = dmaGeoJson
        let featuresDMA = dmaData?.features
        let input = inputGeoJson
        let featuresInput = input?.features

        dataPressure?.forEach((item) => {
          let index = features?.findIndex((x) => {
            let name = x.properties.NAME ?? x.properties.NAMA ?? x.properties.name
            return name.replace(/\s/g, "") === item?.label
          })

          let indexZone = featuresZone?.findIndex((x) => {
            let name = x.properties.NAME ?? x.properties.NAMA ?? x.properties.name
            return name.replace(/\s/g, "") === item?.label
          })

          let indexDMA = featuresDMA?.findIndex((x) => {
            let name = x.properties.NAME ?? x.properties.NAMA ?? x.properties.name
            return name.replace(/\s/g, "") === item?.label
          })

          let indexInput = featuresInput?.findIndex((x) => {
            let name = x.properties.NAME ?? x.properties.NAMA ?? x.properties.name
            return name.replace(/\s/g, "") === item?.label
          })

          if (index > -1) {
            features[index].properties.PRESSURE = item?.value
          }

          if (indexZone > -1) {
            featuresZone[indexZone].properties.PRESSURE = item?.value
          }

          if (indexDMA > -1) {
            featuresDMA[indexDMA].properties.PRESSURE = item?.value
          }

          if (indexInput > -1) {
            featuresInput[indexInput].properties.PRESSURE = item?.value
          }
        })

        setCustomerGeoJson(junctionData)
        setZoneGeoJson(zoneData)
        setDmaGeoJson(dmaData)
        setInputGeoJson(inputGeoJson)
      }

      if (dataLink) {
        let linkData = lineGeoJson
        let features = linkData.features
        if (features) {
          dataLink.forEach((item) => {
            let index = features?.findIndex((x) => {
              let name = x.properties.NAME ?? x.properties.NAMA ?? x.properties.name
              return name.replace(/\s/g, "") === item?.label
            })
            if (index > -1) {
              features[index].properties.VELOCITY = item?.velocity
              features[index].properties.FLOW = item?.flow
            }
          })
          setLineGeoJson(linkData)
        }
      }
    }

    updateGeoJson()
  }, [dataPressure, dataLink])

  const handleClickSuggestion = (value) => {
    if (value) {
      setSearchMap(value.label)
      setInitialCenter([value.y, value.x])
      setSuggestion([])
      setShow(false)
    }
  }

  const handleEdit = (value) => {
    changeCursor(value)
    setDraggable(true)
  }

  const changeCursor = (value) => {
    setDeleteActive(false)
    setCursor(value)
  }

  const calculateLength = (nodeStart, nodeEnd) => {
    if (nodeStart && nodeEnd) {
      let firstCoordinate = L.latLng(nodeStart)
      let lastCoordinate = L.latLng(nodeEnd)
      let length = firstCoordinate?.distanceTo(lastCoordinate)
      return length.toFixed(2)
    } else {
      return 0
    }
  }

  const getElevation = (data) => {
    return new Promise((resolve) => {
      dispatch(ElevationService.getElevation(data, resolve))
    })
  }

  useEffect(() => {
    if (mapRef) {
      mapRef?.current?.getMap()?.flyTo(initialCenter, 18)
    }
  }, [initialCenter, lineGeoJson])

  useClickaway(dropdownRef, () => {
    setShowPopUp(null)
    setIsShow(null)
  })

  useEffect(() => {
    if (dataHydraulic?.name) {
      setTitle(dataHydraulic?.name)
    }

    let allNode = []

    if (dataHydraulic?.dma_geojson) {
      let dmaGeoJson = dataHydraulic?.dma_geojson
      let initial = !dataHydraulic?.input_geojson && !dataHydraulic.customer_geojson
      dmaGeoJson.features = cleanJunction(dmaGeoJson, initial)
      allNode = [...allNode, ...dmaGeoJson.features]
      setDmaGeoJson(dmaGeoJson)
    }

    if (dataHydraulic?.zone_geojson) {
      let zoneGeojson = dataHydraulic?.zone_geojson
      let initial = !dataHydraulic?.input_geojson && !dataHydraulic.customer_geojson && !dataHydraulic.dma_geojson
      zoneGeojson.features = cleanJunction(zoneGeojson, initial)
      allNode = [...allNode, ...zoneGeojson.features]
      setZoneGeoJson(zoneGeojson)
    }

    if (dataHydraulic?.customer_geojson) {
      let customerGeojson = dataHydraulic?.customer_geojson
      let initial = !dataHydraulic?.input_geojson
      customerGeojson.features = cleanJunction(customerGeojson, initial)
      allNode = [...allNode, ...customerGeojson.features]
      setCustomerGeoJson(customerGeojson)
    }

    if (dataHydraulic?.input_geojson) {
      let inputGeojson = dataHydraulic?.input_geojson
      inputGeojson.features = cleanJunction(inputGeojson, true)
      allNode = [...allNode, ...inputGeojson.features]
      setInputGeoJson(inputGeojson)
    }

    if (dataHydraulic?.pipeline_geojson) {
      let lineGeoJson = dataHydraulic?.pipeline_geojson
      let arrayLine = []

      lineGeoJson?.features?.map((item, index) => {
        if (item?.geometry?.coordinates) {
          let coordinate = null
          let node1 = null
          let node2 = null
          if (item?.properties?.clean) {
            coordinate = item?.geometry?.coordinates
            if (allNode.length > lineGeoJson?.features.length) {
              node1 = item.properties.NODE1
              node2 = item.properties.NODE2
            } else {
              allNode.map((node) => {
                let coor = L.latLng(node.geometry.coordinates)
                let startCoor = L.latLng(coordinate[0])
                let endCoor = L.latLng(coordinate[coordinate.length - 1])
                let props = node.properties
                if (startCoor.distanceTo(coor) < 5) {
                  node1 = props.NAME ?? props.NAMA ?? props.Name
                }
                if (endCoor.distanceTo(coor) < 5) {
                  node2 = props.NAME ?? props.NAMA ?? props.Name
                }
              })
            }
          } else {
            coordinate = convertCoordinate(item?.geometry?.coordinates, item?.geometry?.type)
            // check coordinate exactly same
            if (allNode && allNode.length > 0) {
              allNode.map((node) => {
                let coor = L.latLng(node.geometry.coordinates)
                let startCoor = L.latLng(coordinate[0])
                let endCoor = L.latLng(coordinate[coordinate.length - 1])
                let props = node.properties
                if (startCoor.distanceTo(coor) < 5) {
                  node1 = props.NAME ?? props.NAMA ?? props.Name
                }
                if (endCoor.distanceTo(coor) < 5) {
                  node2 = props.NAME ?? props.NAMA ?? props.Name
                }
              })
            }
          }

          let initial = !dataHydraulic?.input_geojson && !dataHydraulic.customer_geojson && !dataHydraulic.dma_geojson && !dataHydraulic?.zone_geojson
          if (index === 0 && initial) {
            setInitialCenter(coordinate[0])
          }

          let feature = lineGeoJson.features[index]
          let geometry = feature.geometry
          let properties = feature.properties
          geometry.coordinates = coordinate
          properties.clean = true
          properties.NAME = properties.NAME ?? properties.NAMA ?? properties.Name ?? properties.PIPE_ID
          properties.PIPE_ID = properties.NAME
          properties.NODE1 = node1 ?? null
          properties.NODE2 = node2 ?? null
          properties.ELEVATION = properties.ELEVATION ?? properties.ELV1
          feature.geometry = geometry
          feature.properties = properties
          arrayLine = [...arrayLine, feature]
        }
      })
      lineGeoJson.features = arrayLine
      setLineGeoJson(lineGeoJson)
    }

    if (dataHydraulic?.status !== undefined && dataHydraulic?.status !== null) {
      setStatus(dataHydraulic?.status)
    }

    if (dataHydraulic?.curves !== null && dataHydraulic?.curves !== undefined) {
      setCurves(dataHydraulic?.curves)
    }

    if (dataHydraulic) {
      setShowMessage(false)
    }
  }, [dataHydraulic])

  const cleanJunction = (data, initial) => {
    let arrayLine = []
    let dataJunction = data
    if (dataJunction.features) {
      dataJunction.features.map((item, index) => {
        if (item?.geometry?.coordinates && item?.geometry.type === "Point") {
          let coordinate = item?.properties?.clean ? item?.geometry?.coordinates : [item?.geometry?.coordinates[1], item?.geometry?.coordinates[0]]
          if (index === 0 && initial) {
            setInitialCenter(coordinate)
          }
          let feature = dataJunction.features[index]
          let geometry = feature.geometry
          let properties = feature.properties
          properties.clean = true
          feature.properties = properties
          geometry.coordinates = coordinate
          feature.geometry = geometry
          arrayLine = [...arrayLine, feature]
        }
      })
    }
    return arrayLine
  }

  const removeDuplicate = (data) => {
    let dataArray = data
    let result = dataArray.filter((e, i) => {
      return (
        dataArray.findIndex((x) => {
          return x.properties.NAME == e.properties.NAME && x.geometry.coordinates.toString() == e.geometry.coordinates.toString()
        }) == i
      )
    })
    let dataLine = lineGeoJson
    dataLine.features = result
    setLineGeoJson(dataLine)
    return dataLine
  }

  const changeColor = (type, value) => {
    return type === "junction"
      ? value === undefined || value === null
        ? "black"
        : value <= 25
        ? "blue"
        : value <= 50
        ? "aqua"
        : value <= 75
        ? "lime"
        : value <= 100
        ? "yellow"
        : "red"
      : value === undefined || value === null
      ? "black"
      : value <= 0.01
      ? "blue"
      : value <= 0.1
      ? "aqua"
      : value <= 1
      ? "lime"
      : value <= 2
      ? "yellow"
      : "red"
  }

  const handleRunSimulation = () => {
    setIsSimulation(true)
    setErrorElement(null)
    let isError = 0
    let countPressureNegative = 0
    let dataPressure = []
    let dataPipe = []
    let isValidRunSimulation = lineGeoJson && inputGeoJson && (customerGeoJson || zoneGeoJson || dmaGeoJson)
    if (isValidRunSimulation) {
      // init model project epanet
      const ws = new Workspace()
      const model = new Project(ws)
      const startTime = moment(new Date()).format("ddd MMM DD hh:mm:ss YYYY")

      model.init("report.rpt", "out.bin", 5, 0)
      model.setTitle(dataHydraulic?.name, "", "")
      model.writeLine(`Analysis begun ${startTime}`)

      // setting flow unit
      model.setFlowUnits(FlowUnits.LPS)

      // setting model option
      model.setOption(Option.HeadlossForm, HeadLossType.HW)
      model.setOption(Option.Accuracy, 0.001)
      model.setOption(Option.Unbalanced, 1)
      model.setOption(Option.Trials, 40)
      model.setOption(Option.SpGravity, 1)
      model.setOption(Option.SpViscos, 1)

      if (inputGeoJson.features) {
        let reservoarData = inputGeoJson?.features
        reservoarData?.map((item, index) => {
          let geometry = item?.geometry
          let properties = item?.properties
          let name = properties?.NAME ?? properties?.NAMA ?? properties?.Name
          if (name) {
            let node = null
            let type = item?.properties?.TYPE === "Tank" ? NodeType.Tank : NodeType.Reservoir
            try {
              node = model.addNode(name.replace(/\s/g, ""), type)
            } catch (err) {
              node = undefined
              isError = handleErrorSimulation(isError, err)
              if (isError === 1) {
                setErrorElement(name.replace(/\s/g, ""))
                setInitialCenter(item.geometry.coordinates)
              }
            }

            if (node) {
              if (geometry.coordinates) {
                model.setCoordinates(node, geometry?.coordinates[0], geometry?.coordinates[1])
              }
              if (type === 2) {
                let elev = properties?.ELEVATION ? Number(properties?.ELEVATION) : 0
                let initlvl = properties?.INITLEVEL ? Number(properties?.INITLEVEL) : 5
                let minlvl = properties?.MINLEVEL ? Number(properties?.MINLEVEL) : 0
                let maxlvl = properties?.MAXLEVEL ? Number(properties?.MAXLEVEL) : 10
                let diam = properties?.DIAMETER ? Number(properties?.DIAMETER) : 10
                let minvol = properties?.MINVOL ? Number(properties?.MINVOL) : 0

                // check if node connect to pump

                if (initlvl > maxlvl) {
                  isError = isError + 1
                  toastError("Initial level tidak boleh lebih besar dari maksimum level")
                } else if (initlvl < minlvl) {
                  isError = isError + 1
                  toastError("Initial level tidak boleh lebih kecil dari minimum level")
                } else if (minlvl > maxlvl) {
                  isError = isError + 1
                  toastError("Minimum level tidak boleh lebih besar dari maksimum level")
                } else {
                  try {
                    model.setTankData(node, elev, initlvl, minlvl, maxlvl, diam, minvol, "")

                    model.setNodeValue(node, NodeProperty.Elevation, elev)
                  } catch (err) {
                    isError = handleErrorSimulation(isError, err)
                  }
                }
              } else {
                // Reservoir type
                let elev = properties?.ELEVATION ? Number(properties?.ELEVATION) : 0
                model.setNodeValue(node, NodeProperty.Elevation, elev)
              }
            }
          } else {
            isError = isError + 1
            if (isError === 0) {
              toastError(`Data reservoar/tank ${index + 1} tidak memiliki nama, silakan atur nama terlebih dahulu`)
            }
          }
        })
      }

      // add all junction to one variable
      let juncCustomer = customerGeoJson ? customerGeoJson?.features : []
      let juncDMA = dmaGeoJson ? dmaGeoJson?.features : []
      let juncZone = zoneGeoJson ? zoneGeoJson?.features : []
      let joinJunction = [...juncCustomer, ...juncDMA, ...juncZone]
      if (joinJunction) {
        joinJunction.map((item, index) => {
          let geometry = item?.geometry
          let properties = item?.properties
          let name = properties?.NAMA ?? properties?.NAME ?? properties?.Name
          if (name && isError === 0) {
            let node = null
            try {
              node = model.addNode(name.replace(/\s/g, ""), NodeType.Junction)
            } catch (err) {
              isError = handleErrorSimulation(isError, err)
              if (isError === 1) {
                setErrorElement(name.replace(/\s/g, ""))
                setInitialCenter(item.geometry.coordinates)
              }
            }
            if (node) {
              if (geometry.coordinates) {
                model.setCoordinates(node, geometry?.coordinates[0], geometry?.coordinates[1])
              }
              let elev = properties?.ELEVATION ? Number(properties?.ELEVATION) : 0
              let dmnd = properties?.DEMAND ? Number(properties?.DEMAND) : 0
              let baseDmnd = properties?.BASEDEMAND ? Number(properties?.BASEDEMAND) : 0

              model.setJunctionData(node, elev, dmnd, "")
              model.addDemand(node, baseDmnd, "", "")
            }
          } else {
            isError = isError + 1
            if (isError === 0) {
              toastError(`Data junction ${index + 1} tidak memiliki nama, silakan atur nama terlebih dahulu`)
            }
          }
        })
      }

      // setting curve
      if (curves) {
        curves.map((item) => {
          let curveId = null
          try {
            model.addCurve(`${item.name.replace(/\s/g, "")}`)
          } catch (err) {
            isError = handleErrorSimulation(isError, err)
          }

          try {
            curveId = model.getCurveIndex(`${item.name.replace(/\s/g, "")}`)
          } catch (err) {
            isError = handleErrorSimulation(isError, err)
          }

          if (curveId) {
            let xValues = item.flow ? item?.flow.split(",").map((item) => Number(item)) : []
            let yValues = item.head ? item.head.split(",").map((item) => Number(item)) : []
            if (xValues.length === yValues.length) {
              xValues.map((item, index) => {
                try {
                  model.setCurveValue(curveId, index + 1, Number(item), Number(yValues[index]))
                } catch (err) {
                  isError = handleErrorSimulation(isError, err)
                }
              })
            }
          }
        })
      }

      // setting pipa data
      if (isError === 0 && lineGeoJson.features) {
        let pipeData = lineGeoJson?.features
        pipeData?.map((item, index) => {
          let properties = item?.properties
          let type = properties?.TYPE
          let name = properties?.NAME ?? properties?.NAMA ?? properties?.Name
          if (name && properties.NODE1 && properties?.NODE2 && isError === 0) {
            let link = null
            try {
              link = model.addLink(name.replace(/\s/g, ""), type === "Pump" ? LinkType.Pump : handleTypeValve(type), properties?.NODE1.replace(/\s/g, ""), properties?.NODE2.replace(/\s/g, ""))
            } catch (err) {
              isError = handleErrorSimulation(isError, err)
              console.log("error name", name)
              if (isError === 1) {
                setErrorElement(name.replace(/\s/g, ""))
                setInitialCenter(item.geometry.coordinates[0])
              }
            }

            if (link) {
              let propsStatus = properties?.STATUS ? (properties?.STATUS).toLowerCase() : null
              let status = propsStatus === "open" ? 1 : 0
              model.setLinkValue(link, LinkProperty.Status, status)
              if (type === "Pump") {
                let curvePump = properties?.CURVE?.replace(/\s/g, "")
                if (curvePump !== null && curvePump !== undefined) {
                  let curveIndex = null
                  try {
                    curveIndex = model.getCurveIndex(`${curvePump}`)
                  } catch (err) {
                    isError = handleErrorSimulation(isError, err)
                  }
                  if (curveIndex !== null) {
                    try {
                      model.setHeadCurveIndex(link, curveIndex)
                    } catch (err) {
                      isError = handleErrorSimulation(isError, err)
                    }
                  }
                } else {
                  isError = isError + 1
                  toastError("Silakan melakukan pengaturan data curve pada pompa")
                }
              } else if (type === null || type === undefined) {
                let length = properties?.PANJANG ? Number(properties?.PANJANG) : 1000
                let diameter = properties?.DIAMETER ? Number(properties?.DIAMETER) : 12
                let roughness = properties?.ROUGHNESS ? Number(properties?.ROUGHNESS) : 100
                let mloss = properties?.MINORLOSS ? Number(properties?.MINORLOSS) : 0
                model.setPipeData(link, length, diameter, roughness, mloss)
              } else {
              }
            }
          } else {
            isError = isError + 1
            if (isError === 1) {
              setErrorElement(name.replace(/\s/g, ""))
              setInitialCenter(item.geometry.coordinates[0])
              toastError("Simulasi Gagal, mohon periksa kembali data pipa")
            }
          }
        })
      }

      const nodeCount = model.getCount(CountType.NodeCount)
      const linkCount = model.getCount(CountType.LinkCount)

      let nodeIds = []
      let linkIds = []

      if (isError === 0) {
        // Push node into array
        for (let i = 1; i <= nodeCount; i++) {
          const nodeId = model.getNodeId(i)
          nodeIds.push(nodeId)
        }

        // Push link into array
        if (linkCount === 1) {
          const linkId = model.getLinkId(linkCount)
          linkIds.push(linkId)
        } else {
          for (let i = 1; i < linkCount + 1; i++) {
            const linkId = model.getLinkId(i)
            linkIds.push(linkId)
          }
        }

        console.log(nodeIds.length)
        console.log(linkIds.length)

        nodeIds?.map((item) => {
          const fireFlowNodeIndex = model.getNodeIndex(item)
          try {
            model.openH()
          } catch (err) {
            isError = handleErrorSimulation(isError, err)
            if (isError === 1) {
              setErrorElement(item)
              let coor = null
              try {
                coor = model.getCoordinates(fireFlowNodeIndex)
              } catch (err) {}
              if (coor) {
                setInitialCenter([coor.x, coor.y])
              }
            }
          }

          if (isError === 0) {
            model.initH(InitHydOption.SaveAndInit)
          }
          let tStep = Infinity
          do {
            try {
              model.runH()
            } catch (err) {
              isError = handleErrorSimulation(isError, err)
              if (isError === 1) {
                setErrorElement(item)
                let coor = null
                try {
                  coor = model.getCoordinates(fireFlowNodeIndex)
                } catch (err) {}
                if (coor) {
                  setInitialCenter([coor.x, coor.y])
                }
              }
            }

            const pressure = model.getNodeValue(fireFlowNodeIndex, NodeProperty.Pressure)

            if (pressure < 0) {
              countPressureNegative = countPressureNegative + 1
            }

            dataPressure = [...dataPressure, { label: item, value: pressure?.toFixed(2) }]
            try {
              tStep = model.nextH()
            } catch (err) {
              tStep = 0
              isError = handleErrorSimulation(isError, err)
            }
          } while (tStep > 0)
        })

        linkIds?.map((item) => {
          const linkIndex = model.getLinkIndex(item)
          const velocity = model.getLinkValue(linkIndex, LinkProperty.Velocity)
          const flow = model.getLinkValue(linkIndex, LinkProperty.Flow)
          // Agak ngawur :(
          dataPipe = [
            ...dataPipe,
            {
              label: item,
              velocity: velocity.toFixed(2),
              flow: flow.toFixed(2),
            },
          ]
        })

        try {
          model.solveH()
        } catch (err) {
          isError = isError + 1
          if (isError === 1) {
            toastError(`Simulasi Gagal, ${err.toString()}`)
          }
        }

        if (isError === 0) {
          try {
            model.close()
          } catch (err) {
            isError = handleErrorSimulation(isError, err)
          }

          const reportFile = ws.readFile("report.rpt")
          console.log(reportFile)
          setReport(reportFile)
        }
      }

      setDataPressure(dataPressure)
      setDataLink(dataPipe)

      if (isError > 0) {
        setStatus(2)
      } else if (countPressureNegative > 0) {
        toastError("Simulasi Gagal")
        setStatus(2)
      } else {
        toastSuccess("Simulasi Berhasil")
        setStatus(1)
      }
      setIsSimulation(false)
      setKey(Math.random())
    } else {
      toastError("Minimal terdapat 1 sumber air, 1 junction dan 1 jaringan untuk menjalankan simulasi")
      setIsSimulation(false)
    }
  }

  const handleZoom = (mode) => {
    setDeleteActive(false)
    switch (mode) {
      case "in":
        mapRef.current.zoomIn(1)
        break
      case "out":
        mapRef.current.zoomOut(1)
        break
    }
  }

  const handleTypeValve = (type) => {
    switch (type) {
      case "PRV":
        return LinkType.PRV
      case "PSV":
        return LinkType.PSV
      case "FCV":
        return LinkType.FCV
      case "TCV":
        return LinkType.TCV
      case "GPV":
        return LinkType.GPV
      case "PBV":
        return LinkType.PBV
      default:
        return LinkType.Pipe
    }
  }

  const deleteLayer = (e) => {
    setDeleteActive(true)
  }

  const handleErrorSimulation = (count, err) => {
    let error = count
    error = error + 1
    if (error === 1) {
      toastError(`Simulasi Gagal, ${err.toString()}`)
    }
    setIsSimulation(false)
    return error
  }

  const draw = (e, type, icons) => {
    setDeleteActive(false)
    if (polylineDrawer) {
      polylineDrawer.disable()
    }

    setShowPopUp(null)
    if (icons) {
      setIcon(null)
      switch (icons) {
        case "reservoar":
          setIcon(reservoar)
          break
        case "pump":
          setIcon(pump)
          setDrawLine(true)
          break
        case "katup":
          setIcon(katup)
          setDrawLine(true)
          break
        case "tank":
          setIcon(tank)
          break
        case "junction":
          setIcon(junction)
          break
        default:
          return null
      }
    } else {
      setIcon(null)
      setDrawLine(true)
    }

    var e = document.createEvent("Event")
    e.initEvent("click", true, true)
    if (icon) {
      let cancel = document.querySelector(".leaflet-draw-actions a")
      if (cancel) {
        cancel.dispatchEvent(e)
      }
    }

    if (icons && icons !== "katup" && icons !== "pump") {
      var cb = document.getElementsByClassName(`leaflet-draw-draw-${type}`)
      return !cb[0].dispatchEvent(e)
    }
  }

  const handleChangeTitle = (value) => {
    setTitle(value)
    let data = dataHydraulic ?? {}
    data.name = value
    setDataHydraulic(data)
  }

  const handleUpdateGeojson = (value) => {
    let type = showPopUp ? showPopUp?.split("-") : []
    let checkValue = type && type[0] ? type[0] : icon ? icon : "PIPE"
    if (checkValue && value) {
      switch (checkValue) {
        case reservoar:
        case "RES":
          setInputGeoJson(value)
          break
        case pump:
        case "PUMP":
          setLineGeoJson(value)
          break
        case katup:
        case "PRV":
          setLineGeoJson(value)
          break
        case "TANK":
        case tank:
          setInputGeoJson(value)
          break
        case "CUST":
        case junction:
          setCustomerGeoJson(value)
          break
        case "ZONE":
          setZoneGeoJson(value)
          break
        case "DMA":
          setDmaGeoJson(value)
          break
        case "PIPE":
          setLineGeoJson(value)
          break
        default:
          setLineGeoJson(value)
      }
    }
  }

  useEffect(() => {
    setShowMessage(true)
    new Promise((resolve) => {
      const param = {
        draw: 1,
        length: 1,
        id: match?.params?.id,
      }
      dispatch(HydraulicService.get(param, resolve))
    }).then((res) => {
      setDataHydraulic(res?.data[0])
    })
  }, [])

  const handlerDrawPolyline = (item) => {
    setJunctionItem({ start: item, end: null })
    let coordinate = item?.geometry?.coordinates
    if (coordinate) {
      let features = []
      if (inputGeoJson && inputGeoJson.features.length > 0) {
        features = features.concat(inputGeoJson.features)
      }

      if (customerGeoJson && customerGeoJson.features.length > 0) {
        features = features.concat(customerGeoJson.features)
      }

      if (zoneGeoJson && zoneGeoJson.features.length > 0) {
        features = features.concat(zoneGeoJson.features)
      }

      if (dmaGeoJson && dmaGeoJson.features.length > 0) {
        features = features.concat(dmaGeoJson.features)
      }

      let polylineDrawer = new L.Draw.Polyline(mapRef.current.getMap(), {})
      setPolylineDrawer(polylineDrawer)
      if (features?.length > 1 && polylineDrawer) {
        polylineDrawer.enable()
        let latlng = L.latLng(coordinate)
        polylineDrawer.addVertex(latlng)
      } else {
        toastError("Minimal terdapat dua node untuk menggambar pipa")
      }
    }
    setDrawLine(false)
  }

  const handleClose = () => {
    if (!disabled) {
      setShowModalConfirm(true)
    } else {
      history.push(`/hydraulic-model`)
    }
  }

  const handlePopUpDelete = (value, index, type) => {
    if (value && index !== null && type) {
      setItemData({ value: value, index: index, type: type })
      setShowModalDelete(true)
    }
  }

  const handleDeleteItem = (value) => {
    let item = value.value
    let index = value.index
    let type = value.type

    if (type === "Pipa") {
      let data = lineGeoJson
      let features = data.features
      features.splice(index, 1)
      setLineGeoJson(data)
    }

    if (type === "Input") {
      let data = inputGeoJson
      let features = data.features
      let name = item?.properties?.NAME ?? item?.properties?.NAMA ?? item?.properties?.Name
      if (name && lineGeoJson) {
        let data = lineGeoJson
        let features = data.features
        lineGeoJson.features.map((item, index) => {
          let lineNode1 = item?.properties?.NODE1
          let lineNode2 = item?.properties?.NODE2
          if (lineNode1 === name || lineNode2 === name) {
            features.splice(index, 1)
          }
        })
        data.features = features
        setLineGeoJson(data)
      }
      features.splice(index, 1)
      setInputGeoJson(data)
    }

    if (type === "Junction" || type === "Zone" || type === "DMA") {
      let data = type === "Junction" ? customerGeoJson : type === "Zone" ? zoneGeoJson : dmaGeoJson
      let features = data.features
      let name = item?.properties?.NAME ?? item?.properties?.NAMA ?? item?.properties?.Name
      if (name && lineGeoJson) {
        let data = lineGeoJson
        let features = data.features
        lineGeoJson.features.map((item, index) => {
          let lineNode1 = item?.properties?.NODE1
          let lineNode2 = item?.properties?.NODE2
          if (lineNode1 === name || lineNode2 === name) {
            features.splice(index, 1)
          }
        })
        setLineGeoJson(data)
      }

      features.splice(index, 1)

      if (type === "Junction") {
        setCustomerGeoJson(data)
      }

      if (type === "Zone") {
        setZoneGeoJson(data)
      }

      if (type === "DMA") {
        setDmaGeoJson(data)
      }
    }
    setShowModalDelete(false)
  }

  const handleDelete = () => {
    if (worksheetType || saved) {
      history.push(`/hydraulic-model`)
    } else {
      const callback = () => {
        setShowModalConfirm(false)
        toastSuccess(`Berhasil membatalkan data simulasi ${dataHydraulic?.name}`)
        history.push(`/hydraulic-model`)
      }
      dispatch(HydraulicService.deleted(dataHydraulic?.id, callback))
    }
  }

  const handleMarkerChange = (coordinate, item, index, type) => {
    let name = item?.properties?.NAME ?? item?.properties?.NAMA ?? item?.properties?.Name
    let dataGeojson = type === "Input" ? inputGeoJson : type === "Zone" ? zoneGeoJson : type === "DMA" ? dmaGeoJson : customerGeoJson
    if (dataGeojson && name) {
      if (coordinate) {
        setInitialCenter([coordinate.lat, coordinate.lng])
      }
      if (lineGeoJson && coordinate) {
        let dataPipa = lineGeoJson
        lineGeoJson.features.map((item, index) => {
          let node1 = item?.properties?.NODE1
          let node2 = item?.properties?.NODE2
          if (node1 === name) {
            dataPipa.features[index].geometry.coordinates[0] = [coordinate.lat, coordinate.lng]
            let previousPoint = null
            let pipeLength = 0
            dataPipa.features[index].geometry.coordinates.map((item) => {
              if (previousPoint) {
                let length = calculateLength(previousPoint, item)
                pipeLength = Number(pipeLength) + Number(length)
              }
              previousPoint = item
            })
            dataPipa.features[index].properties.PANJANG = pipeLength
          }
          if (node2 === name) {
            dataPipa.features[index].geometry.coordinates[dataPipa.features[index].geometry.coordinates.length - 1] = [coordinate.lat, coordinate.lng]
            let previousPoint = null
            let pipeLength = 0
            dataPipa.features[index].geometry.coordinates.map((item) => {
              if (previousPoint) {
                let length = calculateLength(previousPoint, item)
                pipeLength = Number(pipeLength) + Number(length)
              }
              previousPoint = item
            })
            dataPipa.features[index].properties.PANJANG = pipeLength
          }
        })
        setLineGeoJson(dataPipa)
      }
      dataGeojson.features[index].geometry.coordinates = [coordinate.lat, coordinate.lng]
      if (type === "Input") {
        setInputGeoJson(dataGeojson)
      }
      if (type === "Zone") {
        setZoneGeoJson(dataGeojson)
      }
      if (type === "DMA") {
        setDmaGeoJson(dataGeojson)
      }
      if (type === "Junction") {
        setCustomerGeoJson(dataGeojson)
      }
      setKey(Math.random())
    }
  }

  const handleSave = (saveLocal) => {
    setIconCursor(null)
    setDeleteActive(false)
    let dataGeojson = dataHydraulic ?? {}
    let dataPipa = lineGeoJson ? removeDuplicate(lineGeoJson.features) : null
    dataGeojson.customer_geojson = customerGeoJson ? JSON.stringify(customerGeoJson) : null
    dataGeojson.input_geojson = inputGeoJson ? JSON.stringify(inputGeoJson) : null
    dataGeojson.pipeline_geojson = dataPipa ? JSON.stringify(dataPipa) : null
    dataGeojson.dma_geojson = dmaGeoJson ? JSON.stringify(dmaGeoJson) : null
    dataGeojson.zone_geojson = zoneGeoJson ? JSON.stringify(zoneGeoJson) : null
    dataGeojson.curves = curves
    dataGeojson.status = status

    const callback = () => {
      setShowModalConfirm(false)
      setSaved(true)
      setIconCursor("cursor")
      toastSuccess(worksheetType ? `Berhasil mengubah data simulasi ${dataHydraulic?.name}` : `Berhasil menyimpan data simulasi ${dataHydraulic?.name}`)
      if (!saveLocal) {
        history.push(`/hydraulic-model`)
      }
    }
    dispatch(HydraulicService.put(dataGeojson, callback))
  }

  const handleCurve = (value) => {
    if (value) {
      let filterDeleted = value.filter((item) => !item.deleted)
      setCurves(filterDeleted)
    }
  }

  const worksheetType = match?.params?.type
  const isAuthor = currentUser?.id === dataHydraulic?.created_by
  const disabled = !(worksheetType ? showToolbar && isAuthor : true)

  return (
    <div className="menu-container pr-0">
      <div className="main-content-wrapper mini-map-wrapper">
        <HeaderMenu
          elipsis
          optClass="pr-5 nav-with-color"
          rightClass="hydraulic-menu-right"
          title={title}
          titleClass="hydraulic-nav"
          titleChildren={
            <div className="menu-subtitle">
              <p>{dataHydraulic?.user_updater_name}</p>
              <p className="separator">
                <strong>o</strong>
              </p>
              <p className="subtitle-info">{`Simulasi pada ${dataHydraulic?.updated_at ? moment(dataHydraulic?.updated_at).locale("id", localization).format("DD MMMM YYYY, hh:mm") : "-"}`}</p>
            </div>
          }
          editableTitle={showToolbar && isAuthor}
          onChangeEdit={isAuthor ? (event) => handleChangeTitle(event) : null}
        >
          {showMessage && <div className="small-notification">Mohon tunggu sampai data selesai diproses...</div>}
          {isSimulation && <div className="small-notification">Mohon tunggu sampai simulasi selesai...</div>}
          {worksheetType && isAuthor && !showToolbar && <Button title=" Edit" leftIcon="edit" size="md" variant="btn-lg button-light" rounded={true} onClick={() => setShowToolbar(true)} />}
          <Button title="Simulasi" leftIcon="flash_on" size="md" variant="btn-lg button-default" rounded={true} onClick={() => handleRunSimulation()} disabled={isSimulation} />
          <Button iconOnly="close" size="md" variant="btn-lg button-light" rounded={true} onClick={() => handleClose()} />
        </HeaderMenu>
        {showToolbar && !isSimulation && (
          <HydraulicToolBox
            iconCursor={iconCursor}
            toggleDelete={(e) => deleteLayer(e)}
            toggleZoom={(value) => handleZoom(value)}
            toggleSave={(value) => handleSave(value)}
            toggleEdit={(value) => handleEdit(value)}
            toggleCursor={(value) => changeCursor(value)}
            toggleDraw={(e, type, element) => draw(e, type, element)}
          />
        )}
        <div className="hydraulic-map-menu inactive-pointer-evt">
          <div className="hydraulic-menu">
            <div className="hydraulic-search active-pointer-evt">
              <SearchButton placeholder="Cari Lokasi" trigger={(value) => handleSearchMap(value)} value={searchMap} suggestion={suggestion} toggleSuggestion={handleClickSuggestion} show={show} />
            </div>
            <div>
              <Dropdown className="active-pointer-evt" group isOpen={selectMapShow} toggle={toggleSelectMap} style={{ float: "right" }}>
                <DropdownToggle caret color="light" className="shaded">
                  {selectedMapType}&emsp;&emsp;&emsp;
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem onClick={() => setSelectedMapType("Default")}>Default</DropdownItem>
                  <DropdownItem onClick={() => setSelectedMapType("Satelit")}>Satelit</DropdownItem>
                </DropdownMenu>
              </Dropdown>
              <Legend show={showPopUp} option={popUpOption} />
            </div>
          </div>
          {showPopUp && popUpOption && (
            <div ref={dropdownRef} className="hydraulic-popup-wrapper">
              <HydraulicPopUp
                type={showPopUp}
                item={popUpOption}
                curves={curves}
                dmaGeoJson={dmaGeoJson}
                zoneGeoJson={zoneGeoJson}
                lineGeoJson={lineGeoJson}
                inputGeoJson={inputGeoJson}
                customerGeoJson={customerGeoJson}
                handleCurve={(value) => handleCurve(value)}
                toggle={(value) => handleUpdateGeojson(value)}
                setLineGeoJson={(value) => setLineGeoJson(value)}
                readOnly={disabled}
              />
            </div>
          )}
        </div>
        <div className={`map-area ${cursor ? "map-area-" + cursor : ""}`}>
          <Map data={key} ref={mapRef} defaultCenter={initialCenter} defaultZoom={initialZoom} type={selectedMapType}>
            <FeatureGroup
              ref={(featureGroupRef) => {
                setEditableFG(featureGroupRef)
              }}
            >
              <HydraulicDrawMap
                icon={icon}
                baseName={dataHydraulic?.name ? dataHydraulic?.name?.charAt(0) : ""}
                editableFG={editableFG}
                lineGeoJson={lineGeoJson}
                junctionItem={junctionItem}
                dmaGeoJson={dmaGeoJson}
                zoneGeoJson={zoneGeoJson}
                inputGeoJson={inputGeoJson}
                customerGeoJson={customerGeoJson}
                polylineDrawer={polylineDrawer}
                getElevation={(data) => getElevation(data)}
                setIconCursor={(value) => setIconCursor(value)}
                calculateLength={(start, end) => calculateLength(start, end)}
                setJunctionItem={(value) => setJunctionItem(value)}
                handleSetLine={(value) => setLineGeoJson(value)}
                handleUpdateGeojson={(value) => handleUpdateGeojson(value)}
              />
              {inputGeoJson?.features &&
                inputGeoJson?.features?.map((item, index) => {
                  let coordinate = item?.geometry?.coordinates
                  let name = item.properties.NAME ?? item.properties.NAMA ?? item.properties.Name
                  return (
                    <React.Fragment key={index}>
                      <MarkerComponent
                        isShow={name ? isShow === name || errorElement === name.replace(/\s/g, "") : false}
                        item={item}
                        type="Input"
                        index={index}
                        icon={item?.properties?.TYPE === "Tank" ? tank : reservoar}
                        draggable={draggable}
                        coordinate={coordinate}
                        handlerClick={() => {
                          if (deleteActive) {
                            handlePopUpDelete(item, index, "Input")
                          } else if (drawLine) {
                            handlerDrawPolyline(item)
                          } else {
                            handleShowPopUp(item, item?.properties?.TYPE === "Tank" ? `TANK-${index + 1}` : `RES-${index + 1}`, name)
                          }
                        }}
                        handleDrag={(coordinate, item, index, type) => handleMarkerChange(coordinate, item, index, type)}
                      />
                    </React.Fragment>
                  )
                })}
              {customerGeoJson?.features &&
                customerGeoJson?.features?.slice(0, limitationData).map((item, index) => {
                  let coordinate = item?.geometry?.coordinates
                  let pressure = item?.properties?.PRESSURE ? Number(item?.properties?.PRESSURE) : null
                  let name = item.properties.NAME ?? item.properties.NAMA ?? item.properties.Name
                  return (
                    <React.Fragment key={index}>
                      <MarkerComponent
                        isShow={name ? isShow === name || errorElement === name.replace(/\s/g, "") : false}
                        item={item}
                        type="Junction"
                        index={index}
                        color={changeColor("junction", pressure)}
                        draggable={draggable}
                        coordinate={coordinate}
                        handlerClick={() => {
                          if (deleteActive) {
                            handlePopUpDelete(item, index, "Junction")
                          } else if (drawLine) {
                            handlerDrawPolyline(item)
                          } else {
                            handleShowPopUp(item, `CUST-${index + 1}`, name)
                          }
                        }}
                        handleDrag={(coordinate, item, index, type) => handleMarkerChange(coordinate, item, index, type)}
                      />
                    </React.Fragment>
                  )
                })}
              {zoneGeoJson?.features &&
                zoneGeoJson?.features?.map((item, index) => {
                  let coordinate = item?.geometry?.coordinates
                  let pressure = item?.properties?.PRESSURE ? Number(item?.properties?.PRESSURE) : null
                  let name = item.properties.NAME ?? item.properties.NAMA ?? item.properties.Name
                  return (
                    <React.Fragment key={index}>
                      <MarkerComponent
                        isShow={name ? isShow === name || errorElement === name.replace(/\s/g, "") : false}
                        item={item}
                        type="Zone"
                        index={index}
                        color={changeColor("junction", pressure)}
                        draggable={draggable}
                        coordinate={coordinate}
                        handlerClick={() => {
                          if (deleteActive) {
                            handlePopUpDelete(item, index, "Zone")
                          } else if (drawLine) {
                            handlerDrawPolyline(item)
                          } else {
                            handleShowPopUp(item, `ZONE-${index + 1}`, name)
                          }
                        }}
                        handleDrag={(coordinate, item, index, type) => handleMarkerChange(coordinate, item, index, type)}
                      />
                    </React.Fragment>
                  )
                })}
              {dmaGeoJson?.features &&
                dmaGeoJson?.features?.map((item, index) => {
                  let coordinate = item?.geometry?.coordinates
                  let pressure = item?.properties?.PRESSURE ? Number(item?.properties?.PRESSURE) : null
                  let name = item.properties.NAME ?? item.properties.NAMA ?? item.properties.Name
                  return (
                    <React.Fragment key={index}>
                      <MarkerComponent
                        isShow={name ? isShow === name || errorElement === name.replace(/\s/g, "") : false}
                        item={item}
                        type="DMA"
                        index={index}
                        color={changeColor("junction", pressure)}
                        draggable={draggable}
                        coordinate={coordinate}
                        handlerClick={() => {
                          if (deleteActive) {
                            handlePopUpDelete(item, index, "DMA")
                          } else if (drawLine) {
                            handlerDrawPolyline(item)
                          } else {
                            handleShowPopUp(item, `DMA-${index + 1}`, name)
                          }
                        }}
                        handleDrag={(coordinate, item, index, type) => handleMarkerChange(coordinate, item, index, type)}
                      />
                    </React.Fragment>
                  )
                })}
              {lineGeoJson?.features &&
                lineGeoJson?.features?.map((item, index) => {
                  let coordinate = item?.geometry?.coordinates
                  if (coordinate) {
                    let velocity = item?.properties?.VELOCITY !== null && item?.properties?.VELOCITY !== undefined ? Number(item?.properties?.VELOCITY) : null
                    let startCoor = coordinate[0]
                    let endCoor = coordinate[coordinate.length - 1]
                    let name = item.properties.NAME ?? item.properties.NAMA ?? item.properties.Name
                    let show = name ? isShow === name || errorElement === name.replace(/\s/g, "") : false
                    let type = item?.properties?.TYPE
                    return (
                      <React.Fragment key={index}>
                        <Circle center={startCoor} pathOptions={{ color: "pink" }} border radius={1} />
                        <Circle center={endCoor} pathOptions={{ color: "pink" }} radius={1} />
                        <Polyline
                          pathOptions={{
                            className: "blue",
                            color: show ? "aquamarine" : type === "Pump" ? "#6B8E23" : type === null || type === undefined || type.length > 100 ? changeColor("line", velocity) : "#C71585",
                          }}
                          positions={coordinate}
                          eventHandlers={{
                            click: () => {
                              if (deleteActive) {
                                handlePopUpDelete(item, index, "Pipa")
                              } else {
                                handleShowPopUp(item, type === "Pump" ? `PUMP-${index + 1}` : type === null || type === undefined || type.length > 100 ? `PIPE-${index + 1}` : `PIPE-${index + 1}`, name)
                              }
                            },
                          }}
                        />
                      </React.Fragment>
                    )
                  }
                })}
            </FeatureGroup>
          </Map>
        </div>
        {pending && <Loader loading={pending} />}
      </div>
      <HydraulicConfirmationModal icon="info" data={dataHydraulic} show={showModalConfirm} confirm={() => handleSave()} confirmNo={() => handleDelete()} toggle={() => setShowModalConfirm(false)} disabled={pending} />
      <ConfirmationModal
        show={showModalDelete}
        toggle={() => {
          setShowModalDelete(false)
        }}
        confirm={() => handleDeleteItem(itemData)}
        icon="info"
        isDelete
      >
        <div>
          Apakah Anda yakin akan menghapus
          <strong>{` ${itemData?.type} ${itemData?.value?.properties?.NAME}`}</strong> ?
        </div>
      </ConfirmationModal>
    </div>
  )
}

const mapStateToProps = ({ hydraulicModel: { pending } }) => {
  return { pending }
}

export default connect(mapStateToProps)(HydraulicMap)
