import {
  GET_DATA_LOGGER_PENDING,
  GET_DATA_LOGGER_SUCCESS,
  GET_DATA_LOGGER_ERROR,
  EXPORT_DATA_LOGGER_PENDING,
  EXPORT_DATA_LOGGER_SUCCESS,
  EXPORT_DATA_LOGGER_ERROR,
} from "../../actions/data_logger";

const initialState = {
  pending: false,
  error: null,
  data_list: null,
  pending_export: false,
  data_export: null,
};

const dataLogger = (state = initialState, action) => {
  switch (action.type) {
    case GET_DATA_LOGGER_PENDING:
      return {
        ...state,
        pending: true,
      };
    case GET_DATA_LOGGER_SUCCESS:
      return {
        ...state,
        pending: false,
        data_list: action.data,
      };
    case GET_DATA_LOGGER_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case EXPORT_DATA_LOGGER_PENDING:
      return {
        ...state,
        pending_export: true,
      };
    case EXPORT_DATA_LOGGER_SUCCESS:
      return {
        ...state,
        pending_export: false,
        data_export: action.data,
      };
    case EXPORT_DATA_LOGGER_ERROR:
      return {
        ...state,
        pending_export: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default dataLogger;
