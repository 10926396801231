import { history } from "../../../../utils/History";
import { actionPending, actionSuccess, actionError } from "../../actionTypes";

import { toastSuccess, toastError } from "../../../../components/commons/toast";

import API from "../../API";
import { handleResponse } from "../../HandleRespone";

// ACTION TYPES TO DISPATCH: METHOD_URL_TYPES
export const GET_INPUT_PENDING = "GET_INPUT_PENDING";
export const GET_INPUT_SUCCESS = "GET_INPUT_SUCCESS";
export const GET_INPUT_ERROR = "GET_INPUT_ERROR";
export const POST_INPUT_PENDING = "POST_INPUT_PENDING";
export const POST_INPUT_SUCCESS = "POST_INPUT_SUCCESS";
export const POST_INPUT_ERROR = "POST_INPUT_ERROR";
export const DELETE_INPUT_PENDING = "DELETE_INPUT_PENDING";
export const DELETE_INPUT_SUCCESS = "DELETE_INPUT_SUCCESS";
export const DELETE_INPUT_ERROR = "DELETE_INPUT_ERROR";

// URL: URL_{URL}
const INPUT_URL = `v1/master_data/input`;

const get = (param, resolve, reject, callback) => (dispatch) => {
  dispatch(actionPending(GET_INPUT_PENDING));
  API.get(INPUT_URL, { params: param })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(GET_INPUT_SUCCESS, res));
      if (resolve) {
        const records_total = res.data.total;
        let data = res.data.data.map((item, i) => ({
          ...item,
          no: i + 1 + (param?.page - 1) * param?.length,
        }));
        resolve({
          data: data,
          page: param?.page - 1,
          totalCount: records_total,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(GET_INPUT_ERROR));
      let not_logout = handleResponse(err?.response);
      if (not_logout) {
        let message = err?.response?.data?.message;
        if (message?.includes("java")) {
          message = "Data tidak ditemukan";
        }
        toastError(message);
      }
      // toastError(err?.response?.data?.message);
    });
};

const post = (param, callback) => (dispatch) => {
  dispatch(actionPending(POST_INPUT_PENDING));
  API.post(INPUT_URL + "/import", param)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(POST_INPUT_SUCCESS, res));
      toastSuccess("Berhasil import data input");
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(POST_INPUT_ERROR));
      toastError(err?.response?.data?.message);
    });
};

const deleted = (param, callback, name) => (dispatch) => {
  dispatch(actionPending(DELETE_INPUT_PENDING));
  API.delete(INPUT_URL + `?id=${param}`)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(DELETE_INPUT_SUCCESS, res));
      toastSuccess(`Berhasil Hapus Data Input ${name}`);
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(DELETE_INPUT_ERROR));
      toastError(err?.response?.data?.message);
    });
};

const input = {
  get,
  post,
  deleted,
};
export default input;
