import { useRef, useState, useEffect } from "react";
import MenuItem from "../../commons/sidebar/MenuItem";
import DropdownMenu from "../../dropdowns/DropdownMenu";
import { useClickaway } from "../../hooks/ClickawayHooks";
import { Link } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import Notification from "../../../store/actions/notification";
import moment from "moment";
import { history } from "../../../utils/useHistory";

const Index = ({ toggle, notification }) => {
  const dispatch = useDispatch();
  // const [data, setData] = useState([]);
  const [show, setShow] = useState(false);
  let notificationTotal = 0;

  const dropdownRef = useRef();

  useClickaway(dropdownRef, () => {
    setShow(false);
  });

  useEffect(() => {
    getNotif();
    getTotalUnread();
  }, [show]);

  const getNotif = () => {
    const param = {
      length: 5,
    };
    dispatch(Notification.get(param));
  };
  const getTotalUnread = () => dispatch(Notification.getCountUnread());


  if (notification.count_unread && notification.count_unread > 0) {
    notificationTotal = notification.count_unread;
  }

  let data;
  if (notification.data_list) {
    data = notification.data_list.data.data.slice(0, 5);
  }

  const handleReadNotif = (data) => {
    const param = {
      ids: [data.id],
    };

    const callback = () => {
      getNotif();
      getTotalUnread();
    };

    dispatch(Notification.put(param, callback));
  };

  const handleRedirectNotif = (data) => {
    setShow(false);
    handleReadNotif(data);
    switch (data.type_notif) {
      case "MASTER_DMA":
        history.push({ pathname: "/data-master", state: { tab: "3" } });
        break;
      case "MASTER_PDAM":
        history.push({ pathname: "/data-master", state: { tab: "0" } });
        break;
      case "MASTER_USER":
        history.push("/system");
        break;
      case "MASTER_CUSTOMER":
        history.push({ pathname: "/data-master", state: { tab: "4" } });
        break;
      case "REPAIR_WORK":
        history.push("/repair-work-report");
        break;
      case "PHYSICAL_LOSSES":
        history.push("/warning-system");
        break;
      case "COMMERCIAL_LOSSES":
        history.push("/commercial-losses");
        break;
      default:
        return null;
    }
  };

  return (
    <div style={{ position: "relative" }}>
      <div style={{ cursor: "pointer" }} onClick={() => setShow(!show)}>
        <div
          className={`${notificationTotal > 0
            ? "notification-indikator notification-active"
            : ""
            }`}
        />
        <MenuItem
          title={`${notificationTotal} Notifikasi Baru`}
          onClick={() => setShow(!show)}
          icon={"notifications"}
        />
      </div>
      {show && (
        <div ref={dropdownRef}>
          <DropdownMenu title={`${notificationTotal} Notifikasi Baru`}>
            <div className="container-notification-item">
              {data && data.length > 0 ? (
                data.map((item, idx) => {
                  return (
                    <div
                      className="notification-item"
                      key={idx}
                      onClick={() => handleRedirectNotif(item)}
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div className="notification-detail">
                          <div
                            className="notification-info text-bold"
                            dangerouslySetInnerHTML={{ __html: item.body }}
                          />
                          <p className="notification-date text-muted">
                            {moment(item.created_at).format(
                              "ddd, DD/MM, HH:mm"
                            )}
                          </p>
                        </div>
                        {!item.is_read && (
                          <div className="notification-indikator primary" />
                        )}
                      </div>
                      <hr />
                    </div>
                  );
                })
              ) : (
                <div className="notification-item">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      cursor: "default",
                    }}
                  >
                    <div>Notifikasi Kosong</div>
                  </div>
                  <hr />
                </div>
              )}
            </div>
            <div className="notification-item">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div className="" onClick={() => setShow(false)}>
                  <Link to="/notifikasi">Lihat Semua Notifikasi</Link>
                </div>
              </div>
            </div>
          </DropdownMenu>
        </div>
      )}
      <div className="horizontal-line" />
    </div>
  );
};

const mapStateToProps = ({ notification }) => {
  return { notification };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
