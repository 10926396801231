import React, { useState, useRef, useEffect, forwardRef } from "react"
import { useDispatch } from "react-redux"
import { FeatureGroup, Pane, Popup, CircleMarker, Marker, GeoJSON } from "react-leaflet"
import useDynamicRefs from "use-dynamic-refs"
import { divIcon, Leaflet } from "leaflet"

import { reproject, crss } from "reproject"
import gjv from "geojson-validation"
import { v4 } from "uuid"
import { getUser } from "../../../utils/User"
import { convertPipeline } from "../../../utils/ConvertPipeline"

import FileService from "../../../store/actions/file_management"
import MasterInputService from "../../../store/actions/master_data/input"

const Index = forwardRef(({ stepTestData, dmaAllData, dmaDetailData }, ref) => {
  const dispatch = useDispatch()
  const [getMarkerRefs, setMarkerRefs] = useDynamicRefs()
  const pipeline_path = getUser().user?.pdam?.pipeline_geojson_path
  const defaultPathOptions = {
    color: "#919191",
    fillColor: "#919191",
  }
  const highlightPathOptions = {
    color: "#04AA59",
    fillColor: "#04AA59",
  }

  const [DMAGeoJson, setDMAGeoJson] = useState(null)
  const [subDMAGeoJson, setSubDMAGeoJson] = useState(null)
  const [stepTestGeoJson, setStepTestGeoJson] = useState(null)
  const [pipelineGeoJson, setPipelineGeoJson] = useState(null)

  const [DMAGeoJsonID, setDMAGeoJsonID] = useState(v4())
  const [subDMAGeoJsonID, setSubDMAGeoJsonID] = useState(v4())
  const [stepTestGeoJsonID, setStepTestGeoJsonID] = useState(v4())
  const [pipelineGeoJsonID, setPipelineGeoJsonID] = useState(v4())

  useEffect(() => {
    if (Object.keys(dmaDetailData).length !== 0) {
      getMarkerRefs(dmaDetailData?.id)?.current?.bringToFront()

      if (dmaDetailData.geojson_path) {
        getGeoJson(dmaDetailData.geojson_path, function (data) {
          if (gjv.valid(data)) {
            setDMAGeoJson(data)
          } else {
            setDMAGeoJson(null)
          }
        })
      } else {
        setDMAGeoJson(null)
      }

      // get sub dma geojson
      // -------------------
      if (dmaDetailData.subdma_geojson_path) {
        getGeoJson(dmaDetailData.subdma_geojson_path, function (data) {
          if (gjv.valid(data)) {
            setSubDMAGeoJson(data)
          } else {
            setSubDMAGeoJson(null)
          }
        })
      } else {
        setSubDMAGeoJson(null)
      }
      // ------------------------------------------------------------

      // get pipeline geojson
      // --------------------
      if (pipeline_path) {
        getGeoJson(pipeline_path, function (data) {
          if (gjv.valid(data)) {
            data.features = convertPipeline(data.features)
            setPipelineGeoJson(data)
          } else {
            setPipelineGeoJson(null)
          }
        })
      } else {
        setPipelineGeoJson(null)
      }
    }
  }, [dmaDetailData])

  useEffect(() => {
    let features = []
    stepTestData.forEach((stepTest) => {
      if (!stepTest.customer_geojson_path) return
      getGeoJson(stepTest.customer_geojson_path, function (data) {
        features.push(data)
      })
    })

    setStepTestGeoJson(
      features.length > 0
        ? {
            type: "FeatureCollection",
            features: features,
          }
        : null
    )
  }, [stepTestData])

  useEffect(() => {
    setDMAGeoJsonID(v4())
  }, [DMAGeoJson])

  useEffect(() => {
    setSubDMAGeoJsonID(v4())
  }, [subDMAGeoJson])

  useEffect(() => {
    setStepTestGeoJsonID(v4())
  }, [stepTestGeoJson])

  useEffect(() => {
    setPipelineGeoJsonID(v4())
  }, [pipelineGeoJson])

  const getGeoJson = (path, callback) => {
    if (path !== "null" && path !== null && path !== undefined) {
      new Promise((resolve) => {
        const param = {
          file_path: path,
        }
        dispatch(FileService.post(param, resolve))
      }).then((res) => {
        callback(res.data)
      })
    }
  }

  const getDetailInput = (id, callback) => {
    new Promise((resolve) => {
      const param = {
        id: id,
        draw: 1,
        length: 1,
        page: 1,
      }
      dispatch(MasterInputService.get(param, resolve))
    }).then((res) => {
      callback(res.data)
    })
  }

  function onEachFeature(feature, layer) {
    if (feature.properties && Object.keys(feature.properties).length > 0) {
      const popupOptions = {
        className: "popup-poly-detail",
      }
      let popUpContentKey = ``
      let popUpContentVal = ``
      Object.keys(feature.properties).forEach((i) => {
        if (i === "DMA" || i === "dma") {
          popUpContentKey += `<div className='row pt-1 pb-1'><div className='col'>DMA</div></div>`
          popUpContentVal += `<div className='row pt-1 pb-1'><div className='col'>` + dmaDetailData?.name + `</div></div>`
        } else {
          popUpContentKey += `<div className='row pt-1 pb-1'><div className='col'>` + i + `</div></div>`
          popUpContentVal += `<div className='row pt-1 pb-1'><div className='col'>
            ${feature.properties[i] !== null && feature.properties[i] !== undefined ? feature.properties[i] : "-"}</div></div>`
        }
      })
      let popUpContentBase =
        `<div className='row'>
        <div className='col-sm-auto'>` +
        popUpContentKey +
        `</div>
        <div className='col'>` +
        popUpContentVal +
        `</div>
      </div>`
      layer.bindPopup(popUpContentBase, popupOptions)
    }
  }

  return (
    <>
      {dmaAllData.map((item, i) => (
        <FeatureGroup ref={setMarkerRefs(item.id)} pathOptions={item?.id == dmaDetailData?.id ? highlightPathOptions : defaultPathOptions}>
          <Popup className="popup-marker-detail" direction="right" offset={[0, -20]} opacity={1}>
            <div className="tooltip-title">{"DMA " + item.name}</div>
            {"region_name" in item && <div className="sub-title">{"Kelurahan " + item.region_name}</div>}
            {"customer_count" in item && (
              <div className="top-rounded bordered wrapper-col">
                <div>{"Pelanggan"}</div>
                <div>{item.customer_count}</div>
              </div>
            )}
            {"pressure" in item && (
              <div className="bordered wrapper-col">
                <div>{"Tekanan"}</div>
                <div>{item.pressure}</div>
              </div>
            )}
            {"pressure" in item && (
              <div className="bottom-rounded bordered wrapper-col">
                <div>{"Debit"}</div>
                <div>{item.debit}</div>
              </div>
            )}
          </Popup>
          <Marker
            position={[item.latitude, item.longitude]}
            icon={divIcon({
              html: "<div className='marker-title'>DMA " + item.name.toUpperCase() + "</div>",
            })}
          />
          {item?.id == dmaDetailData?.id && <CircleMarker center={[item.latitude, item.longitude]} radius={10} fillOpacity={1} weight={8} opacity={0.4} />}
          {item?.id != dmaDetailData?.id && <CircleMarker center={[item.latitude, item.longitude]} radius={10} fillOpacity={1} weight={8} opacity={0} />}
        </FeatureGroup>
      ))}
      {Object.keys(dmaDetailData).length !== 0 && (
        <>
          {DMAGeoJson !== null && (
            <Pane name="paneDma" style={{ zIndex: 300 }}>
              <GeoJSON
                key={DMAGeoJsonID}
                data={
                  reproject(DMAGeoJson, "ESRI:54004", "EPSG:4326", crss)
                  // DMAGeoJson
                }
                color="#009e20"
                fillColor="#009e20"
                weight={2}
                onEachFeature={onEachFeature}
              />
            </Pane>
          )}
          {subDMAGeoJson !== null && (
            <Pane name="paneSubDma" style={{ zIndex: 400 }}>
              <GeoJSON key={subDMAGeoJsonID} data={reproject(subDMAGeoJson, "ESRI:54004", "EPSG:4326", crss)} color="#00a6ff" fillColor="#00a6ff" weight={2} onEachFeature={onEachFeature} />
            </Pane>
          )}
          {stepTestGeoJson !== null && (
            <Pane name="paneStepTest" style={{ zIndex: 500 }}>
              <GeoJSON key={stepTestGeoJsonID} data={reproject(stepTestGeoJson, "ESRI:54004", "EPSG:4326", crss)} color="yellow" fillColor="yellow" weight={2} onEachFeature={onEachFeature} />
            </Pane>
          )}
          {pipelineGeoJson !== null && (
            <Pane name="panePipeline" style={{ zIndex: 600 }}>
              <GeoJSON key={pipelineGeoJsonID} data={pipelineGeoJson} color="purple" fillColor="purple" weight={2} onEachFeature={onEachFeature} />
            </Pane>
          )}
        </>
      )}
    </>
  )
})

export default Index
