import SyncLoader from "react-spinners/SyncLoader";

const Index = ({ title, data, isLoading }) => {
  return (
    <div className="d-flex flex-column nrw-card">
      <div className="d-flex align-items-center p-4 border-bottom">
        <div>
          <span class="material-icons hijau">location_on</span>
        </div>
        <div className="col">
          <div>
            <span className="title text-bold">{title || ""}</span>
          </div>
        </div>
      </div>
      <div className="px-4 pt-3">
        {isLoading ? (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ minHeight: 150 }}
          >
            <SyncLoader color="#04AA59" loading />
          </div>
        ) : data && data.length > 0 ? (
          data.map((item, idx) => {
            return (
              <div className="d-flex align-items-center mb-3" key={idx}>
                <div className="mr-3">
                  <span className="title text-bold">{idx + 1}</span>
                </div>
                <div className="d-flex w-100 justify-content-between pl-3">
                  <div>
                    <span className="normal-title">DMA {item.dma_name}</span>
                  </div>
                  <div>
                    <span className="normal-title">
                      {item.nrw_percentage || "0"}%
                    </span>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ minHeight: 150 }}
          >
            <span>Tidak Ada Data</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default Index;
