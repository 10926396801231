export const Role = {
  Superadmin: "superadmin",
  It_admin: "it_administrator",
  User_cust_rep: "user_cust_rep",
  Viewer: "viewer",
  Kadiv: "kadiv",
};

export const Status = {
  Pending: "Pending",
  Draft: "Draft",
  NotOk: "Not Ok",
  Reviewed: "Reviewed",
  NotApproved: "Not Approved",
  Approved: "Approved",
  Bridging: "Bridging",
  Closed: "Closed",
};

export const RoleName = {
  superadmin: "Superuser",
  it_administrator: "IT Administrator",
  user_cust_rep: "User Cust Rep",
  viewer: "Viewer",
  kadiv: "Kadiv",
};

export const monthOptions = (length = 12) => {
  let totalMonth = parseInt(length);
  return [
    // { label: "Semua Bulan", value: null },
    ...Array.from({ length: totalMonth }, (item, i) => {
      return {
        label: new Date(0, i).toLocaleString("in-ID", { month: "long" }),
        value: i + 1,
      };
    }),
  ];
};

export const pipelineProps = () => {
  let props = {
    ALIRAN: 'Sekunder',
    CHW: 120,
    CONNECT: 'P',
    CURVE: null,
    DEBIT: 151.07,
    DIAMETER: 400,
    ELV1: 7.9,
    ELV2: 8.1,
    HEAD1: 67.9,
    HEAD2: 67.71,
    HEADLOSS: 0.194,
    JARAKHORIS: 49.31,
    JENIS_PIPA: "HDPE",
    KECEPATAN: 1.2,
    KETERANGAN: null,
    KOMJARAK: 0,
    KOMPANJANG: 0,
    KONSUMEN: 0,
    MINORLOSS: 0,
    NAME: 'PIPA',
    NODE1: "AA010",
    NODE2: "AA012",
    NO_PATTERN: null,
    PANJANG: 0,
    PIPE_ID: 'PIPA',
    POSISI: null,
    PRESS1: 60,
    PRESS2: 59.61,
    PRESSURE: 0,
    ROUGHNESS: 0,
    SETTING: 0,
    STATUS: "Open",
    TAHUN: 2018,
    TYPE: null,
    VELOCITY: 0,
    WILAYAH: "AA",
    altitudeMo: null,
    begin: null,
    clean: true,
    descriptio: null,
    drawOrder: null,
    end: null,
    extrude: null,
    icon: null,
    tessellate: null,
    timestamp: null,
    visibility: null,
  }
  return props;
}

export const inputProps = () => {
  let props = {
    DEVICE_ID: "2000",
    DIAMETER: 40,
    NAME: "R01",
  }
  return props;
}