import React, { forwardRef, useRef, useEffect } from "react"
import { useTable, useRowSelect } from "react-table"
import Pagination from "./pagination"
import "./table.scss"
import Loader from "../commons/Loader"
import _uniqueId from "lodash/uniqueId"

const Index = ({ id, columns, data, dark, hover, small, pagination, isLoading, selection, selectionAlign = "left", customHeaderSelection, onSelection, selectionRow, length }) => {
  const generatedId = id ?? _uniqueId("tbl-")

  const IndeterminateCheckbox = forwardRef(({ value, row, name, index, dataSelection }, ref) => {
    const defaultRef = useRef()
    // let dataSelection = index !== undefined && index !== null ? selection[index] : selection;
    useEffect(() => {
      defaultRef.current.checked = dataSelection?.indexOf(value) > -1
    }, [selection, row])

    return (
      <>
        <label className="checkbox-container" style={{ display: "initial" }}>
          <input name={name} type="checkbox" ref={defaultRef} value={value} onChange={(e) => handleSelect(e, index)} />
          <span className="checkmark"></span>
        </label>
      </>
    )
  })

  useEffect(() => {
    let selectedData = selection ?? []
    const index = selectedData.indexOf("all")
    if (index > -1) {
      data.forEach((item) => {
        const index = selectedData.indexOf(item.id || item.no)
        if (index === -1) {
          selectedData = [...selectedData, item.id || item.no]
        }
      })
      onSelection(selectedData)
    }
  }, [data])

  const handleSelect = (event, index) => {
    let dataSelection = index !== undefined && index !== null ? selection[index] : selection
    let selectedData = dataSelection ?? []
    if (event.target.value === "all") {
      const index = selectedData.indexOf(event.target.value)
      if (index > -1) {
        selectedData = []
      } else {
        selectedData = ["all", ...selectedData]
        data.forEach((item) => {
          selectedData = [...selectedData, item.id || item.no]
        })
      }
    } else {
      if (event.target.value) {
        const indexAll = selectedData.indexOf("all")
        if (indexAll > -1) {
          selectedData.splice(indexAll, 1)
        }
        const index = selectedData.indexOf(event.target.value)
        if (index > -1) {
          selectedData.splice(index, 1)
        } else {
          selectedData = [...selectedData, event.target.value]
        }
      }
    }
    let uniqArr = [...new Set(selectedData)]
    onSelection(uniqArr, index)
  }

  const {
    getTableProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
    state: { selectedRowIds },
  } = useTable(
    {
      columns,
      data: data ? data : [{}],
    },
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => {
        if (selectionRow && customHeaderSelection) {
          let headSelection = Array.isArray(customHeaderSelection)
            ? customHeaderSelection.map((item, index) => {
                let dataSelection = {
                  id: `selection${index}`,
                  Header: ({ getToggleAllRowsSelectedProps }) => <div>{item ? item : <IndeterminateCheckbox value="all" index={index} />}</div>,
                  Cell: ({ row }) => {
                    const id = row?.original?.id
                    return (
                      <div>
                        <IndeterminateCheckbox value={id} row={rows} name={item} index={index} dataSelection={selection[index]} />
                      </div>
                    )
                  },
                }

                return dataSelection
              })
            : {
                id: "selection",
                Header: ({ getToggleAllRowsSelectedProps }) => <div>{customHeaderSelection ? customHeaderSelection : <IndeterminateCheckbox value="all" />}</div>,
                Cell: ({ row }) => {
                  const id = row?.original?.id
                  return (
                    <div>
                      <IndeterminateCheckbox value={id} row={rows} />
                    </div>
                  )
                },
              }
          if (Array.isArray(headSelection)) {
            if (selectionAlign === "left") {
              return [...headSelection, ...columns]
            } else if (selectionAlign === "right") {
              return [...columns, ...headSelection]
            } else {
              return [...columns]
            }
          } else {
            if (selectionAlign === "left") {
              return [headSelection, ...columns]
            } else if (selectionAlign === "right") {
              return [...columns, headSelection]
            } else {
              return [...columns]
            }
          }
        } else {
          return [...columns]
        }
      })
    }
  )

  return (
    <div id={generatedId}>
      <div className="table-responsive" style={{ position: "relative", minHeight: "215px" }}>
        {isLoading && <Loader loading={isLoading} />}
        <table {...getTableProps()} className={`table ${dark && "table-dark"} ${hover && "table-hover"} ${small && "table-sm"}`}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={headerGroup.headers.map((v) => v.Header)}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()} style={{ whiteSpace: "nowrap" }} key={column.Header}>
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {/* {isLoading && (
              <tr>
                <td colSpan={columns.length}>
                  <Loader loading={true} />
                </td>
              </tr>
            )} */}
            {!isLoading && (
              <>
                {rows.map((row, i) => {
                  prepareRow(row)
                  return (
                    <tr {...row.getRowProps()} key={i}>
                      {row.cells.map((cell) => {
                        return (
                          <td {...cell.getCellProps()} style={{ fontSize: 14 }} key={cell.column.Header}>
                            {cell.render("Cell")}
                          </td>
                        )
                      })}
                    </tr>
                  )
                })}
                {rows?.length < 1 && !isLoading && (
                  <tr>
                    <td colSpan={headerGroups ? headerGroups[0]?.headers?.length + 1 : 3} style={{ textAlign: "center" }}>
                      Data tidak ditemukan
                    </td>
                  </tr>
                )}
              </>
            )}
          </tbody>
        </table>
      </div>
      <div style={{ width: "100%" }}>
        {pagination && !isLoading && <Pagination page={pagination?.page !== 0 ? pagination.page : 1} dataTotal={length || data?.length} recordTotal={pagination?.totalData} maxPage={pagination?.maxPage} toggle={pagination?.toggle} />}
      </div>
    </div>
  )
}

export default Index
