import { history } from "../../../utils/History";
import { actionPending, actionSuccess, actionError } from "../actionTypes";

import { toastSuccess, toastError } from "../../../components/commons/toast";

import API from "../API";
import { handleResponse } from "../HandleRespone";

// ACTION TYPES TO DISPATCH: METHOD_URL_TYPES
export const POST_USER_CHANGE_PASSWORD_PENDING =
  "POST_USER_CHANGE_PASSWORD_PENDING";
export const POST_USER_CHANGE_PASSWORD_SUCCESS =
  "POST_USER_CHANGE_PASSWORD_SUCCESS";
export const POST_USER_CHANGE_PASSWORD_ERROR =
  "POST_USER_CHANGE_PASSWORD_ERROR";
export const PUT_USER_CHANGE_PROFILE_PENDING =
  "PUT_USER_CHANGE_PROFILE_PENDING";
export const PUT_USER_CHANGE_PROFILE_SUCCESS =
  "PUT_USER_CHANGE_PROFILE_SUCCESS";
export const PUT_USER_CHANGE_PROFILE_ERROR = "PUT_USER_CHANGE_PROFILE_ERROR";

// URL: URL_{URL}
const USER_CHANGE_PASSWORD_URL = `v1/authentications/change-password`;
const USER_CHANGE_PROFILE_URL = `v1/authentications/edit-profile`;

const changePassword = (param) => (dispatch) => {
  dispatch(actionPending(POST_USER_CHANGE_PASSWORD_PENDING));
  API.patch(USER_CHANGE_PASSWORD_URL, param)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      localStorage.removeItem("user_aws");
      localStorage.removeItem("persist:root");
      history.push("/");
      toastSuccess("Kata sandi berhasil diubah");
    })
    .catch((err) => {
      dispatch(actionError(POST_USER_CHANGE_PASSWORD_ERROR));
      toastError(err?.response?.data?.message);
    });
};

const changeProfile = (param, callback) => (dispatch) => {
  dispatch(actionPending(PUT_USER_CHANGE_PROFILE_PENDING));
  API.put(USER_CHANGE_PROFILE_URL, param)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(PUT_USER_CHANGE_PROFILE_SUCCESS, res));
      toastSuccess("Berhasil mengubah profil");
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(PUT_USER_CHANGE_PROFILE_ERROR));
      toastError(err?.response?.data?.message);
    });
};

const User = {
  changePassword,
  changeProfile,
};
export default User;
