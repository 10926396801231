import { useEffect, useState } from "react"
import { connect, useDispatch } from "react-redux"
import { Col, Row } from "reactstrap"
import CardDiagram from "../../../components/cards/cardDiagram"
import Card from "../../../components/cards/card"
import { getUser } from "../../../utils/User"

import moment from "moment"
import localization from "moment/locale/id"

import WaterBalance from "../../../store/actions/water_balance"

const Index = ({ selectedDate, selectedMonth, switchPDAM, pending, waterBalance, update }) => {
  const dispatch = useDispatch()
  const currentUser = getUser().user
  const [dataDiagrams, setDataDiagrams] = useState([])

  useEffect(() => {
    const param = {
      pdam_id: currentUser?.pdam?.id ?? "",
      month: selectedMonth,
      year: selectedDate ? moment(selectedDate).locale("id", localization).format("YYYY") : "",
    }
    dispatch(WaterBalance.diagrams(param))
  }, [selectedMonth, selectedDate, switchPDAM, update])

  useEffect(() => {
    if (waterBalance?.data_diagrams?.data?.data) {
      setDataDiagrams(waterBalance?.data_diagrams?.data?.data)
    }
  }, [waterBalance])

  const toFixed = (data) => {
    let dataFixed
    dataFixed = parseFloat(data).toFixed(2).replace(".", ",")
    return dataFixed
  }

  const numberWithDots = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
  }

  return (
    <div className="menu-container">
      <div className="content-container-side">
        <Row>
          <Col md={4}>
            <CardDiagram
              sectr1
              sectr1LabelCardXL={"Volume Input Sistem"}
              sectr1LabelCardLG={"Konsumsi Resmi"}
              sectr1LabelCardMD={"Kehilangan Air"}
              sectr1DataCardXL={numberWithDots(toFixed(dataDiagrams?.system_input_volume))}
              sectr1DataCardLG={numberWithDots(toFixed(dataDiagrams?.official_consumption))}
              sectr1DataCardMD={numberWithDots(toFixed(dataDiagrams?.water_losses))}
              sectr1PercentDataCardXL={numberWithDots(toFixed(dataDiagrams?.percent_system_input_volume))}
              sectr1PercentDataCardLG={numberWithDots(toFixed(dataDiagrams?.percent_official_consumption))}
              sectr1PercentDataCardMD={numberWithDots(toFixed(dataDiagrams?.percent_water_losses))}
            />
          </Col>
          <Col md={6}>
            <CardDiagram
              sectr2
              sectr2LabelLeft={"Konsumsi Resmi Berekening"}
              labelStrecth1={"Konsumsi Bermeter Berekening"}
              labelStrecth2={"Konsumsi Tak Bermeter Berekening"}
              labelStrecth3={"Konsumsi Export Bermeter Berekening"}
              sectr2DataLeft={numberWithDots(toFixed(dataDiagrams?.billed_official_consumption))}
              dataStrecth1={numberWithDots(toFixed(dataDiagrams?.metered_and_billed_consumption))}
              dataStrecth2={numberWithDots(toFixed(dataDiagrams?.non_metered_and_billed_consumption))}
              dataStrecth3={numberWithDots(toFixed(dataDiagrams?.export_metered_and_billed_consumption))}
              sectr2PercentDataLeft={numberWithDots(toFixed(dataDiagrams?.percent_billed_official_consumption))}
              dataPercentStrecth1={numberWithDots(toFixed(dataDiagrams?.percent_metered_and_billed_consumption))}
              dataPercentStrecth2={numberWithDots(toFixed(dataDiagrams?.percent_non_metered_and_billed_consumption))}
              dataPercentStrecth3={numberWithDots(toFixed(dataDiagrams?.percent_export_metered_and_billed_consumption))}
            />
            <CardDiagram
              sectr2
              red
              sectr2LabelLeft={"Konsumsi Resmi Tak Berekening"}
              labelStrecth1={"Konsumsi Bermeter Tak Berekening"}
              labelStrecth2={"Konsumsi Tak Bermeter Tak Berekening"}
              sectr2DataLeft={numberWithDots(toFixed(dataDiagrams?.non_billed_official_consumption))}
              dataStrecth1={numberWithDots(toFixed(dataDiagrams?.metered_and_non_billed_consumption))}
              dataStrecth2={numberWithDots(toFixed(dataDiagrams?.non_metered_and_non_billed_consumption))}
              sectr2PercentDataLeft={numberWithDots(toFixed(dataDiagrams?.percent_non_billed_official_consumption))}
              dataPercentStrecth1={numberWithDots(toFixed(dataDiagrams?.percent_metered_and_non_billed_consumption))}
              dataPercentStrecth2={numberWithDots(toFixed(dataDiagrams?.percent_non_metered_and_non_billed_consumption))}
            />
            <CardDiagram
              sectr2
              red
              sectr2LabelLeft={"Kehilangan Air Non-Fisik"}
              labelStrecth1={"Konsumsi Tak Resmi"}
              labelStrecth2={"Ketidakakuratan Meter dan Penanganan Data"}
              sectr2DataLeft={numberWithDots(toFixed(dataDiagrams?.non_physical_water_losses))}
              dataStrecth1={numberWithDots(toFixed(dataDiagrams?.unofficial_consumption))}
              dataStrecth2={numberWithDots(toFixed(dataDiagrams?.data_inaccuracies))}
              sectr2PercentDataLeft={numberWithDots(toFixed(dataDiagrams?.percent_non_physical_water_losses))}
              dataPercentStrecth1={numberWithDots(toFixed(dataDiagrams?.percent_unofficial_consumption))}
              dataPercentStrecth2={numberWithDots(toFixed(dataDiagrams?.percent_data_inaccuracies))}
            />
            <Card>
              <div className="text-wrapper-card-strecth text-center text-dark bg-red">
                <div className="text-extra-bold">{"Kehilangan Air Fisik"}</div>
                <div className="text-medium">
                  {`${numberWithDots(toFixed(dataDiagrams?.physical_water_losses))}`} m<sup>3</sup>
                </div>
                <div className="text-medium">{`${numberWithDots(toFixed(dataDiagrams?.percent_physical_water_losses))}`} %</div>
              </div>
            </Card>
          </Col>
          <Col md={2}>
            <CardDiagram
              sectr3
              sectr3Data1={numberWithDots(toFixed(dataDiagrams?.billed_water))}
              sectr3Data2={numberWithDots(toFixed(dataDiagrams?.nrw))}
              sectr3PercentData1={numberWithDots(toFixed(dataDiagrams?.percent_billed_water))}
              sectr3PercentData2={numberWithDots(toFixed(dataDiagrams?.percent_nrw))}
            />
          </Col>
        </Row>
      </div>
    </div>
  )
}

const mapStateToProps = ({ waterBalance, waterBalance: { pending } }) => {
  return { waterBalance, pending }
}
const mapDispatchToProps = (dispatch) => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(Index)
