import { Role } from "../../../utils/Constants";

const all = [
  Role.Superadmin,
  Role.It_admin,
  Role.User_cust_rep,
  Role.Viewer,
  Role.Kadiv,
];
const user_cust_kadiv = [Role.Superadmin, Role.User_cust_rep, Role.Kadiv];
const notIncIt = [Role.Superadmin, Role.User_cust_rep, Role.Viewer, Role.Kadiv];
const it_kadiv_super = [Role.It_admin, Role.Kadiv, Role.Superadmin];

export const SidebarItem = [
  {
    title: "Dashboard",
    slug: "dashboard",
    url: "/dashboard",
    icon: "grid_view",
    // roles: all,
    roles: notIncIt,
  },
  {
    title: "Data Logger",
    slug: "data-logger",
    url: "/data-logger",
    icon: "trending_up",
    roles: notIncIt,
  },
  {
    title: "Warning System",
    slug: "warning-system",
    url: "/warning-system",
    icon: "warning",
    roles: notIncIt,
  },
  {
    title: "ILI",
    slug: "ili",
    url: "/ili",
    icon: "water_damage",
    roles: notIncIt,
  },
  {
    title: "Commercial Losses",
    slug: "commercial-losses",
    url: "/commercial-losses",
    icon: "cancel",
    roles: notIncIt,
  },
  {
    title: "Step Test Support",
    slug: "step-test-support",
    url: "/step-test-support",
    icon: "support",
    roles: notIncIt,
  },
  {
    title: "Water Balance",
    slug: "water-balance",
    url: "/water-balance",
    icon: "invert_colors",
    roles: notIncIt,
  },
  {
    title: "Hydraulic Model",
    slug: "hydraulic-model",
    url: "/hydraulic-model",
    icon: "water",
    roles: notIncIt,
  },
  {
    title: "Repair Work Report",
    slug: "repair-work-report",
    url: "/repair-work-report",
    icon: "handyman",
    roles: notIncIt,
  },
  {
    title: "System",
    slug: "system",
    url: "/system",
    icon: "settings",
    roles: it_kadiv_super,
  },
  {
    title: "Data Master",
    slug: "data-master",
    url: "/data-master",
    icon: "storage",
    roles: user_cust_kadiv,
  },
];
