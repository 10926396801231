import {
  GET_WARNING_SYSTEM_PENDING,
  GET_WARNING_SYSTEM_SUCCESS,
  GET_WARNING_SYSTEM_ERROR,
  PUT_WARNING_SYSTEM_PENDING,
  PUT_WARNING_SYSTEM_SUCCESS,
  PUT_WARNING_SYSTEM_ERROR,
} from "../../actions/warning_system";

const initialState = {
  pending: false,
  error: null,
  data_list: null,
  pending_put: false,
  data_put: null,
};

const warningSystem = (state = initialState, action) => {
  switch (action.type) {
    case GET_WARNING_SYSTEM_PENDING:
      return {
        ...state,
        pending: true,
      };
    case GET_WARNING_SYSTEM_SUCCESS:
      return {
        ...state,
        pending: false,
        data_list: action.data,
      };
    case GET_WARNING_SYSTEM_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case PUT_WARNING_SYSTEM_PENDING:
      return {
        ...state,
        pending_put: true,
      };
    case PUT_WARNING_SYSTEM_SUCCESS:
      return {
        ...state,
        pending_put: false,
        data_put: action.data,
      };
    case PUT_WARNING_SYSTEM_ERROR:
      return {
        ...state,
        pending_put: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default warningSystem;
