import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import CommercialLosses, {
  setCommercialLossesData,
} from "../../../store/actions/commercial_losses";
import Table from "../../../components/table";
import { history } from "../../../utils/useHistory";
import DetailButton from "../../../components/table/detailButton";
import MenuItem from "@material-ui/core/MenuItem";
import { getUser } from "../../../utils/User";
import moment from "moment";
import ModalCommercialLosses from "./ModalEditCommercialLosses";

const Index = ({
  pending,
  search_text,
  filter,
  sort,
  onSetCommercialLossesData,
  goToMapDetail,
}) => {
  const dispatch = useDispatch();
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [dataCommercialLosses, setDataCommercialLosses] = useState([]);
  const [detailData, setDetailData] = useState(null);
  const [totalData, setTotalData] = useState(null);
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const currentUser = getUser().user;
  const [edit, setEdit] = useState(false);

  const getData = () => {
    new Promise((resolve) => {
      const param = {
        length: 10,
        page: page || 1,
        pdam_id: currentUser?.pdam?.id || "",
        dma_id: filter?.dma_id,
        month: filter?.date
          ? moment(filter.date).format("M")
          : moment().format("M"),
        year: filter?.date
          ? moment(filter.date).format("YYYY")
          : moment().format("YYYY"),
        sort,
        search_text,
      };
      dispatch(CommercialLosses.get(param, resolve));
    }).then((res) => {
      setDataCommercialLosses(res?.data);
      setTotalData(res?.totalCount);
      setMaxPage(Math.ceil(res?.totalCount / 10));
    });
  };

  useEffect(() => {
    setPage((prev) => (prev === 1 ? null : 1));
  }, [search_text, filter, sort, edit]);

  useEffect(() => {
    getData();
  }, [page, currentUser?.pdam?.id]);

  const columns = [
    {
      Header: "Bulan Tahun",
      accessor: "bulan",
      Cell: ({ row }) => {
        const { original } = row;

        return (
          `${moment().month(original.bulan - 1)?.format("MMMM")} ${original.tahun}`
        );

      }
    },
    {
      Header: "DMA",
      accessor: "dma",
    },
    {
      Header: "Cust Number",
      accessor: "custno",
    },
    {
      Header: "Cust Name",
      accessor: "name",
    },
    {
      Header: "Address",
      accessor: "address",
    },
    {
      Header: "Rate",
      accessor: "rate",
    },
    {
      Header: "Meter Size",
      accessor: "meterSize",
      Cell: ({ row }) => {
        const { original } = row;

        return (
          original?.meterSize ?? '-'
        );

      }
    },
    {
      Header: "Issue",
      accessor: "issue",
    },
    {
      Header: "Volume",
      accessor: "volume",
    },
    {
      Header: "Persentase",
      accessor: "presentase",
    },
    // {
    //   Header: "",
    //   accessor: "aksi",
    //   Cell: ({ row }) => {
    //     const { original } = row;

    //     return (
    //       <DetailButton>
    //         <MenuItem
    //           onClick={() => {
    //             onSetCommercialLossesData(original);
    //             history.push(
    //               `/commercial-losses/detail/${original.customer_id}`
    //             );
    //           }}
    //         >
    //           <span style={{ fontSize: 14 }}>Detail</span>
    //         </MenuItem>
    //         <MenuItem onClick={() => handleEdit(original)}>
    //           <span style={{ fontSize: 14 }}>Edit</span>
    //         </MenuItem>
    //         <MenuItem
    //           onClick={() => {
    //             goToMapDetail?.(original);
    //           }}
    //         >
    //           <span style={{ fontSize: 14 }}>Lihat Peta</span>
    //         </MenuItem>
    //       </DetailButton>
    //     );
    //   },
    // },
  ];

  const handleEdit = (data) => {
    setDetailData(data);
    setShowModalEdit(true);
  };

  return (
    <div className="">
      <div className="overflow">
        <Table
          id="tbl-data-comloss"
          columns={columns}
          isLoading={pending}
          data={dataCommercialLosses}
          pagination={{ page, totalData, maxPage, toggle: setPage }}
        />
      </div>
      <ModalCommercialLosses
        type="edit"
        showModal={showModalEdit}
        show={showModalEdit}
        toggle={() => setShowModalEdit(false)}
        refresh={setEdit}
        data={detailData}
      />
    </div>
  );
};

const mapStateToProps = ({ commercialLosses: { pending } }) => {
  return { pending };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSetCommercialLossesData: (data) =>
      dispatch(setCommercialLossesData(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
