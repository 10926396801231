import { useState, useRef, useEffect } from "react";
import Select from "react-select";

function useComponentVisible(initialIsVisible) {
  const [isComponentVisible, setIsComponentVisible] =
    useState(initialIsVisible);
  const ref = useRef(null);

  const handleHideDropdown = (event: KeyboardEvent) => {
    if (event.key === "Escape") {
      setIsComponentVisible(false);
    }
  };

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsComponentVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleHideDropdown, true);
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("keydown", handleHideDropdown, true);
      document.removeEventListener("click", handleClickOutside, true);
    };
  });

  return { ref, isComponentVisible, setIsComponentVisible };
}

const SelectOnClick = ({
  value,
  label,
  options,
  onChange,
  placeholder,
  outside,
}) => {
  const [valueInput, setValueInput] = useState(value ?? "");
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);

  const handleChangeValue = (item) => {
    setValueInput(item.value);
    if (onChange && item.value && label) {
      onChange(item.value, label);
    }
  };

  useEffect(() => {
    setValueInput(value);
  }, [value]);

  const handleShow = () => {
    setIsComponentVisible(true);
  };

  const customStyles = {
    control: (base, state) => ({
      ...base,
      border: "none",
      padding: 0,
    }),
  };

  const check = options?.filter((obj) => {
    return obj?.value === valueInput;
  })[0]?.label;

  return (
    <>
      {isComponentVisible ? (
        <div ref={ref}>
          <Select
            className="basic-single"
            styles={customStyles}
            value={
              options?.filter((obj) => {
                return obj?.value === valueInput;
              })[0] ?? { label: placeholder ?? "Select...", key: "" }
            }
            options={options}
            onChange={handleChangeValue}
          />
        </div>
      ) : (
        <span
          onClick={onChange ? () => handleShow() : ""}
          style={{ width: "100%", wordBreak: "break-word" }}
        >
          {check ? check : "-"}
        </span>
      )}
    </>
  );
};

export default SelectOnClick;
