import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { connect, useDispatch } from "react-redux";
import ButtonModal from "../../components/modal/ButtonModal";
import DynamicButton from "../../components/forms/DynamicButton";
import { useFormik } from "formik";
import moment from "moment";
import DatePicker from "../../components/forms/DateRangePicker";
import Select2 from "../../components/forms/Select2";

function ModalFilter({ show, toggle, filter, toggleFilter }) {
  const dispatch = useDispatch();

  const handleReset = () => {
    formik.resetForm();
    toggleFilter({ end_date: null, start_date: null, status: "" });
    toggle();
  };

  const formik = useFormik({
    initialValues: {
      start_date: filter.start_date,
      end_date: filter.end_date,
      status: filter.status,
    },
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setStatus();
      toggleFilter({
        end_date: values.end_date
          ? moment(values.end_date).format("YYYY-MM-DD")
          : "",
        start_date: values.start_date
          ? moment(values.start_date).format("YYYY-MM-DD")
          : "",
        status: values.status,
      });
      toggle();
    },
  });

  return (
    <>
      <Modal isOpen={show} modalTransition={{ timeout: 200 }}>
        <ModalHeader className="text-extra-bold pt-5">
          Filter{" "}
          <span className="close clickable" onClick={toggle}>
            &times;
          </span>
        </ModalHeader>
        <form onSubmit={formik.handleSubmit}>
          <ModalBody style={{ margin: "0px 20px" }}>
            <DynamicButton
              titleLeft="Pilih data yang ingin difilter"
              iconLeft="info"
            />
            <DatePicker
              title="Tanggal"
              name="start_date"
              handlechange={(startDate, endDate) => {
                formik.setFieldValue("start_date", startDate);
                formik.setFieldValue("end_date", endDate);
              }}
              format="DD MMMM YYYY"
              startDate={formik.values.start_date}
              endDate={formik.values.end_date}
              maxdate={new Date()}
              className="mx-auto"
              errors={formik.errors}
              touched={formik.touched}
            />
            <Select2
              title="Status"
              name="status"
              options={[
                { value: "", label: "Semua Status" },
                { value: 0, label: "Baru" },
                { value: 1, label: "Selesai" },
              ]}
              errors={formik.errors}
              touched={formik.touched}
              value={formik.values.status}
              placeholder="Pilih Status"
              onChange={(name, value) => {
                formik.setFieldValue("status", value);
              }}
              onBlur={formik.setFieldTouched}
            />
            <div style={{ marginTop: 50, marginBottom: 30 }}>
              <ButtonModal
                toggle={toggle}
                reset={handleReset}
                confirmTitle="Terapkan"
              />
            </div>
          </ModalBody>
        </form>
      </Modal>
    </>
  );
}

const mapStateToProps = ({ masterDataZone, masterDataPDAM }) => {
  return { masterDataZone, masterDataPDAM };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalFilter);
