import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { connect, useDispatch } from "react-redux";
import Select2 from "../../../components/forms/Select2";
import ButtonModal from "../../../components/modal/ButtonModal";
import DynamicButton from "../../../components/forms/DynamicButton";
import Loader from "../../../components/commons/Loader"
import { getUser } from "../../../utils/User";

import InputService from "../../../store/actions/master_data/input";
import PDAMService from "../../../store/actions/master_data/pdam";

function ModalFilter({
  show,
  toggle,
  filter,
  toggleFilter,
  isDelete,
  switchPDAM,
  masterDataInput,
  masterDataPDAM
}) {
  const currentUser = getUser();
  const dispatch = useDispatch();
  const [selectedPDAM, setSelectedPDAM] = useState(
    filter?.pdam_id ?? currentUser?.user?.pdam?.id ?? ""
  );
  const [input, setInput] = useState([]);
  const [pdam, setPDAM] = useState([]);
  const search = filter?.search;

  const handleReset = (formik) => {
    formik.setFieldValue("pdam", currentUser?.user?.pdam?.id ?? "");
    formik.setFieldValue("input", "");
    setSelectedPDAM(currentUser?.user?.pdam?.id ?? "")
    toggleFilter({
      pdam_id: currentUser?.user?.pdam?.id ?? "",
      input_id: "",
      search,
    });
    toggle();
  };

  const handleClose = () => {
    setSelectedPDAM(currentUser?.user?.pdam?.id ?? "")
    toggle();
  }

  useEffect(() => {
    const param = {
      draw: 1,
      length: 999,
      page: 1,
      id: switchPDAM?.id,
    };

    dispatch(PDAMService.get(param));


  }, [switchPDAM]);

  useEffect(() => {
    if (masterDataPDAM) {
      let pdam_list = masterDataPDAM?.data_list?.data.data;
      setPDAM(pdam_list)
    }
  }, [masterDataPDAM]);

  useEffect(() => {
    const param = {
      draw: 1,
      length: 999,
      page: 1,
    };

    dispatch(InputService.get(param));
  }, []);

  useEffect(() => {
    setSelectedPDAM(filter?.pdam_id);
  }, [filter]);

  useEffect(() => {

    if (masterDataInput) {
      let input_list = masterDataInput?.data_list?.data?.data;
      if (selectedPDAM) {
        input_list = input_list?.filter(item => item.pdam_id === selectedPDAM);
      }
      setInput(input_list)
    }
  }, [selectedPDAM, masterDataInput]);

  return (
    <>
      {(masterDataInput?.pending || masterDataPDAM?.pending) && show && <Loader loading={masterDataInput?.pending || masterDataPDAM?.pending} />}
      <Modal
        isOpen={show}
        modalTransition={{ timeout: 200 }}
        backdropTransition={{ timeout: 1300 }}
      >
        <ModalHeader className="text-extra-bold pt-5">
          {" "}
          Filter{" "}
          <span className="close clickable" onClick={handleClose}>
            &times;
          </span>
        </ModalHeader>
        <ModalBody style={{ margin: "0px 20px" }}>
          <DynamicButton
            titleLeft="Pilih data yang ingin difilter"
            iconLeft="info"
          />
          <Formik
            initialValues={{
              pdam: currentUser?.user?.pdam?.id ?? filter?.pdam_id ?? "",
              input: filter?.input_id ?? "",
            }}
            onSubmit={(values, { setStatus, setSubmitting }) => {
              setStatus();
              toggleFilter({
                pdam_id: values?.pdam ?? "",
                input_id: values?.input ?? "",
              });
              toggle();
            }}
          >
            {(props) => (
              <Form style={{ marginTop: 30 }}>
                <Select2
                  title="PDAM"
                  name="pdam"
                  options={pdam?.map((item) => ({
                    value: item?.id,
                    label: item?.name,
                  }))}
                  errors={props.errors}
                  touched={props.touched}
                  value={props.values?.pdam}
                  placeholder="Pilih data PDAM"
                  onChange={(name, value) => {
                    props.setFieldValue("pdam", value);
                    props.setFieldValue("input", "");
                    setSelectedPDAM(value);
                  }}
                  onBlur={props.setFieldTouched}
                  disabled={currentUser?.user?.pdam}
                />
                <Select2
                  title="Input"
                  name="input"
                  options={input?.map((item) => ({
                    value: item?.id,
                    label: item?.name,
                  }))}
                  errors={props.errors}
                  touched={props.touched}
                  value={props.values?.input}
                  placeholder="Pilih data Input"
                  onChange={(name, value) => {
                    props.setFieldValue("input", value);
                  }}
                  onBlur={props.setFieldTouched}
                />
                <div style={{ marginTop: 50, marginBottom: 30 }}>
                  <ButtonModal
                    toggle={handleClose}
                    reset={() => handleReset(props)}
                    deleted={isDelete}
                    confirmTitle="Terapkan"
                  />
                </div>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </Modal>
    </>
  );
}

const mapStateToProps = ({ masterDataInput, masterDataPDAM }) => {
  return { masterDataInput, masterDataPDAM };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalFilter);
