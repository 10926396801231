import { connect, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { history } from "../../utils/useHistory";
import Table from "../../components/table";
import { getUser } from "../../utils/User";
import CommercialLosses from "../../store/actions/commercial_losses";
import moment from "moment";

const DetailCommercial = ({ detailData, pending, match }) => {
  const dispatch = useDispatch();
  const [dataCommercialLosses, setDataCommercialLosses] = useState([]);
  const [totalData, setTotalData] = useState(null);
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const currentUser = getUser().user;

  const customerID = match?.params?.id;

  const getData = () => {
    new Promise((resolve) => {
      const param = {
        length: 10,
        page: page,
        pdam_id: currentUser?.pdam?.id,
        customer_id: detailData?.customer_id ?? customerID,
        sort: "year DESC, month DESC",
      };
      dispatch(CommercialLosses.get(param, resolve));
    }).then((res) => {
      setDataCommercialLosses(res?.data);
      setTotalData(res?.totalCount);
      setMaxPage(Math.ceil(res?.totalCount / 10));
    });
  };

  useEffect(() => {
    if (detailData || customerID) {
      getData();
    }
  }, [page]);

  const columns = [
    {
      Header: "NO",
      accessor: "no",
    },
    {
      Header: "BULAN",
      accessor: "month",
      Cell: ({ row }) => {
        const { original } = row;
        const { month, year } = original;

        return <>{moment(`${year}-${month}-01`).format("MMMM YYYY")}</>;
      },
    },
    {
      Header: () => (
        <>
          ST KINI (m<sup>3</sup>)
        </>
      ),
      accessor: "stdkini",
    },
    {
      Header: () => (
        <>
          ST LALU (m<sup>3</sup>)
        </>
      ),
      accessor: "stdlalu",
    },
    {
      Header: "KUBIKASI",
      accessor: "kubik",
      Cell: ({ row }) => {
        const { original } = row;
        const { kubik } = original;

        return <>{kubik?.toFixed(2)}</>;
      },
    },
  ];

  return (
    <div className="menu-container">
      <div>
        <div className={`header-nav inactive-pointer-evt header-title pr-0`}>
          <div className="menu-title text-extra-bold">
            Detail Commercial Losses
            <div className="d-flex align-items-center text-medium normal-title">
              <span className="">
                {detailData?.customer_name ??
                  (dataCommercialLosses?.length > 0
                    ? dataCommercialLosses[0]?.customer_name
                    : "-") ??
                  "-"}
              </span>{" "}
              <div className="dot mx-3" />{" "}
              <span className="subtitle">
                SR{" "}
                {detailData?.customer_sr_number ??
                  (dataCommercialLosses?.length > 0
                    ? dataCommercialLosses[0]?.customer_sr_number
                    : "-") ??
                  "-"}
              </span>
            </div>
          </div>
          <div className="menu-right">
            <div className="active-pointer-evt menu-item">
              <div className="detail-menu-close-icon">
                <span
                  className="material-icons clickable"
                  onClick={() => history.goBack()}
                >
                  close
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="content-container-side">
          <div className="overflow">
            <Table
              columns={columns}
              isLoading={pending}
              data={dataCommercialLosses}
              pagination={{ page, totalData, maxPage, toggle: setPage }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ commercialLosses: { detailData, pending } }) => {
  return { detailData, pending };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(DetailCommercial);
