import React, { useState, useRef } from "react";
import { connect, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { getUser } from "../../utils/User";
import Input from "../../components/forms/Input";
import Button from "../../components/buttons/Button";

// ACTION REDUX
import UserService from "../../store/actions/user";
import AuthService from "../../store/actions/auth";

const ChangePassword = ({ t, match }) => {
  const formikRef = useRef();
  const dispatch = useDispatch();
  const [isMatch, setIsMatch] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [matchMessage, setMatchMessage] = useState(null);
  const [checkMessage, setCheckMessage] = useState(null);
  const alphanumeric = /^(?=.*[a-zA-Z])(?=.*[0-9])/;
  const alphaspecial = /[a-zA-Z]+[(@!#\$%\^\&*\)\(+=._-]{1,}/;
  const numericspecial = /[0-9]+[(@!#\$%\^\&*\)\(+=._-]{1,}/;

  function checkPassword() {
    const values = formikRef?.current?.values;

    if (values?.new_password?.length < 8) {
      setCheckMessage("Password minimal terdiri dari 8 karakter");
      setIsValid(false);
    } else if (
      values?.new_password?.match(alphanumeric) === null &&
      values?.new_password.match(alphaspecial) === null &&
      values?.new_password.match(numericspecial) === null
    ) {
      setCheckMessage(
        "Password minimal terdiri dari kombinasi huruf, angka, atau karakter spesial"
      );
      setIsValid(false);
    } else {
      setCheckMessage(null);
      setIsValid(true);
    }
  }

  function confirmationPassword() {
    const values = formikRef?.current?.values;

    if (values?.new_password?.length > 7 && values?.confirmation_password) {
      if (values?.new_password === values?.confirmation_password) {
        setIsMatch(true);
        setMatchMessage("Kata sandi baru dan konfirmasi sesuai");
      } else {
        setIsMatch(false);
        setMatchMessage("Kata sandi baru dan konfirmasi tidak sesuai");
      }
    } else {
      setIsMatch(false);
      setMatchMessage(null);
    }
  }

  const validation = Yup.object().shape({
    new_password: Yup.string()
      .required("Kata Sandi Baru" + " wajib diisi")
      .nullable(),
    confirmation_password: Yup.string()
      .required("Konfirmasi Kata Sandi" + " wajib diisi")
      .nullable(),
  });

  const currentUser = getUser();

  const title = currentUser ? "Reset Password" : "Reset Kata Sandi";
  const subtitle = currentUser
    ? "Masukkan password baru untuk mereset password"
    : "Masukkan kata sandi baru untuk reset";

  return (
    <div className="main-wrapper">
      <div className="login-container">
        <div className="content-wrapper">
          <p className="super-title text-extra-bold">{title}</p>
          <p className="subtitle text-medium" style={{ marginBottom: 50 }}>
            {subtitle}
          </p>
          <div className="form-wrapper">
            <Formik
              innerRef={formikRef}
              initialValues={{
                new_password: "",
                confirmation_password: "",
              }}
              validationSchema={validation}
              onSubmit={(values, { setStatus, setSubmitting }) => {
                setStatus();
                if (!currentUser) {
                  values.reset_password_verification_code =
                    match?.params?.token;
                  values.user_id = match?.params?.id;
                  dispatch(AuthService.resetPassword(values));
                } else {
                  dispatch(UserService.resetPassword(values));
                }
              }}
            >
              {(props) => (
                <Form>
                  <Input
                    title="Kata Sandi Baru"
                    name="new_password"
                    type="password"
                    placeholder="Masukkan kata sandi baru"
                    errors={props.errors}
                    touched={props.touched}
                    onKeyUp={() => checkPassword()}
                    {...props.getFieldProps("new_password")}
                  />
                  {checkMessage && (
                    <div
                      className="subtitle text-medium"
                      style={{
                        fontSize: 10,
                        marginTop: "-5px",
                        marginBottom: 10,
                      }}
                    >
                      {checkMessage}
                    </div>
                  )}
                  <Input
                    title="Ulangi Kata Sandi"
                    name="confirmation_password"
                    type="password"
                    placeholder="Masukkan konfirmasi kata sandi"
                    errors={props.errors}
                    touched={props.touched}
                    onKeyUp={() => confirmationPassword()}
                    {...props.getFieldProps("confirmation_password")}
                  />
                  {matchMessage && (
                    <div
                      className="text-medium"
                      style={{
                        fontSize: 10,
                        color: isMatch ? "#4894FE" : "#E01640",
                        marginTop: "-5px",
                        marginBottom: 10,
                      }}
                    >
                      {matchMessage}
                    </div>
                  )}
                  <div className="button-wrapper" style={{ marginTop: "30px" }}>
                    <Button
                      title={title}
                      rounded
                      type="submit"
                      variant="button primary"
                      style={{ width: "100%", textAlign: "center" }}
                      disabled={!isMatch || !isValid}
                    />
                  </div>
                </Form>
              )}
            </Formik>
            <div
              style={{ marginTop: 100, position: "absolute", bottom: 10 }}
              className="subtitle"
            ></div>
          </div>
        </div>
        <div className="side-login"></div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ user }) => {
  return { user };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ChangePassword)
);
