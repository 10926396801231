import { Divider } from "@material-ui/core";
import InputOnClick from "../forms/InputOnClick";
import SelectOnClick from "../forms/SelectOnClick";

import "./card.scss";

const CardFlush = ({
  type,
  title,
  desc,
  leftIcon,
  label,
  sideMenu,
  list,
  status,
  iconColor,
  color,
  button,
  dropdownList,
  toggle,
  mapTooltip,
  last,
  reverse,
  size,
  editable,
  disabled,
  options,
  outside,
}) => {
  return (
    <>
      {sideMenu && (
        <div className="card-flush" style={{ padding: 0 }}>
          <div
            style={{
              width: "100%",
              display: "flex",
              padding: "18px 30px 15px",
              alignItems: "center",
            }}
          >
            {leftIcon && (
              <span
                className="material-icons-round"
                style={{
                  fontSize: 35,
                  marginRight: 25,
                  marginTop: "-10px",
                  color: iconColor ?? "#04AA59",
                }}
              >
                {leftIcon}
              </span>
            )}
            <div style={{ wordWrap: "anywhere" }}>
              <p
                className="card-flush-desc"
                style={{ fontSize: 14, marginBottom: 8 }}
              >
                {title}
              </p>
              <p
                className="card-flush-title"
                style={{ fontSize: 18, marginBottom: 10 }}
              >
                {desc}
              </p>
            </div>
          </div>
          <Divider orientation="horizontal" />
        </div>
      )}

      {mapTooltip && (
        <div>
          <div
            style={{
              width: "100%",
              padding: "10px 15px",
              alignItems: "center",
              borderBottom: last ? "" : "solid 1px #5A5A5A1A",
              color: "#242726",
              wordWrap: "break-word",
              display: "block",
            }}
          >
            <p
              className="card-flush-desc"
              style={{ fontSize: 14, marginBottom: 10 }}
            >
              {title}
            </p>
            <p
              className="text-bold"
              style={{ fontSize: 14, marginTop: 0, marginBottom: 10 }}
            >
              {desc}
            </p>
          </div>
        </div>
      )}

      {dropdownList && (
        <>
          <div
            className="mx-3 py-3"
            style={{ cursor: "pointer" }}
            onClick={toggle}
          >
            <div className="text-extra-bold">
              {label}
              <span className="material-icons mr-auto float-right right-icon">
                expand_more
              </span>
            </div>
          </div>
        </>
      )}

      {list && (
        <>
          <div
            className="row justify-content-center mx-auto py-3"
            style={{ fontSize: 14 }}
          >
            {button && (
              <>
                <div className={`col-md-4 ${reverse ? "" : "text-extra-bold"}`}>
                  {label}
                </div>
                <div
                  className={`col-md-7 ${
                    reverse ? "text-extra-bold" : "text-medium"
                  }`}
                >
                  {button}
                </div>
              </>
            )}
            {editable && (
              <>
                <div
                  className={`${
                    size?.label ? "col-md-" + size?.label : "col-md-4"
                  } ${reverse ? "" : "text-extra-bold"}`}
                >
                  {label}
                </div>
                <div
                  className={`${
                    size?.desc ? "col-md-" + size?.desc : "col-md-7"
                  } ${reverse ? "text-extra-bold" : "text-medium"}`}
                >
                  {options && (
                    <SelectOnClick
                      value={desc}
                      label={label}
                      options={options}
                      onChange={editable}
                      outside={outside}
                    />
                  )}
                  {!options && (
                    <InputOnClick
                      value={desc}
                      label={label}
                      type={type ?? "text"}
                      onChangeEdit={editable}
                    />
                  )}
                </div>
              </>
            )}
            {!button && !editable && (
              <>
                <div
                  className={`${
                    size?.label ? "col-md-" + size?.label : "col-md-4"
                  } ${reverse ? "" : "text-extra-bold"}`}
                >
                  {label}
                </div>
                <div
                  className={`${
                    size?.desc ? "col-md-" + size?.desc : "col-md-7"
                  } ${reverse ? "text-extra-bold" : "text-medium"} ${
                    disabled ? "text-disabled" : ""
                  }`}
                  style={{ wordBreak: "break-word" }}
                >
                  {desc}
                </div>
              </>
            )}
          </div>
          <Divider orientation="horizontal" />
        </>
      )}

      {status && (
        <>
          <div className={`wrapper-detail-card ${(color = color)}`}>
            <div
              className="row justify-content-center py-3"
              style={{ fontSize: 16 }}
            >
              <div className="col-1 text-extra-bold">
                <span className="material-icons left-icon">info</span>
              </div>
              <div className="col-10 text-bold">{desc}</div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
export default CardFlush;
