import { useState } from "react";
import { useClickaway } from "../../hooks/ClickawayHooks";

const Index = ({ placeholder, trigger, show, value, toggleSuggestion, suggestion }) => {

  const [val, setVal] = useState(value);

  function handleInputChange(event) {
    if (trigger) {
      trigger(event.target.value)
    }
    setVal(event.target.value);
  }


  return (
    <div className="suggestion-search">
      <div className="search-container search-button-default">
        <input
          className={`input-search default`}
          placeholder={placeholder}
          onChange={handleInputChange}
          value={value}
        />
        <div
          className="search-ico-left search-toggle mini-hide"
          style={{
            cursor: "pointer",
            position: "absolute",
            left: "0px",
            padding: "14px 0px !important",
          }}
        >
          <span className="material-icons-round icon-only mini-hide">search</span>
        </div>
      </div>
      {
        suggestion?.length > 0 ? (
          <ul className="suggestion-wrapper">
            {
              suggestion?.map((item, index) => {
                return (
                  <li className="suggestion-item" key={index} onClick={() => toggleSuggestion(item)}>
                    {item?.label}
                  </li>
                )
              })
            }
          </ul>
        ) : (
          <>
            {
              value && show && (
                <ul className="suggestion-wrapper">
                  <li className="suggestion-not-found">
                    Data tidak ditemukan
                  </li>
                </ul>
              )
            }
          </>
        )
      }
    </div>
  );
};

export default Index;
