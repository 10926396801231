import {
  useState,
  useRef,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import HeaderMenu from "../../commons/menu/HeaderMenu";
import FooterMenu from "../../commons/menu/FooterMenu";
import SearchButton from "../../buttons/SearchButton";
import SearchButtonDefault from "../../buttons/SearchButtonDefault";
import Select from "react-select";

import Button from "../../buttons/Button";

import {
  Container,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,

} from "reactstrap";
import Map from "../../map";

const DataMapBaseLayout = forwardRef(
  ({ 
    title, 
    mapHeaderContent, 
    mapContent, 
    initialCenter,
    initialZoom, 
    elipsis, 
    onMapReady }, ref) => {

    const mapRef = useRef();
    const [selectMapShow, setSelectMapShow] = useState(false);
    const defaultCenter = [-6.8116, 107.6174];
    const defaultZoom = 10;
    const selectedMapType = "Default";

    useImperativeHandle(ref, () => ({
      getMap() {
        return mapRef.current;
      },
    }));


    useEffect(() => {
      if(initialCenter && initialZoom)
        mapRef.current?.flyTo(initialCenter ?? defaultCenter, initialZoom ?? defaultZoom)
    }, []);

    const handleZoom = (mode) => {
      switch (mode) {
        case "in":
          mapRef.current.zoomIn(1);
          break;
        case "out":
          mapRef.current.zoomOut(1);
          break;
      }
    };

    const toggleSelectMap = () => setSelectMapShow(!selectMapShow);

    const MenuZoom = ({ }) => {
      return (
        <nav
          className="active-pointer-evt"
          aria-label="Page navigation example"
        >
          <ul className="line-buttons">
            <li className="button-item">
              <a
                className="button-link lm"
                onClick={() => handleZoom("in")}
              >
                +
              </a>
            </li>
            <li className="button-item">
              <a
                className="button-link lm"
                onClick={() => handleZoom("out")}
              >
                -
              </a>
            </li>
          </ul>
        </nav>
      )
    }

    return (
      <div className={`main-content-wrapper mini-map-wrapper`}>
        <HeaderMenu
          title={title}
          optClass="inactive-pointer-evt pr-5"
          titleClass="header-mini-title"
          elipsis={elipsis}
        >
            {mapHeaderContent}
            <div className="mini-hide">
            <MenuZoom />
            </div>
        </HeaderMenu>
        <div className="map-area">
            <Map
            ref={mapRef}
            defaultCenter={initialCenter}
            defaultZoom={initialZoom}
            type={selectedMapType}
            onCreated={() => onMapReady?.()}
            >
            {mapContent}
            </Map>
        </div>
      </div>
    );
  }
);

export default DataMapBaseLayout;
