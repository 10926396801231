import { useState, useRef, useEffect } from "react";
import HeaderMenu from "../../components/commons/menu/HeaderMenu";
import Button from "../../components/buttons/Button";
import SearchButton from "../../components/buttons/SearchButton";
import ModalFilter from "./ModalFilter";
import ModalEdit from "./ModalEdit";
import { connect, useDispatch } from "react-redux";
import TableStatus from "../../components/table/status";
import DetailButton from "../../components/table/detailButton";
import MenuItem from "@material-ui/core/MenuItem";
import moment from "moment";
import Table from "../../components/table";
import WarningSystem from "../../store/actions/warning_system";
import Toast from "../../components/commons/toast_v2";
import { getUser } from "../../utils/User";

const Index = ({ location, pending }) => {
  const dispatch = useDispatch();
  const [showModalFilter, setShowModalFilter] = useState(false);
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState({
    start_date: null,
    end_date: null,
    status: "",
  });
  const [dataWarning, setDataWarning] = useState([]);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [itemData, setItemData] = useState(null);
  const [totalData, setTotalData] = useState(null);
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const currentUser = getUser().user;
  const isSwitch = currentUser?.pdam?.id ? true : false;

  const handleSearch = (value) => {
    setPage(1)
    setSearch(value);
  };

  const handleModalEdit = (data) => {
    setItemData(data);
    setShowModalEdit(true);
  };

  const columns = [
    {
      Header: "NO",
      accessor: "no",
    },
    {
      Header: "LOKASI",
      accessor: "location_name",
      Cell: ({ row }) => {
        const { original } = row;
        const { type, location_name } = original;

        return location_name ? type.toUpperCase() + " " + location_name : "-";
      },
    },
    {
      Header: "BATAS ATAS",
      accessor: "upper_limit",
    },
    {
      Header: "BATAS BAWAH",
      accessor: "lower_limit",
    },
    {
      Header: "DEBIT",
      accessor: "debit",
    },
    {
      Header: "TEKANAN",
      accessor: "pressure",
    },
    {
      Header: "WAKTU KEJADIAN",
      accessor: "datetime_logger",
      Cell: ({ row }) => {
        const { original } = row;
        const { datetime_logger } = original;

        return datetime_logger
          ? moment(datetime_logger).format("DD/MM/YYYY, HH:mm")
          : "-";
      },
    },
    {
      Header: "WAKTU SELESAI",
      accessor: "finished_at",
      Cell: ({ row }) => {
        const { original } = row;
        const { finished_at } = original;

        return finished_at
          ? moment(finished_at).format("DD/MM/YYYY, HH:mm")
          : "-";
      },
    },
    {
      Header: "STATUS",
      accessor: "status",
      Cell: ({ row }) => {
        const { original } = row;
        const { status } = original;

        return (
          <TableStatus
            status={status === 0 ? "Baru" : status === 1 ? "Selesai" : "-"}
            color={status === 0 ? "warning" : status === 1 ? "primary" : "-"}
          />
        );
      },
    },
    {
      Header: "",
      accessor: "aksi",
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        const { status } = original;
        return status === 0 ? (
          <DetailButton>
            <MenuItem onClick={() => handleModalEdit(original)}>
              <span style={{ fontSize: 14 }}>Ubah Status</span>
            </MenuItem>
          </DetailButton>
        ) : (
          <div></div>
        );
      },
    },
  ];

  const getData = () => {
    new Promise((resolve) => {
      const param = {
        length: 10,
        page: page,
        pdam_id: currentUser?.pdam?.id || "",
        start_date: filter.start_date
          ? moment(filter.start_date).format("YYYY-MM-DD")
          : "",
        end_date: filter.end_date
          ? moment(filter.end_date).format("YYYY-MM-DD")
          : "",
        search_text: search,
        status: filter.status,
      };
      dispatch(WarningSystem.get(param, resolve));
    }).then((res) => {
      setDataWarning(res?.data);
      setTotalData(res?.totalCount);
      setMaxPage(Math.ceil(res?.totalCount / 10));
    });
  };

  useEffect(() => {
    if (isSwitch) {
      getData();
    }
  }, []);

  useEffect(() => {
    if (isSwitch) {
      getData();
    }
  }, [filter, search, page, currentUser?.pdam?.id]);

  return (
    <div className="menu-container">
      {/* {!currentUser?.pdam?.id && <Toast label="Switch PDAM dulu" />} */}
      <ModalFilter
        show={showModalFilter}
        toggle={() => setShowModalFilter(false)}
        filter={filter}
        toggleFilter={setFilter}
      />
      <ModalEdit
        show={showModalEdit}
        data={itemData}
        handleRefresh={getData}
        toggle={() => setShowModalEdit(false)}
      />
      <div>
        <HeaderMenu title="Warning System">
          <SearchButton
            placeholder="Masukkan Nama Lokasi"
            toggle={handleSearch}
            optClass={"mr-3"}
          />
          <Button
            title="Filter"
            size="lg"
            rounded={true}
            variant="button-light"
            onClick={() => setShowModalFilter(true)}
          />
        </HeaderMenu>
        <div className="content-container-side">
          <div className="overflow">
            <Table
              isLoading={pending}
              columns={columns}
              data={dataWarning}
              pagination={{ page, totalData, maxPage, toggle: setPage }}
            />
          </div>
        </div>
      </div>
      {/* </>
      )} */}
    </div>
  );
};

const mapStateToProps = ({ warningSystem: { pending } }) => {
  return { pending };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
