import React, { Component } from "react";
import { ErrorMessage } from "formik";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";

export default class Select2Multi extends Component {
  handleChange = (items) => {
    // this is going to call setFieldValue and manually update values.topcis
    this.props.onChange(
      this.props.name,
      items?.map((item) => {
        return item.value;
      }) ?? ""
    );
  };

  handleBlur = () => {
    // this is going to call setFieldTouched and manually update touched.topcis
    this.props.onBlur(this.props.name, true);
  };

  render() {
    const isValid = !(
      this.props.errors[this.props.name] && this.props.touched[this.props.name]
    );
    const isSame = this.props.same;

    const customStyles = {
      dropdownButton: (base, state) => ({
        ...base,
        width: "100%",
      }),
      control: (base, state) => ({
        ...base,
        // state.isFocused can display different borderColor if you need it
        borderColor: state.isFocused
          ? "#ddd"
          : !isValid
            ? "red"
            : isSame === false
              ? "#00ABBD"
              : "#ddd",
        // overwrittes hover style
        "&:hover": {
          borderColor: state.isFocused
            ? "#ddd"
            : !isValid
              ? "red"
              : isSame === false
                ? "#00ABBD"
                : "#ddd",
        },
      }),
    };

    return (
      <div className="form-group">
        {this.props?.title && (
          <label className="form-label">{this.props?.title}</label>
        )}
        <ReactMultiSelectCheckboxes
          isMulti
          isDisabled={this.props.disabled}
          name={this.props.name}
          styles={customStyles}
          value={this.props.options?.filter((obj) => {
            return this.props.value?.includes(obj.value);
          })}
          onBlur={this.handleBlur}
          onChange={this.handleChange}
          options={this.props.options}
          isClearable="true"
          classNamePrefix="select"
          className={"basic-multi-select " + this.props.className}
        ></ReactMultiSelectCheckboxes>
        <ErrorMessage
          name={this.props.name}
          component="div"
          className="invalid-feedback"
          style={{ display: isValid ? "none" : "block" }}
        />
      </div>
    );
  }
}
