import React, { useState, useRef, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  FormGroup,
  Label,
} from "reactstrap";
import { Formik, Form } from "formik";
import { Divider } from "@material-ui/core";
import { getUser } from "../../utils/User";
import * as Yup from "yup";
import moment from "moment";
import { reproject, crss } from "reproject";
import { divIcon } from "leaflet";
import { Pane, Popup, Marker, GeoJSON } from "react-leaflet";

import Select2 from "../../components/forms/Select2";
import ButtonModal from "../../components/modal/ButtonModal";
import ConfirmationModal from "../../components/modal/ConfirmModal";
import Input from "../../components/forms/Input";
import Textarea from "../../components/forms/Textarea";
import Map from "../../components/map";
import DynamicButton from "../../components/forms/DynamicButton";

import { DraggableMarker } from "../../components/map/DraggableMarker";
import LocateControl from "../../components/map/LocateControl";
import { toastError, toastSuccess } from "../../components/commons/toast";

import marker from "../../assets/images/marker/repair.svg";

import PDAMService from "../../store/actions/master_data/pdam";
import DMAService from "../../store/actions/master_data/dma";
import ZoneService from "../../store/actions/master_data/zone";
import InputService from "../../store/actions/master_data/input";
import RepairService from "../../store/actions/repair_work";
import { convertPipeline } from "../../utils/ConvertPipeline";

function ModalAdd({
  show,
  toggle,
  data,
  switchPDAM,
  confirm,
  masterDataDMA,
  masterDataZone,
  masterDataInput,
  repairWork,
  refresh,
}) {
  const formikRef = useRef();
  const fileRef = useRef();
  const mapRef = useRef();
  const currentUser = getUser();
  const dispatch = useDispatch();

  const [pdam, setPDAM] = useState([]);
  const [dataList, setDataList] = useState([]);
  const [selectedPDAM, setSelectedPDAM] = useState(
    data ? data?.pdam?.id : switchPDAM?.id ?? currentUser?.user?.pdam?.id ?? ""
  );
  const [selectedLocation, setSelectedLocation] = useState(
    data?.input_id
      ? "Input"
      : data?.dma_id
      ? "DMA"
      : data?.zone_id
      ? "Zona"
      : null
  );
  const [dataGuideMap, setDataGuideMap] = useState(null);
  const [showModalConfirm, setShowModalConfirm] = useState(false);
  const today_date = data ? "" : moment().format("YYYY-MM-DD");
  const today_time = data ? "" : moment().format("HH:mm");
  const timezone = moment().format("Z");
  const today = data ? "" : today_date + "T" + today_time + timezone;
  const [initialCenter, setInitialCenter] = useState(
    data?.longitude && data?.latitude
      ? [data?.latitude, data?.longitude]
      : [-6.902253152913098, 107.6187018]
  );
  const [fileProp, setFileProp] = useState({
    title: "Berkas Kerusakan",
    subtitle: "File",
    message_error: false,
  });

  const modalTitle = data ? "Edit Laporan" : "Tambah Laporan";

  useEffect(() => {
    if (mapRef) {
      mapRef?.current?.getMap()?.flyTo(initialCenter, 18);
    }

    if (initialCenter) {
      formikRef?.current?.setFieldValue(
        "latitude",
        initialCenter?.lat ?? initialCenter[0]
      );
      formikRef?.current?.setFieldValue(
        "longitude",
        initialCenter?.lng ?? initialCenter[1]
      );
    }
  }, [initialCenter]);

  useEffect(() => {
    const param = {
      draw: 1,
      length: 999,
      page: 1,
      id: switchPDAM?.id,
    };

    new Promise((resolve) => {
      dispatch(PDAMService.get(param, resolve));
    }).then((res) => {
      setPDAM(res?.data);
    });
  }, [switchPDAM]);

  useEffect(() => {
    const param = {
      draw: 1,
      length: 999,
      page: 1,
    };
    dispatch(DMAService.get(param));

    dispatch(ZoneService.get(param));

    dispatch(InputService.get(param));
  }, []);

  useEffect(() => {
    if (repairWork?.data_prepare?.data?.data) {
      let pipeline = repairWork?.data_prepare?.data?.data.pdam_pipeline_geojson;
      if (pipeline) {
        repairWork.data_prepare.data.data.pdam_pipeline_geojson.features =
          convertPipeline(pipeline.features);
      }

      setDataGuideMap(repairWork?.data_prepare?.data?.data);
    }
  }, [repairWork]);

  useEffect(() => {
    if (data?.input_id || data?.dma_id || data?.zone_id) {
      setSelectedLocation(
        data?.input_id
          ? "Input"
          : data?.dma_id
          ? "DMA"
          : data?.zone_id
          ? "Zona"
          : null
      );
    }

    if (data?.pdam_id) {
      setSelectedPDAM(data?.pdam_id ?? null);
    }

    if (data?.latitude && data?.longitude) {
      setInitialCenter([data?.latitude, data?.longitude]);
    }
  }, [data]);

  useEffect(() => {
    if (
      (masterDataDMA || masterDataInput || masterDataZone) &&
      selectedLocation
    ) {
      let data_list = [];
      let location = selectedLocation;
      let data_source =
        location === "DMA"
          ? masterDataDMA
          : location === "Input"
          ? masterDataInput
          : masterDataZone;
      let pdam_id = selectedPDAM ?? formikRef?.current?.values?.pdam_id;
      if (pdam_id) {
        data_list = data_source?.data_list?.data?.data;
        if (pdam_id?.length > 0) {
          data_list = data_list?.filter((item) => item.pdam_id === pdam_id);
        }
      } else {
        if (data?.pdam?.id) {
          data_list = data_source?.data_list?.data?.data;
          data_list = data_list?.filter(
            (item) => item.pdam_id === data?.pdam?.id
          );
        }
      }
      setDataList(data_list);
    }
  }, [
    selectedPDAM,
    masterDataDMA,
    masterDataZone,
    masterDataInput,
    data,
    selectedLocation,
  ]);

  useEffect(() => {
    setSelectedPDAM(switchPDAM?.id ?? currentUser?.user?.pdam?.id ?? "");
  }, [switchPDAM]);

  useEffect(() => {
    if (selectedPDAM) {
      const param = {
        pdam_id: selectedPDAM,
      };
      dispatch(RepairService.prepare_form(param));
    }
  }, [selectedPDAM]);

  const validation = Yup.object().shape({
    pdam_id: Yup.string().required("PDAM wajib diisi").nullable(),
    longitude: Yup.string().required("Longitude wajib diisi").nullable(),
    latitude: Yup.string().required("Latitude wajib diisi").nullable(),
    location: Yup.string().required("Lokasi kebocoran wajib diisi").nullable(),
    location_name: Yup.string().required("Lokasi wajib diisi").nullable(),
    incident: Yup.string().required("Detail kejadian wajib diisi").nullable(),
    person_in_charge: Yup.string()
      .required("Penanggung jawab wajib diisi")
      .nullable(),
    pipe_diameter: Yup.string()
      .required("Diameter pipa wajib diisi")
      .nullable(),
    pipe_material: Yup.string()
      .required("Material pipa wajib diisi")
      .nullable(),
  });

  const handleConfirm = () => {
    setFileProp(
      data
        ? fileProp
        : {
            title: "Berkas Kerusakan",
            subtitle: "File",
            message_error: false,
          }
    );
    setInitialCenter(data ? [data?.latitude, data?.longitude] : initialCenter);
    setSelectedPDAM(data ? selectedPDAM : "");
    setSelectedLocation(data ? selectedLocation : null);
    setDataList(data ? dataList : []);
    setShowModalConfirm(false);
    toggle();
  };

  const callback = () => {
    setFileProp({
      title: "Berkas Kerusakan",
      subtitle: "File",
      message_error: false,
    });
    toastSuccess(
      data ? "Berhasil ubah data laporan" : "Berhasil tambah data laporan"
    );
    toggle();
    refresh();
  };

  const openFileDialog = () => {
    fileRef.current.click();
  };

  const handleChange = (selectorFiles, formik) => {
    if (Number(selectorFiles.files[0].size) < 15728640) {
      formik.setFieldValue("breakage_file", selectorFiles.files[0]);
      setFileProp({
        title: selectorFiles.files[0].name,
        subtitle:
          (Number(selectorFiles.files[0].size) * 0.001).toFixed(2) + " KB",
        message_error: false,
      });
    } else {
      toastError("Maksimal file yang dapat diimport 15 MB");
    }
  };

  useEffect(() => {
    if (
      formikRef?.current?.values?.report_date &&
      formikRef?.current?.values?.report_time
    ) {
      let reported_at =
        formikRef?.current?.values?.report_date +
        "T" +
        formikRef?.current?.values?.report_time +
        ":00.000" +
        timezone;
      formikRef.current?.setFieldValue("reported_at", String(reported_at));
    }
  }, [
    formikRef?.current?.values?.report_date,
    formikRef?.current?.values?.report_time,
  ]);

  useEffect(() => {
    if (data?.breakage_file_path) {
      setFileProp({
        title: (data?.breakage_file_path?.split("/"))[1],
        subtitle: "File",
        message_error: false,
      });
    }
  }, [data]);

  const handleFile = (formik) => {
    if (!formik.values.breakage_file && !formik?.values.breakage_file_path) {
      setFileProp({
        title: "Berkas Kerusakan",
        subtitle: "File",
        message_error: "File wajib diisi",
      });
    }
  };

  function onEachFeature(feature, layer) {
    let dataFeature = layer?.defaultOptions?.pane
      ? layer?.defaultOptions?.pane.split("-")
      : null;

    if (feature?.properties && Object.keys(feature?.properties).length > 0) {
      const popupOptions = {
        className: "popup-poly-detail",
      };
      let popUpContentKey = ``;
      let popUpContentVal = ``;
      popUpContentKey +=
        `<div className='row pt-1 pb-1'><div className='col'>` +
        (feature?.properties.PIPE_ID ? "PIPA " : " DMA") +
        ` </div></div>`;
      popUpContentVal +=
        `<div className='row pt-1 pb-1'><div className='col'>` +
        (feature?.properties.NAME ??
          feature?.properties.PIPE_ID ??
          "Tidak terdeteksi") +
        `</div></div>`;
      popUpContentKey += `<div className='row pt-1 pb-1'><div className='col'>Diameter </div></div>`;
      popUpContentVal +=
        `<div className='row pt-1 pb-1'><div className='col'>` +
        dataFeature[2] +
        `</div></div>`;
      let popUpContentBase =
        `<div className='row'>
            <div className='col-sm-auto'>` +
        popUpContentKey +
        `</div>
            <div className='col'>` +
        popUpContentVal +
        `</div>
          </div>`;
      layer.bindPopup(popUpContentBase, popupOptions);
    }
  }

  return (
    <>
      <Modal
        isOpen={show}
        modalTransition={{ timeout: 200 }}
        backdropTransition={{ timeout: 1300 }}
      >
        <ModalHeader className="text-extra-bold pt-5">
          {" "}
          {modalTitle}{" "}
          <span
            className="close clickable"
            onClick={() => setShowModalConfirm(true)}
          >
            &times;
          </span>
        </ModalHeader>
        <Formik
          innerRef={formikRef}
          enableReinitialize={data ? true : false}
          initialValues={{
            pdam_id: data?.pdam?.id ?? currentUser?.user?.pdam?.id ?? "",
            location: data?.input_id
              ? "Input"
              : data?.dma_id
              ? "DMA"
              : data?.zone_id
              ? "Zona"
              : "",
            location_name:
              data?.input?.id ?? data?.zone?.id ?? data?.dma?.id ?? "",
            incident: data?.incident ?? "",
            latitude: data?.latitude ?? "",
            longitude: data?.longitude ?? "",
            person_in_charge: data?.person_in_charge ?? "",
            pipe_diameter: data?.pipe_diameter ?? "",
            pipe_material: data?.pipe_material ?? "",
            reported_at: data?.reported_at ?? today,
            report_date: data?.reported_at
              ? moment(data?.reported_at).format("YYYY-MM-DD")
              : today_date,
            report_time: data?.reported_at
              ? moment(data?.reported_at).format("HH:mm")
              : today_time,
            breakage_file_path: data?.breakage_file_path,
          }}
          validationSchema={validation}
          onSubmit={(values, { setStatus }) => {
            setStatus();
            values.reported_at =
              values?.report_date +
              "T" +
              values?.report_time +
              ":00.000" +
              timezone;
            if (values?.location === "Input") {
              values.input_id = values.location_name;
            } else if (values?.location === "Zona") {
              values.zone_id = values.location_name;
            } else {
              values.dma_id = values.location_name;
            }

            if (values?.breakage_file || values?.breakage_file_path) {
              if (data) {
                values.id = data?.id;
              }

              let formData = new FormData();
              Object.keys(values).forEach(function eachKey(key) {
                formData.append(key, values[key]);
              });

              confirm(formData, callback);
            } else {
              setFileProp({
                title: "Berkas Kerusakan",
                subtitle: "File",
                message_error: "File wajib diisi",
              });
            }
          }}
        >
          {(props) => (
            <Form style={{ marginTop: 30 }}>
              <ModalBody style={{ margin: "0px 20px" }}>
                <Select2
                  title="PDAM"
                  name="pdam_id"
                  options={pdam?.map((item) => ({
                    value: item?.id,
                    label: item?.name,
                  }))}
                  errors={props.errors}
                  touched={props.touched}
                  value={props.values?.pdam_id}
                  placeholder="Pilih data PDAM"
                  onChange={(name, value) => {
                    props.setFieldValue("pdam_id", value);
                    setSelectedPDAM(value);
                    setDataGuideMap(null);
                  }}
                  onBlur={props.setFieldTouched}
                  disabled={currentUser?.user?.pdam}
                />
                {!props.values?.pdam_id && (
                  <div
                    style={{
                      margin: "10px 0px",
                      fontSize: 12,
                      opacity: 0.5,
                      width: "100%",
                      textAlign: "center",
                    }}
                  >
                    Silahkan pilih PDAM untuk menampilkan informasi mengenai
                    Input, Zona dan DMA
                  </div>
                )}
                {props.values?.pdam_id && !dataGuideMap && (
                  <div
                    style={{
                      margin: "10px 0px",
                      fontSize: 12,
                      opacity: 0.5,
                      width: "100%",
                      textAlign: "center",
                    }}
                  >
                    Mohon tunggu hingga semua informasi mengenai Input, Zona dan
                    DMA selesai ditampilkan
                  </div>
                )}
                <div
                  style={{
                    width: "100%",
                    height: "350px",
                    position: "relative",
                    marginBottom: 20,
                  }}
                >
                  <div
                    style={{
                      position: "absolute",
                      zIndex: 0,
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "350px",
                    }}
                  >
                    <Map
                      ref={mapRef}
                      defaultCenter={initialCenter}
                      defaultZoom={18}
                      type={"Default"}
                    >
                      {dataGuideMap?.dma?.map((item, index) => {
                        if (item?.dma_geojson) {
                          return (
                            <Pane
                              name={`paneDma-${index}-${item?.diameter}`}
                              style={{ zIndex: 200 }}
                            >
                              <GeoJSON
                                data={reproject(
                                  item?.dma_geojson,
                                  "ESRI:54004",
                                  "EPSG:4326",
                                  crss
                                )}
                                color="#ff0000"
                                fillColor="#ff0000"
                                weight={2}
                                onEachFeature={onEachFeature}
                              />
                            </Pane>
                          );
                        }
                      })}
                      {dataGuideMap?.pdam_pipeline_geojson && (
                        <Pane
                          name={`panePipeline-${1}-${1}`}
                          style={{ zIndex: 200 }}
                        >
                          <GeoJSON
                            data={dataGuideMap?.pdam_pipeline_geojson}
                            color="#FFFF00"
                            fillColor="#FFFF00"
                            weight={2}
                            onEachFeature={onEachFeature}
                          />
                        </Pane>
                      )}
                      {dataGuideMap?.input?.map((item, index) => {
                        return (
                          <Marker
                            position={[item.latitude, item.longitude]}
                            icon={divIcon({
                              html: "<i className='material-icons-round' style='color: #D10D0D'>all_inbox</i>",
                            })}
                          >
                            <Popup>
                              <div style={{ minWidth: 50 }}>
                                <div className="row">{`Input ${item?.name}`}</div>
                                <div className="row">{`Diameter ${item?.diameter}`}</div>
                              </div>
                            </Popup>
                          </Marker>
                        );
                      })}
                      {dataGuideMap?.zone?.map((item, index) => {
                        return (
                          <Marker
                            position={[item.latitude, item.longitude]}
                            icon={divIcon({
                              html: "<i className='material-icons-round' style='color: #FFFF00'>circle</i>",
                            })}
                          >
                            <Popup>
                              <div style={{ minWidth: 50 }}>
                                <div className="row">{`Zona ${item?.name}`}</div>
                                <div className="row">{`Diameter ${item?.diameter}`}</div>
                              </div>
                            </Popup>
                          </Marker>
                        );
                      })}
                      <DraggableMarker
                        center={initialCenter}
                        source={marker}
                        labelName="Lokasi Laporan"
                        toggle={(value) => setInitialCenter(value)}
                      />
                      <LocateControl
                        startDirectly
                        getLocation={
                          data ? null : (value) => setInitialCenter(value)
                        }
                        notZoom={data}
                      />
                    </Map>
                  </div>
                </div>

                <Row>
                  <Col md={6}>
                    <Input
                      title="Longitude"
                      name="longitude"
                      type="text"
                      errors={props?.errors}
                      touched={props?.touched}
                      placeholder="Masukkan longitude"
                      {...props?.getFieldProps("longitude")}
                      value={props.values?.longitude}
                      disabled
                    />
                  </Col>
                  <Col md={6}>
                    <Input
                      title="Latitude"
                      name="latitude"
                      type="text"
                      errors={props?.errors}
                      touched={props?.touched}
                      placeholder="Masukkan latitude"
                      {...props?.getFieldProps("latitude")}
                      value={props.values?.latitude}
                      disabled
                    />
                  </Col>
                </Row>

                <Select2
                  title="Lokasi Kebocoran"
                  name="location"
                  options={[
                    { value: "Input", label: "Input" },
                    { value: "Zona", label: "Zona" },
                    { value: "DMA", label: "DMA" },
                  ]}
                  errors={props.errors}
                  touched={props.touched}
                  value={props.values?.location}
                  onChange={(name, value) => {
                    props.setFieldValue("location", value);
                    setSelectedLocation(value);
                  }}
                  onBlur={props.setFieldTouched}
                />
                {(data?.location_name || selectedLocation) && (
                  <Select2
                    title={`Lokasi ${props?.values?.location}`}
                    name="location_name"
                    options={dataList?.map((item) => ({
                      value: item?.id,
                      label: item?.name,
                    }))}
                    errors={props.errors}
                    touched={props.touched}
                    value={props.values?.location_name}
                    placeholder={`Pilih data ${props?.values?.location}`}
                    onChange={(name, value) => {
                      props.setFieldValue("location_name", value);
                    }}
                    onBlur={props.setFieldTouched}
                  />
                )}
                <Divider orientation="horizontal" className="my-3" />
                <FormGroup>
                  <Label className="form-label">Waktu Lapor</Label>
                  <Row>
                    <Col md={6}>
                      <Input
                        name="report_date"
                        type="date"
                        errors={props?.errors}
                        touched={props?.touched}
                        max={
                          data?.reported_at
                            ? moment(data?.reported_at).format("YYYY-MM-DD")
                            : today_date
                        }
                        {...props?.getFieldProps("report_date")}
                        placeholder="Masukkan tanggal laporan"
                        value={props.values?.report_date}
                        disabled={data && data?.status !== 0}
                      />
                    </Col>
                    <Col md={6}>
                      <Input
                        name="report_time"
                        type="time"
                        errors={props?.errors}
                        touched={props?.touched}
                        {...props?.getFieldProps("report_time")}
                        placeholder="Masukkan waktu laporan"
                        value={props.values?.report_time}
                        max={
                          data?.reported_at
                            ? moment(data?.reported_at).format("YYYY-MM-DD") ===
                              props.values?.report_date
                              ? moment(data?.reported_at).format("HH:mm")
                              : ""
                            : today_date === props.values?.report_date
                            ? today_time
                            : ""
                        }
                        disabled={data && data?.status !== 0}
                      />
                    </Col>
                  </Row>
                </FormGroup>
                <FormGroup>
                  <Textarea
                    title="Detail Kejadian"
                    rows="4"
                    name="incident"
                    placeholder="Tuliskan detail kejadian"
                    errors={props?.errors}
                    touched={props?.touched}
                    value={props?.values?.incident}
                    {...props?.getFieldProps("incident")}
                  />
                </FormGroup>
                <Row>
                  <Col md={6}>
                    <FormGroup>
                      <Input
                        title="Diameter Pipa"
                        name="pipe_diameter"
                        type="text"
                        errors={props?.errors}
                        touched={props?.touched}
                        {...props?.getFieldProps("pipe_diameter")}
                        placeholder="Masukkan diameter pipa"
                        value={props.values?.pipe_diameter}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Input
                        title="Material Pipa"
                        name="pipe_material"
                        type="text"
                        errors={props?.errors}
                        touched={props?.touched}
                        {...props?.getFieldProps("pipe_material")}
                        placeholder="Masukkan material pipa"
                        value={props.values?.pipe_material}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <FormGroup>
                  <Input
                    title="Penanggung Jawab"
                    name="person_in_charge"
                    type="text"
                    errors={props?.errors}
                    touched={props?.touched}
                    {...props?.getFieldProps("person_in_charge")}
                    placeholder="Masukkan penanggung jawab"
                    value={props.values?.person_in_charge}
                  />
                </FormGroup>
                <DynamicButton
                  titleLeft={fileProp?.title}
                  subtitleLeft={fileProp?.subtitle}
                  iconLeft="insert_drive_file"
                  toggle={openFileDialog}
                  error={fileProp?.message_error}
                />

                <input
                  type="file"
                  ref={fileRef}
                  style={{ display: "none" }}
                  onChange={(e) => handleChange(e.target, props)}
                  accept="image/png, image/jpeg"
                />
              </ModalBody>
              <ModalFooter>
                <ButtonModal
                  toggle={() => setShowModalConfirm(true)}
                  confirmTitle="Simpan"
                  confirm={() => handleFile(props)}
                  isLoading={repairWork.pending_post || repairWork.pending_put}
                  disabledConfrim={
                    repairWork.pending_post || repairWork.pending_put
                  }
                />
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </Modal>

      <ConfirmationModal
        show={showModalConfirm}
        toggle={() => setShowModalConfirm(false)}
        confirm={() => handleConfirm()}
        isDelete="delete"
        cancelTitle="Tidak"
        confirmTitle="Ya"
        message={`Apakah Anda yakin ingin membatalkan ${
          data ? "perubahan" : "penambahan"
        } <b>laporan</b> ? `}
        icon="info"
      />
    </>
  );
}

const mapStateToProps = ({
  masterDataPDAM,
  masterDataDMA,
  masterDataZone,
  masterDataInput,
  repairWork,
}) => {
  return {
    masterDataPDAM,
    masterDataDMA,
    masterDataZone,
    masterDataInput,
    repairWork,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalAdd);
