import { history } from "../../../../utils/History";
import { actionPending, actionSuccess, actionError } from "../../actionTypes";

import { toastSuccess, toastError } from "../../../../components/commons/toast";

import API from "../../API";
import { handleResponse } from "../../HandleRespone";

// ACTION TYPES TO DISPATCH: METHOD_URL_TYPES
export const GET_PIPELINE_PENDING = "GET_PIPELINE_PENDING";
export const GET_PIPELINE_SUCCESS = "GET_PIPELINE_SUCCESS";
export const GET_PIPELINE_ERROR = "GET_PIPELINE_ERROR";
export const POST_PIPELINE_PENDING = "POST_PIPELINE_PENDING";
export const POST_PIPELINE_SUCCESS = "POST_PIPELINE_SUCCESS";
export const POST_PIPELINE_ERROR = "POST_PIPELINE_ERROR";
export const DELETE_PIPELINE_PENDING = "DELETE_PIPELINE_PENDING";
export const DELETE_PIPELINE_SUCCESS = "DELETE_PIPELINE_SUCCESS";
export const DELETE_PIPELINE_ERROR = "DELETE_PIPELINE_ERROR";

// URL: URL_{URL}
const PIPELINE_URL = `v1/master_data/pipeline`;

const post = (param, callback) => (dispatch) => {
  dispatch(actionPending(POST_PIPELINE_PENDING));
  API.post(PIPELINE_URL + "/import", param)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(POST_PIPELINE_SUCCESS, res));
      toastSuccess("Berhasil import data jaringan");
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(POST_PIPELINE_ERROR));
      toastError(err?.response?.data?.message);
    });
};

const pipeline = {
  post,
};
export default pipeline;
