import {
    GET_REPAIR_WORK_PENDING,
    GET_REPAIR_WORK_SUCCESS,
    GET_REPAIR_WORK_ERROR,
    POST_REPAIR_WORK_PENDING,
    POST_REPAIR_WORK_SUCCESS,
    POST_REPAIR_WORK_ERROR,
    PUT_REPAIR_WORK_PENDING,
    PUT_REPAIR_WORK_SUCCESS,
    PUT_REPAIR_WORK_ERROR,
    DELETE_REPAIR_WORK_PENDING,
    DELETE_REPAIR_WORK_SUCCESS,
    DELETE_REPAIR_WORK_ERROR,
    PREPARE_FORM_REPAIR_WORK_PENDING,
    PREPARE_FORM_REPAIR_WORK_SUCCESS,
    PREPARE_FORM_REPAIR_WORK_ERROR,
    DOWNLOAD_REPAIR_WORK_PENDING,
    DOWNLOAD_REPAIR_WORK_SUCCESS,
    DOWNLOAD_REPAIR_WORK_ERROR,
} from "../../actions/repair_work";

const initialState = {
    pending: false,
    error: null,
    data_list: null,
    pending_post: false,
    data_post: null,
    pending_put: false,
    data_put: false,
    pending_delete: false,
    data_delete: null,
    pending_prepare: false,
    data_prepare: null,
    pending_download: false,
    data_download: null
};

const repair = (state = initialState, action) => {
    switch (action.type) {
        case GET_REPAIR_WORK_PENDING:
            return {
                ...state,
                pending: true,
            };
        case GET_REPAIR_WORK_SUCCESS:
            return {
                ...state,
                pending: false,
                data_list: action.data,
            };
        case GET_REPAIR_WORK_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error,
            };
        case POST_REPAIR_WORK_PENDING:
            return {
                ...state,
                pending_post: true,
            };
        case POST_REPAIR_WORK_SUCCESS:
            return {
                ...state,
                pending_post: false,
                data_post: action.data,
            };
        case POST_REPAIR_WORK_ERROR:
            return {
                ...state,
                pending_post: false,
                error: action.error,
            };
        case PUT_REPAIR_WORK_PENDING:
            return {
                ...state,
                pending_put: true,
            };
        case PUT_REPAIR_WORK_SUCCESS:
            return {
                ...state,
                pending_put: false,
                data_put: action.data,
            };
        case PUT_REPAIR_WORK_ERROR:
            return {
                ...state,
                pending_put: false,
                error: action.error,
            };
        case DELETE_REPAIR_WORK_PENDING:
            return {
                ...state,
                pending_delete: true,
            };
        case DELETE_REPAIR_WORK_SUCCESS:
            return {
                ...state,
                pending_delete: false,
                data_delete: action.data,
            };
        case DELETE_REPAIR_WORK_ERROR:
            return {
                ...state,
                pending_delete: false,
                error: action.error,
            };
        case PREPARE_FORM_REPAIR_WORK_PENDING:
            return {
                ...state,
                pending_prepare: true,
            };
        case PREPARE_FORM_REPAIR_WORK_SUCCESS:
            return {
                ...state,
                pending_prepare: false,
                data_prepare: action.data,
            };
        case PREPARE_FORM_REPAIR_WORK_ERROR:
            return {
                ...state,
                pending_prepare: false,
                error: action.error,
            };
        case DOWNLOAD_REPAIR_WORK_PENDING:
            return {
                ...state,
                pending_download: true,
            };
        case DOWNLOAD_REPAIR_WORK_SUCCESS:
            return {
                ...state,
                pending_download: false,
                data_download: action.data,
            };
        case DOWNLOAD_REPAIR_WORK_ERROR:
            return {
                ...state,
                pending_download: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export default repair;
