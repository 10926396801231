import { useState, useRef, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { history } from "../../utils/useHistory";
import MenuItem from "@material-ui/core/MenuItem";
import Table from "../../components/table";
import TableStatus from "../../components/table/status";
import DetailButton from "../../components/table/detailButton";
import Loader from "../../components/commons/Loader";

// Service
import RepairService from "../../store/actions/repair_work"

// Modal
import ModalAdd from "./ModalAddEdit";

const Index = ({ switchPDAM, pending, status, search, page, totalData, maxPage, dataRepair, setPage, setShowModalAdd, showModalAdd, handleRefresh }) => {

    const dispatch = useDispatch();

    const handleAdd = (value, callback) => {
        dispatch(RepairService.post(value, callback));
    }

    const columns =
        [
            // {
            //     Header: "#TIKET",
            //     accessor: "ticket_number",
            // },
            {
                Header: "LOKASI",
                accessor: "lokasi",
            },
            {
                Header: "KEJADIAN",
                accessor: "incident_short",
            },
            {
                Header: "WAKTU LAPOR",
                accessor: "report_time",
                Cell: ({ row }) => {
                    const { original } = row;
                    const { argo, reported_time, status } = original;
                    return (
                        <div>
                            {reported_time}<span style={{ opacity: 0.5 }}>{argo ? ` — ${argo}` : ''}</span>
                        </div>
                    );
                },
            },
            {
                Header: "WAKTU MULAI",
                accessor: "start_repairing_time"
            },
            {
                Header: "WAKTU SELESAI",
                accessor: "finish_repairing_time"
            },
            {
                Header: "PJ",
                accessor: "penanggungJawab"
            },
            {
                Header: "STATUS",
                accessor: "status",
                Cell: ({ row }) => {
                    const { original } = row;
                    const { status } = original;

                    return (
                        <TableStatus
                            status={status ?? "-"}
                            color={
                                status === "Baru"
                                    ? "danger"
                                    : status === "Sedang Perbaikan"
                                        ? "warning"
                                        : status === "Selesai"
                                            ? "primary"
                                                : "-"
                            }
                        />
                    );
                },
            },
            {
                Header: "",
                accessor: "aksi",
                Cell: ({ row }) => {
                    const original = row ? row?.original : [];
                    return (
                        <DetailButton>
                            <MenuItem onClick={() => history.push(`/repair-work-report/detail/${original?.workOrderId}`)}>
                                <span style={{ fontSize: 14 }}>Detail</span>
                            </MenuItem>
                        </DetailButton>
                    );
                },
            },
        ]

    return (
        <div>
            <div className="overflow">
                <Table
                    id="tbl-data-repair-work-report"
                    isLoading={pending}
                    columns={columns}
                    data={dataRepair}
                    pagination={{
                        page,
                        totalData,
                        maxPage,
                        toggle: setPage
                    }}
                />
            </div>

            {/* Modal */}
            {/* <ModalAdd
                show={showModalAdd}
                toggle={() => setShowModalAdd(false)}
                refresh={handleRefresh}
                switchPDAM={switchPDAM}
                confirm={handleAdd}
            /> */}
        </div>
    );
};

const mapStateToProps = ({ repairWork: { pending } }) => {
    return { pending };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};


export default connect(mapStateToProps, mapDispatchToProps)(Index);
