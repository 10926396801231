import React from "react"
import Table from "../../../components/table/index"
import DetailButton from "../../../components/table/detailButton/index"
import MenuItem from "@material-ui/core/MenuItem"
import masterDataINPUTService from "../../../store/actions/master_data/input"
import { useState, useEffect, forwardRef, useImperativeHandle } from "react"
import { connect, useDispatch } from "react-redux"
import ConfirmDelete from "../../../components/modal/ConfirmModal"
import { Role } from "../../../utils/Constants"
import { getUser } from "../../../utils/User"

const Index = forwardRef((props, ref) => {
  const [dataInput, setDataInput] = useState([])
  const dispatch = useDispatch()
  const [searchText, setSearchText] = useState("")
  const [totalData, setTotalData] = useState(null)
  const [page, setPage] = useState(1)
  const [maxPage, setMaxPage] = useState(1)
  const { filter, search, add } = props
  const currentUser = getUser().user
  const [pending, setPending] = useState(false)
  const [showModalDelete, setShowModalDelete] = useState(false)
  const [selectedData, setSelectedData] = useState([])

  const changeModalDelete = (data) => {
    setShowModalDelete(!showModalDelete)
    setSelectedData(data)
  }

  useImperativeHandle(ref, () => ({
    handleSearch(searchText) {
      setSearchText(searchText.length > 0 ? searchText : "")
      handleRefresh()
    },
    refresh() {
      handleRefresh()
    },
  }))

  const handleDelete = (deletedData) => {
    const callback = () => {
      if (page === 1) {
        handleRefresh()
      } else {
        setPage(1)
      }
      setShowModalDelete(!showModalDelete)
    }
    if (deletedData) {
      const id = deletedData?.id
      dispatch(masterDataINPUTService.deleted(id, callback, deletedData?.name))
    }
  }

  const columns =
    currentUser.role_code === Role.Kadiv || currentUser.role_code === Role.Superadmin
      ? [
          {
            Header: "INPUT",
            accessor: "name",
          },
          {
            Header: "PDAM",
            accessor: "pdam_name",
          },
          {
            Header: "ID SENSOR",
            accessor: "device_id",
          },
          {
            Header: "DIAMETER",
            accessor: "diameter",
            Cell: ({ row }) => {
              const { original } = row
              return <>{original.diameter + " mm"}</>
            },
          },
          {
            Header: "LONGITUDE, LATITUDE",
            accessor: "longitude",
            Cell: ({ row }) => {
              const { original } = row
              return <>{original.longitude + ", " + original.latitude}</>
            },
          },
          {
            Header: "KETERANGAN",
            accessor: "is_output",
            Cell: ({ row }) => {
              const { original } = row
              return <>{original.is_output ? "Output" : "Input"}</>
            },
          },
          {
            Header: "",
            accessor: "aksi",
            Cell: ({ row }) => {
              const { original } = row

              return (
                <>
                  <DetailButton>
                    {(currentUser.role_code === Role.Kadiv || currentUser.role_code === Role.Superadmin) && (
                      <MenuItem onClick={() => changeModalDelete(original)}>
                        <span style={{ fontSize: 14 }}>Hapus</span>
                      </MenuItem>
                    )}
                  </DetailButton>
                </>
              )
            },
          },
        ]
      : [
          {
            Header: "INPUT",
            accessor: "name",
          },
          {
            Header: "PDAM",
            accessor: "pdam_name",
          },
          {
            Header: "ID SENSOR",
            accessor: "device_id",
          },
          {
            Header: "DIAMETER",
            accessor: "diameter",
            Cell: ({ row }) => {
              const { original } = row
              return <>{original.diameter + " mm"}</>
            },
          },
          {
            Header: "LONGITUDE, LATITUDE",
            accessor: "longitude",
            Cell: ({ row }) => {
              const { original } = row
              return <>{original.longitude + ", " + original.latitude}</>
            },
          },
        ]

  const handleRefresh = () => {
    new Promise((resolve) => {
      const param = {
        draw: 1,
        id: null,
        length: 10,
        page: page ?? 1,
        search_text: search,
        pdam_id: filter.pdam_id,
      }
      setPending(true)
      dispatch(masterDataINPUTService.get(param, resolve))
    }).then((res) => {
      setDataInput(res?.data)
      setTotalData(res?.totalCount)
      setMaxPage(Math.ceil(res?.totalCount / 10))
      setPending(false)
    })
  }

  useEffect(() => {
    handleRefresh()
  }, [page])

  useEffect(() => {
    if (page === 1) {
      handleRefresh()
    } else {
      setPage(1)
    }
  }, [filter, search, add])

  return (
    <>
      <Table id="tbl-master-input" isLoading={pending} columns={columns} data={dataInput} pagination={{ page, totalData, maxPage, toggle: setPage }} />

      <ConfirmDelete show={showModalDelete} toggle={() => setShowModalDelete(false)} isDelete icon="info" confirm={() => handleDelete(selectedData)} disabledConfrim={props.masterDataInput.pending_delete}>
        <div>
          Apakah Anda yakin akan menghapus data
          <strong>{" Input " + selectedData.name}</strong> ?
        </div>
      </ConfirmDelete>
    </>
  )
})

const mapStateToProps = ({ masterDataInput }) => {
  return { masterDataInput }
}

const mapDispatchToProps = (dispatch) => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(Index)
