export const convertCoordinate = (data, type) => {
    let coordinate = [];
    if (data) {
        if (type === "Polygon") {
            data?.map((item) => {
                let array = []
                item?.map((coor) => {
                    let longLat = [coor[1], coor[0]]
                    array = [...array, longLat]
                })
                coordinate = array;
            })
        } else if (type === "LineString") {
            data?.map((item) => {
                let longLat = [item[1], item[0]]
                coordinate = [...coordinate, longLat]
            })
        } else if (type == "MultiLineString") {
            data?.map((item) => {
                let array = []
                if (item?.length > 0) {
                    item?.map((arr) => {
                        let longLat = [arr[1], arr[0]]
                        array = [...array, longLat]
                    })
                    coordinate = array;
                }
            })
        } else {
            data?.map((item) => {
                let array = []
                if (item?.length > 0) {
                    item?.map((arr) => {
                        let array_coor = [];
                        arr?.map((coor) => {
                            let longLat = [coor[1], coor[0]]
                            array_coor = [...array_coor, longLat]
                        })
                        array = [...array, array_coor]
                    })
                    coordinate = array;
                }
            })
        }
    }
    return coordinate
}

export const ConverCoordinateLine = (data) => {
    let array = []
    data?.map((coor) => {
        let longLat = [coor[1], coor[0]]
        array = [...array, longLat]
    })

    return array;
}