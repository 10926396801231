import {
    POST_FILE_PENDING,
    POST_FILE_SUCCESS,
    POST_FILE_ERROR,
    SHOW_FILE_PENDING,
    SHOW_FILE_SUCCESS,
    SHOW_FILE_ERROR,
} from "../../actions/file_management";

const initialState = {
    pending: false,
    error: null,
    pending_post: false,
    data_post: null,
    pending_show: false,
    data_show: null,
};

const file = (state = initialState, action) => {
    switch (action.type) {
        case POST_FILE_PENDING:
            return {
                ...state,
                pending_post: true,
            };
        case POST_FILE_SUCCESS:
            return {
                ...state,
                pending_post: false,
                data_post: action.data,
            };
        case POST_FILE_ERROR:
            return {
                ...state,
                pending_post: false,
                error: action.error,
            };
        case SHOW_FILE_PENDING:
            return {
                ...state,
                pending_show: true,
            };
        case SHOW_FILE_SUCCESS:
            return {
                ...state,
                pending_show: false,
                data_show: action.data,
            };
        case SHOW_FILE_ERROR:
            return {
                ...state,
                pending_show: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export default file;
