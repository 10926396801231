import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { connect, useDispatch } from "react-redux";
import { getUser } from "../../../utils/User";
import Select2 from "../../../components/forms/Select2";
import ButtonModal from "../../../components/modal/ButtonModal";
import DynamicButton from "../../../components/forms/DynamicButton";
import ZoneService from "../../../store/actions/master_data/zone";
import PDAMService from "../../../store/actions/master_data/pdam";

function ModalFilter({
  show,
  toggle,
  filter,
  toggleFilter,
  isDelete,
  switchPDAM,
  masterDataPDAM,
  masterDataZone
}) {
  const currentUser = getUser();
  const dispatch = useDispatch();
  const [selectedPDAM, setSelectedPDAM] = useState(
    filter?.pdam_id ?? currentUser?.user?.pdam?.id ?? ""
  );
  const [zone, setZone] = useState([]);
  const [pdam, setPDAM] = useState([]);

  const handleReset = (formik) => {
    formik.setFieldValue("pdam", currentUser?.user?.pdam?.id ?? "");
    formik.setFieldValue("zona", "");
    setSelectedPDAM(currentUser?.user?.pdam?.id ?? "")
    toggleFilter({
      pdam_id: currentUser?.user?.pdam?.id ?? "",
      input_id: "",
    });
    toggle();
  };

  useEffect(() => {
    const param = {
      draw: 1,
      length: 999,
      page: 1,
      id: switchPDAM?.id
    };
    dispatch(PDAMService.get(param));
  }, [switchPDAM]);

  useEffect(() => {
    if (masterDataPDAM) {
      let pdam_list = masterDataPDAM?.data_list?.data.data;
      setPDAM(pdam_list)
    }
  }, [masterDataPDAM]);

  useEffect(() => {
    const param = {
      draw: 1,
      length: 999,
      page: 1,
    };
    dispatch(ZoneService.get(param));
  }, []);

  useEffect(() => {
    setSelectedPDAM(filter?.pdam_id);
  }, [filter]);

  useEffect(() => {
    if (masterDataZone) {
      let zone_list = masterDataZone?.data_list?.data.data;
      if (selectedPDAM) {
        zone_list = zone_list?.filter(item => item.pdam_id === selectedPDAM);
      }
      setZone(zone_list)
    }
  }, [selectedPDAM]);

  const handleClose = () => {
    setSelectedPDAM(currentUser?.user?.pdam?.id ?? "")
    toggle()
  }

  return (
    <>
      <Modal
        isOpen={show}
        modalTransition={{ timeout: 200 }}
        backdropTransition={{ timeout: 1300 }}
      >
        <ModalHeader className="text-extra-bold pt-5">
          {" "}
          Filter{" "}
          <span className="close clickable" onClick={handleClose}>
            &times;
          </span>
        </ModalHeader>
        <ModalBody style={{ margin: "0px 20px" }}>
          <DynamicButton
            titleLeft="Pilih data yang ingin difilter"
            iconLeft="info"
          />
          <Formik
            initialValues={{
              pdam: filter?.pdam_id ?? "",
              zone: filter?.zone_id ?? "",
            }}
            enableReinitialize
            onSubmit={(values, { setStatus, setSubmitting }) => {
              setStatus();
              toggleFilter({
                pdam_id: values?.pdam ?? "",
                zone_id: values?.zone ?? "",
              });
              toggle();
            }}
          >
            {(props) => (
              <Form style={{ marginTop: 30 }}>
                <Select2
                  title="PDAM"
                  name="pdam"
                  options={pdam?.map((item) => ({
                    value: item?.id,
                    label: item?.name,
                  }))}
                  errors={props.errors}
                  touched={props.touched}
                  value={props.values?.pdam}
                  placeholder="Pilih data PDAM"
                  onChange={(name, value) => {
                    props.setFieldValue("pdam", value);
                    props.setFieldValue("zone", "");
                    setSelectedPDAM(value);
                  }}
                  onBlur={props.setFieldTouched}
                  disabled={currentUser?.user?.pdam?.id}
                />
                <Select2
                  title="Zona"
                  name="zone"
                  options={zone?.map((item) => ({
                    value: item?.id,
                    label: item?.name,
                  }))}
                  errors={props.errors}
                  touched={props.touched}
                  value={props.values?.zone}
                  placeholder="Pilih data zona"
                  onChange={(name, value) => {
                    props.setFieldValue("zone", value);
                  }}
                  onBlur={props.setFieldTouched}
                />
                <div style={{ marginTop: 50, marginBottom: 30 }}>
                  <ButtonModal
                    toggle={handleClose}
                    reset={() => handleReset(props)}
                    deleted={isDelete}
                    confirmTitle="Terapkan"
                  />
                </div>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </Modal>
    </>
  );
}

const mapStateToProps = ({ masterDataZone, masterDataPDAM }) => {
  return { masterDataZone, masterDataPDAM };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalFilter);
