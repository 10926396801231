import {
    useState,
    useRef,
    forwardRef,
} from "react";
import HeaderMenu from "../../commons/menu/HeaderMenu";

const DataDiagramBaseLayout = forwardRef(
    ({ title,
        dataHeaderContent,
        dataContent,
        diagramHeaderContent,
        diagramContent,
        onChangeView,
        elipsis },
        ref) => {
        const diagramRef = useRef();
        const [selectDiagramShow, setSelectDiagramShow] = useState(false);
        const [activeView, setActiveView] = useState("diagram");

        const toggleSelectDiagram = () => setSelectDiagramShow(!selectDiagramShow);

        const MenuTab = ({ view }) => {
            return (
                <nav
                    className="active-pointer-evt pl-3"
                    aria-label="Page navigation example"
                >
                    <ul className="line-buttons mb-0" style={{ zIndex: 2 }}>
                        <li className={`button-item ${view === 'mobile' && activeView === "diagram" ? 'mini-hide' : ''}`}>
                            <a
                                className={`button-link ${activeView === "diagram" ? "active" : ""}`}
                                onClick={() => { setActiveView("diagram"); onChangeView?.('diagram') }}
                            >
                                Diagram
                            </a>
                        </li>
                        <li className={`button-item ${view === 'mobile' && activeView === "data" ? 'mini-hide' : ''}`}>
                            <a
                                className={`button-link ${activeView == "data" ? "active" : ""}`}
                                onClick={() => { setActiveView("data"); onChangeView?.('data') }}
                            >
                                Data
                            </a>
                        </li>
                    </ul>
                </nav>
            )
        }
        return (
            <div className="main-content-wrapper">
                <HeaderMenu
                    title={title}
                    optClass="pr-5"
                    elipsis={elipsis}
                    titleChildren={
                        (
                            <div className="mini-show">
                                <MenuTab view="mobile" />
                            </div>
                        )
                    }
                >
                    {activeView === "data" && (
                        <>{dataHeaderContent}</>
                    )}
                    {activeView === "diagram" && (
                        <>{diagramHeaderContent}</>
                    )}
                    <div className="mini-hide">
                        <MenuTab view="web" />
                    </div>
                </HeaderMenu>
                {activeView === "diagram" && (
                    <>{diagramContent}</>
                )}
                {activeView === "data" && (
                    <>{dataContent}</>
                )}
            </div>
        );
    }
);

export default DataDiagramBaseLayout;
