const Legend = ({ show }) => {
  let typeShow = show ? show?.split("-") : [];
  let title = typeShow[0] !== "PIPE" ? "Pressure" : "Velocity";

  const LegendWrapper = ({ title, subtitle, children }) => {
    return (
      <div style={{ marginBottom: 15 }}>
        <p className="text-bold" style={{ fontSize: 14, marginBottom: 3 }}>
          {title}
        </p>
        <p
          style={{
            opacity: 0.5,
            fontSize: 10,
            fontWeight: 500,
            marginBottom: 0,
          }}
        >
          {subtitle}
        </p>
        <div
          className="hydraulic-information-detail"
          style={{ padding: "10px 5px", marginTop: 5 }}
        >
          {children}
        </div>
      </div>
    );
  };

  const LegendInformation = ({ color, desc, bottom }) => {
    return (
      <div style={{ display: "flex", position: "relative" }}>
        <div style={{ width: 5, height: 20, backgroundColor: color }} />
        <div style={{ paddingLeft: 10, fontSize: 10 }}>
          {!bottom && <span style={{ verticalAlign: "middle" }}>{desc}</span>}
          {bottom && (
            <span style={{ position: "absolute", bottom: "-8px" }}>{desc}</span>
          )}
        </div>
      </div>
    );
  };

  return (
    <div
      className="hydraulic-information active-pointer-evt"
      style={{ marginTop: 60 }}
    >
      <LegendWrapper
        title="Informasi Garis"
        subtitle="Informasi mengenai warna garis"
      >
        <LegendInformation color="black" desc="Pipa" />
        <LegendInformation color="#6B8E23" desc="Pompa" />
        <LegendInformation color="#C71585" desc="Katup" />
      </LegendWrapper>
      {show && (
        <LegendWrapper
          title={title}
          subtitle={`Informasi mengenai ${title.toLowerCase()}`}
        >
          <LegendInformation
            color="blue"
            desc={typeShow[0] !== "PIPE" ? "25.00" : "0.01"}
            bottom
          />
          <LegendInformation
            color="aqua"
            desc={typeShow[0] !== "PIPE" ? "50.00" : "0.10"}
            bottom
          />
          <LegendInformation
            color="lime"
            desc={typeShow[0] !== "PIPE" ? "75.00" : "1.00"}
            bottom
          />
          <LegendInformation
            color="yellow"
            desc={typeShow[0] !== "PIPE" ? "100.00" : "2.00"}
            bottom
          />
          <LegendInformation
            color="red"
            desc={typeShow[0] !== "PIPE" ? "m" : "m/s"}
            bottom
          />
        </LegendWrapper>
      )}
    </div>
  );
};

export default Legend;
