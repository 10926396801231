import React from "react"
import { Col, Row } from "reactstrap"
import Card from "./card"

const CardDiagram = ({
  sectr1,
  sectr2,
  sectr3,
  red,
  data,
  sectr1LabelCardXL,
  sectr1LabelCardLG,
  sectr1LabelCardMD,
  sectr1DataCardXL,
  sectr1DataCardLG,
  sectr1DataCardMD,
  sectr2LabelLeft,
  sectr2DataLeft,
  labelStrecth1,
  labelStrecth2,
  labelStrecth3,
  dataStrecth1,
  dataStrecth2,
  dataStrecth3,
  sectr3Data1,
  sectr3Data2,
  sectr1PercentDataCardXL,
  sectr1PercentDataCardLG,
  sectr1PercentDataCardMD,
  sectr2PercentDataLeft,
  dataPercentStrecth1,
  dataPercentStrecth2,
  dataPercentStrecth3,
  sectr3PercentData1,
  sectr3PercentData2,
}) => {
  return (
    <>
      {sectr1 && (
        <>
          <Row className="text-center">
            <Col md={6}>
              <Card>
                <div className="text-wrapper-card-xl-stretch">
                  <span className="material-icons-outlined text-primary">invert_colors</span>
                  <div className="text-extra-bold">{sectr1LabelCardXL}</div>
                  <div className="text-medium">
                    {sectr1DataCardXL} m<sup>3</sup>
                  </div>
                  <div className="text-medium">{sectr1PercentDataCardXL} %</div>
                </div>
              </Card>
            </Col>
            <Col md={6}>
              <Card className="border-success">
                <div className="text-wrapper-card-lg-stretch">
                  <span className="material-icons-outlined text-success">invert_colors</span>
                  <div className="text-extra-bold">{sectr1LabelCardLG}</div>
                  <div className="text-medium">
                    {sectr1DataCardLG} m<sup>3</sup>
                  </div>
                  <div className="text-medium">{sectr1PercentDataCardLG} %</div>
                </div>
              </Card>
              <Card className="border-danger">
                <div className="text-wrapper-card-md">
                  <span className="material-icons-outlined text-danger">invert_colors</span>
                  <div className="text-extra-bold">{sectr1LabelCardMD}</div>
                  <div className="text-medium">
                    {sectr1DataCardMD} m<sup>3</sup>
                  </div>
                  <div className="text-medium">{sectr1PercentDataCardMD} %</div>
                </div>
              </Card>
            </Col>
          </Row>
        </>
      )}
      {sectr2 && (
        <Row className="text-center">
          <Col md={4}>
            <Card>
              <div className={`${labelStrecth3 ? "text-wrapper-card-md-stretch" : "text-wrapper-card-sm"} text-dark ${red ? "bg-red" : "bg-green"}`}>
                <div className="text-extra-bold">{sectr2LabelLeft}</div>
                <div className="text-medium">
                  {sectr2DataLeft} m<sup>3</sup>
                </div>
                <div className="text-medium">{sectr2PercentDataLeft} %</div>
              </div>
            </Card>
          </Col>
          <Col md={8}>
            <Card>
              <div className={`text-wrapper-card-strecth text-dark ${red ? "bg-red" : "bg-green"}`}>
                <div className="text-extra-bold">{labelStrecth1}</div>
                <div className="text-medium">
                  {dataStrecth1} m<sup>3</sup>
                </div>
                <div className="text-medium">{dataPercentStrecth1} %</div>
              </div>
            </Card>
            {/* check if there any label strecth 3 */}
            {(labelStrecth3 || dataStrecth3) && (
              <Card>
                <div className={`text-wrapper-card-strecth text-dark ${red ? "bg-red" : "bg-green"}`}>
                  <div className="text-extra-bold">{labelStrecth3}</div>
                  <div className="text-medium">
                    {dataStrecth3} m<sup>3</sup>
                  </div>
                  <div className="text-medium">{dataPercentStrecth3} %</div>
                </div>
              </Card>
            )}
            <Card>
              <div className={`text-wrapper-card-strecth text-dark ${red ? "bg-red" : "bg-green"}`}>
                <div className="text-extra-bold">{labelStrecth2}</div>
                <div className="text-medium">
                  {dataStrecth2} m<sup>3</sup>
                </div>
                <div className="text-medium">{dataPercentStrecth2} %</div>
              </div>
            </Card>
          </Col>
        </Row>
      )}
      {sectr3 && (
        <Row className="text-center">
          <Col>
            <Card className="border-success">
              <div className={`text-wrapper-card-md`}>
                <div className="text-extra-bold">
                  Air
                  <br />
                  Berekening
                </div>
                <div className="text-medium">
                  {sectr3Data1} m<sup>3</sup>
                </div>
                <div className="text-medium">{sectr3PercentData1} %</div>
              </div>
            </Card>
            <Card className="border-danger">
              <div className={`text-wrapper-card`} style={{ height: "637px", padding: "306px 5px" }}>
                <div className="text-extra-bold">
                  Air Tak <br /> Berekening
                </div>
                <div className="text-medium">
                  {sectr3Data2} m<sup>3</sup>
                </div>
                <div className="text-medium">{sectr3PercentData2} %</div>
              </div>
            </Card>
          </Col>
        </Row>
      )}
    </>
  )
}

export default CardDiagram
