import React, { useState, useRef, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import ButtonModal from "../../../components/modal/ButtonModal";
import ConfirmationModal from "../../../components/modal/ConfirmModal";
import CommercialLossesService from "../../../store/actions/commercial_losses";
import Input from "../../../components/forms/Input";
import { toastError } from "../../../components/commons/toast";

const ModalAdd = ({ show, toggle, refresh, type, data, pending_put }) => {
  const dispatch = useDispatch();
  const [showModalConfirm, setShowModalConfirm] = useState(false);

  const validation = Yup.object().shape({
    stdkini: Yup.number()
      .typeError("ST Kini harus angka")
      .required("ST Kini wajib diisi")
      .nullable(),
    stdlalu: Yup.number()
      .typeError("ST Lalu harus angka")
      .required("ST Lalu wajib diisi")
      .nullable(),
  });

  const handleConfirm = () => {
    setShowModalConfirm(false);
    toggle(false);
  };

  return (
    <>
      <Modal isOpen={show} modalTransition={{ timeout: 200 }}>
        <ModalHeader className="text-extra-bold pt-5">
          Edit Commercial Losses
          <span className="close clickable" onClick={() => handleConfirm()}>
            &times;
          </span>
        </ModalHeader>
        <Formik
          initialValues={{
            stdkini: data?.stdkini,
            stdlalu: data?.stdlalu,
          }}
          validationSchema={validation}
          onSubmit={(values) => {
            const param = {
              ...values,
              id: data?.id,
            };
            const callback = () => {
              refresh(values);
              handleConfirm();
            };
            dispatch(CommercialLossesService.put(param, callback));
          }}
        >
          {(props) => (
            <Form style={{ marginTop: 30 }}>
              <ModalBody style={{ margin: "0px 20px" }}>
                <Input
                  title="ST Kini"
                  label="ST Kini"
                  placeholder=""
                  type="text"
                  name="stdkini"
                  errors={props?.errors}
                  touched={props?.touched}
                  {...props?.getFieldProps("stdkini")}
                  value={props.values.stdkini}
                  disabled={pending_put}
                />
                <Input
                  title="ST Lalu"
                  label="ST Lalu"
                  placeholder=""
                  type="text"
                  name="stdlalu"
                  errors={props?.errors}
                  touched={props?.touched}
                  {...props?.getFieldProps("stdlalu")}
                  value={props.values.stdlalu}
                  disabled={pending_put}
                />
              </ModalBody>
              <ModalFooter>
                {type !== "detail" && (
                  <ButtonModal
                    toggle={() => setShowModalConfirm(true)}
                    confirmTitle="Simpan"
                    disabledConfrim={pending_put}
                  />
                )}
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </Modal>

      <ConfirmationModal
        show={showModalConfirm}
        toggle={() => setShowModalConfirm(false)}
        confirm={() => handleConfirm()}
        cancelTitle="Tidak"
        confirmTitle="Ya"
        message="Apakah Anda yakin ingin membatalkan ubah data?"
        icon="info"
      />
    </>
  );
};

const mapStateToProps = ({
  masterDataRegion,
  commercialLosses: { pending_put },
}) => {
  return { masterDataRegion, pending_put };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalAdd);
