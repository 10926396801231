import { convertCoordinate } from "../ConverCoordinate";

export const convertPipeline = (dataFeature) => {
  let feature = dataFeature;
  if (feature) {
    feature.map((item, index) => {
      if (item.properties.clean) {
        let coordinate = item.geometry.coordinates;
        feature[index].properties.Name = item.properties.NAME;
        feature[index].properties.NAMA = item.properties.NAME;
        feature[index].geometry.coordinates = convertCoordinate(
          coordinate,
          item?.geometry?.type
        );
      }
    });
  }
  return feature;
};
