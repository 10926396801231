import { Container } from "reactstrap";
import { connect, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Table from "../../../components/table/index";
import { RoleName } from "../../../utils/Constants";
import Notification, {
  setPutManagementNotificationData,
} from "../../../store/actions/management_notification";

const Index = ({
  toggleEdit,
  toggleSave,
  isEdit,
  isCancel,
  isSave,
  pending_all,
  pending_role,
}) => {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState("it_administrator");
  const [allData, setAllData] = useState([]);
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const [dataItAdmin, setDataItAdmin] = useState([]);
  const [dataKadiv, setDataKadiv] = useState([]);
  const [dataSuperadmin, setDataSuperadmin] = useState([]);
  const [dataUserCust, setDataUserCust] = useState([]);
  const [dataViewer, setDataViewer] = useState([]);
  const [selectedDataItAdmin, setSelectedDataItAdmin] = useState([]);
  const [selectedDataKadiv, setSelectedDataKadiv] = useState([]);
  const [selectedDataSuperadmin, setSelectedDataSuperadmin] = useState([]);
  const [selectedDataUserCust, setSelectedDataUserCust] = useState([]);
  const [selectedDataViewer, setSelectedDataViewer] = useState([]);
  const [selectedDataItAdminEmail, setSelectedDataItAdminEmail] = useState([]);
  const [selectedDataKadivEmail, setSelectedDataKadivEmail] = useState([]);
  const [selectedDataSuperadminEmail, setSelectedDataSuperadminEmail] = useState([]);
  const [selectedDataUserCustEmail, setSelectedDataUserCustEmail] = useState([]);
  const [selectedDataViewerEmail, setSelectedDataViewerEmail] = useState([]);

  const handleTab = (e, value) => {
    setActiveTab(value);
  };

  const handleSelect = (data, index) => {
    if (index === 0) {
      switch (activeTab) {
        case "it_administrator":
          setSelectedDataItAdmin(data);
          break;
        case "kadiv":
          setSelectedDataKadiv(data);
          break;
        case "superadmin":
          setSelectedDataSuperadmin(data);
          break;
        case "user_cust_rep":
          setSelectedDataUserCust(data);
          break;
        case "viewer":
          setSelectedDataViewer(data);
          break;
        default:
          return null;
      }
    } else {
      switch (activeTab) {
        case "it_administrator":
          setSelectedDataItAdminEmail(data);
          break;
        case "kadiv":
          setSelectedDataKadivEmail(data);
          break;
        case "superadmin":
          setSelectedDataSuperadminEmail(data);
          break;
        case "user_cust_rep":
          setSelectedDataUserCustEmail(data);
          break;
        case "viewer":
          setSelectedDataViewerEmail(data);
          break;
        default:
          return null;
      }
    }
  };

  const columns = [
    {
      Header: "NO.",
      accessor: "no",
      Cell: ({ row }) => {
        const { index } = row;
        return index + 1;
      },
    },
    {
      Header: "NAMA NOTIFIKASI",
      accessor: "name",
      Cell: ({ row }) => {
        const { original } = row;
        const { name } = original;
        return name || "-";
      },
    },
    {
      Header: "MENU",
      accessor: "menu",
      Cell: ({ row }) => {
        const { original } = row;
        const { menu } = original;
        return menu || "-";
      },
    },
  ];

  useEffect(() => {
    getRoleData("it_administrator");
    getRoleData("kadiv");
    getRoleData("superadmin");
    getRoleData("user_cust_rep");
    getRoleData("viewer");
    getAllData();
  }, []);

  const getAllData = () => {
    new Promise((resolve) => {
      const param = {};
      dispatch(Notification.getAll(param, resolve));
    }).then((res) => {
      setAllData(res?.data);
    });
  };

  const getRoleData = (role) => {
    new Promise((resolve) => {
      const param = {
        role_code: role,
      };
      dispatch(Notification.getRole(param, resolve));
    }).then((res) => {
      let notifWeb = res.data&& res.data[0] ? res.data[0].notification_list_access : [];
      let notifEmail = res.data&& res.data[0] ? res.data[0].notification_list_access_email : [];
      let allNotif = [...notifWeb, ...notifEmail].reduce((unique, o) => {
        if(!unique.some(obj => obj.id === o.id)){
          unique.push(o)
        }
        return unique;
      }, [])
      console.log(allNotif)
      switch (role) {
        case "it_administrator":
          setDataItAdmin(allNotif);
          setSelectedDataItAdmin(notifWeb.map((item) => item.id));
          setSelectedDataItAdminEmail(notifEmail.map((item) => item.id));
          break;
        case "kadiv":
          setDataKadiv(allNotif);
          setSelectedDataKadiv(notifWeb.map((item) => item.id));
          setSelectedDataKadivEmail(notifEmail.map((item) => item.id));
          break;
        case "superadmin":
          setDataSuperadmin(allNotif);
          setSelectedDataSuperadmin(notifWeb.map((item) => item.id));
          setSelectedDataSuperadminEmail(notifEmail.map((item) => item.id));
          break;
        case "user_cust_rep":
          setDataUserCust(allNotif);
          setSelectedDataUserCust(notifWeb.map((item) => item.id));
          setSelectedDataUserCustEmail(notifEmail.map((item) => item.id));
          break;
        case "viewer":
          setDataViewer(allNotif);
          setSelectedDataViewer(notifWeb.map((item) => item.id));
          setSelectedDataViewerEmail(notifEmail.map((item) => item.id));
          break;
        default:
          return null;
      }
    });
  };

  useEffect(() => {
    if (isCancel) {
      setSelectedDataItAdmin(dataItAdmin.map((item) => item.id));
      setSelectedDataKadiv(dataKadiv.map((item) => item.id));
      setSelectedDataSuperadmin(dataSuperadmin.map((item) => item.id));
      setSelectedDataUserCust(dataUserCust.map((item) => item.id));
      setSelectedDataViewer(dataViewer.map((item) => item.id));
    }

    if (isSave) {
      handleSubmit();
    }
  }, [isCancel, isSave]);

  const handleSubmit = () => {
    const param = [];

    [
      "it_administrator",
      "kadiv",
      "superadmin",
      "user_cust_rep",
      "viewer",
    ].forEach((role_code) => {
      let notification_list_access = [];
      let notification_list_access_email = [];
      let role = RoleName[role_code];
      switch (role_code) {
        case "it_administrator":
          notification_list_access = allData
            .filter((item) => selectedDataItAdmin.includes(item.id))
            .map((item) => item.code);
          notification_list_access_email = allData
            .filter((item) => selectedDataItAdminEmail.includes(item.id))
            .map((item) => item.code);
          break;
        case "kadiv":
          notification_list_access = allData
            .filter((item) => selectedDataKadiv.includes(item.id))
            .map((item) => item.code);
          notification_list_access_email = allData
            .filter((item) => selectedDataKadivEmail.includes(item.id))
            .map((item) => item.code);
          break;
        case "superadmin":
          notification_list_access = allData
            .filter((item) => selectedDataSuperadmin.includes(item.id))
            .map((item) => item.code);
          notification_list_access_email = allData
            .filter((item) => selectedDataSuperadminEmail.includes(item.id))
            .map((item) => item.code);
          break;
        case "user_cust_rep":
          notification_list_access = allData
            .filter((item) => selectedDataUserCust.includes(item.id))
            .map((item) => item.code);
          notification_list_access_email = allData
            .filter((item) => selectedDataUserCustEmail.includes(item.id))
            .map((item) => item.code);
          break;
        case "viewer":
          notification_list_access = allData
            .filter((item) => selectedDataViewer.includes(item.id))
            .map((item) => item.code);
          notification_list_access_email = allData
            .filter((item) => selectedDataViewerEmail.includes(item.id))
            .map((item) => item.code);
          break;
        default:
          return null;
      }
      let valArr = {
        role_code,
        notification_list_access,
        notification_list_access_email,
      };
      param.push(valArr);
    });

    const callback = () => {
      toggleEdit(false);
      toggleSave(false);
      getRoleData("it_administrator");
      getRoleData("kadiv");
      getRoleData("superadmin");
      getRoleData("user_cust_rep");
      getRoleData("viewer");
    };

    dispatch(Notification.put(param, callback));
  };

  const showTable = (active) => {
    let dataTemp = [];
    let selectData = [];
    switch (active) {
      case "it_administrator":
        if (isEdit) {
          dataTemp = allData;
        } else {
          dataTemp = dataItAdmin;
        }
        return (
          <Table
            id="tbl-data-system-notification-management"
            columns={columns}
            selection={isEdit}
            customHeaderSelection={["WEB", "EMAIL"]}
            selection={[selectedDataItAdmin, selectedDataItAdminEmail]}
            selectionAlign="right"
            selectionRow={isEdit}
            onSelection={(value, index) => handleSelect(value, index)}
            isLoading={pending_all || pending_role}
            data={dataTemp}
            pagination={{
              page,
              totalData: dataTemp.length,
              maxPage,
              toggle: setPage,
            }}
          />
        );
      case "kadiv":
        if (isEdit) {
          dataTemp = allData;
        } else {
          dataTemp = dataKadiv;
        }
        return (
          <Table
            id="tbl-data-system-notification-management"
            columns={columns}
            selection={isEdit}
            customHeaderSelection={["WEB", "EMAIL"]}
            selection={[selectedDataKadiv, selectedDataKadivEmail]}
            selectionAlign="right"
            selectionRow={isEdit}
            onSelection={(value, index) => handleSelect(value, index)}
            isLoading={pending_all || pending_role}
            data={dataTemp}
            pagination={{
              page,
              totalData: dataTemp.length,
              maxPage,
              toggle: setPage,
            }}
          />
        );
      case "superadmin":
        if (isEdit) {
          dataTemp = allData;
        } else {
          dataTemp = dataSuperadmin;
        }
        return (
          <Table
            id="tbl-data-system-notification-management"
            columns={columns}
            selection={isEdit}
            customHeaderSelection={["WEB", "EMAIL"]}
            selection={[selectedDataSuperadmin, selectedDataSuperadminEmail]}
            selectionAlign="right"
            selectionRow={isEdit}
            onSelection={(value, index) => handleSelect(value, index)}
            isLoading={pending_all || pending_role}
            data={dataTemp}
            pagination={{
              page,
              totalData: dataTemp.length,
              maxPage,
              toggle: setPage,
            }}
          />
        );
      case "user_cust_rep":
        if (isEdit) {
          dataTemp = allData;
        } else {
          dataTemp = dataUserCust;
        }
        return (
          <Table
            id="tbl-data-system-notification-management"
            columns={columns}
            selection={isEdit}
            customHeaderSelection={["WEB", "EMAIL"]}
            selection={[selectedDataUserCust, selectedDataUserCustEmail]}
            selectionAlign="right"
            selectionRow={isEdit}
            onSelection={(value, index) => handleSelect(value, index)}
            isLoading={pending_all || pending_role}
            data={dataTemp}
            pagination={{
              page,
              totalData: dataTemp.length,
              maxPage,
              toggle: setPage,
            }}
          />
        );
      case "viewer":
        if (isEdit) {
          dataTemp = allData;
        } else {
          dataTemp = dataViewer;
        }
        return (
          <Table
            id="tbl-data-system-notification-management"
            columns={columns}
            selection={isEdit}
            customHeaderSelection={["WEB", "EMAIL"]}
            selection={[selectedDataViewer, selectedDataViewerEmail]}
            selectionAlign="right"
            selectionRow={isEdit}
            onSelection={(value, index) => handleSelect(value, index)}
            isLoading={pending_all || pending_role}
            data={dataTemp}
            pagination={{
              page,
              totalData: dataTemp.length,
              maxPage,
              toggle: setPage,
            }}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Container>
      <div className="pl-4">
        <Tabs
          value={activeTab}
          textColor="primary"
          indicatorColor="primary"
          onChange={handleTab}
        >
          <Tab label="IT Admin" value="it_administrator" />
          <Tab label="Kadiv" value="kadiv" />
          <Tab label="Superuser" value="superadmin" />
          <Tab label="User Cust Rep" value="user_cust_rep" />
          <Tab label="Viewer PDAM" value="viewer" />
        </Tabs>
        {showTable(activeTab)}
      </div>
    </Container>
  );
};

const mapStateToProps = ({
  managementNotification: {
    pending_all,
    pending_role,
    pending_put,
    data_all_list,
    data_role_list,
    data_put,
  },
}) => {
  return {
    pending_all,
    pending_role,
    pending_put,
    data_all_list,
    data_role_list,
    data_put,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSetPutManagementNotificationData: (data) =>
      dispatch(setPutManagementNotificationData(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
