import React, { useState, useRef, useEffect } from 'react'
import { connect, useDispatch } from "react-redux"
import MenuItem from "@material-ui/core/MenuItem";

import { history } from '../../../utils/useHistory'
import Loader from '../../../components/commons/Loader'
import Card from '../../../components/cards/card'
import CardFlush from '../../../components/cards/cardFlush'
import HeaderMenu from '../../../components/commons/menu/HeaderMenu'
import Button from '../../../components/buttons/Button'
import SearchButton from '../../../components/buttons/SearchButton'
import Table from '../../../components/table'
import DetailButton from '../../../components/table/detailButton'
import ConfirmModal from "../../../components/modal/ConfirmModal"
import { toastSuccess, toastError } from '../../../components/commons/toast';
import { getUser } from "../../../utils/User";
import { Role } from "../../../utils/Constants";

import ModalAddSubDMA from './ModalAddSubDMA';

import DMAService from "../../../store/actions/master_data/dma"
import SubDMAService from "../../../store/actions/master_data/subDMA"

const DetailDMA = ({ match, pending, t, switchPDAM }) => {

    const dispatch = useDispatch()

    const [searchText, setSearchText] = useState('')
    const [DMA, setDMA] = useState([])
    const [data, setData] = useState([])
    const [showModalDelete, setShowModalDelete] = useState(false)
    const [itemData, setItemData] = useState(null)
    const [totalData, setTotalData] = useState(null)
    const [page, setPage] = useState(1)
    const [maxPage, setMaxPage] = useState(1)
    const [showModalAdd, setShowModalAdd] = useState(false);
    const [action, setAction] = useState(false);

    const handleSearch = (value) => {
        setSearchText(value)
        setPage(1)
    }

    const handleAction = () => {
        setShowModalAdd(true)
    }

    const handleDelete = () => {
        const callback = () => {
            setShowModalDelete(false)
            toastSuccess(`Berhasil Hapus Data Sub DMA ${itemData?.name}`)
            handleRefresh();
            setAction(!action)
        }
        dispatch(SubDMAService.deleted(itemData?.id, callback));
    }

    const handleShowDelete = (item) => {
        setItemData(item)
        setShowModalDelete(true)
    }

    const handleRefresh = () => {
        new Promise((resolve) => {
            const param = {
                draw: 1,
                length: 10,
                page: page ?? 1,
                search_text: searchText,
                start: null,
                dma_id: match?.params?.id
            }
            dispatch(SubDMAService.get(param, resolve));
        }).then(res => {
            setData(res?.data)
            setTotalData(res?.totalCount)
            setMaxPage(Math.ceil(res?.totalCount / 10))
        })
    }

    const currentUser = getUser().user;

    const columns = (currentUser.role_code === Role.Kadiv || currentUser.role_code === Role.Superadmin) ? [
        {
            Header: "SUB DMA",
            accessor: "name",
        },
        {
            Header: "LOKASI",
            accessor: "region_name",
        },
        {
            Header: "JML PELANGGAN",
            accessor: "customer_count",
        },
        {
            Header: "",
            accessor: "aksi",
            Cell: ({ row }) => {
                const original = row ? row?.original : [];
                return (
                    <DetailButton>
                        <MenuItem onClick={() => handleShowDelete(original)}>
                            <span style={{ fontSize: 14 }}>Hapus</span>
                        </MenuItem>
                    </DetailButton>
                );
            },
        },
    ] : [
        {
            Header: "SUB DMA",
            accessor: "name",
        },
        {
            Header: "LOKASI",
            accessor: "region_name",
        },
        {
            Header: "JML PELANGGAN",
            accessor: "customer_count",
        },
    ]

    useEffect(() => {
        handleRefresh();
    }, [page])

    useEffect(() => {
        setPage(prev => prev === 1 ? null : 1)
    }, [searchText, action])

    useEffect(() => {
        new Promise((resolve) => {
            const param = {
                draw: 1,
                length: 1,
                page: 1,
                id: match?.params?.id
            }
            dispatch(DMAService.get(param, resolve));
        }).then(res => {
            setDMA(res?.data[0])
        })
    }, [action])


    useEffect(() => {
        if (switchPDAM && DMA.length > 0) {
            if (switchPDAM?.id !== DMA?.pdam?.id) {
                setTimeout(function () {
                    history.push({ pathname: '/data-master', state: { tab: '3' } })
                    toastError(`Data Sub DMA tidak sesuai dengan ${switchPDAM?.name}`)
                }, 1000);
            }
        }
    }, [switchPDAM])

    return (
        <div className="menu-container">
            {pending && <Loader loading={pending} />}
            <div className="grid-header">
                <div className="side-menu">
                    <div className="menu-title text-extra-bold">
                        <div className="side-menu-back-icon ">
                            <span className="material-icons clickable" onClick={() => history.push({ pathname: '/data-master', state: { tab: "3" } })}>arrow_back</span>
                        </div>
                        DMA {DMA?.name}
                    </div>
                    <div style={{ padding: '0px 15px 30px 10px' }}>
                        <Card>
                            <CardFlush
                                sideMenu
                                title='PDAM'
                                desc={DMA?.pdam?.name}
                                leftIcon='other_houses'
                            />
                            <CardFlush
                                sideMenu
                                title='Lokasi'
                                desc={DMA?.region_name}
                                leftIcon='place'
                            />
                            <CardFlush
                                sideMenu
                                title='Jumlah Pelanggan'
                                desc={DMA?.customer_count}
                                leftIcon='person'
                            />
                            <CardFlush
                                sideMenu
                                title='Jumlah Sub DMA'
                                desc={DMA?.sub_dma_count}
                                leftIcon='water_damage'
                            />
                            <CardFlush
                                sideMenu
                                title='Zona'
                                desc={DMA?.zone?.name}
                                leftIcon='flag'
                            />
                            <CardFlush
                                sideMenu
                                title='Diameter'
                                desc={DMA?.diameter}
                                leftIcon='sensors'
                            />
                            <CardFlush
                                sideMenu
                                title='ID Sensor'
                                desc={DMA?.device_id}
                                leftIcon='sensors'
                            />
                            <CardFlush
                                sideMenu
                                title="Lokasi Sensor"
                                desc={`${DMA?.longitude},${DMA?.latitude}`}
                                leftIcon='sensors'
                            />
                        </Card>
                    </div>
                </div>
                <div>
                    <HeaderMenu title="Sub DMA">
                        <SearchButton
                            placeholder="Masukkan Nama Sub DMA"
                            toggle={(value) => handleSearch(value)}
                        />

                        <div className="nav-separator mr-3"></div>

                        <Button
                            title='Import Sub DMA'
                            leftIcon='import_export'
                            size="md"
                            variant="btn-lg button-default"
                            rounded={true}
                            onClick={handleAction}
                            disabled={currentUser.role_code === Role.Kadiv}
                        />
                    </HeaderMenu>
                    <div className="content-container-side">

                    </div>
                    <div className="content-container-side">
                        <div className="overflow">
                            <Table columns={columns} data={data} pagination={{ page, totalData, maxPage, toggle: setPage }} />
                        </div>
                    </div>
                </div>
            </div>

            {/* Modal */}
            <ConfirmModal
                show={showModalDelete}
                toggle={() => setShowModalDelete(false)}
                confirm={handleDelete}
                icon='info'
                isDelete
            >
                <div>
                    Apakah Anda yakin akan menghapus
                    <strong>{" Sub DMA " + itemData?.name}</strong> ?
                </div>
            </ConfirmModal>

            <ModalAddSubDMA
                show={showModalAdd}
                toggle={() => setShowModalAdd(false)}
                refresh={setAction}
                dma_id={match?.params?.id}
            />
        </div >
    )

}

const mapStateToProps = ({ masterDataSubDMA: { pending } }) => {
    return { pending };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};


export default connect(mapStateToProps, mapDispatchToProps)(DetailDMA);
