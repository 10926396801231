import {
  GET_ILI_PENDING,
  GET_ILI_SUCCESS,
  GET_ILI_ERROR,
  ILI_DATA,
} from "../../actions/infrastructure_leakage_index";

const initialState = {
  pending: false,
  error: null,
  data_list: null,
  data_put: null,
  detailData: null,
};

const ILI = (state = initialState, action) => {
  switch (action.type) {
    case GET_ILI_PENDING:
      return {
        ...state,
        pending: true,
      };
    case GET_ILI_SUCCESS:
      return {
        ...state,
        pending: false,
        data_list: action.data,
      };
    case GET_ILI_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case ILI_DATA:
      return {
        ...state,
        detailData: action.data,
      };

    default:
      return state;
  }
};

export default ILI;
