import {
  GET_PDAM_PENDING,
  GET_PDAM_SUCCESS,
  GET_PDAM_ERROR,
  GET_ALL_PDAM_PENDING,
  GET_ALL_PDAM_SUCCESS,
  GET_ALL_PDAM_ERROR,
  POST_PDAM_PENDING,
  POST_PDAM_SUCCESS,
  POST_PDAM_ERROR,
  PUT_PDAM_PENDING,
  PUT_PDAM_SUCCESS,
  PUT_PDAM_ERROR,
  DELETE_PDAM_PENDING,
  DELETE_PDAM_SUCCESS,
  DELETE_PDAM_ERROR,
} from "../../../actions/master_data/pdam";

const initialState = {
  pending: false,
  pending_delete: false,
  error: null,
  data_list: null,
  data_all: null,
};

const pdams = (state = initialState, action) => {
  switch (action.type) {
    case GET_PDAM_PENDING:
      return {
        ...state,
        pending: true,
      };
    case GET_PDAM_SUCCESS:
      return {
        ...state,
        pending: false,
        data_list: action.data,
      };
    case GET_PDAM_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case GET_ALL_PDAM_PENDING:
      return {
        ...state,
        pending: true,
      };
    case GET_ALL_PDAM_SUCCESS:
      return {
        ...state,
        pending: false,
        data_all: action.data,
      };
    case GET_ALL_PDAM_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case POST_PDAM_PENDING:
      return {
        ...state,
        pending: true,
      };
    case POST_PDAM_SUCCESS:
      return {
        ...state,
        pending: false,
        data_list: action.data,
      };
    case POST_PDAM_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case PUT_PDAM_PENDING:
      return {
        ...state,
        pending: true,
      };
    case PUT_PDAM_SUCCESS:
      return {
        ...state,
        pending: false,
        data_list: action.data,
      };
    case PUT_PDAM_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case DELETE_PDAM_PENDING:
      return {
        ...state,
        pending_delete: true,
      };
    case DELETE_PDAM_SUCCESS:
      return {
        ...state,
        pending_delete: false,
        data_list: action.data,
      };
    case DELETE_PDAM_ERROR:
      return {
        ...state,
        pending_delete: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default pdams;
