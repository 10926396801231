import React, { useState, useRef, useEffect } from "react"
import { connect, useDispatch } from "react-redux"
import { Formik, Form } from "formik"
import * as Yup from "yup"
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap"

import { getUser } from "../../../utils/User"
import Select2 from "../../../components/forms/Select2"
import ButtonModal from "../../../components/modal/ButtonModal"
import DynamicButton from "../../../components/forms/DynamicButton"
import ConfirmationModal from "../../../components/modal/ConfirmModal"

import InputService from "../../../store/actions/master_data/input"
import { toastError } from "../../../components/commons/toast"

function ModalAdd({ show, toggle, refresh, masterDataPDAM, switchPDAM, masterDataInput }) {
  const fileRef = useRef()
  const formikRef = useRef()
  const dispatch = useDispatch()
  const currentUser = getUser()

  const [fileProp, setFileProp] = useState({
    title: "Import Excel Input",
    subtitle: "File XLS",
    message_error: false,
  })
  const [pdam, setPDAM] = useState([])
  const [selectedPDAM, setSelectedPDAM] = useState(switchPDAM?.id ?? currentUser?.user?.pdam?.id ?? "")
  const [showModalConfirm, setShowModalConfirm] = useState(false)

  useEffect(() => {
    if (masterDataPDAM.data_list) {
      let pdam_list = masterDataPDAM.data_list.data.data
      setPDAM(pdam_list)
    }
  }, [masterDataPDAM])

  useEffect(() => {
    if (switchPDAM?.id) {
      setSelectedPDAM(switchPDAM?.id)
    } else {
      setSelectedPDAM("")
    }
  }, [switchPDAM])

  const openFileDialog = () => {
    fileRef.current.click()
  }

  const handleChange = (selectorFiles, formik) => {
    if (Number(selectorFiles.files[0].size) < 15728640) {
      formik.setFieldValue("file", selectorFiles.files[0])
      setFileProp({
        title: selectorFiles.files[0].name,
        subtitle: (Number(selectorFiles.files[0].size) * 0.001).toFixed(2) + " KB",
        message_error: false,
      })
    } else {
      toastError("Maksimal file yang dapat diimport 15 MB")
    }
  }

  const validation = Yup.object().shape({
    pdam_id: Yup.string().required("PDAM wajib diisi").nullable(),
  })

  const handleConfirm = () => {
    setFileProp({
      title: "Import Excel Input",
      subtitle: "File XLS",
      message_error: false,
    })
    setSelectedPDAM(currentUser?.user?.pdam?.id ?? "")
    setShowModalConfirm(false)
    toggle(false)
  }

  const handleFile = (formik) => {
    if (!formik.values.file) {
      setFileProp({
        title: "Import Excel Input",
        subtitle: "File XLS",
        message_error: "File wajib diisi",
      })
    }
  }

  return (
    <>
      <Modal isOpen={show} modalTransition={{ timeout: 200 }}>
        <ModalHeader className="text-extra-bold pt-5">
          {" "}
          Import Input{" "}
          <span className="close clickable" onClick={() => setShowModalConfirm(true)}>
            &times;
          </span>
        </ModalHeader>
        <Formik
          innerRef={formikRef}
          initialValues={{
            pdam_id: currentUser?.user?.pdam?.id ?? "",
            file: null,
          }}
          validationSchema={validation}
          onSubmit={(values, { setStatus, setSubmitting }) => {
            setStatus()
            if (values.file) {
              let formData = new FormData()
              formData.append("file", values.file)
              formData.append("pdam_id", values.pdam_id)

              const callback = () => {
                toggle()
                setFileProp({
                  title: "Import Excel Input",
                  subtitle: "File XLS",
                  message_error: false,
                })
                refresh(values)
              }
              dispatch(InputService.post(formData, callback))
            } else {
              setFileProp({
                title: "Import Excel Input",
                subtitle: "File XLS",
                message_error: "File wajib diisi",
              })
            }
          }}
        >
          {(props) => (
            <Form style={{ marginTop: 30 }}>
              <ModalBody style={{ margin: "0px 20px" }}>
                <Select2
                  title="PDAM"
                  name="pdam_id"
                  options={pdam?.map((item) => ({
                    value: item?.id,
                    label: item?.name,
                  }))}
                  errors={props.errors}
                  touched={props.touched}
                  value={props.values?.pdam_id}
                  placeholder="Pilih data PDAM"
                  onChange={(name, value) => {
                    props.setFieldValue("pdam_id", value)
                    setSelectedPDAM(value)
                  }}
                  onBlur={props.setFieldTouched}
                  disabled={currentUser?.user?.pdam}
                />
                <DynamicButton titleLeft={fileProp?.title} subtitleLeft={fileProp?.subtitle} iconLeft="insert_drive_file" toggle={openFileDialog} error={fileProp?.message_error} />

                <input type="file" ref={fileRef} style={{ display: "none" }} onChange={(e) => handleChange(e.target, props)} accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" />
              </ModalBody>
              <ModalFooter>
                <ButtonModal toggle={() => setShowModalConfirm(true)} confirmTitle="Import" disabledConfrim={masterDataInput.pending_post} isLoading={masterDataInput.pending_post} confirm={() => handleFile(props)} />
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </Modal>

      <ConfirmationModal isDelete show={showModalConfirm} toggle={() => setShowModalConfirm(false)} confirm={handleConfirm} cancelTitle="Tidak" confirmTitle="Ya" message="Yakin ingin membatalkan import data?" icon="info" />
    </>
  )
}

const mapStateToProps = ({ masterDataInput, masterDataPDAM }) => {
  return { masterDataInput, masterDataPDAM }
}

const mapDispatchToProps = (dispatch) => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalAdd)
