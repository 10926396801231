import React from "react";
import Table from "../../../components/table/index";
import DetailButton from "../../../components/table/detailButton/index";
import TableStatus from "../../../components/table/status/index";
import MenuItem from "@material-ui/core/MenuItem";
import masterDataPDAMService from "../../../store/actions/master_data/pdam";
import ModalImportJaringan from "./ModalImportJaringan";
import {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import { connect, useDispatch } from "react-redux";
import ModalUserPDAM from "./ModalEditDetailPDAM";
import ConfirmDelete from "../../../components/modal/ConfirmModal";
import { Role } from "../../../utils/Constants";
import { getUser } from "../../../utils/User";

const Index = forwardRef((props, ref) => {
  const [dataPDAM, setDataPDAM] = useState([]);
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState("");
  const [totalData, setTotalData] = useState(null);
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const { filter, search, add } = props;
  const [edit, setEdit] = useState(false);
  const currentUser = getUser().user;
  const [pending, setPending] = useState(false);
  const [pdamId, setpdamId] = useState(null)
  const [selectedData, setSelectedData] = useState(null)
  const [typeModal, setTypeModal] = useState('detail')
  const [showModalEdit, setShowModalEdit] = useState(false)
  const [showModalDelete, setShowModalDelete] = useState(false)
  const [showModalImportJaringan, setShowModalImportJaringan] = useState(false);

  useImperativeHandle(ref, () => ({
    handleSearch(searchText) {
      setSearchText(searchText.length > 0 ? searchText : "");
      handleRefresh();
    },
  }));

  const handleDelete = (deletedData) => {
    const callback = () => {
      if (page === 1) {
        handleRefresh();
      } else {
        setPage(1);
      }
      setShowModalDelete(!showModalDelete);
    };
    if (deletedData) {
      const id = deletedData?.id;
      dispatch(
        masterDataPDAMService.deleted(id, callback, deletedData?.name)
      );
    }
  };

  const changeModalEdit = (type, data) => {
    setShowModalEdit(!showModalEdit);
    setTypeModal(type);
    setSelectedData(data)
  };

  const changeModalDelete = (data) => {
    setShowModalDelete(!showModalDelete);
    setSelectedData(data)
  };

  const columns = [
    {
      Header: "NO.REG",
      accessor: "no_reg",
    },
    {
      Header: "BADAN/PERUSAHAAN",
      accessor: "name",
    },
    {
      Header: "PROVINSI",
      accessor: "province",
    },
    {
      Header: "KAB/KOTA",
      accessor: "city",
    },
    {
      Header: "ALAMAT",
      accessor: "address",
    },
    {
      Header: "STATUS",
      accessor: "status",
      Cell: ({ row }) => {
        const { original } = row;
        const { status } = original;

        return (
          <TableStatus
            status={
              status === 1
                ? "Subscribe"
                : status === 2
                  ? "Unsubscribe"
                  : status === 3
                    ? "Unsubscribe"
                    : "-"
            }
            color={
              status === 1
                ? "primary"
                : status === 2
                  ? "danger"
                  : status === 3
                    ? "danger"
                    : "-"
            }
          />
        );
      },
    },
    {
      Header: "",
      accessor: "aksi",
      Cell: ({ row }) => {
        const { original } = row;

        return (
          <>
            <DetailButton>
              <MenuItem onClick={() => changeModalEdit("detail", original)}>
                <span style={{ fontSize: 14 }}>Detail</span>
              </MenuItem>
              {currentUser.role_code === Role.Superadmin && (
                <MenuItem onClick={() => changeModalEdit("edit", original)}>
                  <span style={{ fontSize: 14 }}>Edit</span>
                </MenuItem>
              )}
              {currentUser.role_code !== Role.Kadiv && (
                <MenuItem
                  onClick={() => {
                    setpdamId(original?.id);
                    setShowModalImportJaringan(true);
                  }}
                >
                  <span style={{ fontSize: 14 }}>Import Jaringan</span>
                </MenuItem>
              )}
              {(currentUser.role_code === Role.Kadiv && (original?.status === 2 || original?.status === 3)) && (
                <MenuItem onClick={() => changeModalDelete(original)}>
                  <span style={{ fontSize: 14 }}>Hapus</span>
                </MenuItem>
              )}
            </DetailButton>
          </>
        );
      },
    },
  ];

  const handleRefresh = () => {
    new Promise((resolve) => {
      const param = {
        draw: 1,
        length: 10,
        id: filter.pdam_id,
        page: page ?? 1,
        search_text: search,
        province: filter.province,
        city: filter.city,
        status: filter.status?.join(","),
      };
      setPending(true);
      dispatch(masterDataPDAMService.get(param, resolve));
    }).then((res) => {
      setDataPDAM(res?.data);
      setTotalData(res?.totalCount);
      setMaxPage(Math.ceil(res?.totalCount / 10));
      setPending(false);
    });
  };

  useEffect(() => {
    handleRefresh();
  }, [page]);

  useEffect(() => {
    setPage((prev) => (prev === 1 ? null : 1));
  }, [filter, search, add, edit]);

  return (
    <>
      <Table
        id="tbl-master-pdam"
        isLoading={pending}
        columns={columns}
        data={dataPDAM}
        pagination={{ page, totalData, maxPage, toggle: setPage }}
      />

      <ModalUserPDAM
        type={typeModal}
        showModal={showModalEdit}
        show={showModalEdit}
        toggle={() => setShowModalEdit(false)}
        refresh={setEdit}
        data={selectedData}
      />

      <ConfirmDelete
        show={showModalDelete}
        toggle={() => setShowModalDelete(false)}
        isDelete
        icon="info"
        confirm={() => handleDelete(selectedData)}
        disabledConfrim={props?.asterDataPDAM?.pending_delete}
      >
        <div>
          Apakah Anda yakin akan menghapus data
          <strong>{" " + selectedData?.name}</strong> ?
        </div>
      </ConfirmDelete>

      <ModalImportJaringan
        show={showModalImportJaringan}
        toggle={() => setShowModalImportJaringan(false)}
        onSubmitted={() => { }}
        pdamId={pdamId}
      />
    </>
  );
});

const mapStateToProps = ({ masterDataPDAM }) => {
  return { masterDataPDAM };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
