import React from "react";
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { connect, useDispatch } from "react-redux";
import Button from "../../../components/buttons/Button";
import DatePicker from "../../../components/forms/DateRangePicker";
import TimePicker from "../../../components/forms/TimePicker";
import * as Yup from "yup";
import DataLogger from "../../../store/actions/data_logger";
import { useFormik } from "formik";
import moment from "moment";


function ModalFilter({ show, toggle, device_id, type, pending_export }) {
  const dispatch = useDispatch();

  const validation = Yup.object().shape({
    start_date: Yup.string().required("Range Tanggal wajib diisi").nullable(),
    start_hour: Yup.string().required("Jam Awal wajib diisi").nullable(),
    end_hour: Yup.string().required("Jam Akhir wajib diisi").nullable(),
  });

  const formik = useFormik({
    initialValues: {
      start_date: null,
      end_date: null,
      start_hour: null,
      end_hour: null,
    },
    validationSchema: validation,
    onSubmit: (values) => {
      const startHour = moment(values.start_hour, "HH:mm").format("HH:mm:00");
const endHour = moment(values.end_hour, "HH:mm").format("HH:mm:00");
      const param = {
        start_date:
        moment(values.start_date).format("YYYY-MM-DD") + " " + startHour,
        end_date: moment(values.end_date).format("YYYY-MM-DD") + " " + endHour,
        device_id,
        type: type.toLowerCase(),
        extensions: "excel",
      };
      const callback = () => {
        toggle();
        formik.resetForm();
      };
      dispatch(DataLogger.exportData(param, callback));
    },
  });

  return (
    <>
      <Modal isOpen={show} modalTransition={{ timeout: 200 }}>
        <ModalHeader className="text-extra-bold pt-4">
          Expor Data Logger{" "}
          <span
            className="close clickable"
            onClick={() => {
              toggle();
              formik.resetForm();
            }}
          >
            &times;
          </span>
        </ModalHeader>
        <form onSubmit={formik.handleSubmit}>
          <ModalBody style={{ margin: "0px 20px" }}>
            <DatePicker
              title="Tanggal"
              name="start_date"
              handlechange={(startDate, endDate) => {
                formik.setFieldValue("start_date", startDate);
                formik.setFieldValue("end_date", endDate);
              }}
              format="DD MMMM YYYY"
              startDate={formik.values.start_date}
              endDate={formik.values.end_date}
              className="mx-auto"
              errors={formik.errors}
              touched={formik.touched}
            />
            <Row className="justify-content-between">
              <Col>
                <TimePicker
                  clearable
                  title="Jam Awal"
                  name="start_hour"
                  value={formik.values.start_hour}
                  onChange={(value) => {
                    formik.setFieldValue("start_hour", value);
                  }}
                  errors={formik.errors}
                  touched={formik.touched}
                />
              </Col>
              <Col>
                <TimePicker
                  clearable
                  title="Jam Akhir"
                  name="end_hour"
                  value={formik.values.end_hour}
                  onChange={(value) => {
                    formik.setFieldValue("end_hour", value);
                  }}
                  errors={formik.errors}
                  touched={formik.touched}
                />
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter style={{ margin: 20 }}>
            <Button
              title="Batal"
              size="md"
              variant="outline secondary"
              rounded={true}
              onClick={() => {
                toggle();
                formik.resetForm();
              }}
            />
            <Button
              title="Expor Excel"
              size="md"
              variant="button-default"
              rounded={true}
              type="submit"
              onClick={formik.handleSubmit}
              disabled={pending_export}
            />
          </ModalFooter>
        </form>
      </Modal>
    </>
  );
}

const mapStateToProps = ({ dataLogger: { pending_export } }) => {
  return { pending_export };
};

const mapDispatchToProps = (dispatch) => {
  return {exportData: (param, callback) => dispatch(DataLogger.exportData(param, callback))};
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalFilter);
