

const Index = ({optClass, children}) => { 
    return (
        <div className={`footer-nav ${optClass}`}>
            {children}
        </div>
    )
}

export default Index;