import React, { useState, useEffect, useRef } from "react";
import LineChart from "../../../components/charts/line";
import Button from "../../../components/buttons/Button";
import DatePicker from "../../../components/forms/DateRangePicker";
import ModalExport from "./ModalExport";

const Index = ({
  device_id,
  type,
  loggerDate,
  setLoggerDate,
  dataLogger,
  pendingDataLoger,
}) => {
  const [showModalExport, setShowModalExport] = useState(false);
  const lineChartRef = useRef(null);
  let label = ["00:00", "01:00", "02:00", "03:00", "04:00",
               "05:00", "06:00", "07:00", "08:00", "09:00",
               "10:00", "11:00", "12:00", "13:00", "14:00",
               "15:00", "16:00", "17:00", "18:00", "19:00",
               "20:00", "21:00", "22:00", "23:00"];

  let dataset = [
    {
      label: "Debit Air (lps)",
      data: dataLogger?.dataDebit,
      fill: false,
      borderColor: "#EDAA21",
      pointBackgroundColor: function (context) {
        var index = context.dataIndex;
        var isWarn = dataLogger?.dataWarn[index];
        return isWarn ? "red" : "#EDAA21";
      },
      pointBorderColor: function (context) {
        var index = context.dataIndex;
        var isWarn = dataLogger?.dataWarn[index];
        return isWarn ? "red" : "#EDAA21";
      },
    },
    {
      label: "Batas Min Debit",
      data: dataLogger?.dataMin,
      fill: false,
      borderColor: "#e3f7e9",
      backgroundColor: "#e3f7e9",
    },
    {
      label: "Batas Maks Debit",
      data: dataLogger?.dataMax,
      fill: "-1",
      borderColor: "#e3f7e9",
      backgroundColor: "#e3f7e9",
    },
    {
      label: "Tekanan Air (m)",
      data: dataLogger?.dataPresure,
      fill: false,
      borderColor: "#04AA59",
      pointBorderColor: "#3BC862",
      pointBackgroundColor: "#04AA59",
      yAxisID: 'tickPressure',
    },
  ];

  return (
    <div className="border-top">
      <ModalExport
        show={showModalExport}
        toggle={() => setShowModalExport(false)}
        device_id={device_id}
        type={type}
      />
      <div className="row justify-content-between align-items-center my-4">
        <div className="col">
          <span className="text-bold subtitle" style={{ opacity: "initial" }}>
            Atur Rentang Waktu
          </span>
        </div>
        <div className="col">
          <div className="d-flex justify-content-end align-items-center">
            <DatePicker
              selectionType="single"
              onChange={(name, value) => {
                setLoggerDate(value);
              }}
              format="DD MMMM YYYY"
              value={loggerDate}
              className="mb-0 mr-2"
            />
            <Button
              title="Ekspor"
              leftIcon="save_alt"
              size="md"
              variant="button-default mx-auto"
              rounded={true}
              onClick={() => setShowModalExport(!showModalExport)}
            />
          </div>
        </div>
      </div>
      {/* {pendingDataLoger && <Loader loading={pendingDataLoger} />} */}

      <LineChart
        options={{
          yTickDisplay: true,
          yGridDisplay: true,
          xGridDisplay: false,
        }}
        pending={pendingDataLoger}
        ref={lineChartRef}
        labels={label}
        datasets={dataset}
        height={400}
        stepSize={2}
      />
    </div>
  );
};

export default Index;
