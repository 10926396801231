import React, {
  useRef,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import { FeatureGroup, CircleMarker, Marker, Popup , LayerGroup } from "react-leaflet";
import useDynamicRefs from "use-dynamic-refs";
import { divIcon } from "leaflet";
import MarkerClusterGroup from "react-leaflet-markercluster";
import L from "leaflet";

let pathOtionsCollection = {};

let lastData = null;

const Index = forwardRef(
  (
    {
      locationData = [],
      locationType = "Input",
      currentData,
      initialCenter,
      initialZoom,
    },
    ref
  ) => {
    const [getMarkerRefs, setMarkerRefs] = useDynamicRefs();
    const isClustered = useRef(false);
    const defaultPathOptions = {
      color: "#919191",
      fillColor: "#919191",
    };
    const highlightPathOptions = {
      color: "#04AA59",
      fillColor: "#04AA59",
    };
    const warnPathOptions = {
      color: "#D10D0D",
      fillColor: "#D10D0D",
    };
    const findDuplicateCoordinates = (locationData) => {
      const duplicates = [];
      const uniqueEntries = [];
    
      locationData.forEach((item, index) => {
        const isDuplicate = locationData
          .slice(index + 1)
          .some(
            (otherItem) =>
              item.latitude === otherItem.latitude &&
              item.longitude === otherItem.longitude
          );
    
        if (isDuplicate) {
          duplicates.push(item);
        } else {
          uniqueEntries.push(item);
        }
      });
    
      return { duplicates, uniqueEntries };
    };
    const { duplicates, uniqueEntries } = findDuplicateCoordinates(locationData);
    
    const handleMarkerClick = (item) => {
    };

    useImperativeHandle(ref, () => ({}));

    useEffect(() => {
      if (currentData) {
        pathOtionsCollection[currentData.id] = currentData.is_warning
          ? warnPathOptions
          : highlightPathOptions;
        if (lastData) {
          pathOtionsCollection[lastData.id] = defaultPathOptions;
        }
        lastData = { ...currentData };
        getMarkerRefs(currentData.id)?.current?.bringToFront();
        
      }
    }, [currentData]);

    return (
      <>
      <div className="marker-cluster-wrapper"> 
      <MarkerClusterGroup spiderfyDistanceMultiplier={7}
      >
      {duplicates.map((item, i) => {
          return (
            <FeatureGroup 
            key={item.id}
            ref={setMarkerRefs(item.id)}
              pathOptions={
                item?.id == currentData?.id
                  ? currentData.is_warning
                    ? warnPathOptions
                    : highlightPathOptions
                  : defaultPathOptions
                  
              }>
            <Marker
                position={[item.latitude, item.longitude]}
                icon={divIcon({
                  html:
                    "<div class='marker-title-head'>" +
                    (locationType == "Input" ? (item.is_output ? "Output" : "Input") : locationType) +
                    " " +
                    item.name.toUpperCase() +
                    "</div>",
                })}
                eventHandlers={{
                  click: () => handleMarkerClick(item),
                }}
              >
                {/* ... */}
              <Popup
                className="popup-marker-detail"
                direction="right"
                offset={[0, -20]}
                opacity={1}
              >
                <div className="tooltip-title">
                  {
                    locationType == "Input" ? 
                    (item.is_output ? "Output " : "Input " ) + item.name  : 
                    locationType + " " + item.name
                  }
                </div>
                {"region_name" in item && (
                  <div className="sub-title">
                    {"Kelurahan " + item.region_name}
                  </div>
                )}
                {"customer_count" in item && (
                  <div className="top-rounded bordered wrapper-col">
                    <div>{"Pelanggan"}</div>
                    <div>{item.customer_count}</div>
                  </div>
                )}
                {"pressure" in item && (
                  <div className="bordered wrapper-col">
                    <div>{"Tekanan"}</div>
                    <div>{item.pressure}</div>
                  </div>
                )}
                {"pressure" in item && (
                  <div className="bottom-rounded bordered wrapper-col">
                    <div>{"Debit"}</div>
                    <div>{item.debit}</div>
                  </div>
                )}</Popup>
                </Marker>
              </FeatureGroup>
          );
        })}
      </MarkerClusterGroup>
      </div>
        {uniqueEntries.map((item, i) => {
          const isDuplicate = duplicates.some(
            (duplicateItem) =>
              item.latitude === duplicateItem.latitude &&
              item.longitude === duplicateItem.longitude
          );
        
          const locationName = isDuplicate
            ? item.name + "..."
            : item.name;
          return (
            <FeatureGroup
              ref={setMarkerRefs(item.id)}
              pathOptions={
                item?.id == currentData?.id
                  ? currentData.is_warning
                    ? warnPathOptions
                    : highlightPathOptions
                  : defaultPathOptions
              }
            >
              <Popup
                className="popup-marker-detail"
                direction="right"
                offset={[0, -20]}
                opacity={1}
              >
                <div className="tooltip-title">
                  {
                    locationType == "Input" ? 
                    (item.is_output ? "Output " : "Input " ) + item.name  : 
                    locationType + " " + item.name
                  }
                </div>
                {"region_name" in item && (
                  <div className="sub-title">
                    {"Kelurahan " + item.region_name}
                  </div>
                )}
                {"customer_count" in item && (
                  <div className="top-rounded bordered wrapper-col">
                    <div>{"Pelanggan"}</div>
                    <div>{item.customer_count}</div>
                  </div>
                )}
                {"pressure" in item && (
                  <div className="bordered wrapper-col">
                    <div>{"Tekanan"}</div>
                    <div>{item.pressure}</div>
                  </div>
                )}
                {"pressure" in item && (
                  <div className="bottom-rounded bordered wrapper-col">
                    <div>{"Debit"}</div>
                    <div>{item.debit}</div>
                  </div>
                )}</Popup>
                <Marker
                position={[item.latitude, item.longitude]}
                icon={divIcon({
                  html:
                    "<div class='marker-title-head'>" +
                    (locationType == "Input" ? (item.is_output ? "Output" : "Input") : locationType) +
                    " " +
                    locationName.toUpperCase() +
                    "</div>",
                })}
              />
              {item?.id == currentData?.id && (
                <CircleMarker
                  center={[item.latitude, item.longitude]}
                  radius={10}
                  fillOpacity={1}
                  weight={8}
                  opacity={0.4}
                />
              )}
              {item?.id != currentData?.id && (
                <CircleMarker
                  center={[item.latitude, item.longitude]}
                  radius={10}
                  fillOpacity={1}
                  weight={8}
                  opacity={0}
                />
              )}
              </FeatureGroup>
          );
        })}
      </>
    );
  }
);

export default Index;
