import {
    GET_SUB_DMA_PENDING,
    GET_SUB_DMA_SUCCESS,
    GET_SUB_DMA_ERROR,
    POST_SUB_DMA_PENDING,
    POST_SUB_DMA_SUCCESS,
    POST_SUB_DMA_ERROR,
    DELETE_SUB_DMA_PENDING,
    DELETE_SUB_DMA_SUCCESS,
    DELETE_SUB_DMA_ERROR,
} from "../../../actions/master_data/subDMA";

const initialState = {
    pending: false,
    error: null,
    data_list: null,
    pending_post: false,
    data_post: null,
    pending_delete: false,
    data_delete: null
};

const sub_dma = (state = initialState, action) => {
    switch (action.type) {
        case GET_SUB_DMA_PENDING:
            return {
                ...state,
                pending: true,
            };
        case GET_SUB_DMA_SUCCESS:
            return {
                ...state,
                pending: false,
                data_list: action.data,
            };
        case GET_SUB_DMA_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error,
            };
        case POST_SUB_DMA_PENDING:
            return {
                ...state,
                pending_post: true,
            };
        case POST_SUB_DMA_SUCCESS:
            return {
                ...state,
                pending_post: false,
                data_post: action.data,
            };
        case POST_SUB_DMA_ERROR:
            return {
                ...state,
                pending_post: false,
                error: action.error,
            };
        case DELETE_SUB_DMA_PENDING:
            return {
                ...state,
                pending_delete: true,
            };
        case DELETE_SUB_DMA_SUCCESS:
            return {
                ...state,
                pending_delete: false,
                data_delete: action.data,
            };
        case DELETE_SUB_DMA_ERROR:
            return {
                ...state,
                pending_delete: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export default sub_dma;
