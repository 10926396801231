import React, { useState, useEffect } from "react";
import MenuItem from "@material-ui/core/MenuItem";
import { connect, useDispatch } from "react-redux";

import Table from "../../../components/table";
import DetailButton from "../../../components/table/detailButton";
import ConfirmModal from "../../../components/modal/ConfirmModal";
import { toastSuccess } from "../../../components/commons/toast";
import { history } from "../../../utils/useHistory";
import { getUser } from "../../../utils/User";
import { Role } from "../../../utils/Constants";

import DMAService from "../../../store/actions/master_data/dma";

const Index = ({ filter, search, pending, add }) => {


  const dispatch = useDispatch();
  const [dataZone, setDataZone] = useState([]);
  const [showModalDelete, setShowModalDelete] = useState(false)
  const [itemData, setItemData] = useState(null)
  const [totalData, setTotalData] = useState(null)
  const [page, setPage] = useState(1)
  const [maxPage, setMaxPage] = useState(1)

  const currentUser = getUser().user;
  const columns = [
    {
      Header: "PDAM",
      accessor: "pdam_name",
    },
    {
      Header: "ZONA",
      accessor: "zone_name",
    },
    {
      Header: "DMA",
      accessor: "name",
    },
    {
      Header: "LOKASI",
      accessor: "region_name",
    },
    {
      Header: "ID SENSOR",
      accessor: "device_id",
    },
    {
      Header: "JML SUB DMA",
      accessor: "sub_dma_count"
    },
    {
      Header: "JML PELANGGAN",
      accessor: "customer_count"
    },
    {
      Header: "",
      accessor: "aksi",
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        return (
          <DetailButton>
            <MenuItem onClick={() => history.push(`/dma/detail/${original?.id}`)}>
              <span style={{ fontSize: 14 }}>Detail</span>
            </MenuItem>
            {
              (currentUser.role_code === Role.Kadiv || currentUser.role_code === Role.Superadmin) && (
                <MenuItem onClick={() => handleShowDelete(original)}>
                  <span style={{ fontSize: 14 }}>Hapus</span>
                </MenuItem>
              )
            }
          </DetailButton>
        );
      },
    },
  ];

  const handleRefresh = () => {
    new Promise((resolve) => {
      const param = {
        draw: 1,
        length: 10,
        page: page ?? 1,
        zone_id: filter?.zone_id ?? '',
        pdam_id: filter?.pdam_id ?? '',
        search_text: search,
        start: null,
      }
      dispatch(DMAService.get(param, resolve));
    }).then(res => {
      setDataZone(res?.data)
      setTotalData(res?.totalCount)
      setMaxPage(Math.ceil(res?.totalCount / 10))
    })
  }

  useEffect(() => {
    handleRefresh();
  }, [page])

  useEffect(() => {
    setPage(prev => prev === 1 ? null : 1)
  }, [filter, search, add])

  const handleShowDelete = (item) => {
    setItemData(item)
    setShowModalDelete(true)
  }

  const handleDelete = () => {
    const callback = () => {
      setPage(prev => prev === 1 ? null : 1);
      setShowModalDelete(false)
      toastSuccess(`Berhasil Hapus Data DMA ${itemData?.name}`)
      handleRefresh();
    }
    dispatch(DMAService.deleted(itemData?.id, callback));
  }


  return (
    <>
      <Table
        id="tbl-master-dma"
        columns={columns}
        isLoading={pending}
        data={dataZone}
        pagination={{ page, totalData, maxPage, toggle: setPage }} />

      <ConfirmModal
        show={showModalDelete}
        toggle={() => setShowModalDelete(false)}
        confirm={handleDelete}
        icon='info'
        isDelete
      >
        <div>
          Apakah Anda yakin akan menghapus
          <strong>{" DMA " + itemData?.name}</strong> ?
        </div>
      </ConfirmModal>
    </>
  );
};

const mapStateToProps = ({ masterDataDMA: { pending } }) => {
  return { pending };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};


export default connect(mapStateToProps, mapDispatchToProps)(Index);
