import { connect } from "react-redux";
import Table from "../../../components/table";

const Index = ({
  state = {
    dataStepTest: [],
    totalData: 0,
    page: 1,
    maxPage: 1,
  },
  setPage,
  pending,
}) => {
  
  const toFixed = (data) => {
    let dataFixed;
    dataFixed = parseFloat(data).toFixed(2).replace(".", ",");
    return dataFixed;
  };

  const numberWithDots = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  };

  const columns = [
    {
      Header: "NO",
      accessor: "no",
    },
    {
      Header: "SUB_DMA",
      accessor: "name",
    },
    {
      Header: "LUAS",
      accessor: "area",
      Cell: ({ row }) => {
        const { original } = row;

        const total = numberWithDots(toFixed(original.area))
        return <>{original.area ? `${total}` : "-"}</>;
      },
    },
    {
      Header: "JUMLAH PELANGGAN",
      accessor: "customer_count",
      Cell: ({ row }) => {
        const { original } = row;
        
        const total = numberWithDots((original.customer_count))
        return <>{original.customer_count ? `${total}` : "-"}</>;
      },
    },
    {
      Header: "PANJANG PIPA",
      accessor: "pipe_length",
      Cell: ({ row }) => {
        const { original } = row;

        const total = numberWithDots(toFixed(original.pipe_length))
        return <>{original.pipe_length ? `${total}` : "-"}</>;
      },
    },
    {
      Header: "KONSUMSI PELANGGAN",
      accessor: "debit",
      Cell: ({ row }) => {
        const { original } = row;

        const total = numberWithDots(toFixed(original.debit))
        return <>{original.debit ? `${total}` : "-"}</>;
      },
    },
  ];

  return (
    <div className="overflow">
      <Table
        id="tbl-data-step-test-support"
        isLoading={pending}
        columns={columns}
        data={state.dataStepTest}
        pagination={{
          page: state.page,
          totalData: state.totalData,
          maxPage: state.maxPage,
          toggle: setPage,
        }}
      />
    </div>
  );
};

const mapStateToProps = ({ stepTestSupport: { pending } }) => {
  return { pending };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
