import React, { useState, useRef, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { Row, Col, Collapse } from "reactstrap";
import moment from "moment";

import { history } from "../../utils/useHistory";
import Loader from "../../components/commons/Loader";
import Card from "../../components/cards/card";
import CardFlush from "../../components/cards/cardFlush";
import HeaderMenu from "../../components/commons/menu/HeaderMenu";
import DetailButton from "../../components/table/detailButton";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "../../components/buttons/Button";
import { getUser } from "../../utils/User";
import { Role } from "../../utils/Constants";
import { base64ToBlob } from "../../utils/Base64ToBlob";
import { toastSuccess, toastError } from "../../components/commons/toast";

import RepairService from "../../store/actions/repair_work";
import FileService from "../../store/actions/file_management";

// Modal
import ConfirmModal from "../../components/modal/ConfirmModal";
import ModalEdit from "./ModalAddEdit";
import ModalRepair from "./ModalRepair";
import ModalImage from "./ModalImage";
import { Divider } from "@material-ui/core";

const DetailRepairWork = ({
  match,
  pending,
  t,
  switchPDAM,
  fileManagement,
  pending_put,
}) => {
  const dispatch = useDispatch();

  const [repair, setRepair] = useState([]);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showModalImage, setShowModalImage] = useState(false);
  const [showModalCancel, setShowModalCancel] = useState(false);
  const [image, setImage] = useState(null);
  const [imageReparation, setImageReparation] = useState(null);
  const [refresh, setRefresh] = useState(null);
  const [collapse, setCollapse] = useState(false);
  const [imageType, setImageType] = useState(null);
  const toggle = () => setCollapse(!collapse);

  const currentUser = getUser().user;

  useEffect(() => {
    new Promise((resolve) => {
      const param = {
        draw: 1,
        length: 1,
        page: 1,
        id: match?.params?.id,
      };
      dispatch(RepairService.get(param, resolve));
    }).then((res) => {
      setRepair(res?.data[0] ?? []);
    });
  }, [refresh]);

  useEffect(() => {
    if (switchPDAM?.id && repair?.pdam?.id) {
      if (switchPDAM?.id !== repair?.pdam?.id) {
        setTimeout(function () {
          history.push({ pathname: "/repair-work-report" });
          toastError(`Data laporan tidak sesuai dengan ${switchPDAM?.name}`);
        }, 1000);
      }
    }
  }, [switchPDAM, repair]);

  useEffect(() => {
    if (repair?.breakage_file_path) {
      new Promise((resolve) => {
        dispatch(
          FileService.show({ file_path: repair?.breakage_file_path }, resolve)
        );
      }).then((res) => {
        const blob = new Blob([res?.data]);
        const url = URL.createObjectURL(blob);
        setImage(url);
      });
    }
  }, [repair?.breakage_file_path, repair]);

  useEffect(() => {
    if (image && repair?.reparation_file_path) {
      new Promise((resolve) => {
        dispatch(
          FileService.show({ file_path: repair?.reparation_file_path }, resolve)
        );
      }).then((res) => {
        const blob = new Blob([res?.data]);
        const url = URL.createObjectURL(blob);
        setImageReparation(url);
      });
    } else {
    }
  }, [image, repair?.reparation_file_path, repair]);

  const handleCancel = () => {
    const today_date = moment().format("YYYY-MM-DD");
    const today_time = moment().format("HH:mm:ss");
    const timezone = moment(new Date()).format("Z");
    const today = today_date + "T" + today_time + ".000" + timezone;

    const callback = () => {
      setShowModalCancel(false);
      setRefresh(!refresh);
      toastSuccess("Berhasil ubah status laporan");
    };

    let formData = new FormData();
    formData.append("id", repair?.id);
    formData.append("cancelled_at", today);

    dispatch(RepairService.put(formData, callback));
  };

  const handleModalImage = (dataImage) => {
    setShowModalImage(true);
    setImage(getObjectURLImg(dataImage));
    // setImageType(type);
  };

  const getObjectURLImg = (base64Data) => {
    const blob = base64ToBlob(base64Data, 'image/jpeg');
    const objectURL = URL.createObjectURL(blob);
    return objectURL;
  };

  const stylingImage = {
    color: "#242726",
    width: "100%",
    padding: "15px 20px",
    justifyContent: "space-between",
    background: "#F3F3F894",
    border: `solid 1px #24272605`,
    display: "flex",
    borderRadius: 8,
    marginBottom: 10,
  };

  const statusProps = (status) => {
    switch (status) {
      case "Baru":
        return {
          title: "Baru",
          color: "#D10D0D",
        };
        break;
      case "Sedang Perbaikan":
        return {
          title: "Perbaikan",
          color: "#EDAA21",
        };
        break;
      case "Selesai":
        return {
          title: "Selesai",
          color: "#04AA59",
        };
        break;
      default:
        return null;
    }
  };

  const detail = () => {
    return (
      <>
        <CardFlush
          list
          label="Waktu Lapor"
          desc={
            <div>
              {repair?.reported_time}
              <span style={{ opacity: "0.5" }}>
                {repair?.argo ? ` - ${repair?.argo}` : ""}
              </span>
            </div>
          }
        />
        <CardFlush list label="Waktu Respon" desc={repair?.response_time} />
        <CardFlush
          list
          label="Mulai Perbaikan"
          desc={repair?.start_repairing_time}
        />
        <CardFlush
          list
          label="Selesai Perbaikan"
          desc={repair?.finish_repairing_time}
        />
        <CardFlush list label="Lama Perbaikan" desc={repair?.repairing_time} />
        <CardFlush
          list
          label="Longitude, Latitude"
          desc={
            repair?.longitude !== null && repair?.latitude !== null
              ? `${repair?.longitude},${repair?.latitude}`
              : "-"
          }
        />
        <CardFlush list label="Kejadian" desc={repair?.kejadian} />
        <CardFlush list label="Diameter Pipa" desc={repair?.diameterPipa} />
        <CardFlush list label="Material Pipa" desc={repair?.materialPipa} />
        <CardFlush
          list
          label="Penganggung Jawab"
          desc={repair?.penanggungJawab}
        />
        <CardFlush
          list
          label="Dokumen Kerusakan & Perbaikan"
          // desc={repair?.ticket_number}
          button={
            <>
              {repair?.attachments?.length ?
                repair?.attachments?.map((item) => (
                <div style={stylingImage}>
                  {item?.data ? (
                    <div className="mini-show">
                      <img
                        src={() => getObjectURLImg(item.data)}
                        alt="Dokumen Perbaikan"
                        className="img mini-show"
                        width="100%"
                        style={{ objectFit: "cover", width: "100%" }}
                      />
                      <p style={{ marginTop: 10, fontSize: 12 }}>
                        {item?.fileName}
                      </p>
                    </div>
                  ) : (
                    <p className="mini-show">Dokumen tidak ditemukan</p>
                  )}

                  <p className="mini-hide" style={{ margin: 0 }}>
                    {item?.fileName}
                  </p>
                  {item?.data && (
                    <p
                      className="text-bold mini-hide"
                      style={{ cursor: "pointer", margin: 0 }}
                      onClick={() => handleModalImage(item?.data)}
                    >
                      Lihat
                    </p>
                  )}
                </div>
              )) : (
                <p className="mini-show" style={{ margin: 0 }}>
                  Dokumen tidak ditemukan
                </p>
              )}
              {/* {repair?.status > 1 && (
                <div style={stylingImage}>
                  {image ? (
                    <div className="mini-show">
                      <img
                        src={imageReparation}
                        alt="Dokumen Perbaikan"
                        className="img mini-show"
                        width="100%"
                        style={{ objectFit: "cover", width: "100%" }}
                      />
                      <p style={{ marginTop: 10, fontSize: 12 }}>
                        Dokumen Perbaikan
                      </p>
                    </div>
                  ) : (
                    <p className="mini-show">Dokumen tidak ditemukan</p>
                  )}
                  <p className="mini-hide" style={{ margin: 0 }}>
                    {repair?.reparation_file_path
                      ? "Dokumen Perbaikan"
                      : "Tidak ada dokumen perbaikan"}
                  </p>
                  {repair?.reparation_file_path && (
                    <p
                      className="text-bold mini-hide"
                      style={{ cursor: "pointer", margin: 0 }}
                      onClick={() => handleModalImage("reparation")}
                    >
                      Lihat
                    </p>
                  )}
                </div>
              )} */}
            </>
          }
        />
      </>
    );
  };

  const handleEdit = (value, callback) => {
    dispatch(RepairService.put(value, callback));
  };

  return (
    <div className="menu-container">
      {pending && <Loader loading={pending} />}
      <div className="grid-header">
        <div className="side-menu">
          <div className="menu-title text-extra-bold">
            <div className="side-menu-back-icon ">
              <span
                className="material-icons clickable"
                onClick={() =>
                  history.push({ pathname: "/repair-work-report" })
                }
              >
                arrow_back
              </span>
            </div>
            Repair Work Detail
          </div>
          <div style={{ padding: "0px 15px 30px" }}>
            <Card>
              <CardFlush
                sideMenu
                title="PDAM"
                // desc={repair?.pdam?.name}
                desc="PDAM Tirtawening Bandung"
                leftIcon="other_houses"
              />
              <CardFlush
                sideMenu
                title="Lokasi"
                desc={repair?.lokasi ?? "-"}
                leftIcon="place"
              />
              {/* <CardFlush
                sideMenu
                title="No. Tiket"
                desc={repair?.ticket_number}
                leftIcon="confirmation_number"
              /> */}
            </Card>
            <Card>
              <CardFlush
                sideMenu
                title="Status"
                desc={repair?.status}
                leftIcon="info"
                iconColor={statusProps(repair?.status)?.color}
              />
            </Card>
            <div className="mini-show">
              <div className="wrapper-detail-card">
                <CardFlush dropdownList label="Rincian" toggle={toggle} />
                <Collapse isOpen={collapse}>
                  <Divider orientation="horizontal" />
                  {detail()}
                </Collapse>
              </div>
            </div>
            {/* {(currentUser.role_code === Role.Superadmin ||
              currentUser.role_code === Role.User_cust_rep) &&
              repair?.status !== 3 &&
              repair?.status !== 2 && (
                <Row>
                  <Col xs={9}>
                    <Button
                      title={repair?.status}
                      type="Button"
                      variant="button primary"
                      rounded
                      style={{
                        width: "100%",
                        textAlign: "center",
                        marginBottom: 25,
                      }}
                      onClick={() => setShowModal(true)}
                      disabled={repair?.status === 2 || repair?.status === 3}
                    />
                  </Col>
                  <Col xs={3}>
                    <DetailButton>
                      {(currentUser.role_code == Role.Superadmin ||
                        currentUser.role_code === Role.User_cust_rep) &&
                        (repair?.status === 0 || repair?.status === 1) && (
                          <MenuItem onClick={() => setShowModalEdit(true)}>
                            <span style={{ fontSize: 14 }}>Edit</span>
                          </MenuItem>
                        )}
                      {(currentUser.role_code == Role.Superadmin ||
                        currentUser.role_code === Role.User_cust_rep) &&
                        (repair?.status === 0 || repair?.status === 1) && (
                          <MenuItem onClick={() => setShowModalCancel(true)}>
                            <span style={{ fontSize: 14 }}>Batalkan</span>
                          </MenuItem>
                        )}
                    </DetailButton>
                  </Col>
                </Row>
              )} */}
          </div>
        </div>
        <div className="mini-hide">
          <HeaderMenu />
          <div className="content-container-side">
            <div className="wrapper-detail-card">{detail()}</div>
          </div>
        </div>
      </div>

      {/* Modal */}

      {/* <ModalEdit
        show={showModalEdit}
        toggle={() => setShowModalEdit(false)}
        confirm={handleEdit}
        data={repair}
        switchPDAM={switchPDAM}
        refresh={() => setRefresh(!refresh)}
      /> */}

      <ModalRepair
        show={showModal}
        toggle={() => setShowModal(false)}
        repair_id={match?.params?.id}
        data={repair}
        confirm={handleEdit}
        refresh={() => setRefresh(!refresh)}
      />

      <ModalImage
        show={showModalImage}
        toggle={() => setShowModalImage(false)}
        image={imageType === "reparation" ? imageReparation : image}
        type={imageType}
      />

      <ConfirmModal
        show={showModalCancel}
        toggle={() => setShowModalCancel(false)}
        confirm={handleCancel}
        icon="info"
        confirmTitle={"Ya"}
        isDelete
        disabledConfrim={pending_put}
        isLoading={pending_put}
      >
        <div>
          Apakah Anda yakin akan membatalkan Repair Work Report
          <strong>{" " + repair?.ticket_number}</strong> ?
        </div>
      </ConfirmModal>
    </div>
  );
};

const mapStateToProps = ({
  repairWork: { pending, pending_put },
  fileManagement,
}) => {
  return { pending, pending_put, fileManagement };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(DetailRepairWork);
