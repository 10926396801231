import React, { useState, useRef, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { Formik, Form } from "formik";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { getUser } from "../../../utils/User";
import { convertGISToGeojson } from "../../../utils/ConvertFile";
import ButtonModal from "../../../components/modal/ButtonModal";
import DynamicButton from "../../../components/forms/DynamicButton";
import ConfirmationModal from "../../../components/modal/ConfirmModal";
import SubDMAService from "../../../store/actions/master_data/subDMA";
import { toastError } from "../../../components/commons/toast";
import LoaderModal from "../../../components/commons/LoaderModal";

function ModalAdd({ show, toggle, refresh, dma_id, pending_post }) {
  const fileRefSHP = useRef();
  const fileRefDBF = useRef();
  const formikRef = useRef();
  const dispatch = useDispatch();

  const [filePropSHP, setFilePropSHP] = useState({
    title: "Import SHP Sub DMA",
    subtitle: "File SHP",
    message_error: false,
  });
  const [filePropDBF, setFilePropDBF] = useState({
    title: "Import DBF Sub DMA",
    subtitle: "File DBF",
    message_error: false,
  });

  const [showModalConfirm, setShowModalConfirm] = useState(false);

  const openFileDialog = (type) => {
    if (type === "DBF") {
      fileRefDBF.current.click();
    } else {
      fileRefSHP.current.click();
    }
  };

  const handleChange = async (selectorFiles, formik, type) => {
    if (selectorFiles) {
      const file = selectorFiles.files[0];
      const file_type = file?.name.split(".");
      const fileProp = {
        title: file?.name,
        subtitle: (Number(file?.size) * 0.001).toFixed(2) + " KB",
        message_error: false,
      };
      if (Number(file.size) < 15728640) {
        if (type === "DBF") {
          if (file_type[1] === "dbf") {
            formik.setFieldValue("dbf", file);
            setFilePropDBF(fileProp);
          } else {
            toastError("Format file harus berekstensi .dbf");
          }
        } else {
          if (file_type[1] === "shp") {
            formik.setFieldValue("shp", file);
            setFilePropSHP(fileProp);
          } else {
            toastError("Format file harus berekstensi .shp");
          }
        }
      } else {
        toastError("Maksimal file yang dapat diimport 15 MB");
      }
    }
  };

  const handleConfirm = () => {
    setFilePropSHP({
      title: "Import SHP Sub DMA",
      subtitle: "File SHP",
      message_error: false,
    });
    setFilePropDBF({
      title: "Import DBF Sub DMA",
      subtitle: "File DBF",
      message_error: false,
    });
    setShowModalConfirm(false);
    toggle(false);
  };

  useEffect(() => {
    const asyncGeoJson = async () => {
      let values = formikRef?.current?.values;
      if (values?.dbf && values?.shp) {
        let geojson = {
          type: "FeatureCollection",
        };
        const promGeojson = await convertGISToGeojson([
          values.shp,
          values.dbf,
        ]).then((res) => {
          geojson.features = res;
        });
  
        formikRef?.current?.setFieldValue("geojson", JSON.stringify(geojson));
      }
    };
    asyncGeoJson();
  }, [formikRef?.current?.values]);

  const handleFile = (formik) => {
    if (!formik.values.geojson) {
      setFilePropSHP({
        title: "Import SHP Sub DMA",
        subtitle: "File SHP",
        message_error: "File wajib diisi",
      });
    }

    if (!formik.values.dbf) {
      setFilePropDBF({
        title: "Import DBF Sub DMA",
        subtitle: "File DBF",
        message_error: "File wajib diisi",
      });
    }
  };

  const currentUser = getUser();

  return (
    <>
      <Modal
        isOpen={show}
        modalTransition={{ timeout: 200 }}
        backdropTransition={{ timeout: 1300 }}
      >
        <LoaderModal show={pending_post} />
        <ModalHeader className="text-extra-bold pt-5">
          {" "}
          Import Sub DMA{" "}
          <span className="close clickable" 
          onClick={() => setShowModalConfirm(true)}
          >
            &times;
          </span>
        </ModalHeader>
        <Formik
          innerRef={formikRef}
          initialValues={{
            dma_id: dma_id,
            geojson: null,
            dbf: null,
          }}
          onSubmit={(values, { setStatus, setSubmitting }) => {
            setStatus();
            if (values.dbf && values.geojson) {
              let formData = new FormData();
              formData.append("geojson", values.geojson);
              formData.append("dbf", values.dbf);
              formData.append("dma_id", values.dma_id);

              const callback = () => {
                toggle();
                setFilePropSHP({
                  title: "Import SHP Sub DMA",
                  subtitle: "File SHP",
                  message_error: false,
                });
                setFilePropDBF({
                  title: "Import DBF Sub DMA",
                  subtitle: "File DBF",
                  message_error: false,
                });
                refresh(values);
                setSubmitting(false);
              };
              dispatch(SubDMAService.post(formData, callback));
            }

            if (!values.dbf) {
              setFilePropDBF({
                title: "Import DBF Sub DMA",
                subtitle: "File DBF",
                message_error: "File DBF wajib diisi",
              });
            }

            if (!values.geojson) {
              setFilePropSHP({
                title: "Import SHP Sub DMA",
                subtitle: "File SHP",
                message_error: "File SHP wajib diisi",
              });
            }
          }}
        >
          {(props) => (
            <Form style={{ marginTop: 30 }}>
              <ModalBody style={{ margin: "0px 20px" }}>
                <DynamicButton
                  titleLeft={filePropSHP?.title}
                  subtitleLeft={filePropSHP?.subtitle}
                  iconLeft="insert_drive_file"
                  toggle={() => openFileDialog("SHP")}
                  error={filePropSHP?.message_error}
                />

                <DynamicButton
                  titleLeft={filePropDBF?.title}
                  subtitleLeft={filePropDBF?.subtitle}
                  iconLeft="insert_drive_file"
                  toggle={() => openFileDialog("DBF")}
                  error={filePropDBF?.message_error}
                />

                <input
                  type="file"
                  ref={fileRefSHP}
                  style={{ display: "none" }}
                  onChange={(e) => handleChange(e.target, props, "SHP")}
                  accept=".shp"
                />
                <input
                  type="file"
                  ref={fileRefDBF}
                  style={{ display: "none" }}
                  onChange={(e) => handleChange(e.target, props, "DBF")}
                  accept=".dbf"
                />
              </ModalBody>
              <ModalFooter>
                <ButtonModal
                  toggle={() => setShowModalConfirm(true)}
                  confirmTitle="Import"
                  confirm={() => handleFile(props)}
                  // isLoading={pending_post}
                  // disabledConfrim={pending_post}
                />
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </Modal>

      <ConfirmationModal
        isDelete
        show={showModalConfirm}
        toggle={() => setShowModalConfirm(false)}
        confirm={handleConfirm}
        cancelTitle="Tidak"
        confirmTitle="Ya"
        message="Yakin ingin membatalkan import data?"
        icon="info"
      />
    </>
  );
}

const mapStateToProps = ({ masterDataSubDMA: { pending_post } }) => {
  return { pending_post };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalAdd);
