import React, { useState, useRef, useEffect, forwardRef, useImperativeHandle } from "react";
import { connect, useDispatch } from "react-redux";
import { FeatureGroup, Pane, Tooltip, Marker, GeoJSON } from 'react-leaflet'
import icon from '../../assets/images/marker/repair.svg';
import useDynamicRefs from 'use-dynamic-refs';
import L, { divIcon, Leaflet } from "leaflet";
import Button from "../../components/buttons/Button";
import CardFlush from '../../components/cards/cardFlush';

import { reproject, crss } from 'reproject';
import gjv from "geojson-validation";

// services
import FileService from "../../store/actions/file_management";

let pathOtionsCollection = {};

let lastData = null;

const Index = forwardRef(({ dataRepair = [], detailRepair }, ref) => {

    const dispatch = useDispatch();
    const [getMarkerRefs, setMarkerRefs] = useDynamicRefs();
    const defaultPathOptions = {
        color: '#919191',
        fillColor: '#919191',
    }
    const highlightPathOptions = {
        color: '#04AA59',
        fillColor: '#04AA59',
    }
    const warnPathOptions = {
        color: '#D10D0D',
        fillColor: '#D10D0D',
    }

    const [dmaGeoJson, setDmaGeoJson] = useState(null);

    useImperativeHandle(ref, () => ({

    }));

    useEffect(() => {
        if (detailRepair) {
            if (Object.keys(detailRepair).length !== 0) {

                pathOtionsCollection[detailRepair?.id] = warnPathOptions;
                if (lastData) {
                    pathOtionsCollection[lastData.id] = defaultPathOptions;
                }
                lastData = { ...detailRepair }
                getMarkerRefs(detailRepair?.id)?.current.bringToFront()
            }
        }
    }, [detailRepair]);

    const getGeoJson = (path, callback) => {
        new Promise((resolve) => {
            const param = {
                file_path: path,
            };
            dispatch(FileService.post(param, resolve));
        }).then((res) => {
            callback(res.data);
        });
    };

    function onEachFeature(feature, layer) {

        if (feature.properties && Object.keys(feature.properties).length > 0) {
            const popupOptions = {
                // minWidth: 250,
                // maxWidth: 500,
                className: "popup-poly-detail"
            };
            let popUpContentKey = ``;
            let popUpContentVal = ``;
            Object.keys(feature.properties).forEach(i => {
                popUpContentKey += `<div className='row pt-1 pb-1'><div className='col'>` + i + `</div></div>`;
                popUpContentVal += `<div className='row pt-1 pb-1'><div className='col'>` + feature.properties[i] + `</div></div>`;
            });
            let popUpContentBase = `<div className='row'>
        <div className='col-sm-auto'>`+ popUpContentKey + `</div>
        <div className='col'>`+ popUpContentVal + `</div>
      </div>`;
            layer.bindPopup(popUpContentBase, popupOptions);
        }
    }

    const TooltipInfo = ({ item }) => {
        return (
            <Tooltip className="popup-marker-detail" direction='right' offset={[20, 0]} opacity={1}>
                {/* <div className="text-bold" style={{ fontSize: 18, marginBottom: 10 }}>{"#" + item?.ticket_number}</div> */}
                <div className="sub-title" style={{ fontSize: 16, marginBottom: 10 }}>{item?.lokasi}</div>
                <div
                    className="danger"
                    style={{
                        color: "#FFF",
                        fontSize: 18,
                        borderRadius: '20px',
                        padding: '10px 15px'
                    }}
                >
                    <span className="material-icons-round left-icon">error</span>
                    <p style={{ margin: 0 }}>Baru</p>
                </div>
                <div style={{ borderRadius: 8, border: 'solid 2px #5A5A5A1A', display: 'block', marginTop: 20 }}>
                    <CardFlush
                        mapTooltip
                        title='Waktu Lapor'
                        desc={(<div>{item?.reported_time}<span style={{ opacity: 0.5 }}>{item?.argo ? ` — ${item?.argo}` : ''}</span></div>)}
                    />
                    <CardFlush
                        mapTooltip
                        last
                        title='Posisi'
                        desc={item?.longitude + ',' + item?.latitude}
                    />
                </div>
            </Tooltip>
        )
    }

    return (
        <>
            {dataRepair?.map((item, i) => {
                return <FeatureGroup
                    ref={setMarkerRefs(item.id)}
                    pathOptions={pathOtionsCollection[item?.id] ? pathOtionsCollection[item?.id] : warnPathOptions}>
                    <TooltipInfo item={item} />
                    <Marker
                        position={[item?.latitude, item?.longitude]}
                        icon={divIcon({
                            html: "<div className='marker-wrapper'><img src='" + icon + "'/><div/><div className='marker-title marker-title-bottom text-bold' style=''>" + item?.lokasi?.toUpperCase() + "</div>"
                        })}
                    />
                </FeatureGroup>
            })}
            {detailRepair && Object.keys(detailRepair).length !== 0 && (
                <>
                    {dmaGeoJson !== null && (
                        <Pane name="paneDma" style={{ zIndex: 200 }}>
                            <GeoJSON data={
                                reproject(dmaGeoJson, "ESRI:54004", "EPSG:4326", crss)
                            }
                                color='#ff0000'
                                fillColor='#ff0000'
                                weight={2}
                                onEachFeature={onEachFeature}
                            />
                        </Pane>
                    )}
                </>
            )}
        </>
    );
});

export default Index;
