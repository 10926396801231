import { useState, useEffect } from "react";

const Index = ({ placeholder, width, style, toggle, optClass, refresh, disabled=false }) => {

    const [show, setShow] = useState(false);
    const [value, setValue] = useState('');

    function handleInputChange(event) {
        setValue(event.target.value)
    }

    function handleOnKeyDown(event) {
        if (event.key === 'Enter') {
            if (toggle) {
                toggle(value)
            }
        }
    }

    useEffect(() => {
        setValue('')
        toggle('')
    }, [refresh])


    return (
        <>
            <div className={`search-container mini-hide ${optClass}`} style={{ ...style }}>
                <input
                    className={`input-search`}
                    placeholder={placeholder}
                    onChange={handleInputChange}
                    value={value}
                    onKeyDown={handleOnKeyDown}
                    style={{
                        width: show ? width ? width : "200px" : "0",
                        visibility: show ? "visible" : "hidden",
                    }} />
                <div
                    className="button search-toggle mini-hide"
                    style={{
                        cursor: disabled ? "not-allowed" : "pointer",
                        position: "absolute",
                        right: "0px",
                        paddingRight: "10px",
                    }}
                    onClick={() => {
                        if(disabled) return;
                        setShow(!show);
                    }}
                >
                    <span className="material-icons-round icon-only mini-hide">search</span>
                </div>
            </div>
            <div
                className="mini-show"
                style={{ position: 'relative', width: '98%' }}

            >
                <input
                    className="input-search-mini"
                    style={{ borderRadius: "32px" }}
                    placeholder={placeholder}
                    onChange={handleInputChange}
                    onKeyDown={handleOnKeyDown}
                />

                <div
                    className="button search-toggle"
                    style={{
                        cursor: "pointer",
                        position: "absolute",
                        marginLeft: "-90px",
                    }}
                >
                    <span className="material-icons-round icon-only">search</span>
                </div>
            </div>
        </>
    );
};

export default Index;
