import { history } from "../../../../utils/History";
import { actionPending, actionSuccess, actionError } from "../../actionTypes";

import { toastSuccess, toastError } from "../../../../components/commons/toast";

import API from "../../API";
import { handleResponse } from "../../HandleRespone";

// ACTION TYPES TO DISPATCH: METHOD_URL_TYPES
export const GET_ZONE_PENDING = "GET_ZONE_PENDING";
export const GET_ZONE_SUCCESS = "GET_ZONE_SUCCESS";
export const GET_ZONE_ERROR = "GET_ZONE_ERROR";
export const POST_ZONE_PENDING = "POST_ZONE_PENDING";
export const POST_ZONE_SUCCESS = "POST_ZONE_SUCCESS";
export const POST_ZONE_ERROR = "POST_ZONE_ERROR";
export const DELETE_ZONE_PENDING = "DELETE_ZONE_PENDING";
export const DELETE_ZONE_SUCCESS = "DELETE_ZONE_SUCCESS";
export const DELETE_ZONE_ERROR = "DELETE_ZONE_ERROR";

// URL: URL_{URL}
const ZONE_URL = `v1/master_data/zone`;

const get = (param, resolve, reject, callback) => (dispatch) => {
  dispatch(actionPending(GET_ZONE_PENDING));
  API.get(ZONE_URL, { params: param })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(GET_ZONE_SUCCESS, res));
      if (resolve) {
        const records_total = res.data.total;
        let data = res.data.data.map((item, i) => ({
          ...item,
          no: i + 1 + (param?.page - 1) * param?.length,
          longlat:
            item?.longitude && item?.latitude
              ? `${item?.longitude}, ${item?.latitude}`
              : "-",
          pdam_name: item?.pdam?.name ?? "-",
          input_name: Array.isArray(item?.parent_input)
            ? item.parent_input.map((input) => input.name).join(", ")
            : item?.input?.name ?? "-",
          diameter_label: item?.diameter + " mm",
        }));
        resolve({
          data: data,
          page: param?.page - 1,
          totalCount: records_total,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(GET_ZONE_ERROR));
      let not_logout = handleResponse(err?.response);
      if (not_logout) {
        let message = err?.response?.data?.message;
        if (message?.includes("java")) {
          message = "Data tidak ditemukan";
        }
        toastError(message);
      }
    });
};

const post = (param, callback) => (dispatch) => {
  dispatch(actionPending(POST_ZONE_PENDING));
  API.post(ZONE_URL + "/import", param)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(POST_ZONE_SUCCESS, res));
      toastSuccess("Berhasil import data zona");
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(POST_ZONE_ERROR));
      toastError(err?.response?.data?.message);
    });
};

const deleted = (param, callback) => (dispatch) => {
  dispatch(actionPending(DELETE_ZONE_PENDING));
  API.delete(ZONE_URL + `?id=${param}`)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(DELETE_ZONE_SUCCESS, res));
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(DELETE_ZONE_ERROR));
      toastError(err?.response?.data?.message);
    });
};

const zone = {
  get,
  post,
  deleted,
};
export default zone;
