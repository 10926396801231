import { useState, useEffect } from "react"
import HeaderMenu from "../../components/commons/menu/HeaderMenu"
import Button from "../../components/buttons/Button"
import SearchButton from "../../components/buttons/SearchButton"
import MenuItem from "@material-ui/core/MenuItem"
import { connect, useDispatch } from "react-redux"
import Select from "react-select"

import Table from "../../components/table"
import TableStatus from "../../components/table/status"
import DetailButton from "../../components/table/detailButton"
import ConfirmModal from "../../components/modal/ConfirmModal"
import { toastSuccess } from "../../components/commons/toast"
import { history } from "../../utils/useHistory"
import { getUser } from "../../utils/User"
import { Role } from "../../utils/Constants"

import HydraulicService from "../../store/actions/hydraulic_model"

import ModalAdd from "./ModalAdd"
import ModalCopy from "./ModalCopy"

const Index = ({ pending, add, switchPDAM }) => {
  const dispatch = useDispatch()
  const [dataZone, setDataZone] = useState([])
  const [itemData, setItemData] = useState(null)
  const [totalData, setTotalData] = useState(null)
  const [page, setPage] = useState(1)
  const [maxPage, setMaxPage] = useState(1)
  const [search, setSearch] = useState("")
  const [status, setStatus] = useState("")
  const [pdam, setPDAM] = useState(null)
  const [typeAdd, setTypeAdd] = useState("blank")
  const [showModalAdd, setShowModalAdd] = useState(false)
  const [showModalDelete, setShowModalDelete] = useState(false)
  const [showModalCopy, setShowModalCopy] = useState(false)

  const currentUser = getUser().user
  const columns = [
    {
      Header: "NO.",
      accessor: "no",
    },
    {
      Header: "SIMULASI",
      accessor: "name",
    },
    {
      Header: "PDAM",
      accessor: "pdam_name",
    },
    {
      Header: "WAKTU DIBUAT",
      accessor: "created_time",
    },
    {
      Header: "TERAKHIR DIUBAH",
      accessor: "updated_time",
    },
    {
      Header: "USER",
      accessor: "user_updater_name",
    },
    {
      Header: "STATUS SIMULASI",
      accessor: "status",
      Cell: ({ row }) => {
        const { original } = row
        const { status } = original

        return <TableStatus status={status === 0 ? "Baru" : status === 1 ? "Berhasil" : status === 2 ? "Gagal" : "-"} color={status === 0 ? "warning" : status === 1 ? "primary" : status === 2 ? "danger" : "-"} />
      },
    },
    {
      Header: "",
      accessor: "aksi",
      Cell: ({ row }) => {
        const original = row ? row?.original : []
        return (
          <DetailButton>
            <MenuItem onClick={() => history.push(`/hydraulic-model/simulation/detail/${original?.id}`)}>
              <span style={{ fontSize: 14 }}>Detail</span>
            </MenuItem>
            {currentUser.role_code !== Role?.Viewer && (
              <MenuItem onClick={() => handleShowCopy(original)}>
                <span style={{ fontSize: 14 }}>Salin</span>
              </MenuItem>
            )}
            {currentUser.role_code !== Role?.Viewer && (
              <MenuItem onClick={() => handleExport(original)}>
                <span style={{ fontSize: 14 }}>Export</span>
              </MenuItem>
            )}
            {currentUser.id === original?.created_by && (
              <MenuItem onClick={() => handleShowDelete(original)}>
                <span style={{ fontSize: 14 }}>Hapus</span>
              </MenuItem>
            )}
          </DetailButton>
        )
      },
    },
  ]

  const handleRefresh = () => {
    new Promise((resolve) => {
      const param = {
        draw: 1,
        length: 10,
        page: page === 0 ? 1 : page ?? 1,
        search_text: search,
        status: status ?? "",
        start: null,
        pdam_id: pdam ?? "",
      }
      dispatch(HydraulicService.get(param, resolve))
    }).then((res) => {
      setDataZone(res?.data)
      setTotalData(res?.totalCount)
      setMaxPage(Math.ceil(res?.totalCount / 10))
    })
  }

  const handleCopy = (param) => {
    const callback = () => {
      setPage((prev) => (prev === 1 ? null : 1))
      setShowModalCopy(false)
      toastSuccess(`Berhasil menyalin ${itemData?.name} menjadi ${param?.name}`)
      handleRefresh()
    }
    dispatch(HydraulicService.copy(param, callback))
  }

  const handleExport = (param) => {
    if (param) {
      let value = {
        id: param?.id,
        name: param?.name,
      }

      dispatch(HydraulicService.download(value))
    }
  }

  useEffect(() => {
    handleRefresh()
  }, [page])

  useEffect(() => {
    setPage((prev) => (prev === 1 ? null : 1))
  }, [search, status, add, pdam])

  useEffect(() => {
    if (switchPDAM !== null) {
      setPDAM(switchPDAM?.id)
    }
  }, [switchPDAM])

  const handleShowDelete = (item) => {
    setItemData(item)
    setShowModalDelete(true)
  }

  const handleShowCopy = (item) => {
    setItemData(item)
    setShowModalCopy(true)
  }

  const handleDelete = () => {
    const callback = () => {
      setPage((prev) => (prev === 1 ? null : 1))
      setShowModalDelete(false)
      toastSuccess(`Berhasil hapus data simulasi ${itemData?.name}`)
      handleRefresh()
    }
    dispatch(HydraulicService.deleted(itemData?.id, callback))
  }

  const handleShowModal = (type) => {
    setShowModalAdd(true)
    setTypeAdd(type)
  }

  const handleSearch = (value) => {
    setSearch(value)
  }

  const handleStatus = (value) => {
    setStatus(value?.value)
  }

  const customSelect = {
    control: (base, state) => ({
      ...base,
      background: "#fffff",
      borderRadius: "34px",
      marginLeft: "10px",
      marginRight: "10px",
      padding: "1px",
      height: "48px",
      minWidth: "200px",
      textAlign: "center",
      alignItems: "center",
      border: "1px solid #d8d8d8",
      boxShadow: null,
      "&:hover": {
        borderColor: "#d8d8d8",
      },
    }),
    option: (options) => ({
      ...options,
      textAlign: "left",
    }),
  }

  return (
    <div className="menu-container">
      <HeaderMenu title="Hydraulic Model">
        <SearchButton placeholder="Masukkan Simulasi" toggle={handleSearch} optClass={"mr-3"} />

        <div className="nav-separator mr-3"></div>

        <Select
          isClearable={false}
          isSearchable={false}
          options={[
            { label: "Baru", value: 0 },
            { label: "Berhasil", value: 1 },
            { label: "Gagal", value: 2 },
            { label: "Semua Status", value: "" },
          ]}
          styles={customSelect}
          placeholder="Status"
          className="mr-2"
          onChange={(value) => handleStatus(value)}
        />
        <DetailButton button={<Button title="Tambah Simulasi" leftIcon="add" size="md" variant="btn-lg button-default" rounded={true} onClick={""} disabled={currentUser.role_code === Role.Kadiv || currentUser.role_code === Role.Viewer} />}>
          <MenuItem onClick={() => handleShowModal("blank")}>
            <span style={{ fontSize: 14 }}>Blank</span>
          </MenuItem>
          <MenuItem onClick={() => handleShowModal("pdam")}>
            <span style={{ fontSize: 14 }}>Dari PDAM</span>
          </MenuItem>
          <MenuItem onClick={() => handleShowModal("import")}>
            <span style={{ fontSize: 14 }}>Import</span>
          </MenuItem>
        </DetailButton>
      </HeaderMenu>

      <div className="content-container-side">
        <Table id="tbl-data-hydraulic-model" columns={columns} data={dataZone} isLoading={pending} pagination={{ page, totalData, maxPage, toggle: setPage }} />
      </div>

      <ConfirmModal show={showModalDelete} toggle={() => setShowModalDelete(false)} confirm={handleDelete} icon="info" isDelete disabledConfrim={pending}>
        <div>
          Apakah Anda yakin akan menghapus
          <strong>{" simulasi " + itemData?.name}</strong> ?
        </div>
      </ConfirmModal>

      <ModalAdd show={showModalAdd} toggle={() => setShowModalAdd(false)} type={typeAdd} />

      <ModalCopy show={showModalCopy} toggle={() => setShowModalCopy(false)} data={itemData} confirm={(value) => handleCopy(value)} />
    </div>
  )
}

const mapStateToProps = ({ hydraulicModel: { pending } }) => {
  return { pending }
}

const mapDispatchToProps = (dispatch) => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(Index)
