import React from "react";
import L from "leaflet";
import { EditControl } from "react-leaflet-draw";

import { pipelineProps, inputProps } from "../../utils/Constants";

import reservoar from "../../assets/images/marker/reservoar.svg";
import pump from "../../assets/images/marker/pump.svg";
import katup from "../../assets/images/marker/katup.svg";
import tank from "../../assets/images/marker/tank.svg";
import junction from "../../assets/images/marker/junction.svg";

class HydraulicDrawMap extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      junctionGeoJson: null,
      inputGeoJson: null,
      customerGeoJson: null,
      lineGeoJson: null,
      vertices: [],
    };

    this.handleCreate = this.handleCreate.bind(this);
    this.handleVertex = this.handleVertex.bind(this);
    this.handleDrawStop = this.handleDrawStop.bind(this);
    this.handleCreatePipe = this.handleCreatePipe.bind(this);
  }

  handleVertex(e) {
    const {
      inputGeoJson,
      customerGeoJson,
      zoneGeoJson,
      dmaGeoJson,
      junctionItem,
      setJunctionItem,
      polylineDrawer,
    } = this.props;

    let layers = e.layers.getLayers();
    let coordinateEnd = layers[layers.length - 1].getLatLng();
    let verticesItem = null;
    let features = [];

    if (inputGeoJson && inputGeoJson.features.length > 0) {
      features = features.concat(inputGeoJson.features);
    }
    if (customerGeoJson && customerGeoJson.features.length > 0) {
      features = features.concat(customerGeoJson.features);
    }
    if (zoneGeoJson && zoneGeoJson.features.length > 0) {
      features = features.concat(zoneGeoJson.features);
    }
    if (dmaGeoJson && dmaGeoJson.features.length > 0) {
      features = features.concat(dmaGeoJson.features);
    }

    let startNode = junctionItem?.start?.geometry?.coordinates
      ? L.latLng(junctionItem?.start?.geometry?.coordinates)
      : null;

    if (polylineDrawer?._markers?.length > 1) {
      features?.map((item) => {
        let coordinate = item?.geometry?.coordinates
          ? L.latLng(item?.geometry?.coordinates)
          : null;
        if (startNode.toString() !== coordinate.toString()) {
          let CheckDistance = coordinateEnd.distanceTo(coordinate);
          if (CheckDistance < 5) {
            if (junctionItem && setJunctionItem) {
              setJunctionItem({ start: junctionItem?.start, end: item });
            }
            verticesItem = item;
          }
        }
      });
    }

    polylineDrawer._finishShape = function () {
      if (verticesItem) {
        let coordinate = verticesItem?.geometry?.coordinates
          ? L.latLng(verticesItem?.geometry?.coordinates)
          : null;
        polylineDrawer.deleteLastVertex();
        polylineDrawer.addVertex(coordinate);
        polylineDrawer._fireCreatedEvent();
        polylineDrawer.disable();
      }
    };
  }

  handleDrawStop(e) {}

  handleCreatePipe(props, junctionItem, pipeLength, name, type) {
    let properties = props;

    properties = pipelineProps();
    properties.PIPE_ID = name;
    properties.NAME = name;
    properties.PANJANG = pipeLength.toFixed(2);
    properties.ROUGHNESS = 100;
    properties.TYPE = type ? type : null;
    if (junctionItem) {
      if (junctionItem?.start) {
        properties.NODE1 =
          junctionItem.start?.properties?.NAME ??
          junctionItem.start?.properties?.NAMA ??
          junctionItem.start?.properties?.Name ??
          "";
      }
      if (junctionItem?.end) {
        properties.NODE2 =
          junctionItem.end?.properties?.NAME ??
          junctionItem.end?.properties?.NAMA ??
          junctionItem.end?.properties?.Name ??
          "";
      }
    }
    return properties;
  }

  async handleCreate(e) {
    const {
      icon,
      baseName,
      editableFG,
      getElevation,
      junctionItem,
      setIconCursor,
      handleSetLine,
      handleInitial,
      calculateLength,
      handleUpdateGeojson,
    } = this.props;
    const { customerGeoJson, lineGeoJson, inputGeoJson } = this.props;
    setIconCursor(null);
    if (e) {
      let layer = e.layer;
      let longlatArray = layer?.getLatLngs
        ? layer?.getLatLngs()
        : [layer?.getLatLng()];
      let feature = (layer.feature = layer.feature || {});
      let geometry = (feature.geometry = feature.geometry || {});
      let properties = (feature.properties = feature.properties || {});
      feature.type = feature.type || "Feature";

      let typeGeojson = null;
      let coordinates = [];
      let pipeLength = 0;
      let previousPoint = null;
      longlatArray?.map((item, index) => {
        if (previousPoint) {
          let point = [item?.lat, item?.lng];
          let length = calculateLength(previousPoint, point);
          pipeLength = Number(pipeLength) + Number(length);
        }
        previousPoint = [item?.lat, item?.lng];

        if (longlatArray?.length > 1) {
          coordinates = [...coordinates, [item?.lat, item?.lng]];
        } else {
          coordinates = [item?.lat, item?.lng];
        }
      });

      let elevation = { data: null };
      let dataGeojson = null;

      // if (longlatArray[0] && (icon === reservoar || icon === junction)) {
      //   elevation = await getElevation(longlatArray[0]);
      // }

      if (icon) {
        switch (icon) {
          case reservoar:
            properties = inputProps();
            typeGeojson = "Point";
            let junctionName =
              inputGeoJson?.features?.length > 0
                ? baseName + "R" + (inputGeoJson?.features?.length + 1)
                : `${baseName}R1`;
            properties.NAME = junctionName;
            dataGeojson = inputGeoJson;
            break;
          case pump:
            typeGeojson = "LineString";
            dataGeojson = lineGeoJson;
            let pumpName =
              lineGeoJson?.features?.length > 0
                ? "PUMP" + (lineGeoJson?.features?.length + 1)
                : "PUMP1";
            properties = this.handleCreatePipe(
              properties,
              junctionItem,
              pipeLength,
              pumpName,
              "Pump"
            );
            break;
          case katup:
            typeGeojson = "LineString";
            dataGeojson = lineGeoJson;
            let valveName =
              lineGeoJson?.features?.length > 0
                ? "VALVE" + (lineGeoJson?.features?.length + 1)
                : "VALVE1";
            properties = this.handleCreatePipe(
              properties,
              junctionItem,
              pipeLength,
              valveName,
              "PRV"
            );
            break;
          case tank:
            properties = inputProps();
            typeGeojson = "Point";
            let tankName =
              inputGeoJson?.features?.length > 0
                ? baseName + "R" + (inputGeoJson?.features?.length + 1)
                : `${baseName}R1`;
            properties.NAME = tankName;
            properties.TYPE = "Tank";
            dataGeojson = inputGeoJson;
            break;
          case junction:
            properties = inputProps();
            typeGeojson = "Point";
            let customerName =
              customerGeoJson?.features?.length > 0
                ? baseName + "C" + (customerGeoJson?.features?.length + 1)
                : `${baseName}C1`;
            properties.NAME = customerName;
            dataGeojson = customerGeoJson;
            break;
          default:
            return null;
        }
      } else {
        typeGeojson = "LineString";
        dataGeojson = lineGeoJson;
        let pipeName =
          lineGeoJson?.features?.length > 0
            ? "PIPA" + (lineGeoJson?.features?.length + 1)
            : "PIPA1";
        properties = this.handleCreatePipe(
          properties,
          junctionItem,
          pipeLength,
          pipeName,
          null
        );
      }

      if (icon === reservoar || icon === junction || icon === tank) {
        let lineData = lineGeoJson;
        let lineFeatures = lineData?.features;
        if (lineFeatures && lineFeatures.length > 0) {
          lineFeatures.map((item, index) => {
            let lineCoordinate = item.geometry.coordinates;
            let starNode = lineCoordinate[0];
            let endNode = lineCoordinate[lineCoordinate.length - 1];
            if (
              calculateLength(coordinates, starNode) < 4 &&
              lineFeatures[index].properties.NODE1 === null
            ) {
              lineFeatures[index].properties.NODE1 = properties.NAME;
              coordinates = starNode;
            }
            if (
              calculateLength(coordinates, endNode) < 4 &&
              lineFeatures[index].properties.NODE2 === null
            ) {
              lineFeatures[index].properties.NODE2 = properties.NAME;
              coordinates = endNode;
            }
          });
          if (handleSetLine) {
            lineData.features = lineFeatures;
            handleSetLine(lineData);
          }
          if (handleInitial) {
            handleInitial(coordinates);
          }
        }
      }

      geometry.coordinates = coordinates;
      geometry.type = typeGeojson;
      properties.ELEVATION = elevation.data ?? 0;
      properties.clean = true;
      feature.properties = properties;

      let geojson = null;
      if (dataGeojson) {
        geojson = dataGeojson;
        let features = geojson.features;
        features = [...features, feature];
        geojson.features = features;
      } else {
        geojson = {
          type: "FeatureCollection",
        };
        geojson.features = [feature];
      }

      if (handleUpdateGeojson && editableFG) {
        if (!icon) {
          let lastLineGeojson = lineGeoJson
            ? lineGeoJson.features[lineGeoJson.features.length - 1]
            : null;
          if (
            lastLineGeojson
              ? lastLineGeojson?.properties.NAME !== feature.properties.NAME
              : true
          ) {
            let pipeName = "PIPA-New" + (lineGeoJson?.features?.length + 1);
            properties.NAME = pipeName;
          }
        }
        handleUpdateGeojson(geojson);
        editableFG?.removeLayer(layer);
        setIconCursor("cursor");
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.lineGeoJson !== this.props.lineGeoJson) {
      let lineGeoJson = this.props.lineGeoJson;
      this.setState({ lineGeoJson });
    }

    if (prevProps.inputGeoJson !== this.props.inputGeoJson) {
      let inputGeoJson = this.props.inputGeoJson;
      this.setState({ inputGeoJson });
    }

    if (prevProps.customerGeoJson !== this.props.customerGeoJson) {
      let customerGeoJson = this.props.customerGeoJson;
      this.setState({ customerGeoJson });
    }
  }

  render() {
    return (
      <EditControl
        draw={{
          polygon: {
            tooltip: {
              end: "Click a marker to finish line.",
            },
          },
        }}
        edit={{
          remove: false,
          edit: false,
        }}
        position="bottomright"
        onCreated={this.handleCreate}
        onDrawVertex={this.handleVertex}
        onDrawStop={this.handleDrawStop}
      />
    );
  }
}

export default HydraulicDrawMap;
