import { actionPending, actionSuccess, actionError } from "../actionTypes";

import { toastSuccess, toastError } from "../../../components/commons/toast";

import API from "../API";
import { handleResponse } from "../HandleRespone";

// ACTION TYPES TO DISPATCH: METHOD_URL_TYPES
export const GET_WATER_BALANCE_PENDING = "GET_WATER_BALANCE_PENDING";
export const GET_WATER_BALANCE_SUCCESS = "GET_WATER_BALANCE_SUCCESS";
export const GET_WATER_BALANCE_ERROR = "GET_WATER_BALANCE_ERROR";
export const PREPARE_FORM_WATER_BALANCE_PENDING =
  "PREPARE_FORM_WATER_BALANCE_PENDING";
export const PREPARE_FORM_WATER_BALANCE_SUCCESS =
  "PREPARE_FORM_WATER_BALANCE_SUCCESS";
export const PREPARE_FORM_WATER_BALANCE_ERROR =
  "PREPARE_FORM_WATER_BALANCE_ERROR";
export const DIAGRAMS_WATER_BALANCE_PENDING = "DIAGRAMS_WATER_BALANCE_PENDING";
export const DIAGRAMS_WATER_BALANCE_SUCCESS = "DIAGRAMS_WATER_BALANCE_SUCCESS";
export const DIAGRAMS_WATER_BALANCE_ERROR = "DIAGRAMS_WATER_BALANCE_ERROR";
export const PUT_WATER_BALANCE_PENDING = "PUT_WATER_BALANCE_PENDING";
export const PUT_WATER_BALANCE_SUCCESS = "PUT_WATER_BALANCE_SUCCESS";
export const PUT_WATER_BALANCE_ERROR = "PUT_WATER_BALANCE_ERROR";
export const DELETE_WATER_BALANCE_PENDING = "DELETE_WATER_BALANCE_PENDING";
export const DELETE_WATER_BALANCE_SUCCESS = "DELETE_WATER_BALANCE_SUCCESS";
export const DELETE_WATER_BALANCE_ERROR = "DELETE_WATER_BALANCE_ERROR";
export const EXPORT_WATER_BALANCE_PENDING = "EXPORT_WATER_BALANCE_PENDING";
export const EXPORT_WATER_BALANCE_SUCCESS = "EXPORT_WATER_BALANCE_SUCCESS";
export const EXPORT_WATER_BALANCE_ERROR = "EXPORT_WATER_BALANCE_ERROR";

// URL: URL_{URL}
const WATER_BALANCE_URL = `v1/water_balance`;
const PREPARE_FORM_WATER_BALANCE_URL = `v1/water_balance/prepare_form`;
const DIAGRAMS_WATER_BALANCE_URL = `v1/water_balance/diagrams`;

//fixed3 Data
const toFixed = (data) => {
  let dataFixed;

  if (Number.isInteger(data)) {
    dataFixed = data;
  } else {
    dataFixed = parseFloat(data).toFixed(3).replace(".", ",");
  }
  return dataFixed;
};

const numberWithDots = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
};

const get = (param, resolve, callback) => (dispatch) => {
  dispatch(actionPending(GET_WATER_BALANCE_PENDING));
  API.get(WATER_BALANCE_URL, { params: param })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(GET_WATER_BALANCE_SUCCESS, res));
      if (resolve) {
        const records_total = res.data.total;
        let data = res.data.data.map((item, i) => ({
          ...item,
          no: `${
            param?.page === 0
              ? ""
              : param?.page - 1 === 0
              ? ""
              : param?.page - 1
          } ${i + 1}`,
          dma_name: item?.dma?.name ?? "-",
          region_name: item?.dma?.region_name ?? "-",
          nrw_percentage: `${item?.nrw_percentage} %` ?? "-",
          production_total: numberWithDots(toFixed(item?.production_total)),
          water_losses: numberWithDots(toFixed(item?.water_losses)),
          customers_consumption: numberWithDots(toFixed(item?.customers_consumption))
        }));
        resolve({
          data: data,
          page: param?.page - 1,
          totalCount: records_total,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(GET_WATER_BALANCE_ERROR));
      let not_logout = handleResponse(err?.response);
      if (not_logout) {
        let message = err?.response?.data?.message;
        if (message?.includes("java")) {
          message = "Data tidak ditemukan";
        }
        toastError(message);
      }
    });
};

const prepare_form = (param, resolve, callback) => (dispatch) => {
  dispatch(actionPending(PREPARE_FORM_WATER_BALANCE_PENDING));
  API.get(PREPARE_FORM_WATER_BALANCE_URL, { params: param })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(PREPARE_FORM_WATER_BALANCE_SUCCESS, res));
      if (resolve) {
        const records_total = res.data.total;
        let data = res.data.data.map((item, i) => ({
          ...item,
          no: i + 1 + (param?.page - 1) * param?.length,
        }));
        resolve({
          data: data,
          page: param?.page - 1,
          totalCount: records_total,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(PREPARE_FORM_WATER_BALANCE_ERROR));
      let message = err?.response?.data?.message;
      if (message?.includes("java")) {
        message = "Data tidak ditemukan";
      }
      toastError(message);
    });
};
const diagrams = (param, resolve, callback) => (dispatch) => {
  dispatch(actionPending(DIAGRAMS_WATER_BALANCE_PENDING));
  API.get(DIAGRAMS_WATER_BALANCE_URL, { params: param })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(DIAGRAMS_WATER_BALANCE_SUCCESS, res));
      if (resolve) {
        const records_total = res.data.total;
        let data = res.data.data.map((item, i) => ({
          ...item,
        }));
        resolve({
          data: data,
          page: param?.page - 1,
          totalCount: records_total,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(DIAGRAMS_WATER_BALANCE_ERROR));
      let message = err?.response?.data?.message;
      if (message?.includes("java")) {
        message = "Data tidak ditemukan";
      }
      toastError(message);
    });
};

const put = (param, callback) => (dispatch) => {
  dispatch(actionPending(PUT_WATER_BALANCE_PENDING));
  API.put(WATER_BALANCE_URL, param)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(PUT_WATER_BALANCE_SUCCESS, res));
      toastSuccess("Berhasil update data diagram");
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      let message = err?.response?.data?.message;
      if (message?.includes("java")) {
        message = "Format file tidak sesuai";
      }
      toastError(message);
      dispatch(actionError(PUT_WATER_BALANCE_ERROR));
    });
};

const exportWater = (param, callback) => (dispatch) => {
  dispatch(actionPending(EXPORT_WATER_BALANCE_PENDING));
  API.get(WATER_BALANCE_URL + "/export", {
    params: param,
    responseType: "blob",
  })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(EXPORT_WATER_BALANCE_SUCCESS, res));
      if (callback) {
        callback();
      }
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `Water Balance Export ${param?.month}-${param?.year}.xlsx`
      );
      document.body.appendChild(link);
      link.click();

      return res;
    })
    .catch((err) => {
      let message = err?.response?.data?.message;
      if (message?.includes("java")) {
        message = "Data tidak ditemukan";
      }
      toastError(message);
      dispatch(actionError(EXPORT_WATER_BALANCE_ERROR));
    });
};

const water_balance = {
  get,
  prepare_form,
  diagrams,
  put,
  exportWater,
};
export default water_balance;
