import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { connect, useDispatch } from "react-redux";
import { getUser } from "../../../utils/User";
import Select2 from "../../../components/forms/Select2";
import ButtonModal from "../../../components/modal/ButtonModal";
import DynamicButton from "../../../components/forms/DynamicButton";
import Checkbox from "../../../components/forms/Checkbox";

import Region from "../../../store/actions/master_data/region";

function ModalFilter({ show, toggle, filter, toggleFilter, isDelete }) {
  const search = filter?.search;
  const currentUser = getUser();
  const dispatch = useDispatch();
  const [provinceList, setProvinceList] = useState([]);
  const [cityList, setCityList] = useState([]);

  const handleReset = (formik) => {
    formik.setFieldValue("status", "");
    formik.setFieldValue("province", "");
    formik.setFieldValue("city", "");
    toggleFilter({ status: [], province: "", city: "", search });
    toggle();
  };

  useEffect(() => {
    const param = {
      draw: 1,
      length: 999,
      page: 1,
    };

    new Promise((resolve) => {
      dispatch(Region.get(param, resolve));
    }).then((res) => {
      let province_list = [],
        city_list = [];
      province_list = res?.data.map((prov) => {
        prov.city.forEach((city) => {
          city_list.push(city);
        });
        return prov.province;
      });
      setProvinceList(province_list);
      setCityList(city_list);
    });
  }, []);

  return (
    <>
      <Modal isOpen={show} modalTransition={{ timeout: 200 }}>
        <ModalHeader className="text-extra-bold pt-5">
          {" "}
          Filter{" "}
          <span className="close clickable" onClick={toggle}>
            &times;
          </span>
        </ModalHeader>
        <ModalBody style={{ margin: "0px 20px" }}>
          <DynamicButton
            titleLeft="Pilih data yang ingin difilter"
            iconLeft="info"
          />
          <Formik
            initialValues={{
              status: filter?.status ?? "",
              province: filter?.province ?? "",
              city: filter?.city ?? "",
            }}
            enableReinitialize
            onSubmit={(values, { setStatus, setSubmitting }) => {
              setStatus();
              toggleFilter({
                status: values?.status ?? "",
                province: values?.province ?? "",
                city: values?.city ?? "",
                search,
              });
              toggle();
            }}
          >
            {(props) => (
              <Form style={{ marginTop: 30 }}>
                <Select2
                  title="Provinsi"
                  name="province"
                  options={provinceList.map((item) => ({
                    value: item,
                    label: item,
                  }))}
                  errors={props.errors}
                  touched={props.touched}
                  value={props.values?.province}
                  placeholder="Pilih data Provinsi"
                  onChange={(name, value) => {
                    props.setFieldValue("province", value);
                  }}
                  onBlur={props.setFieldTouched}
                />
                <Select2
                  title="Kab/Kota"
                  name="city"
                  options={cityList.map((item) => ({
                    value: item,
                    label: item,
                  }))}
                  errors={props.errors}
                  touched={props.touched}
                  value={props.values?.city}
                  placeholder="Pilih data Kab/Kota"
                  onChange={(name, value) => {
                    props.setFieldValue("city", value);
                  }}
                  onBlur={props.setFieldTouched}
                />
                <div className="form-group">
                  <label className="form-label">Status</label>
                </div>
                <Checkbox
                  name="status"
                  label="Subscribe"
                  checked={props?.values?.status}
                  value={1}
                  onChange={props?.handleChange}
                />
                <Checkbox
                  name="status"
                  label="Unsubscribe"
                  checked={props?.values?.status}
                  value={2}
                  onChange={props?.handleChange}
                  custom
                />
                <div style={{ marginTop: 50, marginBottom: 30 }}>
                  <ButtonModal
                    toggle={toggle}
                    reset={() => handleReset(props)}
                    deleted={isDelete}
                    confirmTitle="Terapkan"
                  />
                </div>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </Modal>
    </>
  );
}

const mapStateToProps = ({ masterDataRegion }) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalFilter);
