import {
  GET_DASHBOARD_RESUME_AVERAGE_LOGGER_PENDING,
  GET_DASHBOARD_RESUME_AVERAGE_LOGGER_SUCCESS,
  GET_DASHBOARD_RESUME_AVERAGE_LOGGER_ERROR,
  GET_DASHBOARD_RESUME_TOTAL_PDAM_PENDING,
  GET_DASHBOARD_RESUME_TOTAL_PDAM_SUCCESS,
  GET_DASHBOARD_RESUME_TOTAL_PDAM_ERROR,
  GET_DASHBOARD_RESUME_AVERAGE_TOTAL_PENDING,
  GET_DASHBOARD_RESUME_AVERAGE_TOTAL_SUCCESS,
  GET_DASHBOARD_RESUME_AVERAGE_TOTAL_ERROR,
  GET_DASHBOARD_RESUME_REPAIR_WORK_PENDING,
  GET_DASHBOARD_RESUME_REPAIR_WORK_SUCCESS,
  GET_DASHBOARD_RESUME_REPAIR_WORK_ERROR,
  GET_DASHBOARD_RESUME_PDAM_PENDING,
  GET_DASHBOARD_RESUME_PDAM_SUCCESS,
  GET_DASHBOARD_RESUME_PDAM_ERROR,
  GET_DASHBOARD_NRW_MAX_PENDING,
  GET_DASHBOARD_NRW_MAX_SUCCESS,
  GET_DASHBOARD_NRW_MAX_ERROR,
  GET_DASHBOARD_NRW_MIN_PENDING,
  GET_DASHBOARD_NRW_MIN_SUCCESS,
  GET_DASHBOARD_NRW_MIN_ERROR,
} from "../../actions/dashboard";

const initialState = {
  pending: false,
  error: null,
  data: null,
};

export const dashboardAverageLogger = (state = initialState, action) => {
  switch (action.type) {
    case GET_DASHBOARD_RESUME_AVERAGE_LOGGER_PENDING:
      return {
        ...state,
        pending: true,
      };
    case GET_DASHBOARD_RESUME_AVERAGE_LOGGER_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.data,
      };
    case GET_DASHBOARD_RESUME_AVERAGE_LOGGER_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export const dashboardTotalPDAM = (state = initialState, action) => {
  switch (action.type) {
    case GET_DASHBOARD_RESUME_TOTAL_PDAM_PENDING:
      return {
        ...state,
        pending: true,
      };
    case GET_DASHBOARD_RESUME_TOTAL_PDAM_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.data,
      };
    case GET_DASHBOARD_RESUME_TOTAL_PDAM_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export const dashboardAverageTotal = (state = initialState, action) => {
  switch (action.type) {
    case GET_DASHBOARD_RESUME_AVERAGE_TOTAL_PENDING:
      return {
        ...state,
        pending: true,
      };
    case GET_DASHBOARD_RESUME_AVERAGE_TOTAL_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.data,
      };
    case GET_DASHBOARD_RESUME_AVERAGE_TOTAL_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export const dashboardRepairWork = (state = initialState, action) => {
  switch (action.type) {
    case GET_DASHBOARD_RESUME_REPAIR_WORK_PENDING:
      return {
        ...state,
        pending: true,
      };
    case GET_DASHBOARD_RESUME_REPAIR_WORK_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.data,
      };
    case GET_DASHBOARD_RESUME_REPAIR_WORK_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export const dashboardPDAM = (state = initialState, action) => {
  switch (action.type) {
    case GET_DASHBOARD_RESUME_PDAM_PENDING:
      return {
        ...state,
        pending: true,
      };
    case GET_DASHBOARD_RESUME_PDAM_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.data,
      };
    case GET_DASHBOARD_RESUME_PDAM_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export const dashboardNRWMax = (state = initialState, action) => {
  switch (action.type) {
    case GET_DASHBOARD_NRW_MAX_PENDING:
      return {
        ...state,
        pending: true,
      };
    case GET_DASHBOARD_NRW_MAX_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.data,
      };
    case GET_DASHBOARD_NRW_MAX_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export const dashboardNRWMin = (state = initialState, action) => {
  switch (action.type) {
    case GET_DASHBOARD_NRW_MIN_PENDING:
      return {
        ...state,
        pending: true,
      };
    case GET_DASHBOARD_NRW_MIN_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.data,
      };
    case GET_DASHBOARD_NRW_MIN_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
};
