import { useRef } from "react";
import { Marker } from "react-leaflet";
import { divIcon } from "leaflet";

const MarkerComponent = ({
  key,
  type,
  icon,
  item,
  index,
  color,
  isShow,
  draggable,
  coordinate,
  handleDrag,
  handlerClick,
}) => {
  const markerRef = useRef();

  return (
    <Marker
      key={key}
      ref={markerRef}
      draggable={draggable}
      position={coordinate}
      eventHandlers={{
        click: () => {
          handlerClick();
        },
        dragend: () => {
          if (markerRef.current) {
            handleDrag(markerRef.current.getLatLng(), item, index, type);
          }
        },
      }}
      icon={divIcon({
        html: icon
          ? `<div className='marker-wrapper ${
              isShow ? "aquamarine" : ""
            }' ><img src="${icon}"/></div>`
          : `<div className='marker-wrapper ${
              isShow ? "aquamarine" : ""
            }' style="background-color:${color}; width:9px;height: 9px;border-radius: 50%;"><div/>`,
      })}
    />
  );
};

export default MarkerComponent;
