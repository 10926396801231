import { Button } from "reactstrap"
import CircularProgress from "@material-ui/core/CircularProgress"

const Index = ({ onClick, leftIcon, rightIcon, iconOnly, title, variant, rounded, style, type, size, disabled, isLoading }) => {
  return (
    <Button
      className={`text-bold ${size === "sm" || size === "md" ? "btn" : "button"} 
      mr-2 ${variant} ${rounded ? "rounded-button" : ""}`}
      style={style}
      type={type ?? "button"}
      onClick={onClick}
      disabled={disabled || false}
    >
      {isLoading && <CircularProgress color="inherit" size={20} style={{ marginRight: 20 }} />}
      {leftIcon && <span className="material-icons-round left-icon">{leftIcon}</span>}

      {iconOnly && <span className="material-icons-round icon-only">{iconOnly}</span>}

      {title ?? ""}

      {rightIcon && <span className="material-icons-round right-icon">{rightIcon}</span>}
    </Button>
  )
}

export default Index
