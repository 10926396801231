import React from "react"
import { createRoot } from "react-dom/client"
import App from "./App"

// router
import { Provider } from "react-redux"
import { PersistGate } from "redux-persist/integration/react"
import { store, persistor } from "./store"
import { Router } from "react-router"
import { history } from "./utils/History"

const rootElement = document.getElementById("root")
const root = createRoot(rootElement)

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Router history={history}>
        <App />
      </Router>
    </PersistGate>
  </Provider>
)
