import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { getUser } from "../../../utils/User";
import Table from "../../../components/table";
import ili from "../../../store/actions/infrastructure_leakage_index";
import moment from "moment";

const Index = ({ search_text, filter, sort }) => {
  const dispatch = useDispatch();
  const [dataILI, setDataILI] = useState([]);
  const [totalData, setTotalData] = useState(0);
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const currentUser = getUser().user;
  const [pending, setPending] = useState(false);

  const toFixed = (data) => {
    let dataFixed;
    dataFixed = parseFloat(data).toFixed(2).replace(".", ",");
    return dataFixed;
  };

  const numberWithDots = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  };

  const getData = () => {
    new Promise((resolve) => {
      const param = {
        length: 10,
        page: page && page !== 0 ? page : 1,
        pdam_id: currentUser?.pdam?.id ?? filter.pdam_id ?? "",
        id: null,
        start_date:
          filter?.start_date !== ""
            ? moment(filter?.start_date).format("YYYY-MM-DD")
            : "",
        end_date:
          filter?.end_date !== ""
            ? moment(filter?.end_date).format("YYYY-MM-DD")
            : "",
        search_text: search_text,
      };
      setPending(true);
      dispatch(ili.get(param, resolve));
    }).then((res) => {
      setDataILI(res?.data);
      setTotalData(res?.totalCount);
      setMaxPage(Math.ceil(res?.totalCount / 10));
      setPending(false);
    });
  };

  useEffect(() => {
    getData();
  }, [page]);

  useEffect(() => {
    setPage((prev) => (prev === 1 ? null : 1));
  }, [search_text, filter, sort, currentUser?.pdam?.id]);

  const columns = [
    {
      Header: "NO.",
      accessor: "no",
      Cell: ({ row }) => {
        const { original } = row;

        return <>{original.no}</>;
      },
    },
    {
      Header: "PDAM",
      accessor: "pdam_name",
    },
    {
      Header: "DMA",
      accessor: "dma_name",
    },
    {
      Header: "TGL KINI",
      Cell: ({ row }) => {
        const { original } = row;
        return (
          <>
            {original.last_updated_log
              ? moment(original.last_updated_log).format("DD/MM/YYYY")
              : " - "}
          </>
        );
      },
    },
    {
      Header: "TGL LALU",
      Cell: ({ row }) => {
        const { original } = row;
        return (
          <>
            {original.stdlalu_date
              ? moment(original.stdlalu_date).format("DD/MM/YYYY")
              : " - "}
          </>
        );
      },
    },
    {
      Header: () => (
        <>
          ST KINI (m<sup>3</sup>)
        </>
      ),
      accessor: "stdkini",
      Cell: ({ row }) => {
        const { original } = row;

        const stdkini = numberWithDots(toFixed(original.stdkini))
        return <>{original.stdkini ? `${stdkini}` : "-"}</>;
      },
    },
    {
      Header: () => (
        <>
          ST LALU (m<sup>3</sup>)
        </>
      ),
      accessor: "stdlalu",
      Cell: ({ row }) => {
        const { original } = row;

        const stdlalu = numberWithDots(toFixed(original.stdlalu))
        return <>{original.stdlalu ? `${stdlalu}` : "-"}</>;
      },
    },
    {
      Header: "SELISIH",
      accessor: "difference",
      Cell: ({ row }) => {
        const { original } = row;

        const difference = numberWithDots(toFixed(original.difference))
        return <>{original.difference ? `${difference}` : "-"}</>;
      },
    },
    {
      Header: "NRW",
      Cell: ({ row }) => {
        const { original } = row;
        return (
          <>
            {original.nrw_percentage !== null &&
            original.nrw_percentage !== undefined
              ? original.nrw_percentage + " %"
              : " - "}
          </>
        );
      },
    },
    {
      Header: "ILI",
      accessor: "ili",
      Cell: ({ row }) => {
        const { original } = row;

        const ili = (original.ili).toString().replace(".", ",");
        return <>{original.ili ? `${ili}` : "-"}</>;
      },
    },
  ];

  return (
    <div className="">
      {/* <div className="content-container-side"> */}
      <div className="overflow">
        <Table
          id="tbl-data-ili"
          columns={columns}
          isLoading={pending}
          data={dataILI}
          pagination={{ page, totalData, maxPage, toggle: setPage }}
        />
      </div>
      {/* </div> */}
    </div>
  );
};

export default Index;
