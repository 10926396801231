import SyncLoader from "react-spinners/SyncLoader";

const Index = ({ show, message }) => {
  return (
    <>
      {show && (
        <div className="loading-modal">
          <div className="text-center loading-content">
            <SyncLoader color="#FFF" loading />
            <div className="message">
              {message ?? "Mohon tunggu, data sedang diproses"}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Index;
