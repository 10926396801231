import React, { useState, useRef, useEffect, forwardRef, useImperativeHandle } from "react";
import { connect, useDispatch } from "react-redux";
import { FeatureGroup, Pane, Tooltip, Popup, CircleMarker, Marker, GeoJSON } from 'react-leaflet'
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import useDynamicRefs from 'use-dynamic-refs';
import { divIcon, Leaflet } from "leaflet";

import { reproject, crss } from 'reproject';
import gjv from "geojson-validation";
import { getUser } from "../../../utils/User";

// services
import FileService from "../../../store/actions/file_management";

const Index = forwardRef(({ activeILI = null, ILI = [] }, ref) => {

  const dispatch = useDispatch();
  const markerRefs = useRef({});
  const [getMarkerRefs, setMarkerRefs] = useDynamicRefs();
  const defaultPathOptions = {
    color: '#919191',
    fillColor: '#919191',
  }
  const highlightPathOptions = {
    color: '#04AA59',
    fillColor: '#04AA59',
  }
  const warnPathOptions = {
    color: '#D10D0D',
    fillColor: '#D10D0D',
  }

  const currentUser = getUser().user;
  const isSwitch = currentUser?.pdam?.id ? true : false;

  const [subDMAGeoJson, setSubDMAGeoJson] = useState(null);
  const [dmaGeoJson, setDmaGeoJson] = useState(null);
  const [zoneGeoJson, setZoneGeoJson] = useState(null);

  const toFixed = (data) => {
    let dataFixed;
    dataFixed = parseFloat(data).toFixed(2).replace(".", ",");
    return dataFixed;
  };

  const numberWithDots = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  };

  useImperativeHandle(ref, () => ({

  }));

  useEffect(() => {

    if (activeILI !== null) {

      // get GeoJsons
      // ------------
      if (activeILI.geojson_path) {
        getGeoJson(activeILI.geojson_path, function (data) {
          if (gjv.valid(data)) {
            setDmaGeoJson(data);
          } else {
            setDmaGeoJson(null);
          }
        })
      } else {
        setDmaGeoJson(null);
      }
      if (activeILI.subdma_geojson_path) {
        getGeoJson(activeILI.subdma_geojson_path, function (data) {
          if (gjv.valid(data)) {
            setSubDMAGeoJson(data);
          } else {
            setSubDMAGeoJson(null);
          }
        })
      } else {
        setSubDMAGeoJson(null);
      }
      if (activeILI?.zone?.dma_geojson_path) {
        getGeoJson(activeILI?.zone?.dma_geojson_path, function (data) {
          if (gjv.valid(data)) {
            setZoneGeoJson(data);
          } else {
            setZoneGeoJson(null);
          }
        })
      } else {
        setZoneGeoJson(null);
      }
      // ------------------------------------------------------------------------------------

      // bring current selected DMA ILI to front of map
      getMarkerRefs(activeILI.id)?.current.bringToFront()
    }
  }, [activeILI]);


  const getGeoJson = (path, callback) => {
    new Promise((resolve) => {
      const param = {
        file_path: path,
      };
      dispatch(FileService.post(param, resolve));
    }).then((res) => {
      callback(res.data);
    });
  };

  function onEachFeature(feature, layer) {

    if (feature.properties && Object.keys(feature.properties).length > 0) {
      const popupOptions = {
        // minWidth: 250,
        // maxWidth: 500,
        className: "popup-poly-detail"
      };
      let popUpContentKey = ``;
      let popUpContentVal = ``;
      Object.keys(feature.properties).forEach(i => {
        popUpContentKey += `<div className='row pt-1 pb-1'><div className='col'>` + i + `</div></div>`;
        popUpContentVal += `<div className='row pt-1 pb-1'><div className='col'>` + feature.properties[i] + `</div></div>`;
      });
      let popUpContentBase = `<div className='row'>
        <div className='col-sm-auto'>`+ popUpContentKey + `</div>
        <div className='col'>`+ popUpContentVal + `</div>
      </div>`;
      layer.bindPopup(popUpContentBase, popupOptions);
    }
  }

  return (
    <>
      {ILI.map((item, i) => {
        return <>
          <FeatureGroup
            ref={setMarkerRefs(item.id)}
            pathOptions={item.id == activeILI?.id ? highlightPathOptions : defaultPathOptions}>
            <Popup className="popup-marker-detail" direction='right' offset={[0, -20]} opacity={1}>
              <div className="tooltip-title">{'DMA ' + item.dma_name}</div>
              {"dma_region_name" in item && (
                <div className="sub-title">{"Kelurahan " + item.dma_region_name}</div>
              )}
              {"ili" in item && (
                <div className="top-rounded bordered wrapper-col"><div>{'ILI'}</div><div>{numberWithDots(toFixed(item.ili))}</div></div>
              )}
              {"customer_count" in item && (
                <div className="bordered wrapper-col"><div>{'Jml Pelanggan'}</div><div>{numberWithDots(item.customer_count)}</div></div>
              )}
              {"stdkini" in item && (
                <div className="bordered wrapper-col"><div>{'ST Kini'}</div><div>{numberWithDots(toFixed(item.stdkini))}</div></div>
              )}
              {"stdlalu" in item && (
                <div className="bordered wrapper-col"><div>{'ST Lalu'}</div><div>{numberWithDots(toFixed(item.stdlalu))}</div></div>
              )}
            </Popup>
            {item.id == activeILI?.id && (
              <Marker
                position={[item.latitude, item.longitude]}
                icon={divIcon({
                  html: "<div className='marker-title'>DMA " + activeILI?.dma_name?.toUpperCase() + "</div>"
                })}
              />
            )}
            {/* <Marker
                  position={[item.latitude, item.longitude]}
                  icon={divIcon({
                  html: "<div className='marker-title-right'>"+item?.ili+"</div>"
                  })}
              /> */}
            {item.id == activeILI?.id && (

              <CircleMarker center={[item.latitude, item.longitude]} radius={10} fillOpacity={1} weight={8} opacity={0.4} />
            )}
            {item.id != activeILI?.id && (

              <CircleMarker center={[item.latitude, item.longitude]} radius={10} fillOpacity={1} weight={8} opacity={0} />
            )}
          </FeatureGroup>
        </>
      })}
      {activeILI !== null && (
        <>
          {zoneGeoJson !== null && (
            <Pane name="paneZone" style={{ zIndex: 200 }}>
              <GeoJSON data={
                reproject(zoneGeoJson, "ESRI:54004", "EPSG:4326", crss)
                // zoneGeoJson
              }
                color='#ff0000'
                fillColor='#ff0000'
                weight={2}
                onEachFeature={onEachFeature}
              />
            </Pane>
          )}
          {dmaGeoJson !== null && (
            <Pane name="paneDma" style={{ zIndex: 200 }}>
              <GeoJSON data={
                reproject(dmaGeoJson, "ESRI:54004", "EPSG:4326", crss)
                // dmaGeoJson
              }
                color='#ff0000'
                fillColor='#ff0000'
                weight={2}
                onEachFeature={onEachFeature}
              />
            </Pane>
          )}
          {subDMAGeoJson !== null && (
            <Pane name="paneSubDma" style={{ zIndex: 300 }}>
              <GeoJSON data={
                reproject(subDMAGeoJson, "ESRI:54004", "EPSG:4326", crss)
                // subDMAGeoJson
              }
                color='#00a6ff'
                fillColor='#00a6ff'
                weight={2}
                onEachFeature={onEachFeature}
              />
            </Pane>
          )}

          {/* <FeatureGroup 
              ref={setMarkerRefs(activeILI.id)}
              pathOptions={pathOtionsCollection[activeILI?.id] ? pathOtionsCollection[activeILI?.id] : defaultPathOptions}>
              <Popup className="popup-marker-detail" direction='right' offset={[0, -20]} opacity={1}>
                  <div className="tooltip-title">{'DMA '+activeILI.dma_name}</div>
                  {"dma_region_name" in activeILI && (
                  <div className="sub-title">{"Kelurahan "+activeILI.dma_region_name}</div>
                  )}
                  {"ili" in activeILI && (
                  <div className="top-rounded bordered wrapper-col"><div>{'ILI'}</div><div>{activeILI.ili}</div></div>
                  )}
                  {"customer_count" in activeILI && (
                  <div className="bordered wrapper-col"><div>{'Jml Pelanggan'}</div><div>{activeILI.customer_count}</div></div>
                  )}
                  {"stdkini" in activeILI && (
                  <div className="bordered wrapper-col"><div>{'ST Kini'}</div><div>{activeILI.stdkini}</div></div>
                  )}
                  {"stdlalu" in activeILI && (
                  <div className="bottom-rounded bordered wrapper-col"><div>{'ST Lalu'}</div><div>{activeILI.stdlalu}</div></div>
                  )}
                  <div className="bottom-rounded bordered wrapper-col"><div>{'coords'}</div><div>{
                    reproject(
                      {
                        "type": "Point",
                        "coordinates": [activeILI.latitude, activeILI.longitude]
                      }
                      , "ESRI:54004", "EPSG:4326", crss
                    ).coordinates
                  }</div></div>
              </Popup>
              <Marker
                  position={reproject(
                    {
                      "type": "Point",
                      "coordinates": [activeILI.latitude, activeILI.longitude]
                    }
                    , "ESRI:54004", "EPSG:4326", crss
                  ).coordinates}
                  icon={divIcon({
                  html: "<div className='marker-title'>DMA "+activeILI?.dma_name?.toUpperCase()+"</div>"
                  })}
              />
              <CircleMarker center={reproject(
                {
                  "type": "Point",
                  "coordinates": [activeILI.latitude, activeILI.longitude]
                }
                , "ESRI:54004", "EPSG:4326", crss
              ).coordinates} radius={10} fillOpacity={1} weight={8} opacity={0.4}/>
            </FeatureGroup> */}
        </>
      )}
    </>
  );
});

const mapStateToProps = ({ }) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
