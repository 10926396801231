import { useState } from "react";
import { withRouter, NavLink } from "react-router-dom";
import MenuItem from "../../commons/sidebar/MenuItem";
import Profile from "./Profile";
import Notification from "./Notification";
import { SidebarItem } from "./SidebarItem";
import { getUser } from "../../../utils/User";
import "./sidebar.scss";

const Index = ({ visible, close, t, togglePDAM }) => {
  const currentUser = getUser();
  const [activeMenu, setActiveMenu] = useState("");

  return (
    <div className={visible ? "sidebar sidebar-mini" : "sidebar sidebar-full"}>
      <div
        className={visible ? "backdrop back-show" : "backdrop"}
        onClick={close}
      />
      <div className="sidebar-title">
        <label style={{ fontSize: 20 }}>
          <Profile togglePDAM={togglePDAM} />
        </label>
      </div>
      <Notification />
      <div className="sidebar-item-wrapper">
        {SidebarItem.map((item, index) => {
          if (item.roles?.indexOf(currentUser.user.role_code) !== -1) {
            return (
              <NavLink to={item.url} key={index}>
                <MenuItem
                  title={item.title}
                  onClick={() => setActiveMenu(item.title)}
                  icon={item.icon}
                />
              </NavLink>
            );
          }
        })}
      </div>
    </div>
  );
};

export default withRouter(Index);
