import React, { Component } from "react";
import { ErrorMessage } from "formik";
import Moment from "moment";

import "./forms.scss";

export default class Input extends Component {

  render() {
    return (
      <div className="form-group">
        {this.props?.title && (
          <label className="form-label">{this.props?.title}</label>
        )}
        <div className="input-wrapper" style={{ width: "100%" }}>
          {this.props.leftIcon && (
            <span className="material-icons-round left-icon-input">
              {this.props.leftIcon}
            </span>
          )}

          <input
            {...this.props}
            className={
              "form-control form-input " +
              this.props?.variant +
              (this.props?.disabled ? "input-disabled " : "") +
              (this.props?.errors && this.props?.touched
                ? this.props?.errors[this.props?.name] &&
                  this.props?.touched[this.props?.name]
                  ? " is-invalid"
                  : ""
                : "")
            }
            value={this.props?.value != null ? this.props?.value : ""}
            onChange={this.props?.onChange}
            onKeyUp={this.props?.onKeyUp}
            onKeyDown={this.props?.onKeyDown}
            disabled={this.props?.disabled}
            maxLength={this.props?.maxLength}
            style={this.props?.style}
            min={this.props?.min}
            max={this.props?.max}
            step={this.props?.step}
            data-date={
              this.props?.type === "date" && this.props?.value
                ? Moment(this.props?.value).format("DD/MM/YYYY")
                : "dd/mm/yyyy"
            }
          />

          {this.props.righticon && (
            <span
              className="material-icons-round right-icon-input"
              style={{ cursor: this.props.toggleicon ? "pointer" : "" }}
              onClick={this.props.toggleicon}
            >
              {this.props.righticon}
            </span>
          )}

          {this.props?.rightLabel && (
            <span className="right-icon-input" style={{ cursor: this.props?.toggleicon ? 'pointer' : '', opacity: '0.8', marginRight: '20px' }} onClick={this.props?.toggleicon}>{this.props?.rightLabel}</span>
          )}

          <ErrorMessage
            name={this.props?.name}
            component="div"
            className="invalid-feedback"
          />
        </div>
      </div>
    );
  }
}
