import React, { useState, useRef, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { getUser } from "../../../utils/User";
import { convertGISToGeojson } from "../../../utils/ConvertFile";
import ButtonModal from "../../../components/modal/ButtonModal";
import DynamicButton from "../../../components/forms/DynamicButton";
import ConfirmationModal from "../../../components/modal/ConfirmModal";
import PipelineService from "../../../store/actions/master_data/pipeline";
import { toastError } from "../../../components/commons/toast";
import { reproject, crss } from "reproject";

function Index({ show, toggle, onSubmitted, pdamId, pending_post }) {
  const [isLoading, setIsLoading] = useState(false);
  const currentUser = getUser();
  const formikRef = useRef();
  const fileRefSHP = useRef();
  const fileRefDBF = useRef();
  const [showModalConfirm, setShowModalConfirm] = useState(false);
  const dispatch = useDispatch();

  const [filePropSHP, setFilePropSHP] = useState({
    title: "Import SHP Jaringan",
    subtitle: "File SHP",
    message_error: false,
  });

  const [filePropDBF, setFilePropDBF] = useState({
    title: "Import DBF Jaringan",
    subtitle: "File DBF",
    message_error: false,
  });

  const openFileDialog = (type) => {
    if (type === "DBF") {
      fileRefDBF.current.click();
    } else if (type === "SHP") {
      fileRefSHP.current.click();
    }
  };

  const handleChange = async (selectorFiles, formik, type) => {
    if (selectorFiles) {
      const file = selectorFiles.files[0];
      const file_type = file?.name.split(".");
      const fileProp = {
        title: file?.name,
        subtitle: (Number(file?.size) * 0.001).toFixed(2) + " KB",
        message_error: false,
      };
      if (Number(file.size) < 31457280) {
        // before is 15728640
        if (type === "DBF") {
          if (file_type[1] === "dbf") {
            formik.setFieldValue("dbf", file);
            setFilePropDBF(fileProp);
          } else {
            toastError("Format file harus berekstensi .dbf");
          }
        } else if (type === "SHP") {
          if (file_type[1] === "shp") {
            formik.setFieldValue("shp", file);
            setFilePropSHP(fileProp);
          } else {
            toastError("Format file harus berekstensi .shp");
          }
        }
      } else {
        toastError("Maksimal file yang dapat diimport 15 MB");
      }
    }
  };

  const validation = Yup.object().shape({
    pdam_id: Yup.string().required("INPUT wajib diisi").nullable(),
  });

  const handleConfirm = () => {
    setFilePropSHP({
      title: "Import SHP Jaringan",
      subtitle: "File SHP",
      message_error: false,
    });
    setFilePropDBF({
      title: "Import DBF Jaringan",
      subtitle: "File DBF",
      message_error: false,
    });
    setShowModalConfirm(false);
    toggle(false);
  };

  const handleFile = (formik) => {
    if (!formik.values.shp) {
      setFilePropSHP({
        title: "Import SHP Jaringan",
        subtitle: "File SHP",
        message_error: "File wajib diisi",
      });
    }

    if (!formik.values.dbf) {
      setFilePropDBF({
        title: "Import DBF Jaringan",
        subtitle: "File DBF",
        message_error: "File wajib diisi",
      });
    }
  };

  const convertToGeojson = (shp, dbf, callback) => {
    if (shp && dbf) {
      let geojson = {
        type: "FeatureCollection",
      };
      const promGeojson = convertGISToGeojson([shp, dbf]).then((res) => {
        geojson.features = res;
        // reproject(pipelineGeoJson, "WGS:84", "ESRI:54004", crss)
        // const reprojectedJson = reproject(geojson, "ESRI:54004", "EPSG:4326", crss);
        callback(geojson);
      });
    }
  };

  return (
    <>
      <Modal
        isOpen={show}
        modalTransition={{ timeout: 200 }}
        backdropTransition={{ timeout: 1300 }}
      >
        <ModalHeader className="text-extra-bold pt-5">
          {" "}
          Import Jaringan{" "}
          <span className="close clickable" onClick={handleConfirm}>
            &times;
          </span>
        </ModalHeader>
        <Formik
          innerRef={formikRef}
          initialValues={{
            pdam_id: pdamId,
            geojson: null,
            dbf: null,
            shp: null,
          }}
          validationSchema={validation}
          onSubmit={(values, { setStatus, setSubmitting }) => {
            setStatus();
            setIsLoading(true);
            if (values.pdam_id && values.shp && values.dbf) {
              setSubmitting(true);
              const callback = () => {
                toggle();
                setFilePropSHP({
                  title: "Import SHP Jaringan",
                  subtitle: "File SHP",
                  message_error: false,
                });
                setFilePropDBF({
                  title: "Import DBF Jaringan",
                  subtitle: "File DBF",
                  message_error: false,
                });
                onSubmitted?.();
                setSubmitting(false);
              };
              convertToGeojson(values.shp, values.dbf, function (geojson) {
                const param = {
                  pdam_id: values.pdam_id,
                  input_id: null,
                  geojson: JSON.stringify(geojson),
                };
                dispatch(PipelineService.post(param, callback));
                setIsLoading(false);
              });
            }

            if (!values.dbf) {
              setFilePropDBF({
                title: "Import DBF Jaringan",
                subtitle: "File DBF",
                message_error: "File wajib diisi",
              });
            }

            if (!values.shp) {
              setFilePropSHP({
                title: "Import SHP Jaringan",
                subtitle: "File SHP",
                message_error: "File wajib diisi",
              });
            }
          }}
        >
          {(props) => (
            <Form style={{ marginTop: 30 }}>
              <ModalBody style={{ margin: "0px 20px" }}>
                <DynamicButton
                  titleLeft={filePropSHP?.title}
                  subtitleLeft={filePropSHP?.subtitle}
                  iconLeft="insert_drive_file"
                  toggle={() => openFileDialog("SHP")}
                  error={filePropSHP?.message_error}
                />

                <DynamicButton
                  titleLeft={filePropDBF?.title}
                  subtitleLeft={filePropDBF?.subtitle}
                  iconLeft="insert_drive_file"
                  toggle={() => openFileDialog("DBF")}
                  error={filePropDBF?.message_error}
                />

                <input
                  type="file"
                  ref={fileRefSHP}
                  style={{ display: "none" }}
                  onChange={(e) => handleChange(e.target, props, "SHP")}
                  accept=".shp"
                />
                <input
                  type="file"
                  ref={fileRefDBF}
                  style={{ display: "none" }}
                  onChange={(e) => handleChange(e.target, props, "DBF")}
                  accept=".dbf"
                />
              </ModalBody>
              <ModalFooter>
                <ButtonModal
                  toggle={() => setShowModalConfirm(true)}
                  confirmTitle="Import"
                  confirm={() => handleFile(props)}
                  disabledConfrim={isLoading || pending_post}
                  isLoading={isLoading || pending_post}
                />
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </Modal>

      <ConfirmationModal
        isDelete
        show={showModalConfirm}
        toggle={() => setShowModalConfirm(false)}
        confirm={handleConfirm}
        cancelTitle="Tidak"
        confirmTitle="Ya"
        message="Yakin ingin membatalkan import data?"
        icon="info"
      />
    </>
  );
}

const mapStateToProps = ({ masterDataPipeline: { pending_post } }) => {
  return { pending_post };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
