import Chart from "chart.js"
import React, { useState, useRef, useEffect } from "react"
import _uniqueId from "lodash/uniqueId"
import Loader from "../commons/Loader"

let chart

const LineChart = ({
  height = 250,
  labels = ["Jan", "Feb", "March"],
  options = {
    yTickDisplay: true,
  },
  stepSize = 2,
  datasets = [
    {
      label: "Sales",
      data: [86, 67, 91],
      fill: false,
    },
  ],
  pending = true,
}) => {
  const chartRef = useRef(null)
  const [id] = useState(_uniqueId("linechart-"))

  useEffect(() => {
    buildChart()
  })

  const buildChart = () => {
    if (chart) chart.destroy()
    const myChartRef = chartRef.current.getContext("2d")

    // const {height: height} = myChartRef.canvas;

    datasets.forEach((elem) => {
      if (elem.fill) {
        let bgStartColor = "borderColor" in elem ? elem.borderColor.replace(/[\d\.]+\)$/g, ".6)") : "rgba(0, 166, 207, .6)"
        let bgStopColor = "borderColor" in elem ? elem.borderColor.replace(/[\d\.]+\)$/g, "0)") : "rgba(0, 166, 207, 0)"

        let bgGradient = myChartRef.createLinearGradient(0, 0, 0, height)
        bgGradient.addColorStop(0, bgStartColor)
        bgGradient.addColorStop(1, bgStopColor)

        elem.backgroundColor = bgGradient
      }
    })

    chart = new Chart(myChartRef, {
      type: "line",
      data: {
        //Bring in data
        labels: labels,
        datasets: datasets,
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        elements: {
          line: {
            tension: 0.4,
            borderWidth: 4,
          },
          point: {
            borderWidth: 3.5,
            radius: 3,
          },
        },
        legend: {
          position: "bottom",
          align: "start",
        },
        scales: {
          yAxes: [
            {
              ticks: {
                display: "yTickDisplay" in options ? options.yTickDisplay : true,
                stepSize: stepSize,
                beginAtZero: true,
                callback: function (value, index, ticks) {
                  return value + " lps"
                },
              },
              gridLines: {
                display: "yGridDisplay" in options ? options.yGridDisplay : true,
              },
            },
            {
              id: "tickPressure",
              position: "right",
              ticks: {
                display: "yTickDisplay" in options ? options.yTickDisplay : true,
                stepSize: stepSize,
                beginAtZero: true,
                callback: function (value, index, ticks) {
                  return value + " m"
                },
              },
              gridLines: {
                display: "yGridDisplay" in options ? options.yGridDisplay : true,
              },
            },
          ],
          xAxes: [
            {
              ticks: {
                display: "xTickDisplay" in options ? options.xTickDisplay : true,
                stepSize: stepSize,
              },
              gridLines: {
                display: "xGridDisplay" in options ? options.xGridDisplay : true,
                // drawOnChartArea: "ydrawOnChartArea" in options ? options.ydrawOnChartArea : true,
              },
            },
          ],
        },
      },
    })
  }

  return (
    <div style={{ height: height + "px", position: "relative" }}>
      {pending && <Loader loading={pending} />}
      <canvas id={id} ref={chartRef} />
    </div>
  )
}

export default LineChart
