import "./card.scss";

const card = (props) => {

  return (
    <div className={`card ${props.className}`}>
      {props.children}
    </div>
  );

};

export default card;
