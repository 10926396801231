import { history } from "../../../utils/History";
import { actionPending, actionSuccess, actionError } from "../actionTypes";

import { toastSuccess, toastError } from "../../../components/commons/toast";

import API from "../API";
import { handleResponse } from "../HandleRespone";

// ACTION TYPES TO DISPATCH: METHOD_URL_TYPES
export const GET_NOTIFICATION_PENDING = "GET_NOTIFICATION_PENDING";
export const GET_NOTIFICATION_SUCCESS = "GET_NOTIFICATION_SUCCESS";
export const GET_NOTIFICATION_ERROR = "GET_NOTIFICATION_ERROR";
export const GET_UNREAD_NOTIFICATION_PENDING =
  "GET_UNREAD_NOTIFICATION_PENDING";
export const GET_UNREAD_NOTIFICATION_SUCCESS =
  "GET_UNREAD_NOTIFICATION_SUCCESS";
export const GET_UNREAD_NOTIFICATION_ERROR = "GET_UNREAD_NOTIFICATION_ERROR";
export const PUT_NOTIFICATION_PENDING = "PUT_NOTIFICATION_PENDING";
export const PUT_NOTIFICATION_SUCCESS = "PUT_NOTIFICATION_SUCCESS";
export const PUT_NOTIFICATION_ERROR = "PUT_NOTIFICATION_ERROR";
export const NOTIFICATION_DATA = "NOTIFICATION_DATA";

// URL: URL_{URL}
const NOTIFICATION_URL = `v1/notifications`;

const get = (param, resolve, reject, callback) => (dispatch) => {
  dispatch(actionPending(GET_NOTIFICATION_PENDING));
  API.get(NOTIFICATION_URL, { params: param })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(GET_NOTIFICATION_SUCCESS, res));
      if (resolve) {
        const records_total = res.data.total;
        let data = res.data.data.map((item, i) => ({
          ...item,
          no: i + 1 + (param?.page - 1) * param?.length,
        }));
        resolve({
          data: data,
          page: param?.page - 1,
          totalCount: records_total,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(GET_NOTIFICATION_ERROR));
      let not_logout = handleResponse(err?.response);
      if (not_logout) {
        let message = err?.response?.data?.message;
        if (message?.includes("java")) {
          message = "Data tidak ditemukan";
        }
        toastError(message);
      }
      // toastError(err?.response?.data?.message);
    });
};

const getCountUnread = (param, resolve, reject, callback) => (dispatch) => {
  dispatch(actionPending(GET_UNREAD_NOTIFICATION_PENDING));
  API.get(NOTIFICATION_URL + "/count_unread", { params: param })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(GET_UNREAD_NOTIFICATION_SUCCESS, res));
      if (resolve) {
        const records_total = res.data.total;
        resolve({
          totalCount: records_total,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(GET_UNREAD_NOTIFICATION_ERROR));
      let not_logout = handleResponse(err?.response);
      if (not_logout) {
        let message = err?.response?.data?.message;
        if (message?.includes("java")) {
          message = "Data tidak ditemukan";
        }
        toastError(message);
      }
      // toastError(err?.response?.data?.message);
    });
};

const put = (param, callback, resolve, reject) => (dispatch) => {
  dispatch(actionPending(PUT_NOTIFICATION_PENDING));
  API.put(NOTIFICATION_URL + "/read", param)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(PUT_NOTIFICATION_SUCCESS, res));
      // toastSuccess("Berhasil Ubah Data Commercial Losses");
      if (resolve) {
        resolve({
          data: res.data.data,
          message: res.data.message,
          status: res.data.status,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(PUT_NOTIFICATION_ERROR));
      toastError(err?.response?.data?.message);
    });
};

const notification = {
  get,
  getCountUnread,
  put,
};
export default notification;

export const setNotificationData = (data) => (dispatch) =>
  dispatch({ type: NOTIFICATION_DATA, data });
