import React, { useState, useEffect } from 'react'
import { connect } from "react-redux"
import { Role } from "../../../utils/Constants";
import { getUser } from "../../../utils/User";
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from 'reactstrap';

import ButtonModal from '../../../components/modal/ButtonModal';
import CardFlush from '../../../components/cards/cardFlush';
import ModalCustomer from './ModalCustomer';
import { toastError } from '../../../components/commons/toast';

function ModalDetailCust({
    show,
    toggle,
    data,
    detailCust,
    refresh
}) {
    const [showModalEditCust, setShowModalEditCust] = useState(false)
    const currentUser = getUser().user;

    const handleEdit = () => {
        currentUser.role_code !== Role.Kadiv ? setShowModalEditCust(true) : toastError('Maaf anda tidak memiliki akses')
        toggle(false)
    }

    return (
        <>
            <Modal isOpen={show} modalTransition={{ timeout: 200 }} backdropTransition={{ timeout: 1300 }}>
                <ModalHeader className="text-extra-bold pt-5"> Detail Pelanggan <span className="close clickable" onClick={toggle}>&times;</span></ModalHeader>
                <ModalBody className="mx-4">
                    <div className="wrapper-detail-card">
                        {detailCust = [
                            { label: 'Nama pelanggan', desc: data?.name },
                            { label: 'No SR', desc: data?.sr_number ?? '-' },
                            { label: 'ID Sensor', desc: data?.device_id ?? '-' },
                            { label: 'Sambungan', desc: data?.connection_type ?? '-' },
                            { label: 'Merek Pompa', desc: data?.brand ?? '-' },
                            { label: 'Tanggal Pasang', desc: data?.installation_date ?? '-' },
                            { label: 'Longitude, Latitude', desc: data?.longitude && data?.latitude ? `${data?.longitude}, ${data?.latitude}` : '-' },
                            { label: 'Alamat', desc: data?.address },
                        ].map((detailCust, index) => (
                            <CardFlush
                                list
                                key={index}
                                label={detailCust.label}
                                desc={detailCust.desc}
                            />
                        ))
                        }
                    </div>
                    <div className="wrapper-detail-card">
                        {detailCust = [
                            { label: 'PDAM', desc: data?.pdam_name ?? data?.pdam?.name ?? '-' },
                            { label: 'DMA', desc: data?.dma_name ?? data?.dma?.name ?? '-' },
                            { label: 'Sub DMA', desc: data?.sub_dma_name ?? data?.sub_dma?.name ?? '-' },
                            { label: 'Zona', desc: data?.zone_name ?? data?.zone?.name ?? '-' },
                        ].map((detailCust, index) => (
                            <CardFlush
                                list
                                key={index}
                                label={detailCust.label}
                                desc={detailCust.desc}
                            />
                        ))
                        }
                    </div>
                    <CardFlush
                        status
                        desc={data?.status === 1
                            ? "Status Aktif"
                            : data?.status === 2
                                ? "Status Ditangguhkan"
                                : data?.status === 3
                                    ? "Status Putus"
                                    : "-"}
                        color={data?.status === 1
                            ? "bg-green"
                            : data?.status === 2
                                ? "bg-yellow"
                                : data?.status === 3
                                    ? "bg-red"
                                    : ""}
                    />
                </ModalBody>
                <ModalFooter>
                    <ButtonModal
                        cancelTitle='Tutup'
                        confirmTitle='Edit Data'
                        confirm={handleEdit}
                        toggle={toggle}
                        hideConfirm={currentUser.role_code === Role.Kadiv}
                        disabledConfrim={currentUser.role_code === Role.Kadiv}
                    />
                </ModalFooter>
            </Modal>

            <ModalCustomer
                show={showModalEditCust}
                toggle={() => setShowModalEditCust(false)}
                data={data}
                refresh={refresh}
            />
        </>
    )
}

const mapStateToProps = ({ masterDataZone }) => {
    return { masterDataZone };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};


export default connect(mapStateToProps, mapDispatchToProps)(ModalDetailCust);
