import React, { useState, forwardRef, useImperativeHandle } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Container,
} from "reactstrap";
import moment from "moment";
import { Formik, Form } from "formik";
import Button from "../../../components/buttons/Button";
import Select2 from "../../../components/forms/Select2";
import DynamicButton from "../../../components/forms/DynamicButton";
import DateRangePicker from "../../../components/forms/DateRangePicker";

const FilterModal = forwardRef((props, ref) => {
  const [show, setShow] = useState(false);
  const { onApplyFilter, filter } = props;
  const timezone = moment(new Date()).format("Z");

  useImperativeHandle(ref, () => ({
    toggle() {
      callToggle();
    },
  }));

  const callToggle = () => {
    setShow(!show);
  };

  const onClickCancel = () => {
    callToggle();
  };

  const roleList = [
    { value: 'superadmin', label: 'Superuser' },
    { value: 'it_administrator', label: 'IT Administrator' },
    { value: 'user_cust_rep', label: 'User Cust Rep' },
    { value: 'viewer', label: 'Viewer' },
    { value: 'kadiv', label: 'Kadiv' }
  ]

  return (
    <>
      <Modal
        isOpen={show}
        modalTransition={{ timeout: 200 }}
        toggle={callToggle}
      >
        <ModalHeader className="text-extra-bold pt-4">
          Filter
          <span className="close clickable" onClick={callToggle}>
            &times;
          </span>
        </ModalHeader>
        <ModalBody>
          <DynamicButton
            titleLeft="Pilih data yang ingin difilter"
            iconLeft="info"
          />
          <Container>
            <Formik
              initialValues={{
                start_date: filter.start,
                end_date: filter.end,
                role: filter.role,
              }}
              enableReinitialize
              onSubmit={(values, { setSubmitting }) => {
                if (values.start_date && values.end_date) {
                  values.start_date = moment(values.start_date).format("YYYY-MM-DD") +
                    "T00:00:00.000" + timezone;
                  values.end_date = moment(values.end_date).format("YYYY-MM-DD") +
                    "T23:59:00.000" + timezone;
                }
                onApplyFilter(values.start_date, values.end_date, values.role);
                setSubmitting(false);
              }}
            >
              {(props) => (
                <Form>
                  <DateRangePicker
                    title="Rentang Tanggal"
                    name="date_range"
                    handlechange={(startDate, endDate) => {
                      props.setFieldValue("start_date", startDate);
                      props.setFieldValue("end_date", endDate);
                    }}
                    format="DD MMMM YYYY"
                    startDate={props.values.start_date}
                    endDate={props.values.end_date}
                    maxdate={new Date()}
                    className="mx-auto"
                    errors={props.errors}
                    touched={props.touched}
                  />

                  <Select2
                    title="Role"
                    name="role"
                    options={roleList}
                    errors={props.errors}
                    touched={props.touched}
                    value={props.values?.role}
                    placeholder="Pilih data Role"
                    onChange={(name, value) => {
                      props.setFieldValue("role", value);
                    }}
                    onBlur={props.setFieldTouched}
                  />
                  <Container className="d-flex align-items-center">
                    <div className="float-left-force">
                      <p
                        className="text-bold"
                        style={{ cursor: "pointer", margin: 0 }}
                        onClick={() => {
                          onApplyFilter(null, null);
                        }}
                      >
                        Reset
                      </p>
                    </div>
                    <Button
                      variant="button secondary"
                      rounded
                      title="Batal"
                      onClick={onClickCancel}
                      leftIcon={null}
                      type="Button"
                    />
                    <Button
                      title="Terapkan"
                      type="Submit"
                      rounded={true}
                      style={{ marginRight: "0 !important" }}
                      variant="button-default btn-lg"
                    />
                  </Container>
                </Form>
              )}
            </Formik>
          </Container>
        </ModalBody>
        <ModalFooter></ModalFooter>
      </Modal>
    </>
  );
});

export default FilterModal;
