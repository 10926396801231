const Index = ({ title, subtitle, toggle, active }) => {
  return (
    <div
      className={`side-menu-item ${active ? "side-menu-item-active " : ""}`}
      onClick={toggle}
    >
      <div className="side-menu-info">
        <div className="side-menu-title text-extra-bold">{title}</div>
        <div className="side-menu-subtitle">{subtitle}</div>
      </div>
      <div className="side-menu-icon">
        <span className="material-icons-round">navigate_next</span>
      </div>
    </div>
  );
};

export default Index;
