import React, { useEffect, useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { connect, useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import WarningSystem from "../../store/actions/warning_system";
import Select2 from "../../components/forms/Select2";
import Button from "../../components/buttons/Button";

function ModalEdit({ show, toggle, handleRefresh, data, pending_put }) {
  const dispatch = useDispatch();

  const validation = Yup.object().shape({
    status: Yup.number().required("Status wajib diisi").nullable(),
  });

  const formik = useFormik({
    initialValues: {
      status: data?.status,
    },
    enableReinitialize: true,
    validationSchema: validation,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setStatus();
      const param = {
        status: values.status,
        id: data?.id,
      };
      const callback = () => {
        handleRefresh();
        toggle();
        formik.resetForm();
      };
      dispatch(WarningSystem.put(param, callback));
    },
  });

  return (
    <>
      <Modal isOpen={show} modalTransition={{ timeout: 200 }}>
        <ModalHeader className="text-extra-bold pt-5">
          Edit Status{" "}
          <span 
            className="close clickable" 
            onClick={()=>{
              toggle();
              formik.resetForm();
            }}>
            &times;
          </span>
        </ModalHeader>
        <form onSubmit={formik.handleSubmit}>
          <ModalBody style={{ margin: "0px 20px" }}>
            <Select2
              title="Status"
              name="status"
              options={[
                { value: 0, label: "Baru" },
                { value: 1, label: "Selesai" },
              ]}
              errors={formik.errors}
              touched={formik.touched}
              value={formik.values.status}
              placeholder="Pilih status"
              onChange={(name, value) => {
                formik.setFieldValue("status", value);
              }}
              onBlur={formik.setFieldTouched}
            />
          </ModalBody>
          <ModalFooter style={{ margin: 20 }}>
            <Button
              title="Batal"
              size="md"
              variant="outline secondary"
              rounded={true}
              onClick={() => {
                toggle();
                formik.resetForm();
              }}
            />
            <Button
              title="Terapkan"
              size="md"
              variant="button-default"
              rounded={true}
              type="submit"
              disabled={pending_put}
            />
          </ModalFooter>
        </form>
      </Modal>
    </>
  );
}

const mapStateToProps = ({ warningSystem: { pending_put } }) => {
  return { pending_put };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalEdit);
