import { useState, useRef, useEffect, forwardRef, useImperativeHandle } from "react"
import HeaderMenu from "../../commons/menu/HeaderMenu"
import FooterMenu from "../../commons/menu/FooterMenu"
import SearchButton from "../../buttons/SearchButton"
import SearchButtonDefault from "../../buttons/SearchButtonDefault"
import Select from "react-select"

import Button from "../../buttons/Button"

import { Container, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap"
import Map from "../../map"

const DataMapBaseLayout = forwardRef(({ title, dataHeaderContent, dataContent, mapHeaderContent, mapContent, initialCenter, initialZoom, onChangeView, elipsis, onMapReady }, ref) => {
  const mapRef = useRef()
  const [selectMapShow, setSelectMapShow] = useState(false)
  const [selectedMapType, setSelectedMapType] = useState("Default")
  const [activeView, setActiveView] = useState("data")
  const defaultCenter = [-6.8116, 107.6174]
  const defaultZoom = 10

  useImperativeHandle(ref, () => ({
    getMap() {
      return mapRef.current
    },
    getActiveView() {
      return activeView
    },
    setActiveView(view) {
      if (!["data", "peta"].includes(view)) return
      setActiveView(view)
      onChangeView(view)
    },
  }))

  useEffect(() => {
    if (initialCenter && initialZoom) mapRef.current?.flyTo(initialCenter ?? defaultCenter, initialZoom ?? defaultZoom)
  }, [])

  const handleZoom = (mode) => {
    switch (mode) {
      case "in":
        mapRef.current.zoomIn(1)
        break
      case "out":
        mapRef.current.zoomOut(1)
        break
    }
  }

  const toggleSelectMap = () => setSelectMapShow(!selectMapShow)

  const MenuTab = ({ view }) => {
    return (
      <nav className="active-pointer-evt pl-3" aria-label="Page navigation example">
        <ul className="line-buttons mb-0" style={{ zIndex: 2 }}>
          <li className={`button-item ${view === "mobile" && activeView === "data" ? "mini-hide" : ""}`}>
            <a
              className={`button-link ${activeView === "data" ? "active" : ""}`}
              onClick={() => {
                setActiveView("data")
                onChangeView?.("data")
              }}
            >
              Data
            </a>
          </li>
          <li className={`button-item ${view === "mobile" && activeView === "peta" ? "mini-hide" : ""}`}>
            <a
              className={`button-link ${activeView == "peta" ? "active" : ""}`}
              onClick={() => {
                setActiveView("peta")
                onChangeView?.("peta")
              }}
            >
              Peta
            </a>
          </li>
        </ul>
      </nav>
    )
  }

  const MenuZoom = ({}) => {
    return (
      <nav className="active-pointer-evt" aria-label="Page navigation example">
        <ul className="line-buttons">
          <li className="button-item">
            <a className="button-link lm" onClick={() => handleZoom("in")}>
              +
            </a>
          </li>
          <li className="button-item">
            <a className="button-link lm" onClick={() => handleZoom("out")}>
              -
            </a>
          </li>
        </ul>
      </nav>
    )
  }

  return (
    <div className={`main-content-wrapper ${activeView === "peta" ? "mini-map-wrapper" : ""}`}>
      <HeaderMenu
        title={title}
        optClass="inactive-pointer-evt pr-5"
        titleClass="header-mini-title"
        elipsis={elipsis}
        titleChildren={
          <div className="mini-show">
            <MenuTab view="mobile" />
          </div>
        }
      >
        {activeView === "data" && <>{dataHeaderContent}</>}
        {activeView === "peta" && (
          <>
            {mapHeaderContent}
            <div className="mini-hide">
              <MenuZoom />
            </div>
          </>
        )}
        <div className="mini-hide">
          <MenuTab view="web" />
        </div>
      </HeaderMenu>
      {activeView === "peta" && (
        <>
          <div className="map-area">
            <Map ref={mapRef} defaultCenter={initialCenter} defaultZoom={initialZoom} type={selectedMapType} onCreated={() => onMapReady?.()}>
              {mapContent}
            </Map>
          </div>
          <FooterMenu optClass="inactive-pointer-evt pr-5 pb-4 footer-map">
            <Dropdown className="active-pointer-evt" group isOpen={selectMapShow} toggle={toggleSelectMap}>
              <DropdownToggle caret color="light" className="shaded">
                {selectedMapType}&emsp;&emsp;&emsp;
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem onClick={() => setSelectedMapType("Default")}>Default</DropdownItem>
                <DropdownItem onClick={() => setSelectedMapType("Satelit")}>Satelit</DropdownItem>
              </DropdownMenu>
            </Dropdown>
            {/* <nav className="active-pointer-evt" aria-label="Page navigation example" className="pl-3">
                <ul className="line-buttons">
                    <li className="button-item">
                        <a
                        onClick={() => handleZoom("in")} 
                        className="button-link lm" 
                        href="#">
                            +
                        </a>
                    </li>
                    <li className="button-item">
                        <a 
                        onClick={() => handleZoom("out")}
                        className="button-link lm" 
                        href="#">
                            -
                        </a>
                    </li>
                </ul>
                </nav> */}
          </FooterMenu>
        </>
      )}
      {activeView === "data" && <div className="main-fill-content">{dataContent}</div>}
    </div>
  )
})

export default DataMapBaseLayout
