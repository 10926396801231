import { history } from "../../../../utils/History";
import { actionPending, actionSuccess, actionError } from "../../actionTypes";

import { toastSuccess, toastError } from "../../../../components/commons/toast";

import API from "../../API";
import { handleResponse } from "../../HandleRespone";

// ACTION TYPES TO DISPATCH: METHOD_URL_TYPES
export const GET_CUSTOMER_PENDING = "GET_CUSTOMER_PENDING";
export const GET_CUSTOMER_SUCCESS = "GET_CUSTOMER_SUCCESS";
export const GET_CUSTOMER_ERROR = "GET_CUSTOMER_ERROR";
export const POST_CUSTOMER_PENDING = "POST_CUSTOMER_PENDING";
export const POST_CUSTOMER_SUCCESS = "POST_CUSTOMER_SUCCESS";
export const POST_CUSTOMER_ERROR = "POST_CUSTOMER_ERROR";
export const ADD_CUSTOMER_PENDING = "ADD_CUSTOMER_PENDING";
export const ADD_CUSTOMER_SUCCESS = "ADD_CUSTOMER_SUCCESS";
export const ADD_CUSTOMER_ERROR = "ADD_CUSTOMER_ERROR";
export const PUT_CUSTOMER_PENDING = "PUT_CUSTOMER_PENDING";
export const PUT_CUSTOMER_SUCCESS = "PUT_CUSTOMER_SUCCESS";
export const PUT_CUSTOMER_ERROR = "PUT_CUSTOMER_ERROR";
export const DELETE_CUSTOMER_PENDING = "DELETE_CUSTOMER_PENDING";
export const DELETE_CUSTOMER_SUCCESS = "DELETE_CUSTOMER_SUCCESS";
export const DELETE_CUSTOMER_ERROR = "DELETE_CUSTOMER_ERROR";

// URL: URL_{URL}
const CUSTOMER_URL = `v1/master_data/customer`;

const get = (param, resolve, reject, callback) => (dispatch) => {
  dispatch(actionPending(GET_CUSTOMER_PENDING));
  API.get(CUSTOMER_URL, { params: param })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(GET_CUSTOMER_SUCCESS, res));
      if (resolve) {
        const records_total = res.data.total;
        let data = res.data.data.map((item, i) => ({
          ...item,
          no: i + 1 + (param?.page - 1) * param?.length,
          pdam_name: item?.pdam?.name ?? "-",
          zone_name: item?.zone?.name ?? "-",
          dma_name: item?.dma?.name ?? "-",
          region_name: item?.region_name ?? "-",
          device_id: item?.device_id ?? "-",
        }));
        resolve({
          data: data,
          page: param?.page - 1,
          totalCount: records_total,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(GET_CUSTOMER_ERROR));
      let not_logout = handleResponse(err?.response);
      if (not_logout) {
        let message = err?.response?.data?.message;
        if (message?.includes("java")) {
          message = "Data tidak ditemukan";
        }
        toastError(message);
      }
    });
};

const add = (param, callback) => (dispatch) => {
  dispatch(actionPending(ADD_CUSTOMER_PENDING));
  API.post(CUSTOMER_URL, param)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(ADD_CUSTOMER_SUCCESS, res));
      toastSuccess("Berhasil tambah data pelanggan");
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      let message = err?.response?.data?.message;
      if (message?.includes("java")) {
        message = "Mohon periksa kembali data yang Anda masukkan";
      }
      toastError(message);
      dispatch(actionError(ADD_CUSTOMER_ERROR));
    });
};

const post = (param, callback) => (dispatch) => {
  dispatch(actionPending(POST_CUSTOMER_PENDING));
  API.post(CUSTOMER_URL + "/import_excel", param)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(POST_CUSTOMER_SUCCESS, res));
      toastSuccess("Berhasil import data pelanggan");
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      let message = err?.response?.data?.message;
      if (message?.includes("java")) {
        message = "Format file tidak sesuai";
      }
      toastError(message);
      dispatch(actionError(POST_CUSTOMER_ERROR));
    });
};

const put = (param, callback) => (dispatch) => {
  dispatch(actionPending(PUT_CUSTOMER_PENDING));
  API.put(CUSTOMER_URL, param)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(PUT_CUSTOMER_SUCCESS, res));
      toastSuccess("Berhasil ubah data pelanggan");
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      let message = err?.response?.data?.message;
      if (message?.includes("java")) {
        message = "Format file tidak sesuai";
      }
      toastError(message);
      dispatch(actionError(PUT_CUSTOMER_ERROR));
    });
};

const deleted = (param, callback) => (dispatch) => {
  dispatch(actionPending(DELETE_CUSTOMER_PENDING));
  API.delete(CUSTOMER_URL + `?id=${param}`)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(DELETE_CUSTOMER_SUCCESS, res));
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(DELETE_CUSTOMER_ERROR));
      let message = err?.response?.data?.message;
      if (message?.includes("java")) {
        message = "Gagal menghapus data";
      }
      toastError(message);
    });
};


const deletedAllByPdam = (param, callback, callbackFinish) => (dispatch) => {
  dispatch(actionPending(DELETE_CUSTOMER_PENDING));
  API.delete(CUSTOMER_URL + `/delete-all-by-pdam?pdamId=${param}`)
    .then((res) => {
      if (res.error) {
        callbackFinish();
        throw res.error;
      }
      dispatch(actionSuccess(DELETE_CUSTOMER_SUCCESS, res));
      if (callback) {
        callback();
        callbackFinish();
      }
    })
    .catch((err) => {
      callbackFinish();
      dispatch(actionError(DELETE_CUSTOMER_ERROR));
      let message = err?.response?.data?.message;
      if (message?.includes("java")) {
        message = "Gagal menghapus semua data pelanggan";
      }
      toastError(message);
    });
};

const dma = {
  get,
  post,
  add,
  deleted,
  put,
  deletedAllByPdam
};
export default dma;
