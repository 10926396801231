import React, { useState, useRef, useEffect } from "react"
import { connect, useDispatch } from "react-redux"
import { Divider } from "@material-ui/core"
import { Formik, Form } from "formik"
import * as Yup from "yup"
import { Modal, ModalHeader, ModalBody, ModalFooter, Row, Col } from "reactstrap"
import { getUser } from "../../utils/User"
import Input from "../../components/forms/Input"
import Select2 from "../../components/forms/Select2"
import ButtonModal from "../../components/modal/ButtonModal"
import DynamicButton from "../../components/forms/DynamicButton"
import { convertGISToGeojson } from "../../utils/ConvertFile"
import { toastError } from "../../components/toast/index"
import LoaderModal from "../../components/commons/LoaderModal"

import PDAMService from "../../store/actions/master_data/pdam"
import HydraulicService from "../../store/actions/hydraulic_model"

function ModalAdd({ show, toggle, switchPDAM, masterDataPDAM, type, hydraulicModel }) {
  const currentUser = getUser()
  const formikRef = useRef()
  const fileRefInput = useRef()
  const fileRefZone = useRef()
  const fileRefSHPDMA = useRef()
  const fileRefDBFDMA = useRef()
  const fileRefSHPNet = useRef()
  const fileRefDBFNet = useRef()
  const fileRefCust = useRef()
  const [pdamList, setPDAMList] = useState([])

  const dispatch = useDispatch()

  const [filePropInput, setFilePropInput] = useState({
    title: "Import Excel Input",
    subtitle: "File Excel Input",
    message_error: false,
  })

  const [filePropZone, setFilePropZone] = useState({
    title: "Import Excel Zona",
    subtitle: "File Excel Zona",
    message_error: false,
  })

  const [filePropSHPDMA, setFilePropSHPDMA] = useState({
    title: "Import SHP DMA",
    subtitle: "File SHP DMA",
    message_error: false,
  })

  const [filePropDBFDMA, setFilePropDBFDMA] = useState({
    title: "Import DBF DMA",
    subtitle: "File DBF DMA",
    message_error: false,
  })

  const [filePropSHPNet, setFilePropSHPNet] = useState({
    title: "Import SHP Jaringan",
    subtitle: "File SHP Jaringan",
    message_error: false,
  })

  const [filePropDBFNet, setFilePropDBFNet] = useState({
    title: "Import DBF Jaringan",
    subtitle: "File DBF Jaringan",
    message_error: false,
  })

  const [filePropCust, setFilePropCust] = useState({
    title: "Import Excel Pelanggan",
    subtitle: "File Excel Pelanggan",
    message_error: false,
  })

  const validation = Yup.object().shape({
    name: Yup.string().required("Nama simulasi wajib diisi").nullable(),
    pdam_id: Yup.string().required("PDAM wajib diisi").nullable(),
  })

  const title = type == "blank" ? "Blank" : type === "import" ? "Import Data" : "Pilih PDAM"

  useEffect(() => {
    const param = {
      draw: 1,
      length: 999,
      page: 1,
      id: switchPDAM?.id ?? "",
    }

    dispatch(PDAMService.get(param))
  }, [switchPDAM])

  useEffect(() => {
    if (masterDataPDAM) {
      setPDAMList(masterDataPDAM.data_list?.data?.data ?? [])
    }
  }, [masterDataPDAM])

  const openFileDialog = (type) => {
    if (type === "Input") {
      fileRefInput.current.click()
    }
    if (type === "Zone") {
      fileRefZone.current.click()
    }
    if (type === "SHPDMA") {
      fileRefSHPDMA.current.click()
    }
    if (type === "DBFDMA") {
      fileRefDBFDMA.current.click()
    }
    if (type === "SHPNet") {
      fileRefSHPNet.current.click()
    }
    if (type === "DBFNet") {
      fileRefDBFNet.current.click()
    }
    if (type === "Cust") {
      fileRefCust.current.click()
    }
  }

  const handleChange = async (selectorFiles, formik, type) => {
    if (selectorFiles) {
      const file = selectorFiles.files[0]
      const file_type = file?.name.split(".")
      const fileProp = {
        title: file?.name,
        subtitle: (Number(file?.size) * 0.001).toFixed(2) + " KB",
        message_error: false,
      }
      if (Number(file.size) < 15728640) {
        if (type === "Input") {
          formik.setFieldValue("input_excel", file)
          setFilePropInput(fileProp)
          fileRefInput.current.value = ""
        }
        if (type === "Zone") {
          formik.setFieldValue("zone_excel", file)
          setFilePropZone(fileProp)
          fileRefZone.current.value = ""
        }
        if (type === "Cust") {
          formik.setFieldValue("customer_excel", file)
          setFilePropCust(fileProp)
          fileRefCust.current.value = ""
        }
        if (type === "SHPDMA") {
          if (file_type[1] === "shp") {
            formik.setFieldValue("shpDMA", file)
            setFilePropSHPDMA(fileProp)
          } else {
            toastError("Format file harus berekstensi .shp")
          }
        }
        if (type === "SHPNet") {
          if (file_type[1] === "shp") {
            formik.setFieldValue("shpNet", file)
            setFilePropSHPNet(fileProp)
          } else {
            toastError("Format file harus berekstensi .shp")
          }
        }
        if (type === "DBFDMA") {
          if (file_type[1] === "dbf") {
            formik.setFieldValue("dbfDMA", file)
            setFilePropDBFDMA(fileProp)
          } else {
            toastError("Format file harus berekstensi .dbf")
          }
        }
      }
      if (type === "DBFNet") {
        if (file_type[1] === "dbf") {
          formik.setFieldValue("dbfNet", file)
          setFilePropDBFNet(fileProp)
        } else {
          toastError("Format file harus berekstensi .dbf")
        }
      }
    }
  }

  useEffect(() => {
    const updateGeojson = async () => {
      let values = formikRef?.current?.values
      if (values?.dbfNet && values?.shpNet) {
        let geojson = {
          type: "FeatureCollection",
        }
        const resShpNet = await convertGISToGeojson([values.shpNet, values.dbfNet])
        geojson.features = resShpNet
        formikRef?.current?.setFieldValue("pipeline_geojson", JSON.stringify(geojson))
      }

      if (values?.dbfDMA && values?.shpDMA) {
        let geojson = {
          type: "FeatureCollection",
        }
        const resShpDMA = await convertGISToGeojson([values.shpDMA, values.dbfDMA])
        geojson.features = resShpDMA
        formikRef?.current?.setFieldValue("dma_geojson", JSON.stringify(geojson))
      }
    }

    updateGeojson()
  }, [formikRef?.current?.values])

  const handleConfirm = () => {
    setFilePropInput({
      title: "Import Excel Input",
      subtitle: "File Excel Input",
      message_error: false,
    })
    setFilePropZone({
      title: "Import Excel Zone",
      subtitle: "File Excel Zone",
      message_error: false,
    })
    setFilePropCust({
      title: "Import Excel Pelanggan",
      subtitle: "File Excel Pelanggan",
      message_error: false,
    })
    setFilePropDBFDMA({
      title: "Import DBF DMA",
      subtitle: "File DBF DMA",
      message_error: false,
    })
    setFilePropDBFNet({
      title: "Import DBF Jaringan",
      subtitle: "File DBF Jaringan",
      message_error: false,
    })
    setFilePropSHPDMA({
      title: "Import SHP DMA",
      subtitle: "File SHP DMA",
      message_error: false,
    })
    setFilePropSHPNet({
      title: "Import SHP Jaringan",
      subtitle: "File SHP Jaringan",
      message_error: false,
    })
    toggle()
  }

  return (
    <>
      <Modal isOpen={show} modalTransition={{ timeout: 200 }} backdropTransition={{ timeout: 1300 }}>
        <LoaderModal show={hydraulicModel.pending} />
        <ModalHeader className="text-extra-bold pt-5">
          {" "}
          {title}{" "}
          <span className="close clickable" onClick={handleConfirm}>
            &times;
          </span>
        </ModalHeader>
        <Formik
          innerRef={formikRef}
          initialValues={{
            name: "",
            pdam_id: "",
          }}
          validationSchema={validation}
          onSubmit={(values, { setStatus, setSubmitting }) => {
            setStatus()
            if (type === "blank") {
              values.url = ""
              dispatch(HydraulicService.post(values))
            } else if (type === "pdam") {
              values.url = "/import_by_input"
              dispatch(HydraulicService.post(values))
            } else {
              if (values.input_excel || values.customer_excel || values.zone_excel || values.pipeline_geojson || values.dma_geojson) {
                let formData = new FormData()
                if (values.input_excel) {
                  formData.append("input_excel", values.input_excel)
                }
                if (values.customer_excel) {
                  formData.append("customer_excel", values.customer_excel)
                }
                if (values.zone_excel) {
                  formData.append("zone_excel", values.zone_excel)
                }
                if (values.pipeline_geojson) {
                  formData.append("pipeline_geojson", values.pipeline_geojson)
                }
                if (values.dma_geojson) {
                  formData.append("dma_geojson", values.dma_geojson)
                }
                formData.append("pdam_id", values.pdam_id)
                formData.append("name", values.name)

                dispatch(HydraulicService.post(formData))
              } else {
                toastError("Mohon untuk upload minimal satu file")
              }
            }
          }}
        >
          {(props) => (
            <Form style={{ marginTop: 30 }}>
              <ModalBody style={{ margin: "0px 20px" }}>
                <Input title="Nama Simulasi" name="name" errors={props.errors} touched={props.touched} {...props.getFieldProps("name")} placeholder="Masukkan nama simulasi" type="text" disabled={hydraulicModel.pending} />
                {type === "pdam" && (
                  <>
                    <Divider orientation="horizontal" />
                    <DynamicButton titleLeft="Pilih PDAM yang ingin anda simulasikan" iconLeft="info" />
                  </>
                )}
                <Select2
                  title={type !== "pdam" ? "PDAM" : ""}
                  name="pdam_id"
                  options={pdamList.map((item) => ({
                    value: item?.id,
                    label: item?.name,
                  }))}
                  errors={props.errors}
                  touched={props.touched}
                  value={props.values?.pdam_id}
                  placeholder="Pilih data PDAM"
                  onChange={(name, value) => {
                    props.setFieldValue("pdam_id", value)
                  }}
                  onBlur={props.setFieldTouched}
                  disabled={hydraulicModel.pending}
                />
                {type === "import" && (
                  <>
                    <Divider orientation="horizontal" />
                    <div className="form-group" style={{ marginTop: 20, marginBottom: 0 }}>
                      <label className="form-label">Import Excel</label>
                    </div>
                    <Row>
                      <Col md={6}>
                        <DynamicButton titleLeft={filePropInput?.title} subtitleLeft={filePropInput?.subtitle} toggle={() => openFileDialog("Input")} error={filePropInput?.message_error} />
                        <input
                          type="file"
                          ref={fileRefInput}
                          style={{ display: "none" }}
                          onChange={(e) => handleChange(e.target, props, "Input")}
                          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        />
                      </Col>
                      <Col md={6}>
                        <DynamicButton titleLeft={filePropZone?.title} subtitleLeft={filePropZone?.subtitle} toggle={() => openFileDialog("Zone")} error={filePropZone?.message_error} />
                        <input
                          type="file"
                          ref={fileRefZone}
                          style={{ display: "none" }}
                          onChange={(e) => handleChange(e.target, props, "Zone")}
                          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        />
                      </Col>
                      <Col md={12}>
                        <DynamicButton titleLeft={filePropCust?.title} subtitleLeft={filePropCust?.subtitle} toggle={() => openFileDialog("Cust")} error={filePropCust?.message_error} />
                        <input
                          type="file"
                          ref={fileRefCust}
                          style={{ display: "none" }}
                          onChange={(e) => handleChange(e.target, props, "Cust")}
                          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        />
                      </Col>
                    </Row>
                    <Divider orientation="horizontal" />
                    <Row>
                      <Col md={6}>
                        <div className="form-group" style={{ marginTop: 20, marginBottom: 0 }}>
                          <label className="form-label">Import SHP</label>
                        </div>
                        <DynamicButton titleLeft={filePropSHPDMA?.title} subtitleLeft={filePropSHPDMA?.subtitle} toggle={() => openFileDialog("SHPDMA")} error={filePropSHPDMA?.message_error} />
                        <input type="file" ref={fileRefSHPDMA} style={{ display: "none" }} onChange={(e) => handleChange(e.target, props, "SHPDMA")} accept=".shp" />
                        <DynamicButton titleLeft={filePropSHPNet?.title} subtitleLeft={filePropSHPNet?.subtitle} toggle={() => openFileDialog("SHPNet")} error={filePropSHPNet?.message_error} />
                        <input type="file" ref={fileRefSHPNet} style={{ display: "none" }} onChange={(e) => handleChange(e.target, props, "SHPNet")} accept=".shp" />
                      </Col>
                      <Col md={6}>
                        <div className="form-group" style={{ marginTop: 20, marginBottom: 0 }}>
                          <label className="form-label">Import DBF</label>
                        </div>
                        <DynamicButton titleLeft={filePropDBFDMA?.title} subtitleLeft={filePropDBFDMA?.subtitle} toggle={() => openFileDialog("DBFDMA")} error={filePropDBFDMA?.message_error} />
                        <input type="file" ref={fileRefDBFDMA} style={{ display: "none" }} onChange={(e) => handleChange(e.target, props, "DBFDMA")} accept=".dbf" />
                        <DynamicButton titleLeft={filePropDBFNet?.title} subtitleLeft={filePropDBFNet?.subtitle} toggle={() => openFileDialog("DBFNet")} error={filePropDBFNet?.message_error} />
                        <input type="file" ref={fileRefDBFNet} style={{ display: "none" }} onChange={(e) => handleChange(e.target, props, "DBFNet")} accept=".dbf" />
                      </Col>
                    </Row>
                  </>
                )}
              </ModalBody>
              <ModalFooter>
                <ButtonModal toggle={handleConfirm} confirmTitle={type === "import" ? "Import" : "Simpan"} disabledConfrim={hydraulicModel.pending} />
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  )
}

const mapStateToProps = ({ masterDataPDAM, hydraulicModel }) => {
  return { masterDataPDAM, hydraulicModel }
}

const mapDispatchToProps = (dispatch) => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalAdd)
