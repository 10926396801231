import { actionPending, actionSuccess, actionError } from "../actionTypes";

import { toastError } from "../../../components/commons/toast";

import API from "../API";
import moment from "moment";
import localization from "moment/locale/id";
import { handleResponse } from "../HandleRespone";
import { history } from "../../../utils/useHistory";

// ACTION TYPES TO DISPATCH: METHOD_URL_TYPES
export const GET_HYDRAULIC_PENDING = "GET_HYDRAULIC_PENDING";
export const GET_HYDRAULIC_SUCCESS = "GET_HYDRAULIC_SUCCESS";
export const GET_HYDRAULIC_ERROR = "GET_HYDRAULIC_ERROR";
export const POST_HYDRAULIC_PENDING = "POST_HYDRAULIC_PENDING";
export const POST_HYDRAULIC_SUCCESS = "POST_HYDRAULIC_SUCCESS";
export const POST_HYDRAULIC_ERROR = "POST_HYDRAULIC_ERROR";
export const COPY_HYDRAULIC_PENDING = "COPY_HYDRAULIC_PENDING";
export const COPY_HYDRAULIC_SUCCESS = "COPY_HYDRAULIC_SUCCESS";
export const COPY_HYDRAULIC_ERROR = "COPY_HYDRAULIC_ERROR";
export const PUT_HYDRAULIC_PENDING = "PUT_HYDRAULIC_PENDING";
export const PUT_HYDRAULIC_SUCCESS = "PUT_HYDRAULIC_SUCCESS";
export const PUT_HYDRAULIC_ERROR = "PUT_HYDRAULIC_ERROR";
export const DELETE_HYDRAULIC_PENDING = "DELETE_HYDRAULIC_PENDING";
export const DELETE_HYDRAULIC_SUCCESS = "DELETE_HYDRAULIC_SUCCESS";
export const DELETE_HYDRAULIC_ERROR = "DELETE_HYDRAULIC_ERROR";
export const DOWNLOAD_HYDRAULIC_PENDING = "DOWNLOAD_HYDRAULIC_PENDING";
export const DOWNLOAD_HYDRAULIC_SUCCESS = "DOWNLOAD_HYDRAULIC_SUCCESS";
export const DOWNLOAD_HYDRAULIC_ERROR = "DOWNLOAD_HYDRAULIC_ERROR";

// URL: URL_{URL}
const HYDRAULIC_URL = `v1/hydraulic_model`;

const get = (param, resolve, callback) => (dispatch) => {
  dispatch(actionPending(GET_HYDRAULIC_PENDING));
  API.get(HYDRAULIC_URL, { params: param })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(GET_HYDRAULIC_SUCCESS, res));
      if (resolve) {
        const records_total = res.data.total;
        let data = res.data.data.map((item, i) => ({
          ...item,
          no: `${
            i + 1 === 10
              ? param?.page === 0 || param?.page - 1 === 0
                ? 1
                : param?.page
              : param?.page === 0 || param?.page - 1 === 0
              ? ""
              : param?.page - 1
          } ${i + 1 === 10 ? 0 : i + 1}`,
          created_time: item?.created_at
            ? moment(item?.created_at)
                .locale("id", localization)
                .format("dddd, DD/MM/YYYY, HH:mm")
            : "-",
          updated_time: item?.updated_at
            ? moment(item?.updated_at)
                .locale("id", localization)
                .format("dddd, DD/MM/YYYY, HH:mm")
            : "-",
        }));
        resolve({
          data: data,
          totalCount: records_total,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(GET_HYDRAULIC_ERROR));
      let not_logout = handleResponse(err?.response);
      if (not_logout) {
        let message = err?.response?.data?.message;
        if (message?.includes("java")) {
          message = "Data tidak ditemukan";
        }
        toastError(message);
      }
    });
};

const post = (param, callback) => (dispatch) => {
  dispatch(actionPending(POST_HYDRAULIC_PENDING));
  API.post(
    HYDRAULIC_URL + (param?.url !== undefined ? param?.url : "/import"),
    param
  )
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(POST_HYDRAULIC_SUCCESS, res));
      const id = res?.data?.data?.id;
      history.push(`/hydraulic-model/simulation/${id}`);
    })
    .catch((err) => {
      let message = err?.response?.data?.message;
      if (message?.includes("java")) {
        message = "Format file tidak sesuai";
      }
      toastError(message);
      dispatch(actionError(POST_HYDRAULIC_ERROR));
    });
};

const copy = (param, callback) => (dispatch) => {
  dispatch(actionPending(COPY_HYDRAULIC_PENDING));
  API.post(HYDRAULIC_URL + "/copy", param)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(COPY_HYDRAULIC_SUCCESS, res));
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      let message = err?.response?.data?.message;
      if (message?.includes("java")) {
        message = "Gagal menyalin dokumen";
      }
      toastError(message);
      dispatch(actionError(COPY_HYDRAULIC_ERROR));
    });
};

const put = (param, callback) => (dispatch) => {
  dispatch(actionPending(PUT_HYDRAULIC_PENDING));
  API.put(HYDRAULIC_URL, param)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(PUT_HYDRAULIC_SUCCESS, res));
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      let message = err?.response?.data?.message;
      if (message?.includes("java")) {
        message = "Format file tidak sesuai";
      }
      toastError(message);
      dispatch(actionError(PUT_HYDRAULIC_ERROR));
    });
};

const deleted = (param, callback) => (dispatch) => {
  dispatch(actionPending(DELETE_HYDRAULIC_PENDING));
  API.delete(HYDRAULIC_URL + `?id=${param}`)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(DELETE_HYDRAULIC_SUCCESS, res));
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(DELETE_HYDRAULIC_ERROR));
      let message = err?.response?.data?.message;
      if (message?.includes("java")) {
        message = "Gagal menghapus data";
      }
      toastError(message);
    });
};

const download = (param) => (dispatch) => {
  dispatch(actionPending(DOWNLOAD_HYDRAULIC_PENDING));
  API.get(HYDRAULIC_URL + "/export", {
    params: param,
    responseType: "arraybuffer",
  })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(DOWNLOAD_HYDRAULIC_SUCCESS, res));
      var fileName = `Data Simulasi - ${param?.name}`;
      let blob = new Blob([res.data], { type: "application/zip" });

      const downloadUrl = URL.createObjectURL(blob);
      let a = document.createElement("a");
      a.href = downloadUrl;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
    })
    .catch((err) => {
      dispatch(actionError(DOWNLOAD_HYDRAULIC_ERROR));
      let not_logout = handleResponse(err?.response);
      if (not_logout) {
        let message = err?.response?.data?.message;
        if (message?.includes("java")) {
          message = "Data tidak ditemukan";
        }
        toastError(message);
      }
    });
};

const hydraulic = {
  get,
  post,
  deleted,
  put,
  copy,
  download,
};
export default hydraulic;
