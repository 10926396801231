import React, { useState, useRef } from "react";
import { connect, useDispatch } from "react-redux";
import * as Yup from "yup";
import { withRouter } from "react-router-dom";
import { Formik, Form } from "formik";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Container,
  Row,
  Col,
} from "reactstrap";
import Divider from "@material-ui/core/Divider";
import Input from "../../../forms/Input";
import { getUser } from "../../../../utils/User";
import ButtonModal from "../../../modal/ButtonModal";
import Loader from "../../../commons/Loader";

import UserService from "../../../../store/actions/user";

function ChangeProfil({ t, show, toggle, pendingProfile }) {
  const currentUser = getUser().user;
  const dispatch = useDispatch();
  const [visibility, setVisibility] = useState(false);

  const initialValues = {
    first_name: currentUser?.first_name ?? "",
    mid_name: currentUser?.mid_name ?? "",
    last_name: currentUser?.last_name ?? "",
    username: currentUser?.username ?? "",
    email: currentUser?.email ?? "",
    password: "",
  };

  const validation = Yup.object().shape({
    first_name: Yup.string().required("Nama depan wajib diisi").nullable(),
    last_name: Yup.string().required("Nama belakang wajib diisi").nullable(),
    username: Yup.string().required("Username wajib diisi").nullable(),
    email: Yup.string()
      .email("Email yang Anda masukkan tidak valid")
      .required("Email wajib diisi")
      .nullable(),
    password: Yup.string().required("Kata sandi wajib diisi").nullable(),
  });

  return (
    <>
      {pendingProfile && <Loader loading={pendingProfile} />}
      <Modal
        isOpen={show}
        modalTransition={{ timeout: 700 }}
        backdropTransition={{ timeout: 1300 }}
      >
        <ModalHeader className="text-extra-bold pt-5">
          Edit Profil
          <span className="close clickable" onClick={toggle}>
            &times;
          </span>
        </ModalHeader>
        <Formik
          initialValues={initialValues}
          validationSchema={validation}
          onSubmit={(values, { setStatus, setSubmitting }) => {
            const callback = () => {
              toggle();
              let user = getUser();
              user.user.first_name = values.first_name;
              user.user.mid_name = values.mid_name;
              user.user.last_name = values.last_name;
              user.user.username = values.username;
              user.user.email = values.email;
              localStorage.setItem("user_aws", JSON.stringify(user));
            };

            dispatch(UserService.changeProfile(values, callback));
            setSubmitting(false);
            setStatus();
          }}
        >
          {(props) => (
            <Form>
              <ModalBody>
                <Container>
                  <Row>
                    <Col md={6}>
                      <Input
                        title="Nama Depan"
                        name="first_name"
                        errors={props.errors}
                        touched={props.touched}
                        {...props.getFieldProps("first_name")}
                        placeholder="Nama Depan"
                        type="text"
                      />
                    </Col>
                    <Col md={6}>
                      <Input
                        title="Nama Tengah"
                        name="mid_name"
                        errors={props.errors}
                        touched={props.touched}
                        {...props.getFieldProps("mid_name")}
                        placeholder="Nama Tengah"
                        type="text"
                      />
                    </Col>
                  </Row>
                  <Input
                    title="Nama Belakang"
                    name="last_name"
                    errors={props.errors}
                    touched={props.touched}
                    {...props.getFieldProps("last_name")}
                    placeholder="Nama Belakang"
                    type="text"
                  />
                  <Divider
                    orientation="horizontal"
                    variant="middle"
                    style={{ margin: "30px 0px 20px" }}
                  />
                  <Input
                    title="Username"
                    name="username"
                    errors={props.errors}
                    touched={props.touched}
                    {...props.getFieldProps("username")}
                    placeholder="Username"
                    type="text"
                  />
                  <Input
                    title="Email"
                    name="email"
                    errors={props.errors}
                    touched={props.touched}
                    {...props.getFieldProps("email")}
                    placeholder="Email"
                    type="text"
                  />
                  <Divider
                    orientation="horizontal"
                    variant="middle"
                    style={{ margin: "30px 0px 20px" }}
                  />
                  <Input
                    title="Password"
                    name="password"
                    errors={props.errors}
                    touched={props.touched}
                    {...props.getFieldProps("password")}
                    placeholder="Password"
                    type={visibility ? "text" : "password"}
                    righticon={visibility ? "visibility_off" : "visibility"}
                    toggleicon={() => setVisibility(!visibility)}
                  />
                </Container>
              </ModalBody>
              <ModalFooter>
                <ButtonModal
                  toggle={toggle}
                  confirmTitle="Ubah Profil"
                  disabledConfrim={props?.isSubmitting}
                />
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
}

const mapStateToProps = ({ user: { pendingProfile } }) => {
  return { pendingProfile };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ChangeProfil)
);
