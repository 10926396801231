import React, { useState, useRef, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { getUser } from "../../../utils/User";
import Select2 from "../../../components/forms/Select2";
import ButtonModal from "../../../components/modal/ButtonModal";
import DynamicButton from "../../../components/forms/DynamicButton";
import ConfirmationModal from "../../../components/modal/ConfirmModal";
import CustomerService from "../../../store/actions/master_data/customer";
import LoaderModal from "../../../components/commons/LoaderModal";
import { toastError } from "../../../components/commons/toast";

function ModalAdd({
  show,
  toggle,
  refresh,
  masterDataPDAM,
  masterDataCustomer,
}) {
  const formikRef = useRef();
  const fileRefExcel = useRef();
  const dispatch = useDispatch();

  const [filePropExcel, setFilePropExcel] = useState({
    title: "Import Excel Pelanggan",
    subtitle: "File XLS",
    message_error: false,
  });
  const [pdam, setPDAM] = useState([]);
  const [showModalConfirm, setShowModalConfirm] = useState(false);

  useEffect(() => {
    if (masterDataPDAM) {
      let pdam_list = masterDataPDAM?.data_list?.data.data;
      setPDAM(pdam_list);
    }
  }, [masterDataPDAM]);

  const openFileDialog = () => {
    fileRefExcel.current.click();
  };

  const handleChange = async (selectorFiles, formik, type) => {
    if (selectorFiles) {
      const file = selectorFiles.files[0];
      const fileProp = {
        title: file?.name,
        subtitle: (Number(file?.size) * 0.001).toFixed(2) + " KB",
        message_error: false,
      };
      if (Number(file.size) < 15728640) {
        formik.setFieldValue("file", file);
        setFilePropExcel(fileProp);
      } else {
        toastError("Maksimal file yang dapat diimport 15 MB");
      }
      fileRefExcel.current.value = "";
    }
  };

  const validation = Yup.object().shape({
    pdam_id: Yup.string().required("PDAM wajib diisi").nullable(),
  });

  const handleConfirm = () => {
    setFilePropExcel({
      title: "Import Excel Pelanggan",
      subtitle: "File XLS",
      message_error: false,
    });
    setShowModalConfirm(false);
    toggle(false);
  };

  const handleFile = (formik) => {
    if (!formik.values.file) {
      setFilePropExcel({
        title: "Import Excel Pelanggan",
        subtitle: "File XLS",
        message_error: "File wajib diisi",
      });
    }
  };

  const currentUser = getUser();

  return (
    <>
      <Modal
        isOpen={show}
        modalTransition={{ timeout: 200 }}
        backdropTransition={{ timeout: 1300 }}
      >
        <LoaderModal show={masterDataCustomer?.pending_post} />
        <ModalHeader className="text-extra-bold pt-5">
          {" "}
          Import Pelanggan{" "}
          <span
            className="close clickable"
            onClick={() => setShowModalConfirm(true)}
          >
            &times;
          </span>
        </ModalHeader>
        <Formik
          innerRef={formikRef}
          initialValues={{
            pdam_id: currentUser?.user?.pdam?.id ?? "",
            file: null,
          }}
          validationSchema={validation}
          onSubmit={(values, { setStatus, setSubmitting }) => {
            setStatus();
            if (values.file) {
              let formData = new FormData();
              formData.append("file", values.file);
              formData.append("pdam_id", values.pdam_id);

              const callback = () => {
                toggle();
                setFilePropExcel({
                  title: "Import Excel Pelanggan",
                  subtitle: "File XLS",
                  message_error: false,
                });
                refresh();
              };
              dispatch(CustomerService.post(formData, callback));
            }

            if (!values.file) {
              setFilePropExcel({
                title: "Import Excel Pelanggan",
                subtitle: "File XLS",
                message_error: "File XLS wajib diisi",
              });
            }
          }}
        >
          {(props) => (
            <Form style={{ marginTop: 30 }}>
              <ModalBody style={{ margin: "0px 20px" }}>
                <Select2
                  title="PDAM"
                  name="pdam_id"
                  options={pdam?.map((item) => ({
                    value: item?.id,
                    label: item?.name,
                  }))}
                  errors={props.errors}
                  touched={props.touched}
                  value={props.values?.pdam_id}
                  placeholder="Pilih data PDAM"
                  onChange={(name, value) => {
                    props.setFieldValue("pdam_id", value);
                  }}
                  onBlur={props.setFieldTouched}
                  disabled={currentUser?.user?.pdam}
                />

                <DynamicButton
                  titleLeft={filePropExcel?.title}
                  subtitleLeft={filePropExcel?.subtitle}
                  iconLeft="insert_drive_file"
                  toggle={() => openFileDialog()}
                  error={filePropExcel?.message_error}
                />

                <input
                  type="file"
                  ref={fileRefExcel}
                  style={{ display: "none" }}
                  onChange={(e) => handleChange(e.target, props, "Excel")}
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                />
              </ModalBody>
              <ModalFooter>
                <ButtonModal
                  toggle={() => setShowModalConfirm(true)}
                  confirmTitle="Import"
                  confirm={() => handleFile(props)}
                  disabledConfrim={masterDataCustomer?.pending_post}
                />
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </Modal>

      <ConfirmationModal
        isDelete
        show={showModalConfirm}
        toggle={() => setShowModalConfirm(false)}
        confirm={handleConfirm}
        cancelTitle="Tidak"
        confirmTitle="Ya"
        message="Yakin ingin membatalkan import data?"
        icon="info"
      />
    </>
  );
}

const mapStateToProps = ({
  masterDataPDAM,
  masterDataDMA,
  masterDataSubDMA,
  masterDataCustomer,
}) => {
  return {
    masterDataPDAM,
    masterDataDMA,
    masterDataSubDMA,
    masterDataCustomer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalAdd);
