import { Row, Col } from 'reactstrap';
import Select2 from "../Select2";

const DynamicButton = ({ titleLeft, titleRight, iconLeft, iconRight, color, toggle, outline, subtitleLeft, error }) => {
    return (
        <>
            <div
                className="button"
                style={{
                    color: color ?? '#242726',
                    width: '100%',
                    padding: '21px 25px',
                    justifyContent: 'space-between',
                    cursor: toggle ? 'pointer' : '',
                    background: '#F3F3F894',
                    border: `solid 1px ${error ? '#D10D0D' : '#24272605'}`,
                    margin: '20px 0px'
                }}
                onClick={toggle}
            >
                <div style={{ float: 'left', display: 'flex' }}>
                    {iconLeft && (
                        <span className={outline ? "material-icons-outlined left-icon" : "material-icons-round left-icon"} style={{ color: '#A5A5C0', fontSize: subtitleLeft ? '50px' : '' }}>{iconLeft}</span>
                    )}
                    {titleLeft && (
                        <div>
                            <p className="text-bold" style={{ margin: 0 }}>{titleLeft}</p>
                            {subtitleLeft && <p className="text-bold" style={{ color: '#A5A5C0', marginTop: 10, marginBottom: 0 }}>{subtitleLeft}</p>}
                        </div>

                    )}
                </div>
                <div style={{ float: 'right', display: 'flex' }}>
                    {titleRight && (
                        <span>{titleRight}</span>
                    )}
                    {iconRight && (
                        <span className="material-icons-round left-icon">{iconRight}</span>
                    )}
                </div>
            </div>
            {error && <p style={{ color: '#D10D0D', marginTop: '-15px', fontSize: 12 }}>{error}</p>}
        </>
    )
}

export default DynamicButton