import React from "react";
import Button from "../buttons/Button";

function ButtonModal({
  deleted,
  toggle,
  confirm,
  confirmTitle,
  cancelTitle,
  icon,
  disabledConfrim,
  reset,
  hideConfirm,
  isLoading,
}) {
  return (
    <div style={{ margin: "20px 0px" }}>
      <div
        style={{
          lineHeight: "30px",
          display: "flex",
          justifyContent: reset ? "space-between" : "",
          alignItems: "center",
        }}
      >
        {reset && (
          <div>
            <p
              className="text-bold"
              style={{ cursor: "pointer", margin: 0 }}
              onClick={reset}
            >
              Reset
            </p>
          </div>
        )}
        <div>
          <Button
            variant="button secondary"
            rounded
            title={cancelTitle ?? "Batal"}
            onClick={toggle}
            leftIcon={icon ? "close" : null}
            type="Button"
            disabled={disabledConfrim}
          />
          {!hideConfirm && (
            <Button
              variant={"button " + (deleted ? "danger" : "primary")}
              rounded
              title={confirmTitle ?? "Ya, hapus"}
              onClick={confirm}
              leftIcon={icon ? "done" : null}
              type={deleted ? "Button" : "Submit"}
              isLoading={isLoading}
              disabled={disabledConfrim}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default ButtonModal;
