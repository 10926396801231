import {
  GET_WATER_BALANCE_PENDING,
  GET_WATER_BALANCE_SUCCESS,
  GET_WATER_BALANCE_ERROR,
  PREPARE_FORM_WATER_BALANCE_PENDING,
  PREPARE_FORM_WATER_BALANCE_SUCCESS,
  PREPARE_FORM_WATER_BALANCE_ERROR,
  PUT_WATER_BALANCE_PENDING,
  PUT_WATER_BALANCE_SUCCESS,
  PUT_WATER_BALANCE_ERROR,
  DIAGRAMS_WATER_BALANCE_PENDING,
  DIAGRAMS_WATER_BALANCE_SUCCESS,
  DIAGRAMS_WATER_BALANCE_ERROR,
  EXPORT_WATER_BALANCE_PENDING,
  EXPORT_WATER_BALANCE_SUCCESS,
  EXPORT_WATER_BALANCE_ERROR,
} from "../../actions/water_balance";

const initialState = {
  pending: false,
  error: null,
  data_list: null,
  pending_prepare: false,
  data_prepare: null,
  pending_put: false,
  data_put: false,
  pending_diagrams: false,
  data_diagrams: null,
  data_export: null,
  pending_export: false,
};

const waterBalance = (state = initialState, action) => {
  switch (action.type) {
    case GET_WATER_BALANCE_PENDING:
      return {
        ...state,
        pending: true,
      };
    case GET_WATER_BALANCE_SUCCESS:
      return {
        ...state,
        pending: false,
        data_list: action.data,
      };
    case GET_WATER_BALANCE_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case PREPARE_FORM_WATER_BALANCE_PENDING:
      return {
        ...state,
        pending_prepare: true,
      };
    case PREPARE_FORM_WATER_BALANCE_SUCCESS:
      return {
        ...state,
        pending_prepare: false,
        data_prepare: action.data,
      };
    case PREPARE_FORM_WATER_BALANCE_ERROR:
      return {
        ...state,
        pending_prepare: false,
        error: action.error,
      };
    case PUT_WATER_BALANCE_PENDING:
      return {
        ...state,
        pending_put: true,
      };
    case PUT_WATER_BALANCE_SUCCESS:
      return {
        ...state,
        pending_put: false,
        data_put: action.data,
      };
    case PUT_WATER_BALANCE_ERROR:
      return {
        ...state,
        pending_put: false,
        error: action.error,
      };
    case DIAGRAMS_WATER_BALANCE_PENDING:
      return {
        ...state,
        pending_diagrams: true,
      };
    case DIAGRAMS_WATER_BALANCE_SUCCESS:
      return {
        ...state,
        pending_diagrams: false,
        data_diagrams: action.data,
      };
    case DIAGRAMS_WATER_BALANCE_ERROR:
      return {
        ...state,
        pending_diagrams: false,
        error: action.error,
      };
    case EXPORT_WATER_BALANCE_PENDING:
      return {
        ...state,
        pending_export: true,
      };
    case EXPORT_WATER_BALANCE_SUCCESS:
      return {
        ...state,
        pending_export: false,
        data_export: action.data,
      };
    case EXPORT_WATER_BALANCE_ERROR:
      return {
        ...state,
        pending_export: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default waterBalance;
