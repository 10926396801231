import { Route, Switch } from "react-router-dom";

// styles
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/styles/styles.scss";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

import PrivateRoute from "./components/commons/privateRoute";
import { Role } from "./utils/Constants";
import { getUser } from "./utils/User";
import proj4 from "proj4";

// views
import Login from "./views/auth/Login";
import System from "./views/system/System";
import Dashboard from "./views/dashboard/Dashboard";
import LoggerData from "./views/logger_data/LoggerData";
import EmptyData from "./views/empty_state/empty_data";
import CantAccess from "./views/empty_state/cant_access";
import NotFound from "./views/empty_state/NotFound";
import ForgotPassword from "./views/auth/ForgotPassword";
import MasterData from "./views/master_data/MasterData";
import DetailDMA from "./views/master_data/DMA/DetailDMA";
import ChangePassword from "./views/auth/ChangePassword";
import WarningSystem from "./views/warning_system/WarningSystem";
import StepTestSupport from "./views/step_test_support/StepTestSupport";
import FillPassword from "./views/auth/FillPassword";
import RepairWork from "./views/repair_work_report";
import WaterBalance from "./views/water_balance";
import DetailRepairWork from "./views/repair_work_report/DetailRepairWork";
import CommercialLosses from "./views/commercial_losses";
import DetailCommercialLosses from "./views/commercial_losses/DetailCommercial";
import HydraulicModel from "./views/hydraulic_model";
import HydraulicMap from "./views/hydraulic_model/HydraulicMap";
import ILI from "./views/infrastructure-leakage-index/InfrastructureLeakageIndex";
import Notification from "./views/notification";

const all = [
  Role.Superadmin,
  Role.It_admin,
  Role.User_cust_rep,
  Role.Viewer,
  Role.Kadiv,
];
const admin = [Role.Superadmin, Role.It_admin];
const user_cust_kadiv = [Role.Superadmin, Role.User_cust_rep, Role.Kadiv];
const notIncIt = [Role.Superadmin, Role.User_cust_rep, Role.Viewer, Role.Kadiv];

const App = () => {
  // Important, define ESRI CRS for compatibility GeoJSON Projection from QGIS(ESRI:54004) to WGS84
  proj4.defs(
    "ESRI:54004",
    "+proj=merc +lon_0=0 +k=1 +x_0=0 +y_0=0 +ellps=WGS84 +datum=WGS84 +units=m +no_defs "
  );

  proj4.defs(
    "WGS:84",
    "+proj=longlat +ellps=WGS84 +datum=WGS84 +no_defs "
  );

  const user = getUser();

  let isSwitch = true;
  if (
    user?.user?.role_code === Role.Superadmin ||
    user?.user?.role_code === Role.Kadiv
  ) {
    isSwitch = false;
  }
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={false}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange
        draggable
        pauseOnHover
      />
      <Switch>
        <Route path="/" exact component={Login} />
        <Route path="/login" exact component={Login} />
        <Route path="/forgot-password" exact component={ForgotPassword} />
        <Route
          path="/reset-password/:id/:token"
          exact
          component={ChangePassword}
        />
        <Route
          path="/fill-password/:verification_code"
          exact
          component={FillPassword}
        />
        <PrivateRoute
          exact
          path="/notifikasi"
          component={Notification}
          roles={all}
        />
        <PrivateRoute
          exact
          path="/dashboard"
          component={Dashboard}
          roles={notIncIt}
          mustSwitch={isSwitch}
        />
        <PrivateRoute
          exact
          path="/data-logger"
          component={LoggerData}
          roles={notIncIt}
          mustSwitch
        />
        <PrivateRoute path="/system" component={System} roles={all} />
        <PrivateRoute
          path="/data-master"
          component={MasterData}
          roles={user_cust_kadiv}
        />
        <PrivateRoute
          path="/dma/detail/:id"
          component={DetailDMA}
          roles={user_cust_kadiv}
        />
        <PrivateRoute
          path="/warning-system"
          component={WarningSystem}
          roles={notIncIt}
          mustSwitch
        />
        <PrivateRoute
          path="/commercial-losses"
          exact
          component={CommercialLosses}
          roles={notIncIt}
          mustSwitch
        />
        <PrivateRoute
          path="/commercial-losses/detail/:id"
          component={DetailCommercialLosses}
          roles={notIncIt}
        />
        <PrivateRoute
          path="/step-test-support"
          component={StepTestSupport}
          roles={notIncIt}
          mustSwitch
        />
        <PrivateRoute
          path="/repair-work-report"
          component={RepairWork}
          exact
          roles={notIncIt}
        />
        <PrivateRoute
          path="/repair-work-report/detail/:id"
          component={DetailRepairWork}
          roles={notIncIt}
          hideHeader
        />
        <PrivateRoute path="/ili" component={ILI} roles={notIncIt} />
        <PrivateRoute
          path="/water-balance"
          component={WaterBalance}
          exact
          roles={notIncIt}
          mustSwitch
        />
        <PrivateRoute
          path="/hydraulic-model"
          component={HydraulicModel}
          exact
          roles={notIncIt}
        />
        <PrivateRoute
          path="/hydraulic-model/simulation/:id"
          component={HydraulicMap}
          exact
          roles={notIncIt}
        />
        <PrivateRoute
          path="/hydraulic-model/simulation/:type/:id"
          component={HydraulicMap}
          exact
          roles={notIncIt}
        />
        <PrivateRoute path="/access-denied" exact component={CantAccess} />
        <Route path="*" component={NotFound} />
      </Switch>
    </>
  );
};

export default App;
