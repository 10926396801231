import { useState } from "react";
import CantAccess from '../../assets/images/empty-state/Tidak Ada Akses.svg';


const EmptyState = (props) => {
    // const [showModal, setShowModal] = useState(props);
    const showModal =  props.showModal;
   
    return (
        <div className="empty-state">
            <img src={CantAccess} className="img-empty-state" alt="account"/>
            <div className="empty-state-caption">
                <h5>TIDAK ADA AKSES</h5>
                <p>Anda tidak memiliki akses ke halaman ini</p>
            </div>
        </div>
    );
  };
  
  export default EmptyState;