import {
  GET_USER_PENDING,
  GET_USER_SUCCESS,
  GET_USER_ERROR,
} from "../../../actions/master_data/user";

const initialState = {
    pending: false,
    error: null,
    data: null,
};

const users = (state = initialState, action) => {
  switch (action.type) {
    case GET_USER_PENDING:
        return {
            ...state,
            pending: true,
        };
    case GET_USER_SUCCESS:
        return {
            ...state,
            pending: false,
            data: action.data,
        };
    case GET_USER_ERROR:
        return {
            ...state,
            pending: false,
            error: action.error,
        };
    default:
      return state;
  }
};

export default users;
