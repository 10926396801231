import {
  useState,
  useRef,
  useImperativeHandle,
  forwardRef,
  useEffect,
} from "react";
import { connect, useDispatch } from "react-redux";
import Input from "../../../components/forms/Input";
import Select2Checkbox from "../../../components/forms/Select2Checkbox";
import Select2Multi from "../../../components/forms/Select2Multi";
import Select2 from "../../../components/forms/Select2";
import LockIcon from "@material-ui/icons/Lock";
import AlternateEmailIcon from "@material-ui/icons/AlternateEmail";
import { AlternateEmail } from "@material-ui/icons";
import { Formik, Form } from "formik";
import { history } from "../../../utils/History";
import Button from "../../../components/buttons/Button";
import ConfirmationModal from "../../../components/modal/ConfirmModal";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Container,
} from "reactstrap";
import Divider from "@material-ui/core/Divider";
import masterDataUserService from "../../../store/actions/master_data/user";
import masterDataPDAMService from "../../../store/actions/master_data/pdam";
import { values } from "regenerator-runtime";

const FormUserAdaroModal = forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const formikRef = useRef();
  const [showModalConfirm, setShowModalConfirm] = useState(false);

  const [mstPDAMS, setMstPDAMS] = useState([]);
  const [pdams, setPdams] = useState([]);
  const [actionType, setActionType] = useState("add");
  const [messageConfirm, setMessageConfirm] = useState("");
  const [valueConfirm, setValueConfirm] = useState(null);
  const [initialValues, setInitialValue] = useState({});

  useImperativeHandle(ref, () => ({
    toggle() {
      callToggle();
    },
    setInitialValues(values) {
      let val = values;
      if (values.is_all_pdam === 1) {
        val.user_pdams = ["all"];
      } else {
        val.user_pdams = values.pdam_list[0]?.id;
      }
      setInitialValue(val);
    },
    setActionType(type) {
      setActionType(type);
    },
  }));

  const handleConfirm = () => {
    setShowModalConfirm(false);
    if (messageConfirm === "submit") {
      save(valueConfirm);
    } else {
      callToggle();
    }
  };

  const callToggle = () => {
    setShow(!show);
  };

  const onClickCancel = () => {
    callToggle();
  };

  const save = (param) => {
    param.role_code = "viewer";
    param.is_all_pdam = param.user_pdams?.includes("all") ? 1 : 0;
    param.user_pdams = [param.user_pdams];
    new Promise((resolve) => {
      if (actionType === "add") {
        dispatch(masterDataUserService.post(param, resolve));
      } else {
        dispatch(masterDataUserService.put(param, resolve));
      }
    }).then((res) => {
      if (props.onSubmited) {
        props.onSubmited();
      }
    });
  };

  const getPDAM = () => {
    new Promise((resolve) => {
      let param = {
        length: 9999,
      };
      dispatch(masterDataPDAMService.get(param, resolve));
    }).then((res) => {
      setMstPDAMS(res?.data);
    });
  };

  const displayTitle = () => {
    switch (actionType) {
      case "add":
        return "Tambah User PDAM";
      case "edit":
        return "Edit User PDAM";
      case "detail":
        return "Detail User PDAM";
      default:
        return "Tambah User PDAM";
    }
  };

  useEffect(() => {
    getPDAM();
  }, []);

  useEffect(() => {
    let newMstPDAMS = [...mstPDAMS]?.map((item) => ({
      value: item?.id,
      label: item?.name,
    }));
    // newMstPDAMS.splice(0, 0, { value: "all", label: "Semua PDAM" });
    setPdams(newMstPDAMS);
  }, [mstPDAMS]);

  return (
    <>
      <Modal
        isOpen={show}
        modalTransition={{ timeout: 200 }}
        // backdropTransition={{ timeout: 1300 }}
        toggle={callToggle}
      >
        <ModalHeader className="text-extra-bold pt-5">
          {displayTitle()}
          <span className="close clickable" onClick={callToggle}>
            &times;
          </span>
        </ModalHeader>
        <ModalBody>
          <Container>
            <Formik
              innerRef={formikRef}
              initialValues={{
                id: ["edit", "detail"].includes(actionType)
                  ? initialValues?.id
                  : "",
                first_name: ["edit", "detail"].includes(actionType)
                  ? initialValues?.first_name
                  : "",
                mid_name: ["edit", "detail"].includes(actionType)
                  ? initialValues?.mid_name
                  : "",
                last_name: ["edit", "detail"].includes(actionType)
                  ? initialValues?.last_name
                  : "",
                email: ["edit", "detail"].includes(actionType)
                  ? initialValues?.email
                  : "",
                username: ["edit", "detail"].includes(actionType)
                  ? initialValues?.username
                  : "",
                role_code: ["edit", "detail"].includes(actionType)
                  ? initialValues?.role_code
                  : "",
                status: ["edit", "detail"].includes(actionType)
                  ? initialValues?.status
                  : "",
                user_pdams: ["edit", "detail"].includes(actionType)
                  ? initialValues?.user_pdams
                  : "",
                is_all_pdam: ["edit", "detail"].includes(actionType)
                  ? initialValues?.is_all_pdam
                  : 0,
              }}
              validate={(values) => {
                const errors = {};

                // check first name
                if (!values.first_name) {
                  errors.first_name = "Nama Depan wajib diisi";
                }

                // check mid name
                // if (!values.mid_name) {
                //   errors.mid_name = 'Nama Tengah wajib diisi';
                // }

                // check last name
                if (!values.last_name) {
                  errors.last_name = "Nama Belakang wajib diisi";
                }

                // check email
                if (!values.email) {
                  errors.email = "Email wajib diisi";
                } else if (
                  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                ) {
                  errors.email = "Email tidak valid";
                }

                // check username
                if (!values.username) {
                  errors.username = "Username wajib diisi";
                }

                // check pdam
                if (values.user_pdams.length === 0) {
                  errors.user_pdams = "PDAM wajib diisi";
                }

                // check status
                if (!values.status && values.status !== 0) {
                  errors.status = "Status wajib diisi";
                }
                return errors;
              }}
              onSubmit={(values, { setSubmitting }) => {
                // setTimeout(() => {
                // console.log(JSON.stringify(values, null, 2));
                if (actionType === "add") {
                  save(values);
                } else {
                  setValueConfirm(values);
                }
                // setSubmitting(false);
                // }, 400);
              }}
            >
              {(props) => (
                <Form>
                  <div className="row">
                    <div className="col-6">
                      <Input
                        title="Nama Depan"
                        label="first_name"
                        placeholder="Masukkan nama depan"
                        type="text"
                        disabled={actionType === "detail"}
                        errors={props?.errors}
                        touched={props?.touched}
                        {...props?.getFieldProps("first_name")}
                        value={props.values.first_name}
                      />
                    </div>
                    <div className="col-6">
                      <Input
                        title="Nama Tengah"
                        label="mid_name"
                        placeholder="Masukkan nama tengah"
                        type="text"
                        disabled={actionType === "detail"}
                        errors={props?.errors}
                        touched={props?.touched}
                        {...props?.getFieldProps("mid_name")}
                        value={props.values.mid_name}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <Input
                        title="Nama Belakang"
                        label="last_name"
                        placeholder="Masukkan nama belakang"
                        type="text"
                        disabled={actionType === "detail"}
                        errors={props?.errors}
                        touched={props?.touched}
                        {...props?.getFieldProps("last_name")}
                        value={props.values.last_name}
                      />
                    </div>
                  </div>
                  <Divider
                    orientation="horizontal"
                    variant="middle"
                    style={{ margin: "30px 0px 20px" }}
                  />
                  <div className="row">
                    <div className="col-12">
                      <Input
                        title="Username"
                        label="username"
                        placeholder="Masukkan username"
                        type="text"
                        disabled={
                          actionType === "detail" || actionType === "edit"
                        }
                        errors={props?.errors}
                        touched={props?.touched}
                        {...props?.getFieldProps("username")}
                        value={props.values.username}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <Input
                        title="Email"
                        label="email"
                        placeholder="Masukkan email"
                        type="email"
                        disabled={
                          actionType === "detail" || actionType === "edit"
                        }
                        errors={props?.errors}
                        touched={props?.touched}
                        {...props?.getFieldProps("email")}
                        value={props.values.email}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      {/* {actionType === "detail" ? (
                        <Select2Multi
                          title="PDAM"
                          name="user_pdams"
                          options={pdams}
                          errors={props?.errors}
                          touched={props?.touched}
                          value={props?.values?.user_pdams}
                          onChange={props?.setFieldValue}
                          onBlur={props?.setFieldTouched}
                          placeholder="Pilih PDAM"
                          disabled={actionType === "detail"}
                        />
                      ) : (
                        <Select2Checkbox
                          title="PDAM"
                          name="user_pdams"
                          options={pdams}
                          errors={props?.errors}
                          touched={props?.touched}
                          value={props?.values?.user_pdams}
                          onChange={props?.setFieldValue}
                          onBlur={props?.setFieldTouched}
                          placeholder="Pilih PDAM"
                          disabled={actionType === "detail"}
                        />
                      )} */}
                      <Select2
                        title="PDAM"
                        name="user_pdams"
                        options={pdams}
                        errors={props?.errors}
                        touched={props?.touched}
                        value={props?.values?.user_pdams}
                        onChange={(name, value) => {
                          props?.setFieldValue("user_pdams", value);
                        }}
                        disabled={actionType === "detail"}
                        onBlur={props?.setFieldTouched}
                        placeholder="Pilih PDAM"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <Select2
                        title="Status"
                        name="status"
                        options={[
                          { value: 0, label: "Nonaktif" },
                          { value: 1, label: "Aktif" },
                        ]}
                        errors={props?.errors}
                        touched={props?.touched}
                        value={props?.values?.status}
                        onChange={(name, value) => {
                          props?.setFieldValue("status", value);
                        }}
                        disabled={actionType === "detail"}
                        onBlur={props?.setFieldTouched}
                        placeholder="Pilih status"
                      />
                    </div>
                  </div>
                  {actionType !== "detail" && (
                    <div
                      className="button-wrapper d-flex justify-content-end"
                      style={{ marginTop: "30px" }}
                    >
                      <Button
                        title="Batal"
                        rounded={true}
                        size="sm"
                        variant="button-light"
                        onClick={() => {
                          setShowModalConfirm(true);
                          setMessageConfirm("cancel");
                        }}
                      />
                      <Button
                        title={actionType === "add" ? "Tambah User" : "Simpan" }
                        rounded={true}
                        type="submit"
                        variant="button-default btn-lg"
                        disabled={props.values.first_name === "" || props.values.last_name === ""}
                        onClick={() => {
                          if (actionType === "edit" ) {
                            
                            setShowModalConfirm(true);
                            setMessageConfirm("submit");
                          }
                        }}
                      />
                    </div>
                  )}
                </Form>
              )}
            </Formik>
          </Container>
        </ModalBody>
        <ModalFooter></ModalFooter>
      </Modal>
      <ConfirmationModal
        isDelete
        show={showModalConfirm}
        toggle={() => setShowModalConfirm(false)}
        confirm={handleConfirm}
        cancelTitle="Tidak"
        confirmTitle="Ya"
        message={
          messageConfirm === "cancel"
            ? `Apakah Anda yakin ingin membatalkan ${displayTitle()}?`
            : `Apakah Anda yakin ingin mengubah data ${
                valueConfirm?.first_name +
                " " +
                valueConfirm?.mid_name +
                " " +
                valueConfirm?.last_name
              }?`
        }
        icon="info"
      />
    </>
  );
});

export default FormUserAdaroModal;
