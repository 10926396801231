import { history } from "../../../../utils/History";
import { actionPending, actionSuccess, actionError } from "../../actionTypes";

import { toastSuccess, toastError } from "../../../../components/commons/toast";

import API from "../../API";
import { handleResponse } from "../../HandleRespone";

// ACTION TYPES TO DISPATCH: METHOD_URL_TYPES
export const GET_SUB_DMA_PENDING = "GET_SUB_DMA_PENDING";
export const GET_SUB_DMA_SUCCESS = "GET_SUB_DMA_SUCCESS";
export const GET_SUB_DMA_ERROR = "GET_SUB_DMA_ERROR";
export const POST_SUB_DMA_PENDING = "POST_SUB_DMA_PENDING";
export const POST_SUB_DMA_SUCCESS = "POST_SUB_DMA_SUCCESS";
export const POST_SUB_DMA_ERROR = "POST_SUB_DMA_ERROR";
export const DELETE_SUB_DMA_PENDING = "DELETE_SUB_DMA_PENDING";
export const DELETE_SUB_DMA_SUCCESS = "DELETE_SUB_DMA_SUCCESS";
export const DELETE_SUB_DMA_ERROR = "DELETE_SUB_DMA_ERROR";

// URL: URL_{URL}
const SUB_DMA_URL = `v1/master_data/sub_dma`;

const get = (param, resolve, reject, callback) => (dispatch) => {
  dispatch(actionPending(GET_SUB_DMA_PENDING));
  API.get(SUB_DMA_URL, { params: param })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(GET_SUB_DMA_SUCCESS, res));
      if (resolve) {
        const records_total = res.data.total;
        let data = res.data.data.map((item, i) => ({
          ...item,
          no: i + 1 + (param?.page - 1) * param?.length,
          longlat:
            item?.longitude && item?.latitude
              ? `${item?.longitude}, ${item?.latitude}`
              : "-",
          pdam_name: item?.pdam?.name ?? "-",
          input_name: item?.input?.name ?? "-",
          diameter_label: item?.diameter + " mm",
        }));
        resolve({
          data: data,
          page: param?.page - 1,
          totalCount: records_total,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(GET_SUB_DMA_ERROR));
      let not_logout = handleResponse(err?.response);
      if (not_logout) {
        let message = err?.response?.data?.message;
        if (message?.includes("java")) {
          message = "Data tidak ditemukan";
        }
        toastError(message);
      }
    });
};

const post = (param, callback) => (dispatch) => {
  dispatch(actionPending(POST_SUB_DMA_PENDING));
  API.post(SUB_DMA_URL + "/import", param)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(POST_SUB_DMA_SUCCESS, res));
      toastSuccess("Berhasil import data sub dma");
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      let message = err?.response?.data?.message;
      if (message?.includes("java")) {
        message = "Format file tidak sesuai";
      }
      toastError(message);
      dispatch(actionError(POST_SUB_DMA_ERROR));
    });
};

const deleted = (param, callback) => (dispatch) => {
  dispatch(actionPending(DELETE_SUB_DMA_PENDING));
  API.delete(SUB_DMA_URL + `?id=${param}`)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(DELETE_SUB_DMA_SUCCESS, res));
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      let message = err?.response?.data?.message;
      if (message?.includes("java")) {
        message = "Gagal menghapus data";
      }
      toastError(message);
      dispatch(actionError(DELETE_SUB_DMA_ERROR));
    });
};

const dma = {
  get,
  post,
  deleted,
};
export default dma;
