export async function convertGISToGeojson(files) {
  const filePromises = files?.map((file) => {
    // Return a promise per file
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = async () => {
        try {
          // Resolve the promise with the response value
          resolve(reader.result);
        } catch (err) {
          reject(err);
        }
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsArrayBuffer(file);
    });
  });

  // Wait for all promises to be resolved
  const fileInfos = await Promise.all(filePromises);

  let shapefile = require("shapefile");
  let geojson = [];

  let json = new Promise((resolve, reject) => {
    shapefile
      .open(fileInfos[0], fileInfos[1])
      .then((source) =>
        source.read().then(function log(result) {
          if (result.done) return resolve(geojson);
          geojson.push(result.value);
          return source.read().then(log);
        })
      )
      .catch((error) => console.error(error.stack));
  })

  return json;
};
