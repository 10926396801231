import React from "react";
import DateRangePicker from "react-daterange-picker";
import "react-daterange-picker/dist/css/react-calendar.css";
import originalMoment from "moment";
import { extendMoment } from "moment-range";
const moment = extendMoment(originalMoment);

class Example extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      isOpen: false,
      value: this.props.selectionType === "single" ? null : null,
    };
  }

  componentDidMount() {
    if (this.props.selectionType === "single") {
      this.setState({
        value: this.props.value ? moment(this.props.value) : "",
      });
    } else {
      this.setState({
        value:
          this.props.value ??
          (this.props.startDate && this.props.endDate
            ? moment().range(
                moment(this.props.startDate),
                moment(this.props.endDate)
              )
            : "") ??
          "",
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      if (this.props.selectionType === "single") {
        this.setState({ value: moment(this.props.value, "YYYY/MM/DD") });
      } else {
        this.setState({
          value:
            this.props.value ??
            (this.props.startDate && this.props.endDate
              ? moment().range(
                  moment(this.props.startDate),
                  moment(this.props.endDate)
                )
              : "") ??
            "",
        });
      }
    }
  }

  onClear = () => {
    this.setState({ value: "" });

    if (this.props.selectionType !== "single") {
      this.props.handlechange(
        this.props.default ?? "",
        this.props.default ?? "",
        this.props.type ? this.props.type : null
      );
    }
  };

  onSelect = (value, states) => {
    this.setState({ value, states });

    if (this.props.selectionType !== "single") {
      this.props.handlechange(
        value?.start
          ? moment(value?.start).format("YYYY/MM/DD")
          : "",
        value?.end
          ? moment(value?.end).format("YYYY/MM/DD")
          : "",
        this.props.type ? this.props.type : null
      );

      if (this.props.name && this.props.change) {
        this.props.change(this.props.name, this.state.value);
      }
    }

    if (this.props.onChange) {
      this.props.onChange(
        this.props.name,
        moment(value).format("YYYY/MM/DD") ?? ""
      );
    }

    this.setState({ isOpen: !this.state.isOpen });
  };

  onToggle = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  renderSelectionValue = () => {
    const inputValue =
      this.props.selectionType === "single"
        ? this.state.value
          ? `${moment(this.state.value).format("DD/MM/YYYY")}`
          : "dd/mm/yyyy"
        : this.state.value
        ? `${moment(this.state.value?.start).format("DD/MM/YYYY")} - ${moment(
            this.state.value?.end
          ).format("DD/MM/YYYY")}`
        : "dd/mm/yyyy - dd/mm/yyyy";
    const error =
      this.props.errors && this.props.name
        ? this.props.errors[this.props.name]
        : "";
    const touched =
      this.props.touched && this.props.name
        ? this.props.touched[this.props.name]
        : "";
    const styles = {
      fontFamily: "sans-serif",
      textAlign: "center",
    };

    return (
      <div className={this.props.className} style={styles}>
        <div
          className={`form-group ${this.props.className}`}
          style={{
            position: "relative",
            fontFamily: "sans-serif",
            textAlign: "center",
          }}
          onClick={this.props.disabled ? null : this.onToggle}
        >
          <label
            className="form-label"
            style={{
              textAlign: "left",
              display: this.props.title ? "block" : "none",
            }}
          >
            {this.props.title}
          </label>
          <div className="input-wrapper" style={{ width: "100%" }}>
            <input
              {...this.props}
              value={inputValue}
              className={
                "form-control form-input " +
                this.props.className +
                (error && touched ? " is-invalid" : "")
              }
              readOnly={true}
              style={{
                fontSize: 14,
                minHeight: 48,
                paddingRight: 40,
                paddingLeft: 20,
                display: "inline-block",
              }}
              onBlur={this.handleBlur}
              placeholder={
                this.props.selectionType === "single"
                  ? "dd/mm/yyyy"
                  : "dd/mm/yyyy - dd/mm/yyyy"
              }
              name={this.props.name}
            ></input>
            {this.state.value && this.props.selectionType !== "single" ? (
              <span
                className="material-icons-round right-icon-input"
                style={{ cursor: "pointer" }}
                onClick={this.onClear}
              >
                close
              </span>
            ) : (
              <span
                className="material-icons-round right-icon-input"
                style={{ cursor: "pointer" }}
              >
                event
              </span>
            )}
          </div>
          {touched && error && (
            <div
              className="invalid-feedback"
              style={{ textAlign: "left", display: "block" }}
            >
              {error}
            </div>
          )}
        </div>
        {this.state.isOpen && (
          <div
            className="container-date-range"
            style={{ position: "absolute" }}
          >
            <DateRangePicker
              value={this.state.value}
              onSelect={this.onSelect}
              singleDateRange={true}
              maximumDate={
                this.props.maxdate ? moment(this.props.maxdate) : null
              }
              minimumDate={
                this.props.mindate ? moment(this.props.mindate) : null
              }
              selectionType={
                this.props.selectionType ? this.props.selectionType : "range"
              }
              onBlur={() => this.setState({ isOpen: false })}
            />
          </div>
        )}
      </div>
    );
  };

  render() {
    return (
      <div>
        <div>{this.renderSelectionValue()}</div>
      </div>
    );
  }
}

export default Example;
