import { useState, useEffect } from "react";
import HeaderMenu from "../../components/commons/menu/HeaderMenu";
import Button from "../../components/buttons/Button";
import { Link } from "react-router-dom";
import { history } from "../../utils/useHistory";
import { connect, useDispatch } from "react-redux";
import Notification from "../../store/actions/notification";
import moment from "moment";
import localization from "moment/locale/id";
import Pagination from "../../components/table/pagination";
import CircularProgress from "@material-ui/core/CircularProgress";
import SearchButton from "../../components/buttons/SearchButton";
import EmptyData from "../../components/empty_state/empty_data";

const Index = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [totalData, setTotalData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isDisable, setIsDisable] = useState(false);
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);

  const goBack = () => {
    history.goBack();
  };

  const buttonBack = (
    <Link onClick={goBack} className="material-icons-round left-icon">
      arrow_back
    </Link>
  );

  const handleRefresh = () => {
    setIsLoading(true);
    setIsDisable(true);
    new Promise((resolve) => {
      const param = {
        length: 10,
        page: page ?? 1,
        search_text: searchText,
      };
      dispatch(Notification.get(param, resolve));
    }).then((res) => {
      setData(res?.data);
      setTotalData(res?.totalCount);
      setMaxPage(Math.ceil(res?.totalCount / 10));
      setIsLoading(false);
      setIsDisable(false);
    });
  };

  useEffect(() => {
    handleRefresh();
  }, [searchText, page]);

  const handleSearch = (value) => {
    setSearchText(value);
  };

  const handleReadNotif = (data) => {
    setIsDisable(true);
    const param = data
      ? {
        ids: [data.id],
      }
      : {};

    const callback = () => {
      setIsDisable(false);
      if (!data) {
        handleRefresh();
      }
      getTotalUnread();
    };

    dispatch(Notification.put(param, callback));
  };

  const getTotalUnread = () => dispatch(Notification.getCountUnread());

  const handleRedirectNotif = (data) => {
    handleReadNotif(data);
    switch (data.type_notif) {
      case "MASTER_DMA":
        history.push({ pathname: "/data-master", state: { tab: "3" } });
        break;
      case "MASTER_PDAM":
        history.push({ pathname: "/data-master", state: { tab: "0" } });
        break;
      case "MASTER_USER":
        history.push("/system");
        break;
      case "MASTER_CUSTOMER":
        history.push({ pathname: "/data-master", state: { tab: "4" } });
        break;
      case "REPAIR_WORK":
        history.push("/repair-work-report");
        break;
      case "PHYSICAL_LOSSES":
        history.push("/warning-system");
        break;
      case "COMMERCIAL_LOSSES":
        history.push("/commercial-losses");
        break;
      default:
        return null;
    }
  };

  return (
    <div className="menu-container">
      <div className="main-content-wrapper">
        <HeaderMenu title="Notifikasi" buttonBack={buttonBack}>
          <SearchButton
            placeholder="Cari Notifikasi"
            toggle={handleSearch}
            optClass={"mr-3"}
            disabled={isDisable}
          />
          <Button
            title="Tandai Semua Dibaca"
            leftIcon="check"
            size="md"
            variant="btn-lg button-default"
            disabled={isDisable || data.length === 0}
            rounded={true}
            onClick={() => handleReadNotif()}
          />
        </HeaderMenu>
        <div className="main-fill-content mt-5">
          {isLoading ? (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ minHeight: 400 }}
            >
              <CircularProgress color="inherit" />
            </div>
          ) : (
            <>
              {data && data.length > 0 ? (
                data.map((item, idx) => {
                  return (
                    <>
                      <div className="d-flex justify-content-between" key={idx}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <div className="pr-5">
                            <p className="text-bold">{item.no}</p>
                          </div>
                          <div
                            className={`notification-indikator primary p-2 ${item.is_read ? "opacity-1" : ""
                              }`}
                          />
                          <div className="pl-5">
                            <div
                              className="text-bold"
                              dangerouslySetInnerHTML={{ __html: item.body }}
                            />
                            <p className="text-muted">
                              {moment(item.created_at).format(
                                "ddd, DD/MM, HH:mm"
                              )}
                            </p>
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            cursor: "pointer",
                          }}
                          onClick={() => handleRedirectNotif(item)}
                        >
                          <span className="material-icons-round">
                            navigate_next
                          </span>
                        </div>
                      </div>
                      <hr />
                    </>
                  );
                })
              ) : (
                <EmptyData
                  label="NOTIFIKASI KOSONG"
                  subLabel="Anda tidak memiliki notifikasi terbaru saat ini"
                />
              )}
            </>
          )}
          <div style={{ width: "100%" }}>
            {/* {pagination && ( */}
            <Pagination
              page={page}
              dataTotal={data?.length}
              recordTotal={totalData}
              maxPage={maxPage}
              toggle={setPage}
            />
            {/* )} */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
