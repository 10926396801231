import { useState } from "react";
import { connect, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { history } from "../../utils/History";
import { getUser } from "../../utils/User";
import Input from "../../components/forms/Input";
import Button from "../../components/buttons/Button";
import account from "../../assets/images/icon/adaro-icon.png";

import Auth from "../../store/actions/auth";

const Index = ({ t, loggingIn }) => {
  const dispatch = useDispatch();
  const [visibility, setVisibility] = useState(false);

  if (getUser()) {
    history.push(`/dashboard`);
  }

  const validation = Yup.object().shape({
    username: Yup.string()
      .required("Username atau Email wajib diisi")
      .nullable(),
    password: Yup.string().required("Kata sandi wajib diisi").nullable(),
  });

  return (
    <div className="main-wrapper">
      <div className="login-container">
        <div className="content-wrapper">
          <img src={account} style={{ marginBottom: 57 }} />
          <p className="super-title text-extra-bold">Login</p>
          <p className="subtitle text-medium" style={{ marginBottom: 50 }}>
            Masuk ke Adaro Water Solution (AWS)
          </p>
          <div className="form-wrapper">
            <Formik
              initialValues={{
                username: "",
                password: "",
                remember_me: false,
              }}
              validationSchema={validation}
              onSubmit={(values, { setStatus, setSubmitting }) => {
                setStatus();
                dispatch(Auth.auth_login(values));
              }}
            >
              {(props) => (
                <Form>
                  <Input
                    title="Username atau Email"
                    name="username"
                    type="text"
                    placeholder="Masukkan username atau email"
                    errors={props?.errors}
                    touched={props?.touched}
                    {...props?.getFieldProps("username")}
                  />
                  <Input
                    title="Password"
                    name="password"
                    type={visibility ? "text" : "password"}
                    placeholder="Masukkan password"
                    errors={props?.errors}
                    touched={props?.touched}
                    righticon={visibility ? "visibility_off" : "visibility"}
                    toggleicon={() => setVisibility(!visibility)}
                    {...props?.getFieldProps("password")}
                  />
                  <div
                    className="login-option-container"
                    style={{ display: "flex", marginTop: 26 }}
                  >
                    <div className="form-group">
                      <div>
                        <div className="form-check form-check-inline">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            name="remember_me"
                            // value="true"
                            checked={props?.values.remember_me === true}
                            onChange={props?.handleChange}
                          />
                          <label className="form-check-label">Ingat Saya</label>
                        </div>
                      </div>
                    </div>
                    <div style={{ marginLeft: "auto" }}>
                      <a
                        className="link text-bold"
                        href="#"
                        onClick={() => history.push("forgot-password")}
                        style={{ fontSize: 14 }}
                      >
                        Lupa kata sandi?
                      </a>
                    </div>
                  </div>
                  <div className="button-wrapper" style={{ marginTop: "30px" }}>
                    <Button
                      title="Login"
                      type="submit"
                      variant="button primary"
                      disabled={loggingIn}
                      style={{
                        width: "100%",
                        textAlign: "center",
                        borderRadius: "48px",
                      }}
                    />
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
        <div className="side-login"></div>
      </div>
    </div>
  );
};
const mapStateToProps = ({ auth: { loggingIn } }) => {
  return { loggingIn };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Index));
