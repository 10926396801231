import React from "react";
import { useDispatch } from "react-redux";
import { Container } from "reactstrap";
import Table from "../../../components/table/index";
import TableStatus from "../../../components/table/status/index";
import FilterAuditTrailModal from "./FilterAuditTrailModal";
import moment from "moment";
import {
  useState,
  useEffect,
  forwardRef,
  useRef,
  useImperativeHandle,
} from "react";
import { getUser } from "../../../utils/User";
import { RoleName } from "../../../utils/Constants";

// services
import auditTrailService from "../../../store/actions/audit_trail";

const Index = forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const user = getUser();
  const [activeTab, setActiveTab] = useState(
    user.user.role_code !== "superadmin" ? "0" : "1"
  );
  const [auditTrailData, setAuditTrailData] = useState([]);
  const [pending, setPending] = useState(false);

  // pagination
  const [totalData, setTotalData] = useState(null);
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);

  // refs
  const modalFilterAuditTrailRef = useRef();

  const [filter, setFilter] = useState({
    start: null,
    end: null,
    role: ""
  });

  const dataColumns = [
    {
      Header: "NO.",
      accessor: "no",
      Cell: ({ row }) => {
        const { original } = row;

        return <>{original.no}</>;
      },
    },
    {
      Header: "WAKTU",
      accessor: "updated_at",
      Cell: ({ row }) => {
        const { original } = row;
        const { updated_at } = original;
        return updated_at ? moment(updated_at).format("DD-MM-YY HH:mm") : "-";
      },
    },
    {
      Header: "USERNAME",
      accessor: "username",
    },
    {
      Header: "ROLE",
      accessor: "role_code",
      Cell: ({ row }) => {
        const { original } = row;
        const { role_code } = original;
        return RoleName[role_code] || "-";
      },
    },
    {
      Header: "AKTIVITAS",
      accessor: "operation",
    },
    {
      Header: "STATUS",
      accessor: "status",
      Cell: ({ row }) => {
        const { original } = row;
        const { status } = original;

        return (
          <TableStatus
            status={status === 200 ? "Success" : "Failed"}
            color={status === 200 ? "primary" : "danger"}
          />
        );
      },
    },
  ];

  useImperativeHandle(ref, () => ({
    refresh() {
      handleRefresh();
    },

    toggleModalFilter() {
      modalFilterAuditTrailRef.current.toggle();
    },

    getActiveTab() {
      return activeTab;
    },
  }));

  const onApplyFilter = (start, end, role) => {
    setFilter({
      start: start,
      end: end,
      role: role
    });
    modalFilterAuditTrailRef.current.toggle();
  };

  const handleRefresh = () => {
    new Promise((resolve) => {
      let param = {
        draw: 1,
        id: null,
        length: 10,
        page: page,
        start_date: filter?.start
          ?? "",
        end_date: filter?.end
          ?? "",
        role_code: filter?.role ?? ""
      };
      setPending(true);
      dispatch(auditTrailService.get(param, resolve));
    }).then((res) => {
      setAuditTrailData(res?.data);
      setTotalData(res?.totalCount);
      setMaxPage(Math.ceil(res?.totalCount / 10));
      setPending(false);
    });
  };

  useEffect(() => {
    handleRefresh();
  }, [page, filter]);

  return (
    <Container>
      <FilterAuditTrailModal
        ref={modalFilterAuditTrailRef}
        filter={filter}
        onApplyFilter={onApplyFilter}
        activeTab={activeTab}
      />
      <div className="pl-4">
        <Table
          id="tbl-data-system-audit-trail"
          isLoading={pending}
          columns={dataColumns}
          data={auditTrailData}
          pagination={{ page, totalData, maxPage, toggle: setPage }}
        />
      </div>
    </Container>
  );
});

export default Index;
