import { Container } from "reactstrap";
import  CantAccess from "../../components/empty_state/cant_access";

const Index = () => {
    return (
      <Container>
        <CantAccess></CantAccess>
      </Container>
    );
  };
  
  export default Index;