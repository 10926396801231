import React, { useState, useRef, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { Formik, Form } from "formik";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Container,
} from "reactstrap";
import Input from "../../../forms/Input";
import Button from "../../../buttons/Button";
import { getUser } from "../../../../utils/User";
import { RoleName } from "../../../../utils/Constants";
import DropdownItem from "../../../dropdowns/DropdownItem";
import { toastSuccess } from "../../../commons/toast";

import PDAMService from "../../../../store/actions/master_data/pdam";

function ChangePDAM({ t, show, toggle, data_all, togglePDAM }) {
  const formikRef = useRef();
  const dispatch = useDispatch();

  const currentUser = getUser()?.user;

  const [value, setValue] = useState(null);
  const [pdamList, setPdamList] = useState(
    currentUser?.is_all_pdam === 1
      ? data_all?.data?.data
      : currentUser?.pdam_list
  );

  const changeRole = (item) => {
    let user = getUser();
    user.user.pdam = item ?? null;
    localStorage.setItem("user_aws", JSON.stringify(user));
    togglePDAM(item ?? "");
    toastSuccess(
      item
        ? `Berhasil berganti ke ${item?.name}`
        : `Berhasil kembali sebagai ${RoleName[currentUser?.role_code]}`
    );
    toggle();
  };

  function handleOnKeyDown(event) {
    if (event.key === "Enter") {
      let list =
        currentUser?.is_all_pdam === 1
          ? data_all?.data?.data
          : currentUser?.pdam_list;
      if (value !== undefined && value !== null && value !== "") {
        list = list?.filter(function (item) {
          if (item.name.toLowerCase().indexOf(value.toLowerCase()) >= 0)
            return item;
        });
      } else {
        list =
          currentUser?.is_all_pdam === 1
            ? data_all?.data?.data
            : currentUser?.pdam_list;
      }
      setPdamList(list);
    }
  }

  const handleSearch = (event) => {
    setValue(event.target.value);
  };

  useEffect(() => {
    setPdamList(
      currentUser?.is_all_pdam === 1
        ? data_all?.data?.data
        : currentUser?.pdam_list
    );
  }, [data_all]);

  useEffect(() => {
    let param = {
      page: 1,
      length: 999,
    };
    dispatch(PDAMService.getAll(param));
  }, []);

  return (
    <>
      <Modal
        isOpen={show}
        modalTransition={{ timeout: 700 }}
        backdropTransition={{ timeout: 1300 }}
      >
        <ModalHeader className="text-extra-bold pt-5">
          {"Ganti PDAM"}
          <span className="close clickable" onClick={toggle}>
            &times;
          </span>
        </ModalHeader>
        <Formik
          innerRef={formikRef}
          initialValues={{
            search_text: "",
          }}
        >
          {(props) => (
            <Form>
              <ModalBody>
                <Container>
                  <Input
                    name="search_text"
                    errors={props.errors}
                    touched={props.touched}
                    onKeyUp={handleSearch}
                    onKeyDown={handleOnKeyDown}
                    {...props.getFieldProps("search_text")}
                    leftIcon="search"
                    placeholder="Cari PDAM"
                    type="text"
                  />
                  <div
                    style={{
                      marginTop: 40,
                      marginBottom: 20,
                      height: pdamList?.length > 0 ? 300 : "max-content",
                      overflowY: pdamList?.length > 0 ? "auto" : "hidden",
                      overflowX: "hidden",
                    }}
                  >
                    {pdamList?.map((item, index) => (
                      <DropdownItem
                        title={item?.name}
                        last={index === pdamList[pdamList.length - 1]}
                        itemStyle={{ padding: "10px" }}
                        toggle={() => changeRole(item)}
                      />
                    ))}
                  </div>
                  <div className="text-center subtitle">
                    {`Total ${pdamList?.length ?? 0} PDAM`}
                  </div>
                </Container>
              </ModalBody>
              <ModalFooter>
                <Container>
                  <Button
                    title={`Kembali ke ${RoleName[currentUser?.role_code]}`}
                    type="Button"
                    variant="button secondary"
                    rounded
                    style={{
                      width: "100%",
                      textAlign: "center",
                      marginBottom: 25,
                    }}
                    onClick={() => changeRole(null)}
                  />
                </Container>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
}

const mapStateToProps = ({ masterDataPDAM: { pending, data_all } }) => {
  return { pending, data_all };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangePDAM);
