
import { useEffect } from "react";
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from "reactstrap";
import * as Yup from "yup";
import moment from "moment";
import { Formik, Form } from "formik";
import { connect } from "react-redux";
import ButtonModal from "../../components/modal/ButtonModal";
import DateRangePicker from "../../components/forms/DateRangePicker"
import { toastError } from "../../components/commons/toast";

const ModalDownload = ({
    show,
    toggle,
    confirm,
    repairWork
}) => {

    const timezone = moment(new Date()).format("Z");

    const handleConfirm = () => {
        toggle();
    }

    const validation = Yup.object().shape({
        start_date: Yup.string()
            .required(
                `Tanggal wajib diisi`
            )
            .nullable(),
    });

    useEffect(() => {
        if (!repairWork?.pending_download && repairWork?.data_download) {
            toggle();
        }
    }, [repairWork?.data_download]);

    return (
        <Modal
            isOpen={show}
            modalTransition={{ timeout: 200 }}
            backdropTransition={{ timeout: 1300 }}
        >
            <ModalHeader className="text-extra-bold pt-5">
                {" "}
                Download Repair Work Report{" "}
                <span className="close clickable" onClick={() => handleConfirm()}>
                    &times;
                </span>
            </ModalHeader>
            <Formik
                initialValues={{
                    start_date: "",
                    end_date: "",
                }}
                validationSchema={validation}
                onSubmit={(values, { setStatus }) => {
                    setStatus();
                    values.start_date = moment(values.start_date).format("YYYY-MM-DD") +
                        "T00:00:00.000" + timezone;
                    values.end_date = moment(values.end_date).format("YYYY-MM-DD") +
                        "T23:59:00.000" + timezone;
                    if (values.start_date && values.end_date) {
                        confirm(values);
                    } else {
                        toastError('Mohon pilih tanggal terlebih dahulu')
                    }
                }}
            >
                {(props) => (
                    <Form style={{ marginTop: 30 }}>
                        <ModalBody style={{ margin: "0px 20px" }}>
                            <DateRangePicker
                                title="Tanggal"
                                name="start_date"
                                handlechange={(startDate, endDate) => {
                                    props.setFieldValue("start_date", startDate);
                                    props.setFieldValue("end_date", endDate);
                                }}
                                format="DD MMMM YYYY"
                                startDate={props.values.start_date}
                                endDate={props.values.end_date}
                                className="mx-auto"
                                errors={props.errors}
                                touched={props.touched}
                            />
                        </ModalBody>
                        <ModalFooter>
                            <ButtonModal
                                toggle={() => toggle()}
                                confirmTitle="Download"
                                isLoading={repairWork.pending_download}
                                disabledConfrim={
                                    repairWork.pending_download || !props.values.start_date
                                }
                            />
                        </ModalFooter>
                    </Form>
                )}

            </Formik>

        </Modal>
    )
}

const mapStateToProps = ({
    repairWork,
}) => {
    return {
        repairWork,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalDownload);