import {
  GET_ALL_MANAGEMENT_NOTIFICATION_PENDING,
  GET_ALL_MANAGEMENT_NOTIFICATION_SUCCESS,
  GET_ALL_MANAGEMENT_NOTIFICATION_ERROR,
  GET_ROLE_MANAGEMENT_NOTIFICATION_PENDING,
  GET_ROLE_MANAGEMENT_NOTIFICATION_SUCCESS,
  GET_ROLE_MANAGEMENT_NOTIFICATION_ERROR,
  PUT_MANAGEMENT_NOTIFICATION_PENDING,
  PUT_MANAGEMENT_NOTIFICATION_SUCCESS,
  PUT_MANAGEMENT_NOTIFICATION_ERROR,
  SET_PUT_MANAGEMENT_NOTIFICATION_DATA,
} from "../../actions/management_notification";

const initialState = {
  error: null,
  pending_all: false,
  data_all_list: null,
  pending_role: false,
  data_role_list: [],
  pending_put: false,
  data_put: [],
};

const managementNotification = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_MANAGEMENT_NOTIFICATION_PENDING:
      return {
        ...state,
        pending_all: true,
      };
    case GET_ALL_MANAGEMENT_NOTIFICATION_SUCCESS:
      return {
        ...state,
        pending_all: false,
        data_all_list: action.data,
      };
    case GET_ALL_MANAGEMENT_NOTIFICATION_ERROR:
      return {
        ...state,
        pending_all: false,
        error: action.error,
      };
    case GET_ROLE_MANAGEMENT_NOTIFICATION_PENDING:
      return {
        ...state,
        pending_role: true,
      };
    case GET_ROLE_MANAGEMENT_NOTIFICATION_SUCCESS:
      let data_role_list = state.data_role_list;
      if (
        !data_role_list.find(
          (item) => item.code === action.data.data.data[0].code
        )
      ) {
        data_role_list.push(action.data.data.data[0]);
      }
      return {
        ...state,
        pending_role: data_role_list.length === 5 ? false : true,
        data_role_list,
      };
    case GET_ROLE_MANAGEMENT_NOTIFICATION_ERROR:
      return {
        ...state,
        pending_role: false,
        error: action.error,
      };
    case PUT_MANAGEMENT_NOTIFICATION_PENDING:
      return {
        ...state,
        pending_put: true,
      };
    case PUT_MANAGEMENT_NOTIFICATION_SUCCESS:
      // let data_put = state.data_put;
      // if (!data_put.find((item) => item.code === action.data.data.data.code)) {
      //   data_put.push(action.data.data.data);
      // }
      return {
        ...state,
        pending_put: false,
        data_put: action.data,
      };
    case PUT_MANAGEMENT_NOTIFICATION_ERROR:
      return {
        ...state,
        pending_put: false,
        error: action.error,
        // data_put: ["", "", "", "", ""],
      };
    case SET_PUT_MANAGEMENT_NOTIFICATION_DATA:
      return {
        ...state,
        data_put: action.data,
      };

    default:
      return state;
  }
};

export default managementNotification;
