import Tooltip from "../../commons/tooltip"

const Checkbox = ({
    name,
    checked,
    onChange,
    label,
    value,
    variant,
    variantInput,
    style,
    styleInput,
    info,
    disabled
}) => {

    return (
        <div style={{ marginBottom: 40 }}>
            <label className="checkbox-container">
                <p className="side-menu-title text-extra-bold" style={{ fontSize: 14, cursor: 'context-menu' }}>{label}</p>
                <input type="checkbox"
                    name={name}
                    value={value}
                    checked={checked?.includes(String(value))}
                    onChange={onChange}
                    disabled={disabled}
                />
                <span className="checkmark" style={styleInput}></span>
            </label>
            {info && (
                <Tooltip
                    content={info}
                    direction="right"
                >
                    <span className="material-icons-round" style={{ marginLeft: 10 }}>info</span>
                </Tooltip>
            )}
        </div>
    )
}

export default Checkbox