import React, { Component } from "react";
import { ErrorMessage } from "formik";
import Select from "react-select";

export default class Select2 extends Component {
  handleChange = (selectedOptions) => {
    if (this.props.onChange) {
      if (this.props.isMulti) {
        // Extract the values from the selected options for multiple select
        const values = selectedOptions ? selectedOptions.map(option => option.value) : [];
        this.props.onChange(this.props.name, values);
      } else {
        // For single select
        this.props.onChange(this.props.name, selectedOptions?.value ?? "");
      }
    }
  };

  customFilter(option, searchText) {
    return option.label.toLowerCase().includes(searchText.toLowerCase());
  }

  render() {
    const isValid = !(this.props.errors[this.props.name] && this.props.touched[this.props.name]);

    const customStyles = {
      control: (base, state) => ({
        ...base,
        borderColor: state.isFocused ? "#ddd" : !isValid ? "red" : "#ddd",
        "&:hover": {
          borderColor: state.isFocused ? "#ddd" : !isValid ? "red" : "#ddd",
        },
      }),
    };

    return (
      <div className="form-group">
        {this.props?.title && (
          <label className="form-label">{this.props?.title}</label>
        )}
        <Select
          {...this.props}
          isDisabled={this.props.disabled}
          name={this.props.name}
          styles={customStyles}
          value={
            this.props.isMulti
              ? this.props.options?.filter(option => this.props.value?.includes(option.value))
              : this.props.options?.find(option => option.value === this.props.value) ?? { label: this.props.placeholder ?? "Select...", key: "" }
          }
          filterOption={this.customFilter}
          onBlur={this.handleBlur}
          onChange={this.handleChange}
          options={this.props.options}
          placeholder={this.props.placeholder}
          isClearable={true}
          classNamePrefix="select"
          className={"basic-single " + this.props.className}
          isMulti={this.props.isMulti}
        />
        <div
          className="invalid-feedback"
          style={{ display: isValid ? "none" : "block" }}
        >
          {this.props.errors[this.props.name]}
        </div>
      </div>
    );
  }
}
