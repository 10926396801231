import React, { useState, useRef, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  FormGroup,
} from "reactstrap";
import { Formik, Form } from "formik";
import { Divider } from "@material-ui/core";
import { getUser } from "../../../utils/User";
import * as Yup from "yup";
import moment from "moment";

import Select2 from "../../../components/forms/Select2";
import ButtonModal from "../../../components/modal/ButtonModal";
import ConfirmationModal from "../../../components/modal/ConfirmModal";
import Input from "../../../components/forms/Input";
import LoaderModal from "../../../components/commons/LoaderModal";

import PDAMService from "../../../store/actions/master_data/pdam";
import CustomerService from "../../../store/actions/master_data/customer";
import DMAService from "../../../store/actions/master_data/dma";
import SubDMAService from "../../../store/actions/master_data/subDMA";

function ModalAdd({
  show,
  toggle,
  refresh,
  data,
  switchPDAM,
  masterDataDMA,
  masterDataSubDMA,
  masterDataCustomer,
}) {
  const fileRef = useRef();
  const dispatch = useDispatch();

  const [pdam, setPDAM] = useState([]);
  const [dma, setDMA] = useState([]);
  const [subDMA, setSubDMA] = useState([]);
  const [showModalConfirm, setShowModalConfirm] = useState(false);
  const [selectedPDAM, setSelectedPDAM] = useState(data?.pdam_id ?? "");
  const [selectedDMA, setSelectedDMA] = useState(data?.dma_id ?? "");

  const modalTitle = data ? "Edit Pelanggan" : "Tambah Pelanggan";

  useEffect(() => {
    const param = {
      draw: 1,
      length: 999,
      page: 1,
      id: switchPDAM?.id,
    };

    new Promise((resolve) => {
      dispatch(PDAMService.get(param, resolve));
    }).then((res) => {
      setPDAM(res?.data);
    });
  }, [switchPDAM]);

  useEffect(() => {
    const param = {
      draw: 1,
      length: 999,
      page: 1,
    };
    dispatch(DMAService.get(param));
    dispatch(SubDMAService.get(param));
  }, []);

  useEffect(() => {
    setSelectedPDAM(switchPDAM?.id ?? currentUser?.user?.pdam?.id ?? "");
  }, [switchPDAM]);

  useEffect(() => {
    if (data?.pdam_id) {
      setSelectedPDAM(data?.pdam_id);
    }

    if (data?.dma_id) {
      setSelectedDMA(data?.dma_id);
    }
  }, [data]);

  useEffect(() => {
    if (masterDataDMA) {
      let dma_list = [];
      if (selectedPDAM) {
        dma_list = masterDataDMA?.data_list?.data?.data;
        if (selectedPDAM?.length > 0) {
          dma_list = dma_list?.filter((item) => item.pdam_id === selectedPDAM);
        }
      } else {
        if (data?.pdam?.id) {
          dma_list = masterDataDMA?.data_list?.data?.data;
          dma_list = dma_list?.filter(
            (item) => item.pdam_id === data?.pdam?.id
          );
        }
      }
      setDMA(dma_list);
    }
  }, [selectedPDAM, masterDataDMA]);

  useEffect(() => {
    if (masterDataSubDMA) {
      let sub_dma_list = [];

      if (selectedPDAM && selectedDMA) {
        sub_dma_list = masterDataSubDMA?.data_list?.data?.data;

        if (selectedPDAM?.length > 0) {
          sub_dma_list = sub_dma_list?.filter(
            (item) => item.pdam_id === selectedPDAM
          );
        }

        if (selectedDMA) {
          sub_dma_list = sub_dma_list?.filter(
            (item) => item.dma_id === selectedDMA
          );
        }
      }
      setSubDMA(sub_dma_list);
    }
  }, [selectedPDAM, selectedDMA, masterDataSubDMA]);

  const validation = Yup.object().shape({
    pdam_id: Yup.string().required("PDAM wajib diisi").nullable(),
    dma_id: Yup.string().required("DMA wajib diisi").nullable(),
    sub_dma_id: Yup.string().required("Sub DMA wajib diisi").nullable(),
    name: Yup.string().required("Nama Pelanggan wajib diisi").nullable(),
    sr_number: Yup.string().required("No SR wajib diisi").nullable(),
    device_id: Yup.string().required("ID Sensor wajib diisi").nullable(),
    connection_type: Yup.string().required("Sambungan wajib diisi").nullable(),
    longitude: Yup.string().required("Longitude wajib diisi").nullable(),
    latitude: Yup.string().required("Latitude wajib diisi").nullable(),
    address: Yup.string().required("Alamat wajib diisi").nullable(),
    status: Yup.string().required("Status wajib diisi").nullable(),
    brand: Yup.string().required("Merek wajib diisi").nullable(),
    installation_date: Yup.string()
      .required("Tanggal pasang wajib diisi")
      .nullable(),
  });

  const handleConfirm = () => {
    setDMA(data ? dma : []);
    setSubDMA(data ? subDMA : []);
    setSelectedPDAM(data ? selectedPDAM : "");
    setSelectedDMA(data ? selectedDMA : "");
    setShowModalConfirm(false);
    toggle(false);
  };

  const currentUser = getUser();

  return (
    <>
      <Modal
        isOpen={show}
        modalTransition={{ timeout: 200 }}
        backdropTransition={{ timeout: 1300 }}
      >
        <LoaderModal
          show={
            masterDataCustomer?.pending_add || masterDataCustomer?.pending_put
          }
        />
        <ModalHeader className="text-extra-bold pt-5">
          {" "}
          {modalTitle}{" "}
          <span
            className="close clickable"
            onClick={() => setShowModalConfirm(true)}
          >
            &times;
          </span>
        </ModalHeader>
        <Formik
          enableReinitialize
          initialValues={{
            id: data?.id,
            pdam_id: data?.pdam_id ?? currentUser?.user?.pdam?.id,
            dma_id: data?.dma_id,
            sub_dma_id: data?.sub_dma_id,
            name: data?.name,
            sr_number: data?.sr_number,
            device_id: data?.device_id,
            connection_type: data?.connection_type,
            longitude: data?.longitude,
            latitude: data?.latitude,
            address: data?.address,
            status: data?.status,
            brand: data?.brand ?? "",
            installation_date: data?.installation_date
              ? moment(data?.installation_date).format("YYYY-MM-DD")
              : "",
          }}
          validationSchema={validation}
          onSubmit={(values, { setStatus }) => {
            setStatus();

            const callback = () => {
              toggle();
              refresh(values);
            };

            if (data) {
              dispatch(CustomerService.put(values, callback));
            } else {
              dispatch(CustomerService.add(values, callback));
            }
          }}
        >
          {(props) => (
            <Form style={{ marginTop: 30 }}>
              <ModalBody style={{ margin: "0px 20px" }}>
                <Select2
                  title="PDAM"
                  name="pdam_id"
                  options={pdam?.map((item) => ({
                    value: item?.id,
                    label: item?.name,
                  }))}
                  errors={props.errors}
                  touched={props.touched}
                  value={props.values?.pdam_id}
                  placeholder="Pilih data PDAM"
                  onChange={(name, value) => {
                    props.setFieldValue("pdam_id", value);
                    props.setFieldValue("dma_id", "");
                    props.setFieldValue("sub_dma_id", "");
                    setSelectedPDAM(value);
                  }}
                  onBlur={props.setFieldTouched}
                  disabled={currentUser?.user?.pdam}
                />
                <Row>
                  <Col md={6}>
                    <Select2
                      title="DMA"
                      name="dma_id"
                      options={dma?.map((item) => ({
                        value: item?.id,
                        label: `DMA ` + item?.name,
                      }))}
                      errors={props.errors}
                      touched={props.touched}
                      value={props.values?.dma_id}
                      placeholder="Pilih data DMA"
                      onChange={(name, value) => {
                        props.setFieldValue("dma_id", value);
                        props.setFieldValue("sub_dma_id", "");
                        setSelectedDMA(value);
                      }}
                      onBlur={props.setFieldTouched}
                    />
                  </Col>
                  <Col md={6}>
                    <Select2
                      title="Sub DMA"
                      name="sub_dma_id"
                      options={subDMA?.map((item) => ({
                        value: item?.id,
                        label: item?.name,
                      }))}
                      errors={props.errors}
                      touched={props.touched}
                      value={props.values?.sub_dma_id}
                      placeholder="Pilih data Sub DMA"
                      onChange={(name, value) => {
                        props.setFieldValue("sub_dma_id", value);
                      }}
                      onBlur={props.setFieldTouched}
                    />
                  </Col>
                </Row>
                <Divider orientation="horizontal" className="my-3" />
                <FormGroup>
                  <Input
                    title="Nama Pelanggan"
                    name="name"
                    type="text"
                    errors={props?.errors}
                    touched={props?.touched}
                    {...props?.getFieldProps("name")}
                    placeholder="Masukkan nama pelanggan"
                    value={props.values?.name}
                  />
                </FormGroup>
                <FormGroup>
                  <Input
                    title="No SR"
                    name="sr_number"
                    type="text"
                    errors={props?.errors}
                    touched={props?.touched}
                    {...props?.getFieldProps("sr_number")}
                    placeholder="Masukkan nomor sr"
                    value={props.values?.sr_number}
                  />
                </FormGroup>
                <FormGroup>
                  <Input
                    title="ID Sensor"
                    name="device_id"
                    type="text"
                    errors={props?.errors}
                    touched={props?.touched}
                    {...props?.getFieldProps("device_id")}
                    placeholder="Masukkan id sensor"
                    value={props.values?.device_id}
                  />
                </FormGroup>
                <FormGroup>
                  <Input
                    title="Sambungan"
                    name="connection_type"
                    type="text"
                    errors={props?.errors}
                    touched={props?.touched}
                    {...props?.getFieldProps("connection_type")}
                    placeholder="Masukkan jenis sambungan"
                    value={props.values?.connection_type}
                  />
                </FormGroup>
                <Row>
                  <Col md={6}>
                    <FormGroup>
                      <Input
                        title="Longitude"
                        name="longitude"
                        type="number"
                        errors={props?.errors}
                        touched={props?.touched}
                        {...props?.getFieldProps("longitude")}
                        placeholder="Masukkan longitude"
                        value={props.values?.longitude}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Input
                        title="Latitude"
                        name="latitude"
                        type="number"
                        errors={props?.errors}
                        touched={props?.touched}
                        {...props?.getFieldProps("latitude")}
                        placeholder="Masukkan latitude"
                        value={props.values?.latitude}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <FormGroup>
                  <Input
                    title="Alamat"
                    name="address"
                    type="text"
                    errors={props?.errors}
                    touched={props?.touched}
                    {...props?.getFieldProps("address")}
                    placeholder="Masukkan Alamat"
                    value={props.values?.address}
                  />
                </FormGroup>
                <FormGroup>
                  <Input
                    title="Merek"
                    name="brand"
                    type="text"
                    errors={props?.errors}
                    touched={props?.touched}
                    {...props?.getFieldProps("brand")}
                    placeholder="Masukkan merek"
                    value={props.values?.brand}
                  />
                </FormGroup>
                <FormGroup>
                  <Input
                    title="Tanggal Pasang"
                    name="installation_date"
                    type="date"
                    errors={props?.errors}
                    touched={props?.touched}
                    {...props?.getFieldProps("installation_date")}
                    placeholder="Masukkan tanggal pasang"
                    value={props.values?.installation_date}
                  />
                </FormGroup>
                <Divider orientation="horizontal" className="my-3" />
                <FormGroup>
                  <Select2
                    title="Status"
                    name="status"
                    options={[
                      {
                        value: 1,
                        label: "Aktif",
                      },
                      {
                        value: 2,
                        label: "Ditangguhkan",
                      },
                      {
                        value: 3,
                        label: "Diputus Permanen",
                      },
                    ]}
                    errors={props.errors}
                    touched={props.touched}
                    value={props.values?.status}
                    placeholder="Pilih status"
                    onChange={(name, value) => {
                      props.setFieldValue("status", value);
                    }}
                    onBlur={props.setFieldTouched}
                  />
                </FormGroup>
              </ModalBody>
              <ModalFooter>
                <ButtonModal
                  toggle={() => setShowModalConfirm(true)}
                  confirmTitle="Simpan"
                  disabledConfrim={
                    masterDataCustomer?.pending_add ||
                    masterDataCustomer?.pending_put
                  }
                />
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </Modal>

      <ConfirmationModal
        isDelete
        show={showModalConfirm}
        toggle={() => setShowModalConfirm(false)}
        confirm={handleConfirm}
        isDelete="delete"
        cancelTitle="Tidak"
        confirmTitle="Ya"
        message={
          data
            ? "Apakah anda yakin ingin membatalkan ubah data <b>Pelanggan</b> ?"
            : "Apakah Anda yakin ingin membatalkan penambahan <b>Pelanggan</b> ?"
        }
        icon="info"
      />
    </>
  );
}

const mapStateToProps = ({
  masterDataPDAM,
  masterDataDMA,
  masterDataSubDMA,
  masterDataCustomer,
}) => {
  return {
    masterDataPDAM,
    masterDataDMA,
    masterDataSubDMA,
    masterDataCustomer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalAdd);
