import {
  GET_PIPELINE_PENDING,
  GET_PIPELINE_SUCCESS,
  GET_PIPELINE_ERROR,
  POST_PIPELINE_PENDING,
  POST_PIPELINE_SUCCESS,
  POST_PIPELINE_ERROR,
  DELETE_PIPELINE_PENDING,
  DELETE_PIPELINE_SUCCESS,
  DELETE_PIPELINE_ERROR,
} from "../../../actions/master_data/pipeline";

const initialState = {
  pending: false,
  error: null,
  data_list: null,
  pending_post: false,
  data_post: null,
  pending_delete: false,
  data_delete: null,
};

const pipelines = (state = initialState, action) => {
  switch (action.type) {
    case GET_PIPELINE_PENDING:
      return {
        ...state,
        pending: true,
      };
    case GET_PIPELINE_SUCCESS:
      return {
        ...state,
        pending: false,
        data_list: action.data,
      };
    case GET_PIPELINE_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case POST_PIPELINE_PENDING:
      return {
        ...state,
        pending_post: true,
      };
    case POST_PIPELINE_SUCCESS:
      return {
        ...state,
        pending_post: false,
        data_post: action.data,
      };
    case POST_PIPELINE_ERROR:
      return {
        ...state,
        pending_post: false,
        error: action.error,
      };
    case DELETE_PIPELINE_PENDING:
      return {
        ...state,
        pending_delete: true,
      };
    case DELETE_PIPELINE_SUCCESS:
      return {
        ...state,
        pending_delete: false,
        data_delete: action.data,
      };
    case DELETE_PIPELINE_ERROR:
      return {
        ...state,
        pending_delete: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default pipelines;
