import SyncLoader from "react-spinners/SyncLoader";

const Index = ({ title, total, average, isLoading }) => {
  return (
    <div className="d-flex p-3 average-card">
      <div className="col">
        {average && (
          <div>
            <span className="normal-title">Rata-rata Jumlah</span>
          </div>
        )}
        <div>
          <span className="title text-bold">{title || "-"}</span>
        </div>
        <div className="d-flex justify-content-center">
          {isLoading ? (
            <div className="my-3">
              <SyncLoader color="#04AA59" loading />
            </div>
          ) : (
            <span className="total">{total || "0"}</span>
          )}
        </div>
      </div>
    </div>
  );
};

export default Index;
