import { getUser } from "../../utils/User"
import { connect, useDispatch } from "react-redux"
import { useState, useRef, useEffect } from "react"
import DataMapBaseLayout from "../../components/layout/data_map"
import SearchButtonDefault from "../../components/buttons/SearchButtonDefault"
import moment from "moment"
import Select from "react-select"
import InfiniteScroll from "react-infinite-scroll-component"
import Map from "./Map"
import Data from "./Data"
import SubItem from "./SubItem"
import DMA from "../../store/actions/master_data/dma"
import Zone from "../../store/actions/master_data/zone"
import DataLogger from "../../store/actions/data_logger"
import Input from "../../store/actions/master_data/input"
import Ekspor from "../../store/actions/master_data/ekspor"

let dataPresure = [],
  dataDebit = [],
  dataHour = [],
  dataMin = [],
  dataMax = [],
  dataWarn = []

const LoggerData = ({ dataLogger, masterDataZone, masterDataDMA, masterDataInput }) => {
  const [activeTab, setActiveTab] = useState("")
  const [title, setTitle] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [searchText, setSearchText] = useState("")
  const [selectLocationType, setSelectLocationType] = useState("Input")
  const [locationData, setLocationData] = useState([])
  const [loggerDate, setLoggerDate] = useState(moment().format("YYYY-MM-DD"))
  const [loggerAreaDetail, setLoggerAreaDetail] = useState({
    data: null,
    type: null,
  })
  const dispatch = useDispatch()
  const currentUser = getUser().user

  const [currentSubitemPage, setCurrentSubitemPage] = useState(1)
  const [totalSubitemData, setTotalSubitemData] = useState(1)
  const [maxSubitemPage, setMaxSubitemPage] = useState(1)

  const dataMapBaseLayoutRef = useRef()
  const [currentZoom, setCurrentZoom] = useState(10)
  const flyToZoom = 18

  // map vars
  // --------
  const [currentCenter, setMapCenter] = useState([-6.9344694, 107.6049539])
  const [mapMarkerOptions, setMapMarkerOptions] = useState({
    color: loggerAreaDetail && loggerAreaDetail.is_warning ? "#D10D0D" : "#04AA59",
    fillColor: loggerAreaDetail && loggerAreaDetail.is_warning ? "#D10D0D" : "#04AA59",
  })

  const showContentSubItem = () => {
    if (!loggerAreaDetail.data) return

    setTitle(
      loggerAreaDetail.type == "Input"
        ? (loggerAreaDetail.data.is_output ? "Output " : "Input ") + loggerAreaDetail.data.name
        : loggerAreaDetail.type == "Ekspor"
        ? loggerAreaDetail.data.name
        : loggerAreaDetail.type + " " + loggerAreaDetail.data.name
    )

    setActiveTab(loggerAreaDetail.data.id)
    getDataLogger(loggerAreaDetail.data.device_id, loggerAreaDetail.type.replace(/\s/g, "").toLowerCase())

    // map affect
    const markerColor = loggerAreaDetail.data && loggerAreaDetail.data.is_warning ? "#D10D0D" : "#04AA59"
    setMapMarkerOptions({
      color: markerColor,
      fillColor: markerColor,
    })
    setMapCenter(loggerAreaDetail.data ? [loggerAreaDetail.data.latitude, loggerAreaDetail.data.longitude] : currentCenter)
    setCurrentZoom(flyToZoom)
  }

  const getDataLogger = (device_id, type) =>
    dispatch(
      DataLogger.get({
        device_id,
        type,
        start_date: moment(loggerDate).format("YYYY-MM-DD"),
        end_date: moment(loggerDate).format("YYYY-MM-DD"),
      })
    )

  const triggerSearch = (value) => {
    setSearchText(value)
  }

  useEffect(() => {
    getLocationData(true)
    setTitle("")
    getDataLogger(null, null)
  }, [currentUser?.pdam?.id, selectLocationType, searchText])

  useEffect(() => {
    showContentSubItem()
  }, [loggerDate, loggerAreaDetail])

  useEffect(() => {
    dataMapBaseLayoutRef.current.getMap()?.flyTo(currentCenter, flyToZoom)
  }, [currentCenter])

  const getLocationData = (refresh = false) => {
    if (refresh) setLocationData([])
    setIsLoading(true)
    new Promise((resolve) => {
      let param = {
        draw: 1,
        length: 10,
        page: refresh ? 1 : currentSubitemPage + 1,
        pdam_id: currentUser?.pdam?.id,
        search_text: searchText,
      }
      switch (selectLocationType) {
        case "Input":
          dispatch(Input.get(param, resolve))
          break
        case "Zone":
          dispatch(Zone.get(param, resolve))
          break
        case "DMA":
          dispatch(DMA.get(param, resolve))
          break
        case "Ekspor":
          dispatch(Ekspor.get(param, resolve))
          break
      }
    }).then((res) => {
      setTotalSubitemData(res?.totalCount)
      setMaxSubitemPage(Math.ceil(res?.totalCount / 10))
      if (refresh) {
        setCurrentSubitemPage(1)
        setLocationData(res?.data)
        return
      }
      setCurrentSubitemPage(currentSubitemPage + 1)
      setLocationData(locationData.concat(res?.data))
    })
    setIsLoading(false)
  }

  if (dataLogger.data_list && dataLogger.data_list.data.data.length > 0) {
    dataPresure = []
    dataDebit = []
    dataHour = []
    dataMin = []
    dataMax = []
    dataWarn = []
    dataLogger.data_list.data.data.forEach((item) => {
      dataPresure[item.hour] = item.pressure
      dataDebit[item.hour] = item.debit_lps
      dataHour[item.hour] = item.hour
      dataMin[item.hour] = item.lower_limit
      dataMax[item.hour] = item.upper_limit
      dataWarn[item.hour] = item.is_warning
    })
  } else {
    dataPresure = []
    dataDebit = []
    dataHour = []
    dataMin = []
    dataMax = []
    dataWarn = []
  }

  const filteredTitle = title
    .split(" ")
    .filter((text) => text !== "Output")
    .join(" ")

  const dataContent = (
    <>
      <Data
        setLoggerDate={setLoggerDate}
        loggerDate={loggerDate}
        device_id={loggerAreaDetail.data?.device_id}
        type={loggerAreaDetail.type}
        dataLogger={{
          dataPresure: dataPresure,
          dataDebit: dataDebit,
          dataHour: dataHour,
          dataMin: dataMin,
          dataMax: dataMax,
          dataWarn: dataWarn,
        }}
        pendingDataLoger={dataLogger.pending}
      />
    </>
  )

  const mapContent = (
    <>
      <Map locationData={locationData} locationType={selectLocationType} currentData={loggerAreaDetail.data} />
    </>
  )

  const customSelect = {
    control: (base, state) => ({
      ...base,
      background: "#fffff",
      fontSize: "15px",
      margin: "15px 0 0 0",
      padding: "1px",
      height: "48px",
      width: "100%",
      textAlign: "center",
      alignItems: "center",
      border: "1px solid #d8d8d8",
      boxShadow: null,
      "&:hover": {
        borderColor: "#d8d8d8",
      },
    }),
    option: (options) => ({
      ...options,
      textAlign: "left",
    }),
  }
  return (
    <div className="menu-container pr-0">
      <div className="grid-header">
        <div className="side-menu">
          <div className="menu-title bordered-b">
            <div className="row mb-4">
              <div className="col">
                <Select
                  isSearchable={false}
                  options={[
                    { label: "Input", value: "Input" },
                    { label: "Zone", value: "Zone" },
                    { label: "DMA", value: "DMA" },
                    { label: "Ekspor", value: "Ekspor" },
                  ]}
                  styles={customSelect}
                  placeholder="Pilih Tipe Lokasi"
                  defaultValue={{ label: "Input", value: "Input" }}
                  onChange={(value) => {
                    setSelectLocationType(value.value)
                    setCurrentSubitemPage(1)
                  }}
                />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <SearchButtonDefault placeholder="Cari Lokasi" trigger={(value) => triggerSearch(value)} />
              </div>
            </div>
          </div>
          <div id="subItemContainer-datalogger" style={{ overflow: "auto", height: "calc(100vh - 134px)" }}>
            <InfiniteScroll
              dataLength={locationData.length} //This is important field to render the next data
              next={getLocationData}
              scrollableTarget="subItemContainer-datalogger"
              hasMore={currentSubitemPage - 1 < maxSubitemPage} // currentSubitemPage-1 < maxSubitemPage
              loader={
                masterDataZone.pending || masterDataDMA.pending || masterDataInput.pending ? (
                  <div
                    style={{
                      width: "100",
                      textAlign: "center",
                      padding: "15px",
                    }}
                  >
                    Memuat...
                  </div>
                ) : (
                  <div
                    style={{
                      width: "100",
                      textAlign: "center",
                      padding: "15px",
                    }}
                  >
                    Data tidak ditemukan
                  </div>
                )
              }
              endMessage={
                locationData.length > 0 ? (
                  <p style={{ textAlign: "center" }}>
                    <b>You have seen it all</b>
                  </p>
                ) : (
                  <div
                    style={{
                      width: "100",
                      textAlign: "center",
                      padding: "15px",
                    }}
                  >
                    Data tidak ditemukan
                  </div>
                )
              }
            >
              {locationData.map((item, idx) => (
                <SubItem
                  key={idx}
                  title={item.name
                    .split(" ")
                    .filter((text) => text !== "Output")
                    .join(" ")}
                  toggle={() =>
                    setLoggerAreaDetail({
                      data: item,
                      type: selectLocationType,
                    })
                  }
                  active={item.id === activeTab}
                />
              ))}
            </InfiniteScroll>
          </div>
        </div>
        <>
          <DataMapBaseLayout ref={dataMapBaseLayoutRef} title={`Data Logger ${filteredTitle}`} dataContent={dataContent} mapContent={mapContent} initialCenter={currentCenter} initialZoom={currentZoom} />
        </>
      </div>
    </div>
  )
}

const mapStateToProps = ({ dataLogger, masterDataZone, masterDataDMA, masterDataInput }) => {
  return { dataLogger, masterDataZone, masterDataDMA, masterDataInput }
}

const mapDispatchToProps = () => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(LoggerData)
