import { combineReducers } from "redux";
import auth from "./auth";
import user from "./user";
import masterDataUser from "./master_data/user";
import masterDataPDAM from "./master_data/pdam";
import masterDataZone from "./master_data/zone";
import masterDataDMA from "./master_data/dma";
import masterDataInput from "./master_data/input";
import masterDataEkspor from "./master_data/ekspor";
import masterDataSubDMA from "./master_data/subDMA";
import masterDataRegion from "./master_data/region";
import masterDataCustomer from "./master_data/customer";
import masterDataPipeline from "./master_data/pipeline";
import {
  setSelectedHeader as header,
  setSelectedLinkHeader as linkHeader,
  setGoBack as isBackButton,
  setHeaderModal as headerModal,
} from "./header";
import dataLogger from "./data_logger";
import warningSystem from "./warning_system";
import stepTestSupport from "./step_test_support";
import repairWork from "./repair_work";
import fileManagement from "./file_management";
import commercialLosses from "./commercial_losses";
import waterBalance from "./water_balance";
import ILI from "./infrastructure_leakage_index";
import hydraulicModel from "./hydraulic_model";
import managementNotification from "./management_notification";
import notification from "./notification";
import {
  dashboardAverageLogger,
  dashboardTotalPDAM,
  dashboardAverageTotal,
  dashboardRepairWork,
  dashboardPDAM,
  dashboardNRWMax,
  dashboardNRWMin,
} from "./dashboard";

const rootReducer = combineReducers({
  header,
  linkHeader,
  auth,
  user,
  masterDataUser,
  masterDataPDAM,
  masterDataZone,
  masterDataDMA,
  masterDataInput,
  masterDataEkspor,
  masterDataSubDMA,
  masterDataRegion,
  masterDataCustomer,
  dataLogger,
  warningSystem,
  stepTestSupport,
  repairWork,
  fileManagement,
  commercialLosses,
  ILI,
  waterBalance,
  hydraulicModel,
  managementNotification,
  notification,
  dashboardAverageLogger,
  dashboardTotalPDAM,
  dashboardAverageTotal,
  dashboardRepairWork,
  dashboardPDAM,
  dashboardNRWMax,
  dashboardNRWMin,
  masterDataPipeline,
});

export default rootReducer;
