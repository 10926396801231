import { useState, useEffect } from "react"
import { Container, Row, Col } from "reactstrap"
import { connect, useDispatch } from "react-redux"
import HeaderMenu from "../../components/commons/menu/HeaderMenu"
import MonthPicker from "../../components/forms/MonthPicker"
import Dashboard from "../../store/actions/dashboard"
import { getUser } from "../../utils/User"
import { Role } from "../../utils/Constants"
import Table from "../../components/table"
import { toastSuccess } from "../../components/commons/toast"
import PDAMCard from "./PDAMCard"
import AverageCard from "./AverageCard"
import ProductionCard from "./ProductionCard"
import RepairWorkCard from "./RepairWorkCard"
import NRWCard from "./NRWCard"
import MapCard from "./MapCard"
import SyncLoader from "react-spinners/SyncLoader"
import moment from "moment"

const Index = ({ dashboardAverageLogger, dashboardTotalPDAM, dashboardAverageTotal, dashboardRepairWork, dashboardPDAM, dashboardNRWMax, dashboardNRWMin, switchPDAM }) => {
  const [dataPDAM, setDataPDAM] = useState([])
  const [pendingPDAM, setPendingPDAM] = useState(false)
  const [totalData, setTotalData] = useState(null)
  const [page, setPage] = useState(1)
  const [maxPage, setMaxPage] = useState(1)
  const [monthYear, setMonthYear] = useState(new Date())

  const dispatch = useDispatch()
  const user = getUser()
  let isSwitch = false
  let dataTotalPDAM, dataAverageLogger, dataAverageTotal, dataRepairWork, dataDebit, dataNRWMax, dataNRWMin
  const filter = {
    month: monthYear ? moment(monthYear).format("M") : moment().format("M"),
    year: monthYear ? moment(monthYear).format("YYYY") : moment().format("YYYY"),
  }

  const paramRepairWork = {
    draw: 1,
    length: 5,
    page: 1,
    pdam_id: user.user.pdam?.id,
    start: null,
  };

  // Dont delete this
  useEffect(() => {
    const justLogin = localStorage.getItem("just_login")

    if (justLogin) {
      toastSuccess(`Selamat Anda berhasil login sebagai ${justLogin}`)

      localStorage.removeItem("just_login")
    }
  }, [])

  useEffect(() => {
    if (user.user.pdam?.id) {
      const param = {
        ...filter,
        pdam_id: user.user.pdam?.id
      }

      getDataRepairWork(paramRepairWork)
      getDataNRWMax(param)
      getDataNRWMin(param)
      getDataDebit(param)
    }
  }, [switchPDAM, monthYear])

  useEffect(() => {
    if (!user.user.pdam) {
      getDataAverageLogger(filter)
      getDataTotalPDAM(filter)
      getDataAverageTotal(filter)
    }
  }, [switchPDAM])

  useEffect(() => {
    if (!user.user.pdam) {
      getDataPDAM(filter)
    }
  }, [page, switchPDAM, monthYear])

  const getDataAverageLogger = (param) => dispatch(Dashboard.getAverageLogger(param))
  const getDataTotalPDAM = (param) => dispatch(Dashboard.getTotalPDAM(param))
  const getDataAverageTotal = (param) => dispatch(Dashboard.getAverageTotal(param))
  const getDataRepairWork = (paramRepairWork) => dispatch(Dashboard.getRepairWork(paramRepairWork))
  const getDataNRWMax = (param) => dispatch(Dashboard.getNRWMax(param))
  const getDataNRWMin = (param) => dispatch(Dashboard.getNRWMin(param))
  const getDataDebit = (param) => dispatch(Dashboard.getPDAM(param))
  const getDataPDAM = () => {
    new Promise((resolve) => {
      const param = {
        length: 5,
        page: page ?? 1,
        month: filter.month,
        year: filter.year,
      }
      setPendingPDAM(true)
      dispatch(Dashboard.getPDAM(param, resolve))
    }).then((res) => {
      setDataPDAM(res?.data)
      setTotalData(res?.totalCount)
      setMaxPage(Math.ceil(res?.totalCount / 5))
      setPendingPDAM(false)
    })
  }

  if (dashboardAverageLogger.data) {
    dataAverageLogger = dashboardAverageLogger.data.data.data
  }

  if (dashboardTotalPDAM.data) {
    dataTotalPDAM = dashboardTotalPDAM.data.data.data
  }

  if (dashboardAverageTotal.data) {
    dataAverageTotal = dashboardAverageTotal.data.data.data
  }

  if (dashboardRepairWork.data) {
    dataRepairWork = dashboardRepairWork.data.data.data
  }

  if (dashboardNRWMax.data) {
    dataNRWMax = dashboardNRWMax.data.data.data
  }

  if (dashboardNRWMin.data) {
    dataNRWMin = dashboardNRWMin.data.data.data
  }

  if (dashboardPDAM.data) {
    dataDebit = dashboardPDAM.data.data.data
  }

  if (user.user.pdam || !(user.user.role_code === Role.Superadmin || user.user.role_code === Role.Kadiv)) {
    isSwitch = true
  }

  const columns = [
    {
      Header: "NO",
      accessor: "no",
    },
    {
      Header: "NAMA PDAM",
      accessor: "name",
      Cell: ({ row }) => {
        const { original } = row
        const { name } = original

        return <span>{name}</span>
      },
    },
    {
      Header: "INPUT",
      accessor: "total_input",
    },
    {
      Header: "EKSPOR",
      accessor: "total_output",
    },
    {
      Header: "ZONA",
      accessor: "total_zone",
    },
    {
      Header: "DMA",
      accessor: "total_dma",
    },
    // {
    //   Header: "PELANGGAN",
    //   accessor: "total_customer",
    // },
    {
      Header: "NRW",
      accessor: "nrw",
    },
  ]

  return (
    <div className="menu-container">
      <Row className="dashboard-container">
        <Col sm={12} md={8} className="dashboard-left-content">
          <HeaderMenu
            title={`Dashboard ${isSwitch ? moment(monthYear).format("MMMM YYYY") : ''}`}
            optClass="border-bottom w-100"
            children={isSwitch ? (
              <MonthPicker
                label="Filter Bulan"
                name="monthYear"
                disableFuture
                value={monthYear}
                onChange={(value) => {
                  setMonthYear(value)
                }}
              />
            ) : null}
          />
          {isSwitch ? (
            <>
              {/* switch pdam */}
              <div className="content-container-side">
                <div className="border-bottom mb-3">
                  <div className="text-bold title my-4">
                    <span>Debit (l/s)</span>
                  </div>
                  <Row className="justify-content-between pl-3 mb-4">
                    {dashboardPDAM.pending ? (
                      <div className="d-flex justify-content-center align-items-center" style={{ minHeight: 72, width: "100%" }}>
                        <SyncLoader color="#04AA59" loading />
                      </div>
                    ) : (
                      <>
                        <ProductionCard color="kuning" title="Input" total={dataDebit && dataDebit.length > 0 ? dataDebit[0].total_debit_input : 0} />
                        <ProductionCard color="kuning" title="Ekspor" total={dataDebit && dataDebit.length > 0 ? dataDebit[0].total_debit_output : 0} />
                        <ProductionCard color="biru" title="Zona" total={dataDebit && dataDebit.length > 0 ? dataDebit[0].total_debit_zone : 0} />
                        <ProductionCard color="hijau" title="DMA" total={dataDebit && dataDebit.length > 0 ? dataDebit[0].total_debit_dma : 0} />
                      </>
                    )}
                  </Row>
                </div>
                <div style={{ paddingTop: "15px", paddingBottom: "30px" }}>
                  <MapCard />
                </div>
              </div>
            </>
          ) : (
            <>
              {/* role kadiv/superadmin */}
              <div className="content-container-side">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="text-bold title my-4">
                    <span>Resume PDAM {moment(monthYear).format("MMMM YYYY")}</span>
                  </div>
                  <div className="mt-3">
                    <MonthPicker
                      label="Filter Bulan"
                      name="monthYear"
                      disableFuture
                      value={monthYear}
                      onChange={(value) => {
                        setMonthYear(value)
                      }}
                    />
                  </div>
                </div>
                <div className="overflow">
                  <Table isLoading={pendingPDAM} columns={columns} data={dataPDAM} length={dataPDAM.length / 2} pagination={{ page, totalData, maxPage, toggle: setPage }} />
                </div>
              </div>
            </>
          )}
        </Col>
        <Col sm={12} md={4} className="dashboard-right-content">
          {isSwitch ? (
            <>
              {/* switch pdam */}
              <RepairWorkCard
                title="Top 5"
                subtitle="Repair Work"
                isLoading={dashboardRepairWork.pending}
                data={dataRepairWork?.map((item) => ({
                  location: `${item.lokasi}`,
                  accident: item?.kejadian.length > 50 ? 
                          item?.kejadian.substring(0, 50) + "..."
                          : item?.kejadian,
                  status: item.status,
                  reportedDate: item.waktuLapor,
                  full: item
                }))}
              />
              <NRWCard title="NRW Tertinggi" data={dataNRWMax} isLoading={dashboardNRWMax.pending} />
              <NRWCard title="NRW Terendah" data={dataNRWMin} isLoading={dashboardNRWMin.pending} />
            </>
          ) : (
            <>
              {/* role kadiv/superadmin */}
              <div>
                <div className="text-bold title my-4">
                  <span>PDAM</span>
                </div>
                <div style={{ minHeight: 250 }}>
                  {dataTotalPDAM ? (
                    <>
                      <PDAMCard color="biru" title="Total PDAM" total={dataTotalPDAM.total_pdam} />
                      <PDAMCard color="hijau" title="Subscribe" total={dataTotalPDAM.total_pdam_subscribe} />
                      <PDAMCard color="hitam" title="Unsubscribe" total={dataTotalPDAM.total_pdam_unsubscribe} />
                    </>
                  ) : (
                    <div className="d-flex justify-content-center align-items-center" style={{ minHeight: 250 }}>
                      <SyncLoader color="#04AA59" loading={dashboardTotalPDAM.pending} />
                    </div>
                  )}
                </div>
              </div>
              <div>
                <AverageCard average title="Input, Zona dan DMA" total={dataAverageTotal?.average_input_zone_dma} isLoading={dashboardAverageTotal.pending} />
                <AverageCard average title="Semua Pelanggan" total={dataAverageTotal?.average_customer} isLoading={dashboardAverageTotal.pending} />
                <AverageCard title="Density Rate" total={`1 : ${(dataAverageTotal?.average_customer / dataAverageTotal?.average_input_zone_dma).toFixed(2)}`} isLoading={dashboardAverageTotal.pending} />
              </div>
            </>
          )}
        </Col>
      </Row>
    </div>
  )
}

const mapStateToProps = ({ dashboardAverageLogger, dashboardTotalPDAM, dashboardAverageTotal, dashboardRepairWork, dashboardPDAM, dashboardNRWMax, dashboardNRWMin }) => {
  return {
    dashboardAverageLogger,
    dashboardTotalPDAM,
    dashboardAverageTotal,
    dashboardRepairWork,
    dashboardPDAM,
    dashboardNRWMax,
    dashboardNRWMin,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(Index)
