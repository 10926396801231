import React from 'react';
import SyncLoader from "react-spinners/SyncLoader";

class Loader extends React.Component {
    constructor(props, context) {
        super(props);

        this.state = {

        };
    }

    render() {

        const { color, loading, fullScreen } = this.props;

        return (
            <>
                {fullScreen && (
                    <div style={{ position: "fixed", width: "100vw", height: "100vh", top: 0, left: 0, zIndex: 1042, background: "#00000000" }}>
                        <div style={{ textAlign: "center", marginTop: "50vh" }}>
                            <SyncLoader color={color ?? "#04AA59"} loading={loading} />
                        </div>
                    </div>
                )}

                {!fullScreen && (
                    <div style={{ position: "absolute", width: "100%", height: "100%", top: 0, left: 0, zIndex: 1042, background: "#00000000" }}>
                        <div style={{ textAlign: "center", marginTop: "10%" }}>
                            <SyncLoader color={color ?? "#04AA59"} loading={loading} />
                        </div>
                    </div>
                )}
            </>
        )
    }
}

export default Loader