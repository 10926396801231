import { useEffect, useState } from "react"
import InputOnClick from "../../components/forms/InputOnClick"
import CardFlush from "../../components/cards/cardFlush"
import HydraulicCurvePopUp from "./HydraulicCurvePopUp"

const HydraulicPopUp = ({ type, item, curves, toggle, readOnly, inputGeoJson, lineGeoJson, zoneGeoJson, dmaGeoJson, customerGeoJson, setLineGeoJson, handleCurve }) => {
  const listDefaultCurve = [{ value: "add", label: "Tambah curve" }]
  const [showCurve, setShowCurve] = useState(false)
  const [selectedCurve, setSelectedCurve] = useState(0)
  const [curveOptions, setCurveOptions] = useState(listDefaultCurve)
  let properties = item?.properties ?? null
  let typeShow = type ? type?.split("-") : []

  const handleEdit = (value, label) => {
    let dataGeojson =
      typeShow[0] === "RES" || typeShow[0] === "TANK"
        ? inputGeoJson
        : typeShow[0] === "CUST"
        ? customerGeoJson
        : typeShow[0] === "ZONE"
        ? zoneGeoJson
        : typeShow[0] === "DMA"
        ? dmaGeoJson
        : typeShow[0] === "PIPE" || typeShow[0] === "PUMP" || typeShow[0] === "PRV"
        ? lineGeoJson
        : lineGeoJson
    let features = dataGeojson?.features
    let index = typeShow?.length > 0 ? Number(typeShow[1]) - 1 : 0
    let properties = features ? features[index]?.properties : {}
    let oldName = properties.Name ?? properties.NAMA ?? properties.NAME

    if (value && label && properties) {
      switch (label) {
        case "Name":
          properties.NAME = value
          properties.NAMA = value
          break
        case "*Total Head":
          properties.TOTALHEAD = value
          break
        case "Initial Quality":
          properties.INITQUALITY = value
          break
        case "Source Quality":
          properties.SOURCEQUALITY = value
          break
        case "Initial Level":
          properties.INITLEVEL = value
          break
        case "Minimum Level":
          properties.MINLEVEL = value
          break
        case "Maximum Level":
          properties.MAXLEVEL = value
          break
        case "Minimum Volume":
          properties.MINVOL = value
          break
        case "Elevation":
          properties.ELEVATION = value
          break
        case "Diameter":
          properties.DIAMETER = value
          break
        case "Base Demand":
          properties.BASEDEMAND = value
          break
        case "Starting Node":
          properties.NODE1 = value
          break
        case "Ending Node":
          properties.NODE2 = value
          break
        case "Length":
          properties.PANJANG = value
          break
        case "Roughness":
          properties.ROUGHNESS = value
          break
        case "Type":
          properties.TYPE = value
          break
        case "Curve":
          properties.CURVE = value
          break
        case "Status":
          properties.STATUS = value
          break
        default:
          properties.TOTALHEAD = value
      }
    }

    if (label === "Name" && (typeShow[0] === "RES" || typeShow[0] === "TANK" || typeShow[0] === "CUST" || typeShow[0] === "ZONE" || typeShow[0] === "DMA")) {
      if (lineGeoJson && lineGeoJson?.features) {
        lineGeoJson.features.map((item, index) => {
          let propsNode1 = item?.properties?.NODE1
          let propsNode2 = item?.properties?.NODE2
          if (propsNode1 === oldName) {
            lineGeoJson.features[index].properties.NODE1 = value
          }
          if (propsNode2 === oldName) {
            lineGeoJson.features[index].properties.NODE2 = value
          }
        })
        if (setLineGeoJson) {
          setLineGeoJson(lineGeoJson)
        }
      }
    }
    if (toggle && dataGeojson) {
      toggle(dataGeojson)
    }
  }

  const handleShowCurve = (value) => {
    let id = value
    if (id === "add" || id === "edit") {
      setShowCurve(id)
    } else {
      handleEdit(value, "Curve")
    }
    setSelectedCurve(value)
  }

  const handleSaveCurve = (value) => {
    let name = value.name
    let curvesList = curves ?? []
    let index = curvesList.findIndex((item) => item.name === name)
    if (value) {
      if (index > -1) {
        if (curvesList[index].deleted) {
          curvesList.splice(index, 1)
        } else {
          curvesList[index] = value
        }
      } else {
        curvesList = [...curvesList, value]
      }

      if (handleCurve) {
        handleCurve(curvesList)
        handleEdit(name, "Curve")
      }
      setShowCurve(false)
    }
  }

  const valveType = [
    { value: "FCV", label: "FCV" },
    { value: "GPV", label: "GPV" },
    { value: "PBV", label: "PBV" },
    { value: "PRV", label: "PRV" },
    { value: "PSV", label: "PSV" },
    { value: "TCV", label: "TCV" },
  ]

  useEffect(() => {
    const updateCurveOptions = () => {
      let curveList =
        curves.length > 0
          ? curves?.filter((item) => {
              if (item.deleted === undefined) {
                item.value = item.name
                item.label = item.name
                return item
              }
            })
          : []
      let defaultList = curveList.length > 0 ? [...listDefaultCurve, { value: "edit", label: "Edit Curve" }] : listDefaultCurve
      let curveOptions = curveList ? [...curveList, ...defaultList] : [defaultList]

      setCurveOptions(curveOptions)
    }

    updateCurveOptions()
  }, [curves])

  return (
    <div className="hydraulic-information active-pointer-evt" style={{ maxWidth: showCurve ? "660px" : "" }}>
      <div style={{ display: "flex", placeItems: "center" }}>
        <p className="title-info text-bold">
          <InputOnClick label={"Name"} value={properties?.NAME ?? properties?.NAMA ?? "-"} onChangeEdit={readOnly ? null : (value, type) => handleEdit(value, type)} type="string" />
        </p>
        {(typeShow[0] === "CUST" || typeShow[0] === "ZONE" || typeShow[0] === "DMA") && (
          <div
            style={{
              opacity: 0.5,
              fontSize: 14,
              fontWeight: 500,
              marginLeft: 5,
            }}
          >
            ({typeShow[0] === "CUST" ? "Pelanggan" : typeShow[0] === "ZONE" ? "Zona" : "DMA"})
          </div>
        )}
      </div>
      <p style={{ opacity: 0.5, fontSize: 10, fontWeight: 500 }}>Klik pada nilai untuk melakukan perubahan data</p>
      <div style={{ display: "flex" }}>
        <div
          className="hydraulic-information-detail"
          style={{
            height: 200,
            overflowY: "auto",
            overflowX: "hidden",
            width: 330,
          }}
        >
          {(typeShow[0] === "PIPE" || typeShow[0] === "PUMP" || typeShow[0] === "PRV" || typeShow[0] === "FCV" || typeShow[0] === "GPV" || typeShow[0] === "PBV" || typeShow[0] === "TCV" || typeShow[0] === "PSV") && (
            <>
              <CardFlush list reverse label="Starting Node" desc={properties?.NODE1} size={{ label: 5, desc: 6 }} />
              <CardFlush list reverse label="Ending Node" desc={properties?.NODE2} size={{ label: 5, desc: 6 }} />
              {typeShow[0] === "PIPE" && (
                <>
                  <CardFlush
                    list
                    reverse
                    label="Length"
                    desc={properties?.PANJANG !== "" && properties?.PANJANG !== null && properties?.PANJANG !== undefined ? Number(properties?.PANJANG).toFixed(2) : 1000}
                    type="number"
                    size={{ label: 5, desc: 6 }}
                    editable={readOnly ? null : (value, type) => handleEdit(value, type)}
                  />
                  <CardFlush list reverse label="Diameter" desc={properties?.DIAMETER ?? 12} size={{ label: 5, desc: 6 }} type="number" editable={readOnly ? null : (value, type) => handleEdit(value, type)} />
                  <CardFlush
                    list
                    reverse
                    label="Roughness"
                    type="number"
                    desc={properties?.CHW != null ? properties?.CHW : properties?.ROUGHNESS ?? 100}
                    size={{ label: 5, desc: 6 }}
                    editable={readOnly ? null : (value, type) => handleEdit(value, type)}
                  />
                </>
              )}

              {typeShow[0] === "PUMP" && (
                <>
                  <CardFlush list reverse label="Curve" options={curveOptions} desc={properties?.CURVE ?? selectedCurve ?? null} size={{ label: 5, desc: 6 }} editable={readOnly ? null : (value, label) => handleShowCurve(value, label)} />
                </>
              )}

              {typeShow[0] !== null && typeShow[0] !== undefined && typeShow[0] !== "PIPE" && typeShow[0] !== "PUMP" && (
                <CardFlush list reverse label="Type" options={valveType} desc={properties?.TYPE ?? "PRV"} size={{ label: 5, desc: 6 }} editable={readOnly ? null : (value, label) => handleEdit(value, label)} />
              )}

              <CardFlush
                list
                reverse
                label="Status"
                options={[
                  { value: "Open", label: "Open" },
                  { value: "Close", label: "Close" },
                ]}
                desc={properties?.STATUS ?? "Open"}
                size={{ label: 5, desc: 6 }}
                editable={readOnly ? null : (value, label) => handleEdit(value, label)}
              />
              <CardFlush list reverse label="Flow" type="number" desc={properties?.FLOW ?? 0} size={{ label: 5, desc: 6 }} disabled />
              {typeShow[0] === "PIPE" && <CardFlush list reverse label="Velocity" type="number" desc={properties?.VELOCITY ?? 0} size={{ label: 5, desc: 6 }} disabled />}
              {/* <CardFlush
                list
                reverse
                label="Koor Awal"
                desc={geometry?.coordinates[0].join(",") ?? 0}
                size={{ label: 5, desc: 6 }}
                disabled
              />
              <CardFlush
                list
                reverse
                label="Koor Akhir"
                desc={geometry?.coordinates[1].join(",") ?? 0}
                size={{ label: 5, desc: 6 }}
                disabled
              /> */}
            </>
          )}
          {typeShow[0] === "RES" && (
            <>
              <CardFlush list reverse label="Total Head" type="number" desc={properties?.ELEVATION ?? 0} size={{ label: 5, desc: 6 }} disabled />
              <CardFlush list reverse label="Initial Quality" desc={properties?.INITQUALITY ?? 0} size={{ label: 5, desc: 6 }} type="number" editable={readOnly ? null : (value, type) => handleEdit(value, type)} />
              <CardFlush list reverse label="Source Quality" desc={properties?.SOURCEQUALITY ?? 0} size={{ label: 5, desc: 6 }} type="number" editable={readOnly ? null : (value, type) => handleEdit(value, type)} />
              <CardFlush list reverse label="Elevation" desc={properties?.ELEVATION ?? 0} size={{ label: 5, desc: 6 }} type="number" editable={readOnly ? null : (value, type) => handleEdit(value, type)} />
              <CardFlush list reverse label="Pressure" desc={properties?.PRESSURE ?? 0} size={{ label: 5, desc: 6 }} type="number" disabled />
            </>
          )}
          {typeShow[0] === "TANK" && (
            <>
              <CardFlush list reverse label="Initial Level" desc={properties?.INITLEVEL ?? 1000} size={{ label: 5, desc: 6 }} type="number" editable={readOnly ? null : (value, type) => handleEdit(value, type)} />
              <CardFlush list reverse label="Maximum Level" desc={properties?.MAXLEVEL ?? 2000} size={{ label: 5, desc: 6 }} type="number" editable={readOnly ? null : (value, type) => handleEdit(value, type)} />
              <CardFlush list reverse label="Minimum Level" desc={properties?.MINLEVEL ?? 500} size={{ label: 5, desc: 6 }} type="number" editable={readOnly ? null : (value, type) => handleEdit(value, type)} />
              {/* <CardFlush
                list
                reverse
                label="Minimum Volume"
                desc={properties?.MINVOL ?? 500}
                size={{ label: 5, desc: 6 }}
                type="number"
                editable={
                  readOnly ? null : (value, type) => handleEdit(value, type)
                }
              /> */}
              <CardFlush list reverse label="Diameter" desc={properties?.DIAMETER ?? 12} size={{ label: 5, desc: 6 }} type="number" editable={readOnly ? null : (value, type) => handleEdit(value, type)} />
              <CardFlush list reverse label="Elevation" desc={properties?.ELEVATION ?? 0} size={{ label: 5, desc: 6 }} type="number" editable={readOnly ? null : (value, type) => handleEdit(value, type)} />
              <CardFlush list reverse label="Pressure" type="number" desc={properties?.PRESSURE ?? 0} size={{ label: 5, desc: 6 }} disabled />
            </>
          )}
          {(typeShow[0] === "CUST" || typeShow[0] === "ZONE" || typeShow[0] === "DMA") && (
            <>
              <CardFlush list reverse label="Base Demand" desc={properties?.BASEDEMAND ?? 0} size={{ label: 5, desc: 6 }} type="number" editable={readOnly ? null : (value, type) => handleEdit(value, type)} />
              <CardFlush list reverse label="Elevation" desc={properties?.ELEVATION ?? 0} size={{ label: 5, desc: 6 }} type="number" editable={readOnly ? null : (value, type) => handleEdit(value, type)} />
              <CardFlush list reverse label="Pressure" desc={properties?.PRESSURE ?? 0} size={{ label: 5, desc: 6 }} disabled />
            </>
          )}
        </div>
        {showCurve && <HydraulicCurvePopUp curves={curves} readOnly={readOnly} show={showCurve} toggle={(value) => handleSaveCurve(value)} />}
      </div>
    </div>
  )
}

export default HydraulicPopUp
