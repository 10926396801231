
import Tooltip from '../../components/commons/tooltip'

const HydraulicIcon = ({ icon, image, toggle, active, first, tooltip, filled, toggleClose }) => {
    return (
        <>
            {
                tooltip ? (
                    <Tooltip bubble content={tooltip} direction="bottom">
                        <div className={`hydraulic-icon ${first ? 'hydraulic-icon-first' : ''} ${active ? "active" : ""}`} onClick={toggle} onMouseOut={toggleClose}>
                            <div className='icon-wrapper'>
                                {icon && <span className={`${filled ? 'material-icons' : 'material-icons-round'} icon`}>{icon}</span>}
                                {image}
                            </div>
                        </div>
                    </Tooltip>
                ) : (
                    <div className={`hydraulic-icon ${first ? 'hydraulic-icon-first' : ''} ${active ? "active" : ""}`} onClick={toggle}>
                        <span className={`${filled ? 'material-icons' : 'material-icons-round'} icon`}>{icon}</span>
                    </div>
                )
            }
        </>
    )
}

export default HydraulicIcon;