import SyncLoader from "react-spinners/SyncLoader";
import moment from "moment";
import localization from "moment/locale/id";

const Index = ({ title, subtitle, data, isLoading }) => {
  return (
    <div className="d-flex flex-column repair-work-card">
      <div className="d-flex align-items-center p-4 border-bottom">
        <div>
          <span class="material-icons hijau">location_on</span>
        </div>
        <div className="col detail-pdam">
          <div>
            <span className="normal-title">{title}</span>
          </div>
          <div>
            <span className="title text-bold">{subtitle || ""}</span>
          </div>
        </div>
      </div>
      <div className="px-4 pt-3 data-repair-work">
        {isLoading ? (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ minHeight: 330 }}
          >
            <SyncLoader color="#04AA59" loading />
          </div>
        ) : data && data.length > 0 ? (
          data.map((item, idx) => {
            return (
              <div className="d-flex align-items-center mb-3" key={idx}>
                <div className="mr-3">
                  <span className="title text-bold">{idx + 1}</span>
                </div>
                <div className="col">
                  <div>
                    <span className="normal-title">{item?.location}</span> 
                    <span style={{
                        marginLeft: 5,
                        backgroundColor: bgColor[getColorMode(item?.status)],
                        color: bgColor[getColorMode(item?.status) + "Label"]
                      }}
                      className="status">
                      <span>{item.status}</span>
                    </span>
                  </div>
                  <div>
                    <span className="normal-title" style={{ color: "#919392" }}>
                      {`Waktu Lapor: ${item?.full.waktuLapor ? 
                              moment(item?.full.waktuLapor).locale("id", localization).format("dddd, DD/MM/YYYY, HH:mm")
                              : "-"}`}
                    </span>
                  </div>
                  <div>
                    <span className="normal-title" style={{ color: "#919392" }}>
                      {`Kejadian: ${item.accident}` || "-"}
                    </span>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ minHeight: 330 }}
          >
            <span>Tidak Ada Data</span>
          </div>
        )}
      </div>
    </div>
  );
};

function getColorMode(status){
  return status === "Baru"
          ? "danger"
          : status === "Sedang Perbaikan"
              ? "warning"
              : status === "Selesai"
                  ? "primary"
                      : "-";
}

const bgColor = {
  primary: "#3BC862",
  primaryLabel: "#fff",
  warning: "#EDAA21",
  warningLabel: "#fff",
  danger: "#D10D0D",
  dangerLabel: "#fff",
  secondary: "#B8B8D6",
  secondaryLabel: "#fff",
};

export default Index;
