import { useState, useRef, useEffect } from "react"
import { FeatureGroup, Pane, Marker, GeoJSON } from "react-leaflet"
import { useDispatch } from "react-redux"
import useDynamicRefs from "use-dynamic-refs"
import { Icon, divIcon, Point } from "leaflet"
import { getUser } from "../../utils/User"
import { convertPipeline } from "../../utils/ConvertPipeline"

import DashboardService from "../../store/actions/dashboard"
import FileService from "../../store/actions/file_management"

import { reproject, crss } from "reproject"
import gjv from "geojson-validation"
import Map from "../../components/layout/map"
// import { uuid } from "uuidv4";
import { v4 } from "uuid"

import iconZoneImg from "../../assets/images/marker/blue.svg"
import iconInputImg from "../../assets/images/marker/yellow.svg"
import iconDmaImg from "../../assets/images/marker/green.svg"

let pathOtionsCollection = {}

const Index = ({}) => {
  const dispatch = useDispatch()

  // refs
  // ----
  const mapLayoutRef = useRef()

  // map vars
  // --------
  const [currentCenter, setCurrentCenter] = useState(null)
  const [currentZoom, setCurrentZoom] = useState(10)
  const [sensorLocations, setSensorLocations] = useState([])
  const [pipelineGeojsons, setPipelineGeojsons] = useState([])
  const [dmaGeojsons, setDmaGeojsons] = useState([])

  const [getMarkerRefs, setMarkerRefs] = useDynamicRefs()
  const warnPathOptions = {
    color: "#D10D0D",
    fillColor: "#D10D0D",
  }

  const iconZone = new Icon({
    iconUrl: iconZoneImg,
    iconRetinaUrl: iconZoneImg,
    iconAnchor: null,
    popupAnchor: null,
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new Point(40, 55),
    className: "leaflet-div-icon",
  })

  const iconInput = new Icon({
    iconUrl: iconInputImg,
    iconRetinaUrl: iconInputImg,
    iconAnchor: null,
    popupAnchor: null,
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new Point(40, 55),
    className: "leaflet-div-icon",
  })

  const iconDma = new Icon({
    iconUrl: iconDmaImg,
    iconRetinaUrl: iconDmaImg,
    iconAnchor: null,
    popupAnchor: null,
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new Point(40, 55),
    className: "leaflet-div-icon",
  })

  const currentUser = getUser().user

  const getLocationSensors = () => {
    let pipeLineGeoJsonCollection = []
    let pipeline_geojson_path = currentUser?.pdam?.pipeline_geojson_path ? [currentUser.pdam.pipeline_geojson_path] : null
    pipeline_geojson_path?.forEach((geojson_path) => {
      getGeoJson(geojson_path, function (data) {
        if (gjv.valid(data)) {
          data.feature = convertPipeline(data.features)
          pipeLineGeoJsonCollection.push(data)
        }
      })
    })

    setPipelineGeojsons(pipeLineGeoJsonCollection)

    let param = {
      pdam_id: currentUser?.pdam?.id,
    }
    dispatch(
      DashboardService.getLocationSensor(param, null, null, function (data) {
        setSensorLocations(data?.data?.location_list)
        let dmaGeoJsonCollection = []
        data?.data?.polygon_dma_list.forEach((geojson_path) => {
          getGeoJson(geojson_path, function (data) {
            if (gjv.valid(data)) {
              dmaGeoJsonCollection.push(data)
            }
          })
        })

        setDmaGeojsons(dmaGeoJsonCollection)
      })
    )
  }

  const getGeoJson = (path, callback) => {
    new Promise((resolve) => {
      const param = {
        file_path: path,
      }
      dispatch(FileService.post(param, resolve))
    }).then((res) => {
      callback(res.data)
    })
  }

  useEffect(() => {
    getLocationSensors()
  }, [currentUser?.pdam?.id])

  const mapContent = (
    <>
      {sensorLocations &&
        sensorLocations.map((item, i) => {
          return (
            <FeatureGroup key={i} ref={setMarkerRefs(item.id)} pathOptions={pathOtionsCollection[item?.id] ? pathOtionsCollection[item?.id] : warnPathOptions}>
              <Marker
                position={[item?.latitude, item?.longitude]}
                icon={divIcon({
                  html: "<div className='marker-title text-bold' style=''>" + (item?.type == "zone" ? "Z" : item?.type == "input" ? "I" : "DMA") + item?.location_name?.toUpperCase() + "</div>",
                })}
              />
              {item?.type == "zone" && <Marker position={[item?.latitude, item?.longitude]} icon={iconZone} />}
              {item?.type == "input" && <Marker position={[item?.latitude, item?.longitude]} icon={iconInput} />}
              {item?.type == "dma" && <Marker position={[item?.latitude, item?.longitude]} icon={iconDma} />}
            </FeatureGroup>
          )
        })}
      <Pane name="paneDmaDashboard" style={{ zIndex: 300 }}>
        {dmaGeojsons &&
          dmaGeojsons.map((geojson, i) => {
            return <GeoJSON key={v4()} data={reproject(geojson, "ESRI:54004", "EPSG:4326", crss)} color="#009e20" fillColor="#009e20" weight={2} />
          })}
      </Pane>
      <Pane name="panePipelineDashboard" style={{ zIndex: 300 }}>
        {pipelineGeojsons &&
          pipelineGeojsons.map((geojson, i) => {
            return <GeoJSON key={v4()} data={geojson} color="purple" fillColor="purple" weight={2} />
          })}
      </Pane>
    </>
  )

  return <Map ref={mapLayoutRef} title={`Peta Lokasi Sensor`} mapContent={mapContent} initialCenter={currentCenter} initialZoom={currentZoom} />
}

export default Index
