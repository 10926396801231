import { history } from "../../../utils/History";
import axios from "axios";
import { actionPending, actionSuccess, actionError } from "../actionTypes";

import { toastSuccess, toastError } from "../../../components/commons/toast";
import { getUser } from "../../../utils/User";
import { Role, RoleName } from "../../../utils/Constants";

import API from "../API";

// ACTION TYPES TO DISPATCH: METHOD_URL_TYPES
export const POST_AUTH_LOGIN_PENDING = "POST_AUTH_LOGIN_PENDING";
export const POST_AUTH_LOGIN_SUCCESS = "POST_AUTH_LOGIN_SUCCESS";
export const POST_AUTH_LOGIN_ERROR = "POST_AUTH_LOGIN_ERROR";
export const POST_AUTH_FORGOT_PENDING = "POST_AUTH_FORGOT_PENDING";
export const POST_AUTH_FORGOT_SUCCESS = "POST_AUTH_FORGOT_SUCCESS";
export const POST_AUTH_FORGOT_ERROR = "POST_AUTH_FORGOT_ERROR";
export const POST_AUTH_LOGOUT_PENDING = "POST_AUTH_LOGOUT_PENDING";
export const POST_AUTH_LOGOUT_SUCCESS = "POST_AUTH_LOGOUT_SUCCESS";
export const POST_AUTH_LOGOUT_ERROR = "POST_AUTH_LOGOUT_ERROR";

// URL: URL_{URL}
const AUTH_LOGIN_URL = `${process.env.REACT_APP_API_URL}v1/authentications/login`;
const AUTH_FORGOT_PASSWORD_URL = `${process.env.REACT_APP_API_URL}v1/authentications/forgot-password`;
const AUTH_RESET_PASSWORD_URL = `${process.env.REACT_APP_API_URL}v1/authentications/reset-password`;
const AUTH_FILL_PASSWORD_URL = `${process.env.REACT_APP_API_URL}v1/authentications/fill-password`;
const AUTH_LOGOUT_URL = `v1/authentications/logout`;

const auth_login = (param) => (dispatch) => {
  dispatch(actionPending(POST_AUTH_LOGIN_PENDING));
  axios
    .post(AUTH_LOGIN_URL, param)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(POST_AUTH_LOGIN_SUCCESS, res));
      res.data.data.user.pdam =
        res?.data?.data?.user?.role_code !== Role.Viewer
          ? null
          : res?.data?.data?.user?.pdam_list[0];
      localStorage.setItem("user_aws", JSON.stringify(res.data.data));
      localStorage.setItem(
        "just_login",
        RoleName[res.data.data.user.role_code]
      );
      let isUserIt = res.data.data.user.role_code === "it_administrator";

      if (isUserIt) {
        window.location.href = "/system";
      } else {
        window.location.href = "/dashboard";
      }
    })
    .catch((err) => {
      dispatch(actionError(POST_AUTH_LOGIN_ERROR));
      toastError(err?.response?.data?.message);
    });
};

// const logout = () => {
//   if (getUser() && getUser() !== null) {
//     localStorage.removeItem("user_clinic");
//     localStorage.removeItem("persist:root");
//     history.push("/login");
//   }
// };

const logout = (param, resolve, reject, callback) => (dispatch) => {
  dispatch(actionPending(POST_AUTH_LOGOUT_PENDING));
  API.post(AUTH_LOGOUT_URL, { params: param })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(POST_AUTH_LOGOUT_SUCCESS, res));
      localStorage.removeItem("user_aws");
      localStorage.removeItem("persist:root");
      history.push("/");
      toastSuccess("Logout berhasil");
      if (callback) {
        callback(res.data);
      }
    })
    .catch((err) => {
      dispatch(actionError(POST_AUTH_LOGOUT_ERROR));
      toastError(err?.response?.data?.message);
    });
};

const forgotPassword = (param) => (dispatch) => {
  dispatch(actionPending(POST_AUTH_FORGOT_PENDING));
  axios
    .post(AUTH_FORGOT_PASSWORD_URL, param)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      history.push("/");
      toastSuccess(
        "Silakan periksa email Anda untuk melakukan reset kata sandi"
      );
    })
    .catch((err) => {
      dispatch(actionError(POST_AUTH_FORGOT_ERROR));
      toastError(err?.response?.data?.message);
    });
};

const resetPassword = (param) => (dispatch) => {
  dispatch(actionPending(POST_AUTH_FORGOT_PENDING));
  axios
    .patch(AUTH_RESET_PASSWORD_URL, param)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      history.push("/");
      toastSuccess(`Reset kata sandi berhasil, silahkan login kembali`);
    })
    .catch((err) => {
      dispatch(actionError(POST_AUTH_FORGOT_ERROR));
      toastError(err?.response?.data?.message);
    });
};

const fillPassword = (param) => (dispatch) => {
  dispatch(actionPending(POST_AUTH_FORGOT_PENDING));
  axios
    .post(AUTH_FILL_PASSWORD_URL, param)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      history.push("/");
      toastSuccess(`Kata sandi berhasil dibuat, silahkan login`);
    })
    .catch((err) => {
      dispatch(actionError(POST_AUTH_FORGOT_ERROR));
      toastError(err?.response?.data?.message);
    });
};

const Auth = {
  auth_login,
  logout,
  forgotPassword,
  resetPassword,
  fillPassword,
  logout
};
export default Auth;
