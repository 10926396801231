import React, { useState, useRef, useMemo, useEffect } from 'react'
import { useMap, Marker, Popup } from 'react-leaflet'
import L, { divIcon } from 'leaflet'
import icon from "../../../assets/images/marker/person_location.svg"

function LocationMarker({ getLocation, notZoom, startDirectly, toggleError }) {
    const [position, setPosition] = useState(null);
    const [bbox, setBbox] = useState([]);

    const map = useMap();

    useEffect(() => {

        map.locate().on("locationfound", function (e) {
            setPosition(e.latlng);

            if (getLocation) {
                getLocation(e.latlng)
            }

            if (!notZoom) {
                map.flyTo(e.latlng, map.getZoom());
            }
        });

        function onLocationError(e) {
            alert('Untuk mengetahui lokasi Anda, mohon izinkan browser untuk mengakses lokasi Anda')
        }

        map.on('locationerror', onLocationError);
    }, [map]);

    return position === null ? null : (
        <Marker
            position={position}
            icon={divIcon({
                html: "<div className='marker'><img style='width: 40px' src='" + icon + "'/><div/>"
            })}
        />
    );
}

export default LocationMarker