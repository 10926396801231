import { useState } from "react";
import Empty_Data from "../../assets/images/empty-state/Data Kosong.svg";

const EmptyData = (props) => {
  // const [showModal, setShowModal] = useState(props);
  const showModal = props.showModal;
  const { label, subLabel } = props;

  return (
    <div className="empty-state">
      <img src={Empty_Data} className="img-empty-state" alt="account" />
      <div className="empty-state-caption">
        <h5>{label || "DATA KOSONG"}</h5>
        <p>{subLabel || "Anda tidak memiliki data saat ini"}</p>
      </div>
    </div>
  );
};

export default EmptyData;
