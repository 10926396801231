import { actionPending, actionSuccess, actionError } from "../actionTypes";

import { toastSuccess, toastError } from "../../../components/commons/toast";

import API from "../API";
import { handleResponse } from "../HandleRespone";

// ACTION TYPES TO DISPATCH: METHOD_URL_TYPES
export const GET_ALL_MANAGEMENT_NOTIFICATION_PENDING =
  "GET_ALL_MANAGEMENT_NOTIFICATION_PENDING";
export const GET_ALL_MANAGEMENT_NOTIFICATION_SUCCESS =
  "GET_ALL_MANAGEMENT_NOTIFICATION_SUCCESS";
export const GET_ALL_MANAGEMENT_NOTIFICATION_ERROR =
  "GET_ALL_MANAGEMENT_NOTIFICATION_ERROR";
export const GET_ROLE_MANAGEMENT_NOTIFICATION_PENDING =
  "GET_ROLE_MANAGEMENT_NOTIFICATION_PENDING";
export const GET_ROLE_MANAGEMENT_NOTIFICATION_SUCCESS =
  "GET_ROLE_MANAGEMENT_NOTIFICATION_SUCCESS";
export const GET_ROLE_MANAGEMENT_NOTIFICATION_ERROR =
  "GET_ROLE_MANAGEMENT_NOTIFICATION_ERROR";
export const PUT_MANAGEMENT_NOTIFICATION_PENDING =
  "PUT_MANAGEMENT_NOTIFICATION_PENDING";
export const PUT_MANAGEMENT_NOTIFICATION_SUCCESS =
  "PUT_MANAGEMENT_NOTIFICATION_SUCCESS";
export const PUT_MANAGEMENT_NOTIFICATION_ERROR =
  "PUT_MANAGEMENT_NOTIFICATION_ERROR";
export const SET_PUT_MANAGEMENT_NOTIFICATION_DATA =
  "SET_PUT_MANAGEMENT_NOTIFICATION_DATA";

// URL: URL_{URL}
const MANAGEMENT_NOTIFICATION_URL = `v1/master_data/notifications`;

const getAll = (param, resolve, callback) => (dispatch) => {
  dispatch(actionPending(GET_ALL_MANAGEMENT_NOTIFICATION_PENDING));
  API.get(MANAGEMENT_NOTIFICATION_URL, { params: param })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(GET_ALL_MANAGEMENT_NOTIFICATION_SUCCESS, res));
      if (resolve) {
        const records_total = res.data.total;
        let data = res.data.data.map((item, i) => ({
          ...item,
          no: i + 1,
        }));
        resolve({
          data: data,
          totalCount: records_total,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(GET_ALL_MANAGEMENT_NOTIFICATION_ERROR));
      let not_logout = handleResponse(err?.response);
      if (not_logout) {
        let message = err?.response?.data?.message;
        if (message?.includes("java")) {
          message = "Data tidak ditemukan";
        }
        toastError(message);
      }
    });
};

const getRole = (param, resolve, callback) => (dispatch) => {
  dispatch(actionPending(GET_ROLE_MANAGEMENT_NOTIFICATION_PENDING));
  API.get(MANAGEMENT_NOTIFICATION_URL + "/find_by_role", { params: param })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(GET_ROLE_MANAGEMENT_NOTIFICATION_SUCCESS, res));
      if (resolve) {
        const records_total = res.data.total;
        let data = res.data.data.map((item, i) => ({
          ...item,
        }));
        resolve({
          data: data,
          totalCount: records_total,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(GET_ROLE_MANAGEMENT_NOTIFICATION_ERROR));
      let not_logout = handleResponse(err?.response);
      if (not_logout) {
        let message = err?.response?.data?.message;
        if (message?.includes("java")) {
          message = "Data tidak ditemukan";
        }
        toastError(message);
      }
    });
};

const put = (param, callback, role) => (dispatch) => {
  dispatch(actionPending(PUT_MANAGEMENT_NOTIFICATION_PENDING));
  API.put(MANAGEMENT_NOTIFICATION_URL + "/update_access", param)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(PUT_MANAGEMENT_NOTIFICATION_SUCCESS, res));
      if (callback) {
        callback();
      }
      let message = `Berhasil Ubah Data Notifikasi`;
      if (role) {
        message += ` Role ${role}`;
      }
      toastSuccess(message);
    })
    .catch((err) => {
      let message = err?.response?.data?.message;
      if (message?.includes("java")) {
        message = "Format file tidak sesuai";
      }
      toastError(message);
      dispatch(actionError(PUT_MANAGEMENT_NOTIFICATION_ERROR));
    });
};

const managementNotification = {
  getAll,
  getRole,
  put,
};
export default managementNotification;

export const setPutManagementNotificationData = (data) => (dispatch) =>
  dispatch({ type: SET_PUT_MANAGEMENT_NOTIFICATION_DATA, data });
