import { actionPending, actionSuccess, actionError } from "../actionTypes";

import { toastSuccess, toastError } from "../../../components/commons/toast";

import API from "../API";
import moment from "moment";
import localization from "moment/locale/id";
import { handleResponse } from "../HandleRespone";

// ACTION TYPES TO DISPATCH: METHOD_URL_TYPES
export const GET_REPAIR_WORK_PENDING = "GET_REPAIR_WORK_PENDING";
export const GET_REPAIR_WORK_SUCCESS = "GET_REPAIR_WORK_SUCCESS";
export const GET_REPAIR_WORK_ERROR = "GET_REPAIR_WORK_ERROR";
export const POST_REPAIR_WORK_PENDING = "POST_REPAIR_WORK_PENDING";
export const POST_REPAIR_WORK_SUCCESS = "POST_REPAIR_WORK_SUCCESS";
export const POST_REPAIR_WORK_ERROR = "POST_REPAIR_WORK_ERROR";
export const PUT_REPAIR_WORK_PENDING = "PUT_REPAIR_WORK_PENDING";
export const PUT_REPAIR_WORK_SUCCESS = "PUT_REPAIR_WORK_SUCCESS";
export const PUT_REPAIR_WORK_ERROR = "PUT_REPAIR_WORK_ERROR";
export const DELETE_REPAIR_WORK_PENDING = "DELETE_REPAIR_WORK_PENDING";
export const DELETE_REPAIR_WORK_SUCCESS = "DELETE_REPAIR_WORK_SUCCESS";
export const DELETE_REPAIR_WORK_ERROR = "DELETE_REPAIR_WORK_ERROR";
export const PREPARE_FORM_REPAIR_WORK_PENDING = "PREPARE_FORM_REPAIR_WORK_PENDING";
export const PREPARE_FORM_REPAIR_WORK_SUCCESS = "PREPARE_FORM_REPAIR_WORK_SUCCESS";
export const PREPARE_FORM_REPAIR_WORK_ERROR = "PREPARE_FORM_REPAIR_WORK_ERROR";
export const DOWNLOAD_REPAIR_WORK_PENDING = "DOWNLOAD_REPAIR_WORK_PENDING";
export const DOWNLOAD_REPAIR_WORK_SUCCESS = "DOWNLOAD_REPAIR_WORK_SUCCESS";
export const DOWNLOAD_REPAIR_WORK_ERROR = "DOWNLOAD_REPAIR_WORK_ERROR";

// URL: URL_{URL}
// const REPAIR_WORK_URL = `v1/repair_work_report`;
const REPAIR_WORK_URL = `v1/repair_work_report/v2`;

const getArgo = (start_date, end_date) => {
  if (start_date && end_date) {
    let argo_duration = moment.duration(end_date?.diff(start_date));
    let days = parseInt(argo_duration.days());
    let hours = parseInt(argo_duration.hours());
    let minutes = parseInt(argo_duration.minutes());
    let duration =
      days > 0 || hours || minutes
        ? `${days > 0 ? days + " d" : ""} ${hours + " h"} ${minutes + " m"}`
        : "-";
    return duration;
  }
};

const get = (param, resolve, callback) => (dispatch) => {
  dispatch(actionPending(GET_REPAIR_WORK_PENDING));
  API.get(REPAIR_WORK_URL, { params: param })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(GET_REPAIR_WORK_SUCCESS, res));
      if (resolve) {
        const records_total = res.data.total;
        let data = res.data.data.map((item, i) => ({
          ...item,
          no: i + 1 + (param?.page - 1) * param?.length,
          pdam_name: item?.pdam?.name ?? "-",
          zone_name: item?.zone?.name ?? "-",
          dma_name: item?.dma?.name ?? "-",
          argo:
            item?.waktuLapor && item?.selesaiPerbaikan
              ? getArgo(
                moment(item?.waktuLapor),
                moment(item?.selesaiPerbaikan)
              )
              : "",
          response_time:
            item?.waktuLapor && item?.mulaiPerbaikan
              ? getArgo(
                moment(item?.waktuLapor),
                moment(item?.mulaiPerbaikan)
              )
              : "-",
          repairing_time:
            item?.selesaiPerbaikan && item?.mulaiPerbaikan
              ? getArgo(
                moment(item?.mulaiPerbaikan),
                moment(item?.selesaiPerbaikan)
              )
              : "-",
          reported_time: item?.waktuLapor
            ? moment(item?.waktuLapor)
              .locale("id", localization)
              .format("dddd, DD/MM/YYYY, HH:mm")
            : "-",
          finish_repairing_time: item?.selesaiPerbaikan
            ? moment(item?.selesaiPerbaikan)
              .locale("id", localization)
              .format("dddd, DD/MM/YYYY, HH:mm")
            : "-",
          start_repairing_time: item?.mulaiPerbaikan
            ? moment(item?.mulaiPerbaikan)
              .locale("id", localization)
              .format("dddd, DD/MM/YYYY, HH:mm")
            : "-",
          device_id: item?.device_id ?? "-",
          incident_short:
            item?.kejadian.length > 155
              ? item?.kejadian.substring(0, 155) + "..."
              : item?.kejadian,
        }));
        resolve({
          data: data,
          page: param?.page - 1,
          totalCount: records_total,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(GET_REPAIR_WORK_ERROR));
      let not_logout = handleResponse(err?.response);
      if (not_logout) {
        let message = err?.response?.data?.message;
        if (message?.includes("java")) {
          message = "Data tidak ditemukan";
        }
        toastError(message);
      }
    });
};

const post = (param, callback) => (dispatch) => {
  dispatch(actionPending(POST_REPAIR_WORK_PENDING));
  API.post(REPAIR_WORK_URL, param)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(POST_REPAIR_WORK_SUCCESS, res));
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      let message = err?.response?.data?.message;
      if (message?.includes("java")) {
        message = "Format file tidak sesuai";
      }
      toastError(message);
      dispatch(actionError(POST_REPAIR_WORK_ERROR));
    });
};

const put = (param, callback) => (dispatch) => {
  dispatch(actionPending(PUT_REPAIR_WORK_PENDING));
  API.put(REPAIR_WORK_URL, param)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(PUT_REPAIR_WORK_SUCCESS, res));
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      let message = err?.response?.data?.message;
      if (message?.includes("java")) {
        message = "Format file tidak sesuai";
      }
      toastError(message);
      dispatch(actionError(PUT_REPAIR_WORK_ERROR));
    });
};

const deleted = (param, callback) => (dispatch) => {
  dispatch(actionPending(DELETE_REPAIR_WORK_PENDING));
  API.delete(REPAIR_WORK_URL + `?id=${param}`)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(DELETE_REPAIR_WORK_SUCCESS, res));
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(DELETE_REPAIR_WORK_ERROR));
      let message = err?.response?.data?.message;
      if (message?.includes("java")) {
        message = "Gagal menghapus data";
      }
      toastError(message);
    });
};

const prepare_form = (param, resolve, callback) => (dispatch) => {
  dispatch(actionPending(PREPARE_FORM_REPAIR_WORK_PENDING));
  API.get(REPAIR_WORK_URL + "/prepare_form", { params: param })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(PREPARE_FORM_REPAIR_WORK_SUCCESS, res));
      if (resolve) {
        let data = res.data.data.map((item, i) => ({
          ...item,
        }));
        resolve({
          data: data
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(PREPARE_FORM_REPAIR_WORK_ERROR));
      let not_logout = handleResponse(err?.response);
      if (not_logout) {
        let message = err?.response?.data?.message;
        if (message?.includes("java")) {
          message = "Data tidak ditemukan";
        }
        toastError(message);
      }
    });
};

const download = (param) => (dispatch) => {
  dispatch(actionPending(DOWNLOAD_REPAIR_WORK_PENDING));
  API.get(REPAIR_WORK_URL + "/export", {
    params: param,
    responseType: 'blob',
  })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(DOWNLOAD_REPAIR_WORK_SUCCESS, res));
      console.log(res.data)
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `Repair Work Report - ${moment().format('DD MM YYYY')}.xlsx`); //or any other extension
      document.body.appendChild(link);
      link.click();
    })
    .catch((err) => {
      dispatch(actionError(DOWNLOAD_REPAIR_WORK_ERROR));
      let not_logout = handleResponse(err?.response);
      if (not_logout) {
        let message = err?.response?.data?.message;
        if (message?.includes("java")) {
          message = "Data tidak ditemukan";
        }
        toastError(message);
      }
    });
};

const repair_work_report = {
  get,
  post,
  deleted,
  put,
  prepare_form,
  download
};
export default repair_work_report;
