import { createTheme, ThemeProvider } from "@material-ui/core/styles"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3"
import { TimePicker } from "@mui/x-date-pickers/TimePicker"

const theme = createTheme({
  palette: {
    primary: {
      main: "#04AA59",
    },
  },
})

function BasicDateTimePicker({ label, ampm, autoOk, variant, inputVariant, value, onChange, disableFuture, disablePast, disabled, showToday, format, orientation, errors, touched, name, className, title, fullWidth, minutesStep }) {
  const error = errors && name ? errors[name] : ""
  const touchs = touched && name ? touched[name] : ""

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <div
          className={`form-group ${className}`}
          style={{
            fontFamily: "sans-serif",
          }}
        >
          <label className="form-label" style={{ textAlign: "left", display: "block" }}>
            {title}
          </label>
          <TimePicker
            ampm={ampm || false}
            autoOk={autoOk || true}
            // label={label}
            inputVariant={inputVariant || "filled"}
            onChange={onChange}
            disabled={disabled}
            format={format}
            orientation={orientation || "portrait"}
            fullWidth={fullWidth || true}
            error={touchs && error ? true : false}
            minutesStep={minutesStep}
            value={value}
            variant={variant || "inline"}
          />
          {touchs && error && (
            <div className="invalid-feedback" style={{ textAlign: "left", display: "block" }}>
              {error}
            </div>
          )}
        </div>
      </LocalizationProvider>
    </ThemeProvider>
  )
}

export default BasicDateTimePicker
