import { connect, useDispatch } from "react-redux";
import { useState, useRef, useEffect } from "react";
import SearchButton from "../../components/buttons/SearchButton";
import SearchButtonDefault from "../../components/buttons/SearchButtonDefault";
import SubItem from "./SubItem";
import InfiniteScroll from "react-infinite-scroll-component";
import Data from "./Data";
import Map from "./Map";
import DMA from "../../store/actions/master_data/dma";
import DataMapBaseLayout from "../../components/layout/data_map";
import StepTest from "../../store/actions/step_test_support";
import { getUser } from "../../utils/User";

const LoggerData = ({ masterDataDMA, pending }) => {
  const [activeDMA, setActiveDMA] = useState("");
  const [deviceId, setDeviceId] = useState("");
  const [searchDMA, setSearchDMA] = useState("");
  const [searchTable, setSearchTable] = useState("");
  const [loggerAreaDetail, setLoggerAreaDetail] = useState({
    data: null,
    type: null,
  });
  const currentUser = getUser().user;
  const isSwitch = !!currentUser?.pdam?.id;

  // Data Detail(Table) Vars
  // -----------------------
  const [dataDMA, setDataDMA] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [dataDetailDMA, setDataDetailDMA] = useState({});
  const [dataStepTest, setDataStepTest] = useState([]);
  const [totalData, setTotalData] = useState(null);
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  // --------------------------------------------------

  const [currentSubitemPage, setCurrentSubitemPage] = useState(1);
  const [totalSubitemData, setTotalSubitemData] = useState(1);
  const [maxSubitemPage, setMaxSubitemPage] = useState(1);

  const dispatch = useDispatch();

  const dataMapBaseLayoutRef = useRef();

  // map vars
  // --------
  const [currentCenter, setCurrentCenter] = useState(null);
  const [currentZoom, setCurrentZoom] = useState(10);
  const flyToZoom = 18;
  const [mapMarkerOptions, setMapMarkerOptions] = useState({
    color:
      loggerAreaDetail && loggerAreaDetail.is_warning ? "#D10D0D" : "#04AA59",
    fillColor:
      loggerAreaDetail && loggerAreaDetail.is_warning ? "#D10D0D" : "#04AA59",
  });

  const showContentSubItem = (data) => {
    setActiveDMA(data.id);
    setDeviceId(data.id);
    setCurrentCenter([data.latitude, data.longitude]);
    setCurrentZoom(flyToZoom);
    setDataDetailDMA(data);
  };

  const resetDetail = () => {
    setActiveDMA("");
    setDeviceId("");
    setCurrentZoom(10);
    setDataDetailDMA({});
  };

  const handleSearch = (value) => {
    setSearchTable(value);
  };

  const triggerSearch = (value) => {
    setSearchDMA(value);
  };

  useEffect(() => {
    if (isSwitch) {
      resetDetail();
      getDataDMA(true);
    }
  }, [currentUser?.pdam?.id, searchDMA]);

  useEffect(() => {
    if (isSwitch) {
      getDetailDMA();
    }
  }, [searchTable, page, deviceId]);

  useEffect(() => {
    dataMapBaseLayoutRef.current.getMap()?.flyTo(currentCenter, currentZoom);
  }, [currentCenter]);

  const getDataDMA = (refresh = false) => {
    if (refresh) setDataDMA([]);
    setIsLoading(true);
    new Promise((resolve) => {
      const param = {
        draw: 1,
        length: 10,
        page: refresh ? 1 : currentSubitemPage + 1,
        pdam_id: currentUser?.pdam?.id,
        search_text: searchDMA,
      };
      dispatch(DMA.get(param, resolve));
    }).then((res) => {
      setTotalSubitemData(res?.totalCount);
      setMaxSubitemPage(Math.ceil(res?.totalCount / 10));
      if (refresh) {
        setCurrentSubitemPage(1);
        setDataDMA(res?.data);
        return;
      }
      setCurrentSubitemPage(currentSubitemPage + 1);
      setDataDMA(dataDMA.concat(res?.data));
    });
  };

  const getDetailDMA = () => {
    if (deviceId) {
      new Promise((resolve) => {
        const param = {
          length: 10,
          page: page,
          dma_id: deviceId,
          search_text: searchTable,
        };
        dispatch(StepTest.get(param, resolve));
      }).then((res) => {
        setDataStepTest(res?.data);
        setTotalData(res?.totalCount);
        setMaxPage(Math.ceil(res?.totalCount / 10));
      });
    }
  };

  const dataHeaderContent = (
    <>
      <SearchButton
        placeholder="Masukkan Nama Sub DMA"
        toggle={handleSearch}
        optClass="mr-3"
      />
    </>
  );

  const dataContent = (
    <>
      <Data
        state={{
          dataStepTest: dataStepTest,
          totalData: totalData,
          page: page,
          maxPage: maxPage,
        }}
        setPage={setPage}
        pending={pending}
      />
    </>
  );

  const mapContent = (
    <>
      <Map
        dmaAllData={dataDMA}
        dmaDetailData={dataDetailDMA}
        stepTestData={dataStepTest}
      />
    </>
  );

  return (
    <div className="menu-container pr-0">
      <div className="grid-header">
        <div className="side-menu">
          <div className="menu-title bordered-b">
            <div className="row">
              <div className="col">
                <SearchButtonDefault
                  placeholder="Cari DMA"
                  trigger={(value) => triggerSearch(value)}
                />
              </div>
            </div>
          </div>
          <div
            id="subItemContainer-StepTest"
            style={{ overflow: "auto", height: "calc(100vh - 134px)" }}
          >
            <InfiniteScroll
              dataLength={dataDMA.length} //This is important field to render the next data
              next={getDataDMA}
              scrollableTarget="subItemContainer-StepTest"
              hasMore={currentSubitemPage - 1 < maxSubitemPage} // currentSubitemPage-1 < maxSubitemPage
              loader={
                masterDataDMA.pending ? (
                  <div
                    style={{
                      width: "100",
                      textAlign: "center",
                      padding: "15px",
                    }}
                  >
                    Memuat...
                  </div>
                ) : (
                  <div
                    style={{
                      width: "100",
                      textAlign: "center",
                      padding: "15px",
                    }}
                  >
                    Data tidak ditemukan
                  </div>
                )
              }
              endMessage={
                dataDMA.length > 0 ? (
                  <p style={{ textAlign: "center" }}>
                    <b>You have seen it all</b>
                  </p>
                ) : (
                  <div
                    style={{
                      width: "100",
                      textAlign: "center",
                      padding: "15px",
                    }}
                  >
                    Data tidak ditemukan
                  </div>
                )
              }
            >
              {dataDMA.map((item, idx) => (
                <SubItem
                  key={idx}
                  title={"DMA " + item.name}
                  toggle={() => showContentSubItem(item)}
                  active={item.id === activeDMA}
                />
              ))}
            </InfiniteScroll>
          </div>
        </div>
        <>
          <DataMapBaseLayout
            ref={dataMapBaseLayoutRef}
            title={`Step Test Support`}
            dataHeaderContent={dataHeaderContent}
            dataContent={dataContent}
            mapContent={mapContent}
            initialCenter={currentCenter}
            initialZoom={currentZoom}
          />
        </>
      </div>
    </div>
  );
};

const mapStateToProps = ({ masterDataDMA, stepTestSupport: { pending } }) => {
  return { masterDataDMA, pending };
};

const mapDispatchToProps = () => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(LoggerData);
