import React, { useState, useEffect } from "react";
import MenuItem from "@material-ui/core/MenuItem";
import { connect, useDispatch } from "react-redux";

import Table from "../../../components/table";
import DetailButton from "../../../components/table/detailButton";
import ConfirmModal from "../../../components/modal/ConfirmModal";
import { toastSuccess } from "../../../components/commons/toast";
import { getUser } from "../../../utils/User";
import { Role } from "../../../utils/Constants";

import ZoneService from "../../../store/actions/master_data/zone";

const Index = ({ filter, search, pending, add }) => {

    const dispatch = useDispatch();
    const [dataZone, setDataZone] = useState([]);
    const [showModalDelete, setShowModalDelete] = useState(false)
    const [itemData, setItemData] = useState(null)
    const [totalData, setTotalData] = useState(null)
    const [page, setPage] = useState(1)
    const [maxPage, setMaxPage] = useState(1)
    const currentUser = getUser().user;

    const columns = (currentUser.role_code === Role.Kadiv || currentUser.role_code === Role.Superadmin) ?
        [
            {
                Header: "PDAM",
                accessor: "pdam_name",
            },
            {
                Header: "INPUT",
                accessor: "input_name",
            },
            {
                Header: "ZONA",
                accessor: "name",
            },
            {
                Header: "ID SENSOR",
                accessor: "device_id",
            },
            {
                Header: "DIAMETER",
                accessor: "diameter_label"
            },
            {
                Header: "LONGITUDE,LATITUDE",
                accessor: "longlat"
            },
            {
                Header: "",
                accessor: "aksi",
                Cell: ({ row }) => {
                    const original = row ? row?.original : [];
                    return (
                        <DetailButton>
                            <MenuItem onClick={() => handleShowDelete(original)}>
                                <span style={{ fontSize: 14 }}>Hapus</span>
                            </MenuItem>
                        </DetailButton>
                    );
                },
            },
        ] : [
            {
                Header: "PDAM",
                accessor: "pdam_name",
            },
            {
                Header: "INPUT",
                accessor: "input_name",
            },
            {
                Header: "ZONA",
                accessor: "name",
            },
            {
                Header: "ID SENSOR",
                accessor: "device_id",
            },
            {
                Header: "DIAMETER",
                accessor: "diameter_label"
            },
            {
                Header: "LONGITUDE,LATITUDE",
                accessor: "longlat"
            },
        ]

    const handleRefresh = () => {
        new Promise((resolve) => {
            const param = {
                draw: 1,
                length: 10,
                page: page ?? 1,
                input_id: filter.input_id,
                pdam_id: filter.pdam_id,
                search_text: search,
                start: null,
            }
            dispatch(ZoneService.get(param, resolve));
        }).then(res => {
            setDataZone(res?.data)
            setTotalData(res?.totalCount)
            setMaxPage(Math.ceil(res?.totalCount / 10))
        })
    }

    useEffect(() => {
        handleRefresh();

    }, [page])

    useEffect(() => {
        setPage(prev => prev === 1 ? null : 1)
    }, [filter, search, add])

    const handleShowDelete = (item) => {
        setItemData(item)
        setShowModalDelete(true)
    }

    const handleDelete = () => {
        const callback = () => {
            setPage(prev => prev === 1 ? null : 1);
            setShowModalDelete(false)
            toastSuccess(`Berhasil Hapus Data Zona ${itemData?.name}`)
        }
        dispatch(ZoneService.deleted(itemData?.id, callback));
    }

    return (
        <>
            <Table
                id="tbl-master-zone"
                columns={columns}
                isLoading={pending}
                data={dataZone}
                pagination={{ page, totalData, maxPage, toggle: setPage }}
            />

            <ConfirmModal
                show={showModalDelete}
                toggle={() => setShowModalDelete(false)}
                confirm={handleDelete}
                icon='info'
                isDelete
            >
                <div>
                    Apakah Anda yakin akan menghapus
                    <strong>{" zona " + itemData?.name}</strong> ?
                </div>
            </ConfirmModal>
        </>
    );
};

const mapStateToProps = ({ masterDataZone: { pending } }) => {
    return { pending };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};


export default connect(mapStateToProps, mapDispatchToProps)(Index);
