import { useState, useRef, useEffect } from "react";

function useComponentVisible(initialIsVisible) {
  const [isComponentVisible, setIsComponentVisible] =
    useState(initialIsVisible);
  const ref = useRef(null);

  const handleHideDropdown = (event: KeyboardEvent) => {
    if (event.key === "Escape") {
      setIsComponentVisible(false);
    }
  };

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsComponentVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleHideDropdown, true);
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("keydown", handleHideDropdown, true);
      document.removeEventListener("click", handleClickOutside, true);
    };
  });

  return { ref, isComponentVisible, setIsComponentVisible };
}

const Index = ({ type, value, label, onChangeEdit }) => {
  const [valueInput, setValueInput] = useState(value !== "-" ? value : null);
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);

  const handleChangeValue = (event) => {
    setValueInput(event.target.value ?? "");
  };

  useEffect(() => {
    if (isComponentVisible === false) {
      if (onChangeEdit && valueInput && label) {
        onChangeEdit(valueInput, label);
      }
    }
  }, [isComponentVisible]);

  useEffect(() => {
    if (value !== "-") {
      setValueInput(value);
    }
  }, [value]);

  const handleShow = () => {
    setIsComponentVisible(true);
  };

  return (
    <>
      {isComponentVisible ? (
        <input
          className="input-title"
          style={{ width: "100%" }}
          ref={ref}
          value={valueInput}
          type={type ?? "text"}
          onChange={(event) => handleChangeValue(event)}
        />
      ) : (
        <span
          onClick={onChangeEdit ? () => handleShow() : ""}
          style={{ width: "100%" }}
        >
          {valueInput !== null &&
          valueInput !== undefined &&
          valueInput.length > 0
            ? valueInput
            : type === "number"
            ? 0
            : "-"}
        </span>
      )}
    </>
  );
};

export default Index;
