import React, {
  useState,
  useRef,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import { connect, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Container,
} from "reactstrap";
import Divider from "@material-ui/core/Divider";
import Button from "../../../components/buttons/Button";
import Checkbox from "../../../components/forms/Checkbox";
import DynamicButton from "../../../components/forms/DynamicButton";

import { __SECRET_INTERNALS_DO_NOT_USE_OR_YOU_WILL_BE_FIRED } from "react-dom";

import masterDataRoleService from "../../../store/actions/master_data/role";
import Select2 from "../../../components/forms/Select2";
import { Formik, Form } from "formik";

const FilterModal = forwardRef((props, ref) => {
  const dispatch = useDispatch();

  const [mstRoles, setMstRoles] = useState([]);

  const [filterUserRoles, setFilterUserRoles] = useState([]);
  const [filterUserStatus, setFilterUserStatus] = useState(null);
  const [show, setShow] = useState(false);

  const [plannedRoles, setPlannedRoles] = useState([...filterUserRoles]);
  const [plannedStatus, setPlannedStatus] = useState(filterUserStatus);
  const [showRoleOptions, setShowRoleOptions] = useState(true);
  const [showStatusOptions, setShowStatusOptions] = useState(true);
  const { filterRoleCode, filterStatus, activeTab } = props;

  useImperativeHandle(ref, () => ({
    toggle() {
      callToggle();
    },
    getSelectedRoles() {
      return filterUserRoles;
    },
    getSelectedStatus() {
      return filterUserStatus;
    },
    setShowRole(show) {
      setShowRoleOptions(show);
    },
    setShowStatus(show) {
      setShowStatusOptions(show);
    },
  }));

  const getMstRoles = () => {
    new Promise((resolve) => {
      let param = {};
      dispatch(masterDataRoleService.get(param, resolve));
    }).then((res) => {
      setMstRoles(
        res?.data.filter(function (data) {
          return data.code != "viewer";
        })
      );
    });
  };

  const callToggle = () => {
    if (!show) {
      // setPlannedRoles([...filterUserRoles])
      // setPlannedStatus(filterUserStatus)
      console.log(filterUserStatus);
    }
    setShow(!show);
  };

  // Form Events
  // -----------
  const toggleRoleCheckboxes = (name) => {
    let newPlannedRoles = [...plannedRoles];
    if (newPlannedRoles.includes(name)) {
      const i = newPlannedRoles.indexOf(name);
      if (i !== -1) {
        newPlannedRoles.splice(i, 1);
      }
    } else {
      newPlannedRoles.push(name);
    }
    setPlannedRoles(newPlannedRoles);
  };

  const onClickReset = () => {
    // setPlannedRoles([...filterUserRoles]);
    // setPlannedStatus(filterUserStatus);
    props.onApplyFilter("", "");
  };

  const onClickCancel = () => {
    // onClickReset()
    callToggle();
  };

  const onClickApply = (values) => {
    setFilterUserRoles(values.role_code);
    setFilterUserStatus(values.status);
  };
  // ----------------------------------------------

  // renders
  // -------
  function renderRoleCheckboxes() {
    var checkboxes = [];
    mstRoles.forEach((mstRole) => {
      checkboxes.push(
        <Checkbox
          name={mstRole.code}
          checked={plannedRoles}
          label={mstRole.name}
          value={mstRole.code}
          onChange={() => toggleRoleCheckboxes(mstRole.code)}
        />
      );
    });
    return checkboxes;
  }
  // -----------------------------

  // useEffect(()=>{
  //     if(props.onApplyFilter && show){
  //         console.log('applied_status before', filterUserStatus)
  //         props.onApplyFilter(filterUserRoles, filterUserStatus)
  //     }
  //     // console.log('Roles, Status', [roles, status])
  // }, [filterUserRoles, filterUserStatus])

  useEffect(() => {
    getMstRoles();
  }, []);

  return (
    <>
      <Modal
        isOpen={show}
        modalTransition={{ timeout: 200 }}
        backdropTransition={{ timeout: 1300 }}
        toggle={callToggle}
      >
        <ModalHeader className="text-extra-bold pt-4">
          Filter
          <span className="close clickable" onClick={callToggle}>
            &times;
          </span>
        </ModalHeader>
        <ModalBody>
          <DynamicButton
            titleLeft="Pilih data yang ingin difilter"
            iconLeft="info"
          />
          <Container>
            <Formik
              initialValues={{
                role_code: filterRoleCode,
                status: filterStatus,
              }}
              enableReinitialize
              onSubmit={(values, { setSubmitting }) => {
                // setTimeout(() => {
                props.onApplyFilter(values.role_code, values.status);
                setSubmitting(false);
                // }, 400);
              }}
            >
              {(props) => (
                <Form>
                  {/* {showRoleOptions &&
                        <>
                            role
                            <Divider orientation="horizontal" variant="middle" style={{ margin: '30px 0px 20px' }} />

                            {renderRoleCheckboxes()}
                        </>
                        } */}
                  {activeTab === "0" && (
                    <>
                      <div className="form-group">
                        <label className="form-label">Role</label>
                      </div>
                      <Checkbox
                        name="role_code"
                        label="Superuser"
                        checked={props?.values?.role_code}
                        value="superadmin"
                        onChange={props?.handleChange}
                      />
                      <Checkbox
                        name="role_code"
                        label="Kadiv"
                        checked={props?.values?.role_code}
                        value="kadiv"
                        onChange={props?.handleChange}
                      />
                      <Checkbox
                        name="role_code"
                        label="User Cust Rep"
                        checked={props?.values?.role_code}
                        value="user_cust_rep"
                        onChange={props?.handleChange}
                      />
                      <Checkbox
                        name="role_code"
                        label="IT Administrator"
                        checked={props?.values?.role_code}
                        value="it_administrator"
                        onChange={props?.handleChange}
                      />
                    </>
                  )}
                  <Select2
                    title="Status"
                    name="status"
                    options={[
                      { value: "", label: "Semua Status" },
                      { value: 1, label: "Aktif" },
                      { value: 0, label: "Nonaktif" },
                    ]}
                    errors={props?.errors}
                    touched={props?.touched}
                    value={props?.values.status}
                    placeholder="Pilih Status"
                    onChange={(name, value) => {
                      // setPlannedStatus(value === 'all' ? null : value)
                      props.setFieldValue("status", value);
                    }}
                    onBlur={props.setFieldTouched}
                  />
                  <Container className="d-flex align-items-center">
                    <div className="float-left-force">
                      <p
                        className="text-bold"
                        style={{ cursor: "pointer", margin: 0 }}
                        onClick={onClickReset}
                      >
                        Reset
                      </p>
                    </div>
                    <Button
                      variant="button secondary"
                      rounded
                      title="Batal"
                      onClick={onClickCancel}
                      leftIcon={null}
                      type="Button"
                    />
                    <Button
                      title="Terapkan"
                      type="Submit"
                      rounded={true}
                      style={{ marginRight: "0 !important" }}
                      variant="button-default btn-lg"
                    />
                  </Container>
                </Form>
              )}
            </Formik>
          </Container>
        </ModalBody>
        <ModalFooter></ModalFooter>
      </Modal>
    </>
  );
});

export default FilterModal;
