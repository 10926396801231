const Index = ({ title, subtitle, toggle, active }) => {
  return (
    <div className={`sub-item ${active ? "active" : ""}`} onClick={toggle}>
      <div className="side-menu-info">
        <div className="side-menu-title text-extra-bold">{title}</div>
        <div className="side-menu-subtitle">{subtitle}</div>
      </div>
    </div>
  );
};

export default Index;
