import {
    GET_ZONE_PENDING,
    GET_ZONE_SUCCESS,
    GET_ZONE_ERROR,
    POST_ZONE_PENDING,
    POST_ZONE_SUCCESS,
    POST_ZONE_ERROR,
    DELETE_ZONE_PENDING,
    DELETE_ZONE_SUCCESS,
    DELETE_ZONE_ERROR,
} from "../../../actions/master_data/zone";

const initialState = {
    pending: false,
    error: null,
    data_list: null,
    data_post: null,
    data_delete: null
};

const zone = (state = initialState, action) => {
    switch (action.type) {
        case GET_ZONE_PENDING:
            return {
                ...state,
                pending: true,
            };
        case GET_ZONE_SUCCESS:
            return {
                ...state,
                pending: false,
                data_list: action.data,
            };
        case GET_ZONE_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error,
            };
        case POST_ZONE_PENDING:
            return {
                ...state,
                pending: true,
            };
        case POST_ZONE_SUCCESS:
            return {
                ...state,
                pending: false,
                data_post: action.data,
            };
        case POST_ZONE_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error,
            };
        case DELETE_ZONE_PENDING:
            return {
                ...state,
                pending: true,
            };
        case DELETE_ZONE_SUCCESS:
            return {
                ...state,
                pending: false,
                data_delete: action.data,
            };
        case DELETE_ZONE_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export default zone;
