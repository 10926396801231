import React, { useState, useRef, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Select2 from "../../../components/forms/Select2";
import Textarea from "../../../components/forms/Textarea";
import ButtonModal from "../../../components/modal/ButtonModal";
import ConfirmationModal from "../../../components/modal/ConfirmModal";
import PDAMService from "../../../store/actions/master_data/pdam";
import Input from "../../../components/forms/Input";

const ModalAdd = ({
  show,
  toggle,
  refresh,
  type,
  data,
  masterDataRegion,
  pending,
}) => {
  const dispatch = useDispatch();
  const [showModalConfirm, setShowModalConfirm] = useState(false);
  const [provinceChoose, setProvinceChoose] = useState(data?.province ?? "");
  const [cityList, setCityList] = useState([]);

  const validation = Yup.object().shape({
    no_reg: Yup.number()
      .typeError("No. REG harus angka")
      .required("No. REG wajib diisi")
      .nullable(),
    name: Yup.string().required("Badan/Perusahaan wajib diisi").nullable(),
    province: Yup.string().required("Provinsi wajib diisi").nullable(),
    city: Yup.string().required("Kota wajib diisi").nullable(),
    address: Yup.string().required("Alamat wajib diisi").nullable(),
    status: Yup.number().required("Status wajib diisi").nullable(),
  });


  let province_list = [];
  if (masterDataRegion.data) {
    province_list = masterDataRegion.data?.data?.data?.map((prov) => {
      return {
        label: prov.province,
        value: prov.province,
      };
    });
  }

  const handleConfirm = () => {
    setShowModalConfirm(false);
    setProvinceChoose(data?.province ?? "")
    toggle(false);
  };

  useEffect(() => {
    if (data?.province) {
      setProvinceChoose(data?.province)
    }
  }, [data])

  useEffect(() => {
    if (masterDataRegion.data && (provinceChoose || data?.province)) {
      let temp = masterDataRegion.data?.data?.data?.map((prov) => {
        if (prov.province === provinceChoose || prov.provice === data?.province) {
          const city = prov?.city;
          const cityList = city?.map((item) => ({
            label: item,
            value: item,
          }));
          setCityList(cityList ?? [])
        }
      });
    }
  }, [provinceChoose]);

  return (
    <>
      <Modal isOpen={show} modalTransition={{ timeout: 200 }}>
        <ModalHeader className="text-extra-bold pt-5">
          {type === "detail" ? "Detail" : "Edit"} PDAM{" "}
          <span className="close clickable" onClick={handleConfirm}>
            &times;
          </span>
        </ModalHeader>
        <Formik
          initialValues={{
            no_reg: data?.no_reg,
            name: data?.name,
            province: data?.province,
            city: data?.city,
            address: data?.address,
            status: data?.status,
          }}
          validationSchema={validation}
          onSubmit={(values) => {
            const param = {
              ...values,
              id: data?.id,
            };

            const callback = () => {
              toggle();
              setProvinceChoose("");
              refresh(values);
            };
            dispatch(PDAMService.put(param, callback));
          }}
        >
          {(props) => (
            <Form style={{ marginTop: 30 }}>
              <ModalBody style={{ margin: "0px 20px" }}>
                <Input
                  title="No. REG"
                  label="No. REG"
                  placeholder="Masukkan No.REG"
                  type="text"
                  disabled={type === "detail"}
                  name="no_reg"
                  errors={props?.errors}
                  touched={props?.touched}
                  {...props?.getFieldProps("no_reg")}
                  value={props.values.no_reg}
                />
                <Input
                  title="Badan/Perusahaan"
                  label="Badan/Perusahaan"
                  placeholder="Masukkan Badan/Perusahaan"
                  type="text"
                  disabled={type === "detail"}
                  name="name"
                  errors={props?.errors}
                  touched={props?.touched}
                  {...props?.getFieldProps("name")}
                  value={props.values.name}
                />
                <Select2
                  title="Provinsi"
                  name="province"
                  options={province_list}
                  errors={props.errors}
                  touched={props.touched}
                  disabled={type === "detail"}
                  value={props.values?.province}
                  placeholder="Pilih data Provinsi"
                  onChange={(name, value) => {
                    props.setFieldValue("province", value);
                    props.setFieldValue("city", "");
                    setProvinceChoose(value)
                  }}
                  onBlur={props.setFieldTouched}
                />
                <Select2
                  title="Kab/Kota"
                  name="city"
                  options={cityList}
                  errors={props.errors}
                  touched={props.touched}
                  disabled={type === "detail"}
                  value={props.values?.city}
                  placeholder="Pilih data Kab/Kota"
                  onChange={(name, value) => {
                    props.setFieldValue("city", value);
                  }}
                  onBlur={props.setFieldTouched}
                />
                <Textarea
                  title="Alamat"
                  label="Alamat"
                  placeholder="Masukkan Alamat"
                  type="text"
                  disabled={type === "detail"}
                  name="address"
                  errors={props?.errors}
                  touched={props?.touched}
                  {...props?.getFieldProps("address")}
                  value={props.values.address}
                />
                <Select2
                  title="Status"
                  name="status"
                  options={[
                    {
                      value: 1,
                      label: "Subscribe",
                    },
                    {
                      value: 2,
                      label: "Unsubscribe",
                    },
                  ]}
                  errors={props.errors}
                  touched={props.touched}
                  disabled={type === "detail"}
                  value={props.values?.status}
                  placeholder="Pilih status"
                  onChange={(name, value) => {
                    props.setFieldValue("status", value);
                  }}
                  onBlur={props.setFieldTouched}
                />
              </ModalBody>
              <ModalFooter>
                {type !== "detail" && (
                  <ButtonModal
                    toggle={() => setShowModalConfirm(true)}
                    confirmTitle="Simpan"
                    disabledConfrim={pending}
                  />
                )}
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </Modal>

      <ConfirmationModal
        isDelete
        show={showModalConfirm}
        toggle={() => setShowModalConfirm(false)}
        confirm={handleConfirm}
        cancelTitle="Tidak"
        confirmTitle="Ya"
        message="Apakah Anda yakin ingin membatalkan perubahan PDAM?"
        icon="info"
      />
    </>
  );
};

const mapStateToProps = ({ masterDataRegion, masterDataPDAM: { pending } }) => {
  return { masterDataRegion, pending };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalAdd);
