import { useState, useRef, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import HeaderMenu from "../../components/commons/menu/HeaderMenu";
import Button from "../../components/buttons/Button";
import SearchButton from "../../components/buttons/SearchButton";
import ConfirmationModal from "../../components/modal/ConfirmModal";
import CustomerService from "../../store/actions/master_data/customer";

import { getUser } from "../../utils/User";
import { Role } from "../../utils/Constants";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import PDAM from "./PDAM/PDAM";
import Input from "./Input/Input";
import DMA from "./DMA";
import Zone from "./Zone";
import Customer from "./Customer";

import Ekspor from "./Ekspor/Ekspor";


// Modal
import ModalFilterPDAM from "./PDAM/ModalFilterPDAM";
import ModalFilterInput from "./Input/ModalFilterInput";
import ModalFilterZone from "./Zone/ModalFilterZone";
import ModalFilterDMA from "./DMA/ModalFilterDMA";
import ModalFilterCustomer from "./Customer/ModalFilterCustomer";
import ModalImportInput from "./Input/ModalImportInput";
import ModalUserPDAM from "./PDAM/ModalAddPDAM";
import ModalAddZone from "./Zone/ModalAddZone";
import ModalAddDMA from "./DMA/ModalAddDMA";
import ModalCustomer from "./Customer/ModalCustomer";
import ModalImportCustomer from "./Customer/ModalImportCustomer";
import ModalDetailCustomer from "./Customer/ModalDetailCustomer";

import ModalFilterEkspor from "./Ekspor/ModalFilterEkspor";
import ModalImportEkspor from "./Ekspor/ModalImportEkspor";

const Index = ({ location, switchPDAM }) => {
  const dispatch = useDispatch();
  const currentUser = getUser().user;
  const [searchProperties, setSearchProperties] =
    useState("Masukkan nama PDAM");
  const [buttonProperties, setButtonProperties] = useState({
    title: "Tambah PDAM",
    icon: "add",
  });

  // State filter modal
  const [showModalFilterPDAM, setShowModalFilterPDAM] = useState(false);
  const [showModalFilterInput, setShowModalFilterInput] = useState(false);
  const [showModalFilterZone, setShowModalFilterZone] = useState(false);
  const [showModalFilterDMA, setShowModalFilterDMA] = useState(false);
  const [showModalFilterCust, setShowModalFilterCust] = useState(false);

  const [showModalFilterEkspor, setShowModalEkspor] = useState (false);

  // State add modal
  const [showModalAddPDAM, setShowModalAddPDAM] = useState(false);
  const [showModalImportInput, setShowModalImportInput] = useState(false);
  const [showModalAddZone, setShowModalAddZone] = useState(false);
  const [showModalAddDMA, setShowModalAddDMA] = useState(false);
  const [showModalAddCust, setShowModalAddCust] = useState(false);
  const [showModalImportCust, setShowModalImportCust] = useState(false);
  const [showModalDeleteAllCust, setShowModalDeleteAllCust] = useState(false);

  const [showModalImportEkspor, setShowModalImportEkspor] = useState(false);

  const [deleteAllCustLoading, setDeleteAllCustLoading] = useState(false);

  // State filtering
  const [searchEkspor, setSearchEkspor] = useState("");

  const [searchPDAM, setSearchPDAM] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [searchZone, setSearchZone] = useState("");
  const [searchDMA, setSearchDMA] = useState("");
  const [searchCust, setSearchCust] = useState("");
  const [filterPDAM, setFilterPDAM] = useState({
    province: "",
    city: "",
    status: [],
    pdam_id: currentUser?.pdam?.id ?? "",
  });
  const [filterInput, setFilterInput] = useState({
    pdam_id: currentUser?.pdam?.id ?? "",
  });
  const [filterZone, setFilterZone] = useState({
    pdam_id: currentUser?.pdam?.id ?? "",
    input_id: "",
  });
  const [filterDMA, setFilterDMA] = useState({
    pdam_id: currentUser?.pdam?.id ?? "",
    zone_id: "",
  });
  const [filterCust, setFilterCust] = useState({
    pdam_id: currentUser?.pdam?.id ?? "",
    input_id: "",
    zone_id: "",
    dma_id: "",
    status: "",
  });
  const [filterEkspor, setFilterEkspor] = useState({
    pdam_id: currentUser?.pdam?.id ?? "",
  });

  // State detail
  const [showDetailCust, setShowDetailCust] = useState("");

  // State add or import trigger
  const [addPDAM, setAddPDAM] = useState(false);
  const [addInput, setAddInput] = useState(false);
  const [addDMA, setAddDMA] = useState(false);
  const [addZone, setAddZone] = useState(false);
  const [addCust, setAddCust] = useState(false);

  const [addEkspor, setAddEkspor] = useState(false);

  const btnSearchRef = useRef();

  const triggerSearch = (value) => {
    btnSearchRef.current.handleSearch(value);
  };

  const triggerRefresh = () => {
    btnSearchRef.current.refresh();
  };

  const [activeTab, setActiveTab] = useState(
    currentUser.role_code === Role.User_cust_rep
      ? location?.state?.tab ?? "1"
      : location?.state?.tab ?? "0"
  );

  const showTable = (active) => {
    switch (active) {
      case "0":
        return (
          <PDAM
            filter={filterPDAM}
            search={searchPDAM}
            ref={btnSearchRef}
            add={addPDAM}
          />
        );
      case "1":
        return (
          <Input
            filter={filterInput}
            search={searchInput}
            ref={btnSearchRef}
            add={addInput}
          />
        );
      case "2":
        return <Zone filter={filterZone} search={searchZone} add={addZone} />;
      case "3":
        return <DMA filter={filterDMA} search={searchDMA} add={addDMA} />;
      case "4":
        return (
          <Ekspor
            filter={filterEkspor}
            search={searchEkspor}
            ref={btnSearchRef}
            add={addEkspor}
          />
        );
      case "5":
        return (
          <Customer
            filter={filterCust}
            search={searchCust}
            add={addCust}
            switchPDAM={switchPDAM}
          />
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    handleResetAll();
    switch (activeTab) {
      case "0":
        setButtonProperties({ title: "Tambah PDAM", icon: "add" });
        setSearchProperties("Masukkan Nama PDAM");
        break;
      case "1":
        setButtonProperties({ title: "Import Input", icon: "import_export" });
        setSearchProperties("Masukkan Nama Input");
        break;
      case "2":
        setButtonProperties({ title: "Tambah Zona", icon: "add" });
        setSearchProperties("Masukkan Nama Zona");
        break;
      case "3":
        setButtonProperties({ title: "Import DMA", icon: "import_export" });
        setSearchProperties("Masukkan Nama DMA");
        break;
      case "4":
        setButtonProperties({ title: "Import Ekspor", icon: "import_export" });
        setSearchProperties("Masukkan Nama Ekspor");
        break;
      case "5":
        setButtonProperties({ title: "Tambah Pelanggan", icon: "add" });
        setSearchProperties("Masukkan Nama Pelanggan");
        break;
      default:
        return null;
    }
  }, [activeTab]);

  useEffect(() => {
    if (switchPDAM !== null) {
      setFilterPDAM({
        pdam_id: currentUser?.pdam?.id ?? switchPDAM?.id ?? "",
        province: "",
        city: "",
        status: [],
      });
      setFilterZone({
        pdam_id: currentUser?.pdam?.id ?? switchPDAM?.id ?? "",
        input_id: "",
      });
      setFilterDMA({
        pdam_id: currentUser?.pdam?.id ?? switchPDAM?.id ?? "",
        zone_id: "",
      });
      setFilterCust({
        pdam_id: currentUser?.pdam?.id ?? switchPDAM?.id ?? "",
        input_id: "",
      });
      setFilterInput({
        pdam_id: currentUser?.pdam?.id ?? switchPDAM?.id ?? "",
      });
      setFilterEkspor({
        pdam_id: currentUser?.pdam?.id ?? switchPDAM?.id ?? "",
      });
    }
  }, [switchPDAM]);

  const handleFilterModal = () => {
    switch (activeTab) {
      case "0":
        setShowModalFilterPDAM(true);
        break;
      case "1":
        setShowModalFilterInput(true);
        break;
      case "2":
        setShowModalFilterZone(true);
        break;
      case "3":
        setShowModalFilterDMA(true);
        break;
      case "4":
        setShowModalEkspor(true);
        break;
      case "5":
        setShowModalFilterCust(true);
        break;
      default:
        return null;
    }
  };

  const handleAddModal = () => {
    switch (activeTab) {
      case "0":
        // setShowModalAddPDAM(!showModalAddPDAM);
        setShowModalAddPDAM(true);
        break;
      case "1":
        setShowModalImportInput(true);
        break;
      case "2":
        setShowModalAddZone(true);
        break;
      case "3":
        setShowModalAddDMA(true);
        break;
      case "4":
        setShowModalImportEkspor(true);
      case "5":
        setShowModalAddCust(true);
        break;
      default:
        return null;
    }
  };

  const handleSearch = (value) => {
    switch (activeTab) {
      case "0":
        setSearchPDAM(value);
        break;
      case "1":
        setSearchInput(value);
        break;
      case "2":
        setSearchZone(value);
        break;
      case "3":
        setSearchDMA(value);
        break;
      case "4":
        setSearchEkspor(value);
        break;
      case "5":
        setSearchCust(value);
        break;
      default:
        return null;
    }
  };

  const handleResetAll = () => {
    setFilterPDAM({
      pdam_id: currentUser?.pdam?.id ?? "",
      province: "",
      city: "",
      status: [],
    });
    setFilterInput({ pdam_id: currentUser?.pdam?.id ?? "" });
    setFilterZone({ pdam_id: currentUser?.pdam?.id ?? "", input_id: "" });
    setFilterDMA({ pdam_id: currentUser?.pdam?.id ?? "", zone_id: "" });
    setFilterCust({
      pdam_id: currentUser?.pdam?.id ?? "",
      input_id: "",
      zone_id: "",
      dma_id: "",
      status: "",
    });

    setFilterEkspor({ pdam_id: currentUser?.pdam?.id ?? "" });
  };

  return (
    <div className="menu-container">
      <div>
        <HeaderMenu title="Data Master">
          <SearchButton
            placeholder={searchProperties}
            toggle={handleSearch}
            refresh={activeTab}
            optClass={"mr-3"}
          // disabled={currentUser?.pdam?.id != null}
          />

          <Button
            title="Filter"
            size="sm"
            rounded={true}
            variant="button-light btn-lg"
            onClick={handleFilterModal}
          // disabled={currentUser?.pdam?.id != null}
          />

          <div className="nav-separator mr-3"></div>


          {activeTab === "5" && currentUser?.pdam !== null && (
              <Button
                title="Hapus Semua Pelanggan"
                size="sm"
                rounded={true}
                variant="button-light"
                onClick={() => setShowModalDeleteAllCust(true)}
                disabled={ (currentUser.role_code === Role.Kadiv) }
              />
          )}
          
          
          {activeTab === "5" && (
            <Button
              title="Import Pelanggan"
              size="sm"
              rounded={true}
              variant="button-light"
              onClick={() => setShowModalImportCust(true)}
              disabled={currentUser.role_code === Role.Kadiv}
            />
          )}

          <Button
            title={buttonProperties.title}
            leftIcon={buttonProperties.icon}
            size="md"
            variant="btn-lg button-default"
            rounded={true}
            onClick={handleAddModal}
            disabled={
              // (activeTab === 2 || activeTab === 3 || activeTab === 4) &&
              currentUser.role_code === Role.Kadiv
            }
          />
        </HeaderMenu>
        <div className="content-container-side">
          <div className="tab-menu overflow">
            <div style={{ width: "fit-content" }}>
              <Tabs
                value={activeTab}
                textColor="primary"
                indicatorColor="primary"
                onChange={(event, newValue) => {
                  setActiveTab(newValue);
                }}
              >
                {currentUser.role_code !== Role.User_cust_rep && (
                  <Tab label="PDAM" value="0" />
                )}
                <Tab label="Input" value="1" />
                <Tab label="Zona" value="2" />
                <Tab label="DMA" value="3" />
                <Tab label="Ekspor" value="4" />
                <Tab label="Pelanggan" value="5" />
              </Tabs>
            </div>
          </div>
          <div className="overflow">{showTable(activeTab)}</div>
        </div>
      </div>

      {/* Modal */}
      {activeTab === "0" && (
        <>
          <ModalFilterPDAM
            show={showModalFilterPDAM}
            toggle={() => setShowModalFilterPDAM(false)}
            filter={filterPDAM}
            toggleFilter={setFilterPDAM}
          />

          <ModalUserPDAM
            show={showModalAddPDAM}
            toggle={() => setShowModalAddPDAM(false)}
            refresh={setAddPDAM}
          />
        </>
      )}

      {activeTab === "1" && (
        <>
          <ModalFilterInput
            show={showModalFilterInput}
            toggle={() => setShowModalFilterInput(false)}
            filter={filterInput}
            toggleFilter={setFilterInput}
          />

          <ModalImportInput
            show={showModalImportInput}
            toggle={() => setShowModalImportInput(false)}
            refresh={setAddInput}
            afterSubmit={() => triggerRefresh()}
            switchPDAM={switchPDAM}
          />
        </>
      )}
      {activeTab === "2" && (
        <>
          <ModalFilterZone
            show={showModalFilterZone}
            toggle={() => setShowModalFilterZone(false)}
            filter={filterZone}
            toggleFilter={setFilterZone}
            switchPDAM={switchPDAM}
          />

          <ModalAddZone
            show={showModalAddZone}
            toggle={() => setShowModalAddZone(false)}
            refresh={setAddZone}
            switchPDAM={switchPDAM}
          />
        </>
      )}
      {activeTab === "3" && (
        <>
          <ModalFilterDMA
            show={showModalFilterDMA}
            filter={filterDMA}
            toggle={() => setShowModalFilterDMA(false)}
            toggleFilter={setFilterDMA}
            switchPDAM={switchPDAM}
          />

          <ModalAddDMA
            show={showModalAddDMA}
            toggle={() => setShowModalAddDMA(false)}
            refresh={setAddDMA}
            switchPDAM={switchPDAM}
          />
        </>
      )}
      {activeTab === "4" && (
        <>
          <ModalFilterEkspor
            show={showModalFilterEkspor}
            toggle={() => setShowModalEkspor(false)}
            filter={filterEkspor}
            toggleFilter={setFilterEkspor}
          />

          <ModalImportEkspor
            show={showModalImportEkspor}
            toggle={() => setShowModalImportEkspor(false)}
            refresh={setAddEkspor}
            afterSubmit={() => triggerRefresh()}
            switchPDAM={switchPDAM}
          />
        </>
      )}
      {activeTab === "5" && (
        <>
          <ModalFilterCustomer
            show={showModalFilterCust}
            filter={filterCust}
            toggle={() => setShowModalFilterCust(false)}
            toggleFilter={setFilterCust}
            switchPDAM={switchPDAM}
          />

          <ModalCustomer
            show={showModalAddCust}
            toggle={() => setShowModalAddCust(false)}
            refresh={setAddCust}
            switchPDAM={switchPDAM}
          />

          <ModalDetailCustomer
            show={showDetailCust}
            toggle={() => setShowDetailCust(false)}
            refresh={setShowDetailCust}
            switchPDAM={switchPDAM}
          />

          <ModalImportCustomer
            show={showModalImportCust}
            toggle={() => setShowModalImportCust(false)}
            refresh={() => setAddCust((prev) => (prev === 1 ? 2 : 1))}
            switchPDAM={switchPDAM}
          />

          <ConfirmationModal
            isDelete
            show={showModalDeleteAllCust}
            toggle={() => setShowModalDeleteAllCust(false)}
            confirm={() => {
              var pdamId = currentUser?.pdam?.id ?? "";
              const callback = () => {
                setShowModalDeleteAllCust(false)
                setAddCust(addCust == true || addCust == false ? !addCust : true);
              };
              const callbackFinish = () => {
                setDeleteAllCustLoading(false)
              }

              setDeleteAllCustLoading(true)
              dispatch(CustomerService.deletedAllByPdam(pdamId, callback, callbackFinish));
            }}
            isLoading={deleteAllCustLoading}
            disabledConfrim={deleteAllCustLoading}
            cancelTitle="Tidak"
            confirmTitle="Ya"
            message="Yakin ingin menghapus semua pelanggan?"
            icon="info"
          />
          
        </>
      )}
    </div>   
  );
};

export default Index;
