import { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import DropdownMenu from "../../dropdowns/DropdownMenu";
import DropdownItem from "../../dropdowns/DropdownItem";
import account from "../../../assets/images/account/account.png";
import { getUser } from "../../../utils/User";
import { RoleName, Role } from "../../../utils/Constants";
import { useClickaway } from "../../hooks/ClickawayHooks";

// MODAL
import ChangeProfile from "./profile_modal/ChangeProfile";
import ChangePassword from "./profile_modal/ChangePassword";
import ChangePDAM from "./profile_modal/ChangePDAM";

// SERVICE
import AuthService from "../../../store/actions/auth";

import "./sidebar.scss";

const Index = ({ t, togglePDAM }) => {
  const currentUser = getUser();

  const name = `${currentUser?.user?.first_name} ${currentUser?.user?.mid_name} ${currentUser?.user?.last_name} `;
  const position = RoleName[currentUser?.user?.role_code];
  const namePDAM = currentUser?.user?.pdam?.name;

  const [show, setShow] = useState(false);
  const [changeProfileModal, setChangeProfileModal] = useState(false);
  const [changePasswordModal, setChangePasswordModal] = useState(false);
  const [changePDAMModal, setChangePDAMModal] = useState(false);

  const dispatch = useDispatch();

  const logout = () => {
    dispatch(AuthService.logout());
  };

  const dropdownRef = useRef();

  useClickaway(dropdownRef, () => {
    setShow(false);
  });

  const showChangeProfileModal = () => {
    setChangeProfileModal(!changeProfileModal);
  };

  const showChangePasswordModal = () => {
    setChangePasswordModal(!changePasswordModal);
  };

  const showChangePDAMModal = () => {
    setChangePDAMModal(!changePDAMModal);
  };

  return (
    <div
      className="profile-menu"
      style={{ cursor: "pointer" }}
      onClick={() => setShow(!show)}
    >
      <img src={account} className="profile-image" alt="account" />
      <div className="profile-info hover-show">
        <p className="profile-name text-bold">{name}</p>
        <p className="profile-position">{position} </p>
        {namePDAM && <p className="profile-position">{namePDAM}</p>}
      </div>
      {show && (
        <div ref={dropdownRef}>
          <DropdownMenu title={name} subtitle={position}>
            {currentUser?.user?.role_code !== Role.Viewer &&
              currentUser?.user?.role_code !== Role.It_admin && (
                <DropdownItem title="Ganti PDAM" toggle={showChangePDAMModal} />
              )}
            <DropdownItem title="Edit Profil" toggle={showChangeProfileModal} />
            <DropdownItem
              title="Ubah Kata Sandi"
              toggle={showChangePasswordModal}
            />
            <DropdownItem title="Logout" toggle={logout} last />
          </DropdownMenu>
        </div>
      )}

      {/* MODAL */}

      <ChangeProfile
        show={changeProfileModal}
        toggle={showChangeProfileModal}
      />
      <ChangePassword
        show={changePasswordModal}
        toggle={showChangePasswordModal}
      />
      <ChangePDAM
        show={changePDAMModal}
        toggle={showChangePDAMModal}
        togglePDAM={togglePDAM}
      />
    </div>
  );
};

export default Index;
