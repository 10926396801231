import React, { useEffect, useState } from "react"
import { Formik, Form } from "formik"
import { Modal, ModalHeader, ModalBody } from "reactstrap"
import { connect, useDispatch } from "react-redux"
import { getUser } from "../../utils/User"
import Select2 from "../../components/forms/Select2"
import ButtonModal from "../../components/modal/ButtonModal"
import DynamicButton from "../../components/forms/DynamicButton"
import DMAService from "../../store/actions/master_data/dma"
import DatePicker from "../../components/forms/MonthPicker"
import moment from "moment"

function ModalFilter({ show, toggle, filter, toggleFilter, isDelete, masterDataDMA }) {
  const dispatch = useDispatch()
  const currentUser = getUser().user

  const handleReset = (formik) => {
    formik.setFieldValue("dma", "")
    toggleFilter({
      pdam_id: currentUser?.pdam?.id || "",
      dma_id: "",
      date: new Date(),
    })
    toggle()
  }

  let dma_list
  if (masterDataDMA.data_list) {
    dma_list = masterDataDMA.data_list.data.data.map((item) => ({
      value: item.id,
      label: "DMA " + item.name,
    }))
  }

  useEffect(() => {
    const param = {
      pdam_id: currentUser?.pdam?.id,
      length: 999,
    }
    dispatch(DMAService.get(param))
  }, [currentUser?.pdam?.id])

  const handleClose = () => {
    toggle()
  }

  return (
    <>
      <Modal isOpen={show} modalTransition={{ timeout: 200 }}>
        <ModalHeader className="text-extra-bold pt-5">
          {" "}
          Filter{" "}
          <span className="close clickable" onClick={handleClose}>
            &times;
          </span>
        </ModalHeader>
        <ModalBody style={{ margin: "0px 20px" }}>
          <DynamicButton titleLeft="Pilih data yang ingin difilter" iconLeft="info" />
          <Formik
            initialValues={{
              dma: filter?.dma_id ?? "",
              date: filter?.date ?? null,
            }}
            enableReinitialize
            onSubmit={(values, { setStatus, setSubmitting }) => {
              setStatus()
              toggleFilter({
                pdam_id: currentUser?.pdam?.id || "",
                dma_id: values?.dma || "",
                date: values?.date || null,
              })
              toggle()
            }}
          >
            {(props) => (
              <Form style={{ marginTop: 30 }}>
                {/* <Select2
                  title="DMA"
                  name="dma"
                  options={dma_list}
                  errors={props.errors}
                  touched={props.touched}
                  value={props.values?.dma}
                  placeholder="Pilih data dma"
                  onChange={(name, value) => {
                    props.setFieldValue("dma", value)
                  }}
                  onBlur={props.setFieldTouched}
                /> */}
                <DatePicker
                  clearable
                  title="Bulan"
                  name="date"
                  disableFuture
                  value={props.values?.date}
                  onChange={(value) => {
                    props.setFieldValue("date", value)
                  }}
                  errors={props.errors}
                  touched={props.touched}
                />
                <div style={{ marginTop: 50, marginBottom: 30 }}>
                  <ButtonModal toggle={handleClose} reset={() => handleReset(props)} deleted={isDelete} confirmTitle="Terapkan" />
                </div>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </Modal>
    </>
  )
}

const mapStateToProps = ({ masterDataDMA }) => {
  return { masterDataDMA }
}

const mapDispatchToProps = (dispatch) => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalFilter)
