import { history } from "../../../utils/History";
import { actionPending, actionSuccess, actionError } from "../actionTypes";

import { toastSuccess, toastError } from "../../../components/commons/toast";

import API from "../API";
import { handleResponse } from "../HandleRespone";
import { isTomorrow } from "date-fns";

// ACTION TYPES TO DISPATCH: METHOD_URL_TYPES
export const GET_DASHBOARD_RESUME_AVERAGE_LOGGER_PENDING =
  "GET_DASHBOARD_RESUME_AVERAGE_LOGGER_PENDING";
export const GET_DASHBOARD_RESUME_AVERAGE_LOGGER_SUCCESS =
  "GET_DASHBOARD_RESUME_AVERAGE_LOGGER_SUCCESS";
export const GET_DASHBOARD_RESUME_AVERAGE_LOGGER_ERROR =
  "GET_DASHBOARD_RESUME_AVERAGE_LOGGER_ERROR";
export const GET_DASHBOARD_RESUME_TOTAL_PDAM_PENDING =
  "GET_DASHBOARD_RESUME_TOTAL_PDAM_PENDING";
export const GET_DASHBOARD_RESUME_TOTAL_PDAM_SUCCESS =
  "GET_DASHBOARD_RESUME_TOTAL_PDAM_SUCCESS";
export const GET_DASHBOARD_RESUME_TOTAL_PDAM_ERROR =
  "GET_DASHBOARD_RESUME_TOTAL_PDAM_ERROR";
export const GET_DASHBOARD_RESUME_AVERAGE_TOTAL_PENDING =
  "GET_DASHBOARD_RESUME_AVERAGE_TOTAL_PENDING";
export const GET_DASHBOARD_RESUME_AVERAGE_TOTAL_SUCCESS =
  "GET_DASHBOARD_RESUME_AVERAGE_TOTAL_SUCCESS";
export const GET_DASHBOARD_RESUME_AVERAGE_TOTAL_ERROR =
  "GET_DASHBOARD_RESUME_AVERAGE_TOTAL_ERROR";
export const GET_DASHBOARD_RESUME_REPAIR_WORK_PENDING =
  "GET_DASHBOARD_RESUME_REPAIR_WORK_PENDING";
export const GET_DASHBOARD_RESUME_REPAIR_WORK_SUCCESS =
  "GET_DASHBOARD_RESUME_REPAIR_WORK_SUCCESS";
export const GET_DASHBOARD_RESUME_REPAIR_WORK_ERROR =
  "GET_DASHBOARD_RESUME_REPAIR_WORK_ERROR";
export const GET_DASHBOARD_RESUME_PDAM_PENDING =
  "GET_DASHBOARD_RESUME_PDAM_PENDING";
export const GET_DASHBOARD_RESUME_PDAM_SUCCESS =
  "GET_DASHBOARD_RESUME_PDAM_SUCCESS";
export const GET_DASHBOARD_RESUME_PDAM_ERROR =
  "GET_DASHBOARD_RESUME_PDAM_ERROR";
export const GET_DASHBOARD_NRW_MAX_PENDING = "GET_DASHBOARD_NRW_MAX_PENDING";
export const GET_DASHBOARD_NRW_MAX_SUCCESS = "GET_DASHBOARD_NRW_MAX_SUCCESS";
export const GET_DASHBOARD_NRW_MAX_ERROR = "GET_DASHBOARD_NRW_MAX_ERROR";
export const GET_DASHBOARD_NRW_MIN_PENDING = "GET_DASHBOARD_NRW_MIN_PENDING";
export const GET_DASHBOARD_NRW_MIN_SUCCESS = "GET_DASHBOARD_NRW_MIN_SUCCESS";
export const GET_DASHBOARD_NRW_MIN_ERROR = "GET_DASHBOARD_NRW_MIN_ERROR";
export const GET_LOCATION_SENSOR_PENDING = "GET_LOCATION_SENSOR_PENDING";
export const GET_LOCATION_SENSOR_SUCCESS = "GET_LOCATION_SENSOR_SUCCESS";
export const GET_LOCATION_SENSOR_ERROR = "GET_LOCATION_SENSOR_ERROR";

// URL: URL_{URL}
const DASHBOARD_URL = `v1/dashboard`;

const toFixed = (data) => {
  let dataFixed;

  if (Number.isInteger(data)) {
    dataFixed = data;
  } else {
    dataFixed = parseFloat(data).toFixed(2);
  }
  return dataFixed;
};

const getAverageLogger = (param, resolve, reject, callback) => (dispatch) => {
  dispatch(actionPending(GET_DASHBOARD_RESUME_AVERAGE_LOGGER_PENDING));
  API.get(DASHBOARD_URL + "/resume_average_logger", { params: param })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(GET_DASHBOARD_RESUME_AVERAGE_LOGGER_SUCCESS, res));
      if (resolve) {
        const records_total = res.data.total;
        let data = res.data.data.map((item, i) => ({
          ...item,
          no: i + 1 + (param?.page - 1) * param?.length,
        }));
        resolve({
          data: data,
          page: param?.page - 1,
          totalCount: records_total,
        });
      }
      if (callback) {
        callback(res.data);
      }
    })
    .catch((err) => {
      dispatch(actionError(GET_DASHBOARD_RESUME_AVERAGE_LOGGER_ERROR));
      let not_logout = handleResponse(err?.response);
      if (not_logout) {
        let message = err?.response?.data?.message;
        if (message?.includes("java")) {
          message = "Data tidak ditemukan";
        }
        toastError(message);
      }
      // toastError(err?.response?.data?.message);
    });
};

const getTotalPDAM = (param, resolve, reject, callback) => (dispatch) => {
  dispatch(actionPending(GET_DASHBOARD_RESUME_TOTAL_PDAM_PENDING));
  API.get(DASHBOARD_URL + "/resume_total_pdam", { params: param })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(GET_DASHBOARD_RESUME_TOTAL_PDAM_SUCCESS, res));
      if (resolve) {
        const records_total = res.data.total;
        let data = res.data.data.map((item, i) => ({
          ...item,
          no: i + 1 + (param?.page - 1) * param?.length,
        }));
        resolve({
          data: data,
          page: param?.page - 1,
          totalCount: records_total,
        });
      }
      if (callback) {
        callback(res.data);
      }
    })
    .catch((err) => {
      dispatch(actionError(GET_DASHBOARD_RESUME_TOTAL_PDAM_ERROR));
      let not_logout = handleResponse(err?.response);
      if (not_logout) {
        let message = err?.response?.data?.message;
        if (message?.includes("java")) {
          message = "Data tidak ditemukan";
        }
        toastError(message);
      }
      // toastError(err?.response?.data?.message);
    });
};

const getAverageTotal = (param, resolve, reject, callback) => (dispatch) => {
  dispatch(actionPending(GET_DASHBOARD_RESUME_AVERAGE_TOTAL_PENDING));
  API.get(DASHBOARD_URL + "/resume_average_total", { params: param })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(GET_DASHBOARD_RESUME_AVERAGE_TOTAL_SUCCESS, res));
      if (resolve) {
        const records_total = res.data.total;
        let data = res.data.data.map((item, i) => ({
          ...item,
          no: i + 1 + (param?.page - 1) * param?.length,
        }));
        resolve({
          data: data,
          page: param?.page - 1,
          totalCount: records_total,
        });
      }
      if (callback) {
        callback(res.data);
      }
    })
    .catch((err) => {
      dispatch(actionError(GET_DASHBOARD_RESUME_AVERAGE_TOTAL_ERROR));
      let not_logout = handleResponse(err?.response);
      if (not_logout) {
        let message = err?.response?.data?.message;
        if (message?.includes("java")) {
          message = "Data tidak ditemukan";
        }
        toastError(message);
      }
      // toastError(err?.response?.data?.message);
    });
};

const getLocationSensor = (param, resolve, reject, callback) => (dispatch) => {
  dispatch(actionPending(GET_LOCATION_SENSOR_PENDING));
  API.get(DASHBOARD_URL + "/location_sensor", { params: param })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(GET_LOCATION_SENSOR_SUCCESS, res));
      if (resolve) {
        const records_total = res.data.total;
        let data = res.data.data.map((item, i) => ({
          ...item,
          no: i + 1 + (param?.page - 1) * param?.length,
        }));
        resolve({
          data: data,
          page: param?.page - 1,
          totalCount: records_total,
        });
      }
      if (callback) {
        callback(res.data);
      }
    })
    .catch((err) => {
      dispatch(actionError(GET_LOCATION_SENSOR_ERROR));
      let not_logout = handleResponse(err?.response);
      if (not_logout) {
        let message = err?.response?.data?.message;
        if (message?.includes("java")) {
          message = "Data tidak ditemukan";
        }
        toastError(message);
      }
      // toastError(err?.response?.data?.message);
    });
};

const getRepairWork = (param, resolve, reject, callback) => (dispatch) => {
  dispatch(actionPending(GET_DASHBOARD_RESUME_REPAIR_WORK_PENDING));
  API.get(DASHBOARD_URL + `/resume_repair_work/v2`, { params: param })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(GET_DASHBOARD_RESUME_REPAIR_WORK_SUCCESS, res));
      if (resolve) {
        const records_total = res.data.total;
        let data = res.data.data.map((item, i) => ({
          ...item,
          no: i + 1 + (param?.page - 1) * param?.length,
        }));
        resolve({
          data: data,
          page: param?.page - 1,
          totalCount: records_total,
        });
      }
      if (callback) {
        callback(res.data);
      }
    })
    .catch((err) => {
      dispatch(actionError(GET_DASHBOARD_RESUME_REPAIR_WORK_ERROR));
      let not_logout = handleResponse(err?.response);
      if (not_logout) {
        let message = err?.response?.data?.message;
        if (message?.includes("java")) {
          message = "Data tidak ditemukan";
        }
        toastError(message);
      }
      // toastError(err?.response?.data?.message);
    });
};

const getPDAM = (param, resolve, reject, callback) => (dispatch) => {
  dispatch(actionPending(GET_DASHBOARD_RESUME_PDAM_PENDING));
  API.get(DASHBOARD_URL + "/resume_pdam", { params: param })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(GET_DASHBOARD_RESUME_PDAM_SUCCESS, res));
      if (resolve) {
        const records_total = res.data.total;
        let data = res.data.data.map((item, i) => ({
          ...item,
          no: i + 1 + (param?.page - 1) * param?.length,
          nrw: item.nrw_percentage ? `${item.nrw_percentage} %` : "0%",
        }));
        let count = 1;
        res.data.data.forEach((item, i) => {
          data.splice(i + count, 0, {
            no: "",
            name: `Debit`,
            total_input: item.total_debit_input,
            total_output: item.total_debit_output,
            total_zone: item.total_debit_zone,
            total_dma: item.total_debit_dma,
            total_customer: item.total_debit_customer,
            nrw: "",
          });
          count++;
        });
        resolve({
          data: data,
          page: param?.page - 1,
          totalCount: records_total,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(GET_DASHBOARD_RESUME_PDAM_ERROR));
      let not_logout = handleResponse(err?.response);
      if (not_logout) {
        let message = err?.response?.data?.message;
        if (message?.includes("java")) {
          message = "Data tidak ditemukan";
        }
        toastError(message);
      }
      // toastError(err?.response?.data?.message);
    });
};

const getNRWMax = (param, resolve, reject, callback) => (dispatch) => {
  dispatch(actionPending(GET_DASHBOARD_NRW_MAX_PENDING));
  API.get(DASHBOARD_URL + "/resume_nrw_dma_max", { params: param })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(GET_DASHBOARD_NRW_MAX_SUCCESS, res));
      if (resolve) {
        const records_total = res.data.total;
        let data = res.data.data.map((item, i) => ({
          ...item,
          no: i + 1 + (param?.page - 1) * param?.length,
        }));
        resolve({
          data: data,
          page: param?.page - 1,
          totalCount: records_total,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(GET_DASHBOARD_NRW_MAX_ERROR));
      let not_logout = handleResponse(err?.response);
      if (not_logout) {
        let message = err?.response?.data?.message;
        if (message?.includes("java")) {
          message = "Data tidak ditemukan";
        }
        toastError(message);
      }
      // toastError(err?.response?.data?.message);
    });
};

const getNRWMin = (param, resolve, reject, callback) => (dispatch) => {
  dispatch(actionPending(GET_DASHBOARD_NRW_MIN_PENDING));
  API.get(DASHBOARD_URL + "/resume_nrw_dma_min", { params: param })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(GET_DASHBOARD_NRW_MIN_SUCCESS, res));
      if (resolve) {
        const records_total = res.data.total;
        let data = res.data.data.map((item, i) => ({
          ...item,
          no: i + 1 + (param?.page - 1) * param?.length,
        }));
        resolve({
          data: data,
          page: param?.page - 1,
          totalCount: records_total,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(GET_DASHBOARD_NRW_MIN_ERROR));
      let not_logout = handleResponse(err?.response);
      if (not_logout) {
        let message = err?.response?.data?.message;
        if (message?.includes("java")) {
          message = "Data tidak ditemukan";
        }
        toastError(message);
      }
      // toastError(err?.response?.data?.message);
    });
};

const dashboard = {
  getAverageLogger,
  getTotalPDAM,
  getAverageTotal,
  getRepairWork,
  getPDAM,
  getNRWMax,
  getNRWMin,
  getLocationSensor,
};
export default dashboard;
